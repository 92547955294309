import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../../config/firebase";

class UploadGovtIDModal extends Component {
  state = {
    selectedFile: null,
  };
  onSubmit = async (e) => {
    e.preventDefault();
    let fileref = firebase.storage().ref("govtID/" + this.props.user.uid);
    await fileref.put(this.state.selectedFile);
    let url = await fileref.getDownloadURL();
    let db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user.uid)
      .set({ government_id: url }, { merge: true })
      .then(() => {
        // console.log("gvt id in firestore " + uploadTask);
        this.props.setGovtID(url);
        this.props.setUser({
          ...this.props.currentUser,
          government_id: url,
        });
        this.props.setUserDoc({
          ...this.props.userDoc,
          government_id: url,
        });
      })
      .catch(() => {
        // console.log("gvt id in firestore failed");
      });
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };
  render() {
    return (
      <div
        className="modal fade"
        id="uploadGovtIDModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Upload Government ID
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.onSubmit}>
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  onChange={this.onFileChange}
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSubmit}
                data-dismiss="modal"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
    setUserDoc: (doc) =>
      dispatch({
        type: "SET_USER_DOC",
        payload: {
          userDoc: doc,
        },
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    userDoc: state.user.userDoc,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadGovtIDModal);
