import React from "react";
import logo from "../../../images/Neo-Logo.png";
import "../../../css/container.css";
import "../../../css/navbar.css";
import "../../../css/linkbar.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import { withRouter } from "react-router";
import { Icon } from "semantic-ui-react";

class Navbar extends React.Component {
  state = {
    isLogged: false,
    checked: false,
  };
  unsubscribe;

  componentDidMount() {
    let signedInUser = firebase.auth().currentUser;
    if (signedInUser) {
      this.setState({ isLogged: true });
    } else {
      this.setState({ isLogged: false });
    }
  }

  componentWillUnmount() {
    // this.unsubscribe();
  }

  render() {
    return (
      <div>
        {/* style={{ borderBottom: " 1px solid rgba(70, 67, 67, 0.274)" }} */}
        <div
          className="header-container"
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          <div className="mynavbar-top">
            <Link to="/">
              <img
                src={logo}
                className="img"
                alt="logo"
                style={{ height: "4rem" }}
              />
            </Link>

            <div className="mobile-nav">
              <input
                type="checkbox"
                className="mobile-nav-checkbox"
                id="mobile-nav-toggle"
                onChange={(e) => {
                  this.setState({ checked: !this.state.checked });
                }}
              ></input>
              <div className="mobile-nav-button">
                <label htmlFor="mobile-nav-toggle">
                  <Icon
                    name={this.state.checked ? "cancel" : "bars"}
                    size="big"
                    style={
                      this.state.checked
                        ? { color: "#fff" }
                        : { color: "#00a1cc" }
                    }
                  ></Icon>
                </label>
              </div>
              <div className="mobile-nav-background"></div>
              <div className="mobile-nav-link">
                <ul>
                  <Link to="/">
                    <li>Home</li>
                  </Link>
                  <HashLink to="/#our-services-container">
                    <li>Our Services</li>
                  </HashLink>
                  <Link to="/aboutus">
                    <li>About Us</li>
                  </Link>
                  {/*<Link to="/ourmember">*/}
                  {/*  <li>Our Team</li>*/}
                  {/*</Link>*/}
                  <Link to="/contactus">
                    <li>Contact Us</li>
                  </Link>
                  {this.state.isLogged ? (
                    <span>
                      <Link to="/user">
                        <li>Profile</li>
                      </Link>
                      <div
                        style={{
                          display: "inline",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          firebase
                            .auth()
                            .signOut()
                            .then(() => {
                              // console.log("sign out");

                              this.setState({ isLogged: false });
                              this.props.history.push("/");
                              this.props.clearUser();
                            });
                        }}
                      >
                        <li>Log Out</li>
                      </div>
                    </span>
                  ) : (
                    <span>
                      <Link to="/login">
                        <li>Log In</li>
                      </Link>
                      <Link to="/register">
                        <li>Sign Up</li>
                      </Link>
                    </span>
                  )}
                </ul>
              </div>
            </div>

            <div className="mynavbar-top-primary">
              <div className="mynavbar-top-primary-item">
                <HashLink
                  to="/contactus#map"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  <i
                    aria-hidden="true"
                    className={`map marker alternate topbar icon`}
                  />
                  28 Scholars Way, Dagenham, London, RM8 2FL 
                </HashLink>
              </div>
              <div className="mynavbar-top-primary-item">
                <a
                  href="mailto:info@neolexical.com"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  <i
                    aria-hidden="true"
                    className={`envelope topbar icon`}
                    style={{ marginRight: "8px" }}
                  />
                  info@neolexical.com
                </a>
              </div>
              <div className="mynavbar-top-primary-item">
                <a
                  href="tel:07804-388-055"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  <i
                    aria-hidden="true"
                    className={`phone icon`}
                    style={{ marginRight: "8px" }}
                  />
                  07804-388-055
                </a>
              </div>
            </div>
            <div className="mynavbar-top-button">
              <HashLink
                to="/#consulting"
                className="button"
                role="button"
                aria-pressed="true"
              >
                Free consulting
              </HashLink>
            </div>
          </div>
        </div>
        <div className="linkbarcontainer">
          <div className="link-container linkbar">

            <ul>
              <Link to="/">
                <li className="link-item">
                  <b>Home</b>
                </li>
              </Link>
              {/* <Link to="#"> */}
              <li className="link-item nav-hover-item">
                <b>About</b>
                <div className="sub-nav-menu">
                  <ul>
                    <Link to="/aboutus" style={{ margin: 0, padding: 0 }}>
                      <li>About Us</li>
                    </Link>
                    {/*<Link to="/ourmember" style={{ margin: 0, padding: 0 }}>*/}
                    {/*  <li>Our Team</li>*/}
                    {/*</Link>*/}
                    <Link to="/contactus" style={{ margin: 0, padding: 0 }}>
                      <li>Contact us</li>
                    </Link>
                    <Link to="/tnc" style={{ margin: 0, padding: 0 }}>
                      <li>Terms of Business</li>
                    </Link>
                    <Link to="/complaints" style={{ margin: 0, padding: 0 }}>
                      <li>Complaints Procedure</li>
                    </Link>
                    <Link to="/pricing" style={{ margin: 0, padding: 0 }}>
                      <li>Pricing Information</li>
                    </Link>
                  </ul>
                </div>
              </li>

              <li className="link-item nav-hover-item">
                <b>Services</b>
                <div className="sub-nav-menu">
                  <ul>
                    <Link to="/single-will-info" style={{ margin: 0, padding: 0 }}>
                      <li>Single Will</li>
                    </Link>
                    <Link to="/double-will-info" style={{ margin: 0, padding: 0 }}>
                      <li>Double Will</li>
                    </Link>
                    <Link to="/financial-affairs-info" style={{ margin: 0, padding: 0 }}>
                      <li>Financial LPA</li>
                    </Link>
                    <Link to="/health-welfare-info" style={{ margin: 0, padding: 0 }}>
                      <li>Health LPA</li>
                    </Link>
                    <HashLink
                        to="/#additional-services"  style={{ margin: 0, padding: 0 }}>
                      <li>Trusts</li>
                    </HashLink>
                    <HashLink
                        to="/#additional-services"  style={{ margin: 0, padding: 0 }}>
                      <li>Probate</li>
                    </HashLink>
                    <HashLink
                        to="/#additional-services"  style={{ margin: 0, padding: 0 }}>
                      <li>Inheritance Disputes</li>
                    </HashLink>
                  </ul>
                </div>
              </li>
              {/* </Link> */}

              {this.state.isLogged ? (
                <span>
                  <Link to="/user" style={{ color: "white" }}>
                    <li className="login-btn">Profile</li>
                  </Link>
                  <div
                    style={{
                      display: "inline",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      firebase
                        .auth()
                        .signOut()
                        .then(() => {
                          // console.log("sign out");

                          this.setState({ isLogged: false });
                          this.props.history.push("/");
                          this.props.clearUser();
                        });
                    }}
                  >
                    <li className="login-btn">Log Out</li>
                  </div>
                </span>
              ) : (
                <span>
                  <Link to="/login" style={{ color: "white" }}>
                    <li className="login-btn">Log In</li>
                  </Link>
                  <Link to="/register" style={{ color: "white" }}>
                    <li className="login-btn">Sign Up</li>
                  </Link>
                </span>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearUser: () =>
      dispatch({
        type: "CLEAR_USER",
        payload: {
          currentUser: null,
        },
      }),
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
  };
};

const NavbarWithRouter = withRouter(Navbar);

export default connect(null, mapDispatchToProps)(NavbarWithRouter);
