import React, { Component } from "react";
import { connect } from "react-redux";
import { Step, Icon } from "semantic-ui-react";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import * as actionTypes from "../../../actions/types";

class FinancialAffairsSteps extends Component {
  disableWhenCanBeUsed = true;
  disableAttorney = true;
  disableMakeDecision = true;
  disableReplacementAttorney = true;
  disableReplacementAttorneysMakeDecision = true;
  disableCertificateProvider = true;
  disablePersonToNotify = true;
  disablePreferences = true;
  disableRegistration = true;
  disableCorrespondent = true;
  disableRepeat = true;
  disableSubmit = true;

  componentDidMount() {
    this.goToStep();
  }
  goToStep = () => {
    // console.log("hello");
    for (let index = 1; index <= this.props.stepNo; index++) {
      if (index === 2) {
        this.disableWhenCanBeUsed = false;
      } else if (index === 3) {
        this.disableAttorney = false;
      } else if (index === 4) {
        this.disableMakeDecision = false;
      } else if (index === 5) {
        this.disableReplacementAttorney = false;
      } else if (index === 6) {
        this.disableReplacementAttorneysMakeDecision = false;
      } else if (index === 7) {
        this.disableCertificateProvider = false;
      } else if (index === 8) {
        this.disablePersonToNotify = false;
      } else if (index === 9) {
        this.disablePreferences = false;
      } else if (index === 10) {
        this.disableRegistration = false;
      } else if (index === 11) {
        this.disableCorrespondent = false;
      } else if (index === 12) {
        this.disableRepeat = false;
      } else if (index === 13) {
        this.disableSubmit = false;
      }
    }
  };
  isMultipleAttorney = () => {
    if (this.props.attorney.length > 1) return true;
    return false;
  };
  isMultipleReplacementAttorney = () => {
    if (this.props.replacementAttorney.length > 1) return true;
    return false;
  };

  render() {
    if (this.props.disable) {
      this.disableRegistration = true;
      this.disableCorrespondent = true;
      this.disableRepeat = true;
      this.props.setFinancialLpaDisable(false);
    }
    if (this.props.currentView === FinancialAffairsStepsEnum.WHEN_CAN_BE_USED)
      this.disableWhenCanBeUsed = false;
    else if (this.props.currentView === FinancialAffairsStepsEnum.ATTORNEY)
      this.disableAttorney = false;
    else if (
      this.props.currentView ===
      FinancialAffairsStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
    )
      this.disableMakeDecision = false;
    else if (
      this.props.currentView === FinancialAffairsStepsEnum.REPLACEMENT_ATTORNEY
    )
      this.disableReplacementAttorney = false;
    else if (
      this.props.currentView ===
      FinancialAffairsStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
    )
      this.disableReplacementAttorneysMakeDecision = false;
    else if (
      this.props.currentView === FinancialAffairsStepsEnum.CERTIFICATE_PROVIDER
    )
      this.disableCertificateProvider = false;
    else if (
      this.props.currentView === FinancialAffairsStepsEnum.PERSON_TO_NOTIFY
    )
      this.disablePersonToNotify = false;
    else if (
      this.props.currentView ===
      FinancialAffairsStepsEnum.PREFERENCES_N_INSTRUCTIONS
    )
      this.disablePreferences = false;
    else if (this.props.currentView === FinancialAffairsStepsEnum.REGISTRATION)
      this.disableRegistration = false;
    else if (this.props.currentView === FinancialAffairsStepsEnum.CORRESPONDENT)
      this.disableCorrespondent = false;
    else if (this.props.currentView === FinancialAffairsStepsEnum.REPEAT)
      this.disableRepeat = false;
    else if (this.props.currentView === FinancialAffairsStepsEnum.SUBMIT)
      this.disableSubmit = false;
    return (
      <Step.Group vertical>
        <Step
          link
          className={"step-mini"}
          active={
            this.props.currentView === FinancialAffairsStepsEnum.DONOR
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(FinancialAffairsStepsEnum.DONOR)
          }
        >
          <Icon name="user" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Donor</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableWhenCanBeUsed}
          active={
            this.props.currentView ===
            FinancialAffairsStepsEnum.WHEN_CAN_BE_USED
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              FinancialAffairsStepsEnum.WHEN_CAN_BE_USED
            )
          }
        >
          <Icon name="clock" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>When can the LPA be used?</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableAttorney}
          active={
            this.props.currentView === FinancialAffairsStepsEnum.ATTORNEY
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(FinancialAffairsStepsEnum.ATTORNEY)
          }
        >
          <Icon name="handshake" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Attorney </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableMakeDecision || !this.isMultipleAttorney()}
          active={
            this.props.currentView ===
            FinancialAffairsStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              FinancialAffairsStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
            )
          }
        >
          <Icon name="exclamation" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Attorneys make decisions</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableReplacementAttorney}
          active={
            this.props.currentView ===
            FinancialAffairsStepsEnum.REPLACEMENT_ATTORNEY
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              FinancialAffairsStepsEnum.REPLACEMENT_ATTORNEY
            )
          }
        >
          <Icon name="handshake" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Replacement Attorney </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={
            this.disableReplacementAttorneysMakeDecision ||
            !this.isMultipleReplacementAttorney()
          }
          active={
            this.props.currentView ===
            FinancialAffairsStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              FinancialAffairsStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
            )
          }
        >
          <Icon name="exclamation" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Replacement attorneys make decisions</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableCertificateProvider}
          active={
            this.props.currentView ===
            FinancialAffairsStepsEnum.CERTIFICATE_PROVIDER
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              FinancialAffairsStepsEnum.CERTIFICATE_PROVIDER
            )
          }
        >
          <Icon name="file" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Certificate Provider </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disablePersonToNotify}
          active={
            this.props.currentView ===
            FinancialAffairsStepsEnum.PERSON_TO_NOTIFY
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              FinancialAffairsStepsEnum.PERSON_TO_NOTIFY
            )
          }
        >
          <Icon name="bell" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Person to Notify </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disablePreferences}
          active={
            this.props.currentView ===
            FinancialAffairsStepsEnum.PREFERENCES_N_INSTRUCTIONS
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              FinancialAffairsStepsEnum.PREFERENCES_N_INSTRUCTIONS
            )
          }
        >
          <Icon name="tasks" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Preferences & Instructions</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableRegistration}
          active={
            this.props.currentView === FinancialAffairsStepsEnum.REGISTRATION
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(FinancialAffairsStepsEnum.REGISTRATION)
          }
        >
          <Icon name="registered" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Registration</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableCorrespondent}
          active={
            this.props.currentView === FinancialAffairsStepsEnum.CORRESPONDENT
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(FinancialAffairsStepsEnum.CORRESPONDENT)
          }
        >
          <Icon name="user" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Correspondent</Step.Title>
          </Step.Content>
        </Step>
        <Step
          link
          className={"step-mini"}
          disabled={this.disableRepeat}
          active={
            this.props.currentView === FinancialAffairsStepsEnum.REPEAT
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(FinancialAffairsStepsEnum.REPEAT)
          }
        >
          <Icon name="copy" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Repeat Application</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableSubmit}
          active={
            this.props.currentView === FinancialAffairsStepsEnum.SUBMIT
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(FinancialAffairsStepsEnum.SUBMIT)
          }
        >
          <Icon name="check" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Submit</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaDisable: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_DISABLE_REG_COR,
        payload: {
          disable: data,
        },
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    attorney: state.financial_lpa.attorney,
    replacementAttorney: state.financial_lpa.replacementAttorney,
    disable: state.financial_lpa.disable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialAffairsSteps);
