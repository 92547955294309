import React from "react";
import firebase from "../../config/firebase";

import "../../css/consulting.css";

class Consulting extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    message: "",
    msgSent: null,
    error: "",
  };

  sendContactMessage = async (e) => {
    e.preventDefault();
    const { name, email, phone, message } = this.state;
    const data = { name, email, phone, message };
    // console.log(data);
    var phoneno = /^\+?([0-9]+)$/;
    // // console.log(this.state.phoneNumber.match(phoneno));
    if (this.state.phone.match(phoneno) === null) {
      this.setState({
        error:
          "Please give a valid phone number ( e.g. +4407804388055 or 07804388055 . Do not use any space, dot or other special characters)",
      });
      return;
    } else {
      this.setState({ error: "" });
    }
    if (
      message != null &&
      message.length > 0 &&
      email != null &&
      email.length > 0
    ) {
      try {
        const addMessage = firebase
          .app()
          .functions("europe-west2")
          .httpsCallable("handleWebsiteContactUsMessage");

        this.setState({
          msgSent: true,
        });

        await addMessage(data);
      } catch (error) {
        this.setState({
          msgSent: false,
        });
        console.error(error);
      }
    } else {
      // console.log("Invalid input!");
    }
  };

  render() {
    return (
      <div>
        <div
          className="section-container "
          style={{
            borderBottom: "0.14rem dotted rgba(0, 0, 0, 0.561)",
          }}
          id="consulting"
        >
          <div className="row">
            <div className="col-md-5 consulting-subsection">
              {/* <div className="consulting-subsection"> */}
              <h2 className="section-subtitle-center">Free Consulting</h2>
              <div className="section-emphasize section-paragraph-big-center">
                Call us for an Appointment
                <div>
                  <span className="phone-button">
                    <a href="tel:+447804388055">
                      <i aria-hidden="true" className={`call icon  `}></i> +44
                      78 0438 8055
                    </a>
                  </span>
                </div>
                or fill out the form
              </div>
            </div>
            <div className="col-md-6 offset-md-1">
              {/* <div className="consulting-form-section"> */}
              <form onSubmit={this.sendContactMessage}>
                <div className="row form-group">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      placeholder="Name"
                    ></input>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-6">
                    <input
                      type="email"
                      className="form-control"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="Email"
                      required
                    ></input>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.phone}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      placeholder="Phone"
                    ></input>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Your Message"
                    onChange={(e) => this.setState({ message: e.target.value })}
                    required
                  ></textarea>
                </div>

                {this.state.msgSent == null && (
                  <button
                    type="submit"
                    className="btn btn-outline-primary appointment-btn px-5"
                  >
                    Send
                  </button>
                )}

                

                {this.state.msgSent != null && this.state.msgSent && (
                  <div className="alert alert-success alert-dismissible fade show">
                    <strong>Success!</strong> Your message has been sent
                    successfully.
                    <button
                      type="button"
                      class="close"
                      onClick={(e) => this.setState({ msgSent: null })}
                    >
                      &times;
                    </button>
                  </div>
                )}

                {this.state.msgSent != null && !this.state.msgSent && (
                  <div class="alert alert-danger alert-dismissible fade show">
                    <strong>Error!</strong>
                    Failed to send your message. Please try again!
                    <button
                      type="button"
                      class="close"
                      onClick={(e) => this.setState({ msgSent: null })}
                    >
                      &times;
                    </button>
                  </div>
                )}
              </form>

              
            </div>

            <div
              style={{
                fontSize: "0.5rem",
                fontStyle: "italic",
                fontWeight: "bold",
                marginTop: "4rem",
                marginLeft: "2rem",
                marginRight: "2rem",
                textAlign: "right",
                width: '100%'
              }}
            >
              Please note if your enquiry is made outside office hours, we aim to contact you on the next available business day the office is open.
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Consulting;
