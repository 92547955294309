import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import * as Lists from "../Lists";
import * as actionTypes from "../../../actions/types";
import * as HealthAndWelfareStepsEnum from "./HealthAndWelfareStepsEnum";
import firebase from "../../../config/firebase";

class Correspondent extends Component {
  state = {
    selected: "",
    person: {
      title: "",
      first_name: "",
      last_name: "",

      address: {
        street_or_house_no: "",
        city: "",
        postal_code: "",
        country: "",
      },
      email: "",
    },
    contactMethodPost: false,
    contactMethodPhone: false,
    contactMethodEmail: false,
    contactEmail: "",
    contactPhone: "",
    contactPost: "",
  };

  countryList = Lists.countryList;
  titleList = Lists.titleList;
  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.correspondent !== null) {
      if (this.props.correspondent.selected === "manually") {
        this.setState({
          selected: "manually",
          person: this.props.correspondent.person,

          contactMethodEmail: this.props.correspondent.contactMethodEmail,
          contactMethodPhone: this.props.correspondent.contactMethodPhone,
          contactMethodPost: this.props.correspondent.contactMethodPost,
          contactEmail: this.props.correspondent.contactEmail,
          contactPhone: this.props.correspondent.contactPhone,
          contactPost: this.props.correspondent.contactPost,
        });
      } else
        this.setState({
          selected: this.props.correspondent.selected,

          contactMethodEmail: this.props.correspondent.contactMethodEmail,
          contactMethodPhone: this.props.correspondent.contactMethodPhone,
          contactMethodPost: this.props.correspondent.contactMethodPost,
          contactEmail: this.props.correspondent.contactEmail,
          contactPhone: this.props.correspondent.contactPhone,
          contactPost: this.props.correspondent.contactPost,
        });
    } else {
      this.setState({
        selected: "donor",
        contactEmail: this.props.donor.email,
        contactMethodEmail: true,
      });
    }
  }

  getAttorneyName = (index) => {
    let item = this.props.attorney[index];
    return item.title === undefined
      ? item.company_name
      : item.title + " " + item.first_name + " " + item.last_name;
  };
  getReplacementAttorneyName = (index) => {
    let item = this.props.replacementAttorney[index];
    return item.title === undefined
      ? item.company_name
      : item.title + " " + item.first_name + " " + item.last_name;
  };

  getAttorneyList = () => {
    if (this.props.attorney !== null || this.props.attorney !== undefined)
      return this.props.attorney.map((item, index) =>
        item.reg_no === "12608981" ? (
          <span></span>
        ) : (
          <div style={{ position: "relative", marginTop: "2rem" }}>
            <input
              type="radio"
              className=""
              id={"attorney" + index}
              placeholder="First Name"
              checked={this.state.selected === "attorney" + index ? 1 : 0}
              onChange={() => {
                this.setState({
                  selected: "attorney" + index,
                  contactEmail: this.props.attorney[index].email || "",
                });
              }}
              style={{
                width: "2rem",
                height: "2rem",
                display: "inline-block",
              }}
              name="selected"
            />
            <label
              htmlFor={"attorney" + index}
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              {this.getAttorneyName(index)} (Primary Attorney)
            </label>
          </div>
        )
      );
    else return <span></span>;
  };

  getReplacementAttorneyList = () => {
    if (
      this.props.replacementAttorney !== null ||
      this.props.replacementAttorney !== undefined
    )
      return this.props.replacementAttorney.map((item, index) => (
        <div style={{ position: "relative", marginTop: "2rem" }}>
          <input
            type="radio"
            className=""
            id={"replacementAttorney" + index}
            placeholder="First Name"
            checked={
              this.state.selected === "replacementAttorney" + index ? 1 : 0
            }
            onChange={() => {
              this.setState({
                selected: "replacementAttorney" + index,
                contactEmail: this.props.replacementAttorney[index].email || "",
              });
            }}
            style={{
              width: "2rem",
              height: "2rem",
              display: "inline-block",
            }}
            name="selected"
          />
          <label
            htmlFor={"replacementAttorney" + index}
            // className="col-form-label col-form-label required-field"
            style={{
              // fontWeight: "bold",
              fontSize: "1.4rem",
              position: "absolute",
              top: "15%",
              paddingLeft: "1rem",
              display: "inline-block",
              marginBottom: "0",
            }}
          >
            {this.getReplacementAttorneyName(index)} (Replacement Attorney)
          </label>
        </div>
      ));
    else return <span></span>;
  };

  getCertificateProvider = () => {
    if (
      this.props.certificateProvider !== null ||
      this.props.certificateProvider !== undefined
    )
      return (
        <div style={{ position: "relative", marginTop: "2rem" }}>
          <input
            type="radio"
            className=""
            id={"CertifiacteProvider"}
            placeholder="First Name"
            checked={this.state.selected === "CertifiacteProvider" ? 1 : 0}
            onChange={() => {
              this.setState({
                selected: "CertifiacteProvider",
                contactEmail: "",
              });
            }}
            style={{
              width: "2rem",
              height: "2rem",
              display: "inline-block",
            }}
            name="selected"
          />
          <label
            htmlFor={"CertifiacteProvider"}
            // className="col-form-label col-form-label required-field"
            style={{
              // fontWeight: "bold",
              fontSize: "1.4rem",
              position: "absolute",
              top: "15%",
              paddingLeft: "1rem",
              display: "inline-block",
              marginBottom: "0",
            }}
          >
            {this.props.certificateProvider.title +
              " " +
              this.props.certificateProvider.first_name +
              " " +
              this.props.certificateProvider.last_name}{" "}
            (Certifiacte Provider)
          </label>
        </div>
      );
  };

  onChangeHandler = (e) => {
    if (e.target.name === "street_or_house_no") {
      let person = {
        ...this.state.person,
      };
      person.address["street_or_house_no"] = e.target.value;

      this.setState({ person: person });
    } else if (e.target.name === "city") {
      let person = {
        ...this.state.person,
      };
      person.address["city"] = e.target.value;

      this.setState({ person: person });
    } else if (e.target.name === "postal_code") {
      let person = {
        ...this.state.person,
      };
      person.address["postal_code"] = e.target.value;

      this.setState({ person: person });
    } else if (e.target.name === "country") {
      let person = {
        ...this.state.person,
      };
      person.address["country"] = e.target.value;

      this.setState({ person: person });
    } else {
      let person = {
        ...this.state.person,
      };
      person[e.target.name] = e.target.value;
      this.setState({ person: person });
      if (e.target.name === "email")
        this.setState({ contactEmail: e.target.value });
    }
  };

  dbValidAction = (data) => {
    let db = firebase.firestore();
    let healthLpaCorrespondent = {
      ...data,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    db.collection("healthLpaCorrespondentInfo")
      .doc(this.props.currentUser.uid)
      .set(healthLpaCorrespondent)
      .then(() => {
        // console.log("HealthLpaCorrespondent in firestore");
      })
      .catch(() => {
        // console.log("HealthLpaCorrespondent in firestore failed");
      });
  };

  onSubmitHandle = (e) => {
    e.preventDefault();

    if (this.state.selected === "") {
      return;
    }

    let data;

    if (this.state.selected !== "manually") {
      if (this.state.selected === "donor") {
        data = {
          selected: "donor",
          person: this.props.donor,

          contactMethodEmail: this.state.contactMethodEmail,
          contactMethodPhone: this.state.contactMethodPhone,
          contactMethodPost: this.state.contactMethodPost,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone,
          contactPost: this.state.contactPost,
        };
        this.props.setHealthLpaCorrespondent(data);
        this.dbValidAction(data);
      } else if (this.state.selected === "neolexical") {
        data = {
          selected: this.state.selected,

          contactMethodEmail: this.state.contactMethodEmail,
          contactMethodPhone: this.state.contactMethodPhone,
          contactMethodPost: this.state.contactMethodPost,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone,
          contactPost: this.state.contactPost,
        };
        this.props.setHealthLpaCorrespondent(data);
        this.dbValidAction(data);
      } else if (this.state.selected === "certificateProvider") {
        data = {
          selected: "certificateProvider",
          person: this.props.certificateProvider,

          contactMethodEmail: this.state.contactMethodEmail,
          contactMethodPhone: this.state.contactMethodPhone,
          contactMethodPost: this.state.contactMethodPost,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone,
          contactPost: this.state.contactPost,
        };
        this.props.setHealthLpaCorrespondent(data);
        this.dbValidAction(data);
      } else if (this.state.selected.substr(0, 7) === "attorne") {
        let index = parseInt(this.state.selected.substr(8));
        data = {
          selected: this.state.selected,
          person: this.props.attorney[index],

          contactMethodEmail: this.state.contactMethodEmail,
          contactMethodPhone: this.state.contactMethodPhone,
          contactMethodPost: this.state.contactMethodPost,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone,
          contactPost: this.state.contactPost,
        };
        this.props.setHealthLpaCorrespondent(data);
        this.dbValidAction(data);
      } else if (this.state.selected.includes("replacement")) {
        let index = parseInt(this.state.selected.substr(19));
        data = {
          selected: this.state.selected,
          person: this.props.replacementAttorney[index],

          contactMethodEmail: this.state.contactMethodEmail,
          contactMethodPhone: this.state.contactMethodPhone,
          contactMethodPost: this.state.contactMethodPost,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone,
          contactPost: this.state.contactPost,
        };
        this.props.setHealthLpaCorrespondent(data);
        this.dbValidAction(data);
      }
    } else {
      data = {
        selected: "manually",
        person: this.state.person,

        contactMethodEmail: this.state.contactMethodEmail,
        contactMethodPhone: this.state.contactMethodPhone,
        contactMethodPost: this.state.contactMethodPost,
        contactEmail: this.state.contactEmail,
        contactPhone: this.state.contactPhone,
        contactPost: this.state.contactPost,
      };
      this.props.setHealthLpaCorrespondent(data);
      this.dbValidAction(data);
    }
    this.props.setCurrentView(HealthAndWelfareStepsEnum.REPEAT);
  };

  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Where should we send the registered LPA and any correspondence?{" "}
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
            position: "relative",
          }}
        >
          <p>
            The person below will be the ‘correspondent’. We’ll send them the
            registered LPA and contact them if we have any questions about the
            LPA application.
          </p>
          <p>
            If you want someone else to be the correspondent, change the details
            below.
          </p>
        </div>
        <Divider />
        <form onSubmit={this.onSubmitHandle}>
          <h2>The correspondent</h2>
          <div style={{ position: "relative", marginTop: "2rem" }}>
            <input
              type="radio"
              className=""
              id="donor"
              placeholder="First Name"
              checked={this.state.selected === "donor" ? 1 : 0}
              onChange={() => {
                this.setState({
                  selected: "donor",
                  contactEmail: this.props.donor.email,
                });
              }}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="selected"
            />
            <label
              htmlFor="donor"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              {this.props.donor.title +
                " " +
                this.props.donor.first_name +
                " " +
                this.props.donor.last_name}{" "}
              (Donor)
            </label>
          </div>

          <div style={{ position: "relative", marginTop: "2rem" }}>
            <input
              type="radio"
              className=""
              id="neolexical"
              placeholder="First Name"
              checked={this.state.selected === "neolexical" ? 1 : 0}
              onChange={() => {
                this.setState({
                  selected: "neolexical",
                  contactEmail: "info@neolexical.com",
                });
              }}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="selected"
            />
            <label
              htmlFor="neolexical"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              Safekeeping with Neolexical
            </label>
          </div>

          {this.getAttorneyList()}

          {/* {this.getReplacementAttorneyList()}

          {this.getCertificateProvider()} */}

          <div style={{ position: "relative", marginTop: "2rem" }}>
            <input
              type="radio"
              className=""
              id="manually"
              placeholder="First Name"
              checked={this.state.selected === "manually" ? 1 : 0}
              onChange={() => {
                this.setState({ selected: "manually" });
              }}
              style={{
                width: "2rem",
                height: "2rem",
                display: "inline-block",
              }}
              name="selected"
            />
            <label
              htmlFor="manually"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              Manually
            </label>
          </div>
          {this.state.selected === "manually" ? (
            <div>
              <label
                htmlFor="title"
                className="col-form-label col-form-label required-field"
                style={{ fontWeight: "bold" }}
              >
                Title
              </label>
              <select
                id="title"
                className="form-control"
                value={this.state.person.title}
                onChange={this.onChangeHandler}
                name="title"
                required
              >
                <option value="" disabled hidden>
                  Title
                </option>
                {this.titleList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>

              <label
                htmlFor="firstName"
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                onChange={this.onChangeHandler}
                value={this.state.person.first_name}
                name="first_name"
                required
              />

              <label
                htmlFor="lastname"
                className="col-form-label col-form-label  required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Last Name
              </label>

              <input
                type="text"
                className="form-control"
                id="lastname"
                placeholder="Last Name"
                onChange={this.onChangeHandler}
                value={this.state.person.last_name}
                name="last_name"
                required
              />

              <label
                htmlFor="address"
                className="required-field col-form-label col-form-label"
                style={{
                  marginTop: "2.8rem",
                  marginBottom: "0.5rem",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                Address:
              </label>

              {/* <textarea
            type="textarea"
            className="form-control"
            id="address"
            placeholder="Address"
            onChange={this.onChangeHandler}
            value={this.state.person.address}
            name="address"
            required
          /> */}
              <div id="address" style={{ marginLeft: "0rem" }}>
                <label
                  htmlFor="streetOrHouseNo"
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0rem", fontWeight: "bold" }}
                >
                  Street/House No.
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="streetOrHouseNo"
                  placeholder="Street/House No"
                  onChange={this.onChangeHandler}
                  required
                  value={this.state.person.address.street_or_house_no || ""}
                  name="street_or_house_no"
                />

                <label
                  htmlFor="city"
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  City
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="City"
                  onChange={this.onChangeHandler}
                  required
                  value={this.state.person.address.city || ""}
                  name="city"
                />

                <label
                  htmlFor="postalCode"
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  Postal Code
                </label>
                {/* <div>
                  {this.state.person.error_postal_code === "" ? (
                    <span></span>
                  ) : (
                    <span style={{ color: "red" }}>
                      {this.state.person.error_postal_code}
                    </span>
                  )}
                </div> */}

                <input
                  type="text"
                  className="form-control"
                  id="postalCode"
                  placeholder="Postal Code"
                  onChange={this.onChangeHandler}
                  required
                  value={this.state.person.address.postal_code || ""}
                  name="postal_code"
                />

                <label
                  htmlFor="country"
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  Country
                </label>

                <select
                  id="country"
                  className="form-control"
                  value={this.state.person.address.country}
                  onChange={this.onChangeHandler}
                  name="country"
                  required
                >
                  <option value="" disabled hidden>
                    Country
                  </option>
                  {this.countryList.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <label
                htmlFor="email"
                className="required-field col-form-label col-form-label"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Email
              </label>

              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="email"
                onChange={this.onChangeHandler}
                value={this.state.person.email}
                name="email"
                required
              />
            </div>
          ) : (
            <span></span>
          )}

          {this.state.selected === "neolexical" ? (
            <span></span>
          ) : (
            <div>
              <Divider />
              <h2>How would the person above prefer to be contacted?</h2>
              <span
                style={{
                  fontSize: "1.3rem",
                  display: "block",
                  margin: "2rem 0rem",
                }}
              >
                Select how the correspondent would like to be contacted. You can
                choose more than one option
              </span>

              <div style={{ position: "relative" }}>
                <input
                  type="checkbox"
                  className=""
                  id={"post"}
                  placeholder="First Name"
                  checked={this.state.contactMethodPost}
                  onChange={(e) => {
                    this.setState({
                      contactMethodPost: !this.state.contactMethodPost,
                    });
                  }}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    display: "inline-block",
                  }}
                  name="first_name"
                />
                <label
                  htmlFor={"post"}
                  // className="col-form-label col-form-label required-field"
                  style={{
                    // fontWeight: "bold",
                    fontSize: "1.4rem",
                    position: "absolute",
                    top: "15%",
                    paddingLeft: "1rem",
                    display: "inline-block",
                    marginBottom: "0",
                  }}
                >
                  Post
                </label>
              </div>
              {this.state.contactMethodPost ? (
                <input
                  type="text"
                  className="form-control"
                  id="contactPost"
                  placeholder="Mailing Address"
                  onChange={(e) =>
                    this.setState({ contactPost: e.target.value })
                  }
                  required
                  value={this.state.contactPost || ""}
                  name="contactPost"
                  style={{ marginTop: "1rem", marginBottom: "2rem" }}
                />
              ) : (
                <span></span>
              )}
              <div style={{ position: "relative" }}>
                <input
                  type="checkbox"
                  className=""
                  id={"contactPhone"}
                  placeholder="First Name"
                  checked={this.state.contactMethodPhone}
                  onChange={(e) => {
                    this.setState({
                      contactMethodPhone: !this.state.contactMethodPhone,
                    });
                  }}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    display: "inline-block",
                  }}
                  name="first_name"
                />
                <label
                  htmlFor={"contactPhone"}
                  // className="col-form-label col-form-label required-field"
                  style={{
                    // fontWeight: "bold",
                    fontSize: "1.4rem",
                    position: "absolute",
                    top: "15%",
                    paddingLeft: "1rem",
                    display: "inline-block",
                    marginBottom: "0",
                  }}
                >
                  Phone
                </label>
              </div>
              {this.state.contactMethodPhone ? (
                <input
                  type="text"
                  className="form-control"
                  id="contactPhone"
                  placeholder="Phone No"
                  onChange={(e) =>
                    this.setState({ contactPhone: e.target.value })
                  }
                  required
                  value={this.state.contactPhone || ""}
                  name="contactPhone"
                  style={{ marginTop: "1rem", marginBottom: "2rem" }}
                />
              ) : (
                <span></span>
              )}
              <div style={{ position: "relative" }}>
                <input
                  type="checkbox"
                  className=""
                  id={"contactEmail"}
                  placeholder="First Name"
                  checked={this.state.contactMethodEmail}
                  onChange={(e) => {
                    this.setState({
                      contactMethodEmail: !this.state.contactMethodEmail,
                    });
                  }}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    display: "inline-block",
                  }}
                  name="first_name"
                />
                <label
                  htmlFor={"contactEmail"}
                  // className="col-form-label col-form-label required-field"
                  style={{
                    // fontWeight: "bold",
                    fontSize: "1.4rem",
                    position: "absolute",
                    top: "15%",
                    paddingLeft: "1rem",
                    display: "inline-block",
                    marginBottom: "0",
                  }}
                >
                  Email
                </label>
              </div>
              {this.state.contactMethodEmail ? (
                <input
                  style={{ marginTop: "1rem", marginBottom: "3rem" }}
                  type="email"
                  className="form-control"
                  id="contactEmail"
                  placeholder="Email"
                  onChange={(e) => {
                    this.setState({ contactEmail: e.target.value });
                  }}
                  required
                  value={this.state.contactEmail || ""}
                  name="contactEmail"
                />
              ) : (
                <span></span>
              )}
            </div>
          )}

          <button className=" will-next-button">Save And Proceed</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHealthLpaCorrespondent: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_CORRESPONDENT,
        payload: {
          correspondent: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    donor: state.health_lpa.donor,
    attorney: state.health_lpa.attorney,
    replacementAttorney: state.health_lpa.replacementAttorney,
    certificateProvider: state.health_lpa.certificateProvider,
    correspondent: state.health_lpa.correspondent,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Correspondent);
