import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../footer/Footer";
import Header from "../../landingPage/header/Header";
import FinancialAffairsBody from "./FinancialAffairsBody";
import firebase from "../../../config/firebase";
import { Dimmer, Loader } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";

export class FinancialAffairs extends Component {
  state = {
    visibleFooter: "float-button",
    currentView: "info",
    stepNo: null,
    isLogged: false,
    loadingUser: true,
    dataLoading: true,
  };

  readData = async (uid) => {
    let db = firebase.firestore();
    let stepNo = 0;
    //reading donor info..........
    let donorRef = db.collection("financialLpaDonorInfo").doc(uid);
    let donorDoc = await donorRef.get();
    if (!donorDoc.exists) {
      // console.log("No such donorDocument!");
    } else {
      stepNo = 1;
      this.props.setFinancialLpaDonor({
        ...donorDoc.data(),
        date_of_birth: donorDoc
          .data()
          .date_of_birth.toDate()
          .toLocaleDateString("fr-CA"),
      });
    }

    //reading when can be used info..........
    let whenCanBeUsedRef = db.collection("financialLpaWhenCanBeUsed").doc(uid);
    let whenCanBeUsedDoc = await whenCanBeUsedRef.get();
    if (!whenCanBeUsedDoc.exists) {
      // console.log("No such whenCanBeUsedDoc document!");
    } else {
      stepNo = 2;
      let data = whenCanBeUsedDoc.data().when_can_be_used;
      // console.log(data);
      this.props.setFinancialLpaWhenCanBeUsed(data);
    }

    //reading Attorney info............
    let attorneyRef = db.collection("financialLpaAttorneyInfo").doc(uid);
    let attorneyDoc = await attorneyRef.get();
    if (!attorneyDoc.exists) {
      // console.log("No such attorneyDoc document!");
    } else {
      stepNo = 3;
      let data = attorneyDoc.data().attorney;
      // console.log(data);
      this.props.setFinancialLpaAttorney(data);
    }

    //reading Attorney Make Decision...............
    let attorneyMakeDecisionRef = db
      .collection("financialLpaAttorneyMakeDecision")
      .doc(uid);
    let attorneyMakeDecisionDoc = await attorneyMakeDecisionRef.get();
    if (!attorneyMakeDecisionDoc.exists) {
      // console.log("No such attorneyMakeDecisionDoc!");
    } else {
      stepNo = 4;
      let data = {
        selected: attorneyMakeDecisionDoc.data().attorney_make_decision,
        details: attorneyMakeDecisionDoc.data().details,
      };
      // // console.log(data);
      this.props.setFinancialLpaAttorneysMakeDecision(data);
    }

    //reading replacement Attorney info............
    let replacementAttorneyRef = db
      .collection("financialLpaReplacementAttorneyInfo")
      .doc(uid);
    let replacementAttorneyDoc = await replacementAttorneyRef.get();
    if (!replacementAttorneyDoc.exists) {
      // console.log("No such attorneyDoc document!");
    } else {
      stepNo = 5;
      let data = replacementAttorneyDoc.data().replacementAttorney;
      // console.log(data);
      this.props.setFinancialLpaReplacementAttorney(data);
    }

    //reading Replacement Attorney Make Decision...............
    let replacementAttorneyMakeDecisionRef = db
      .collection("financialLpaReplacementAttorneyMakeDecision")
      .doc(uid);
    let replacementAttorneyMakeDecisionDoc = await replacementAttorneyMakeDecisionRef.get();
    if (!replacementAttorneyMakeDecisionDoc.exists) {
      // console.log("No such replacementAttorneyMakeDecisionDoc!");
    } else {
      stepNo = 6;
      let data = {
        selected: replacementAttorneyMakeDecisionDoc.data()
          .replacement_attorney_make_decision,
        details: replacementAttorneyMakeDecisionDoc.data().details,
      };
      // // console.log(data);
      this.props.setFinancialLpaReplacemnetAttorneysMakeDecision(data);
    }

    // reading certificate provider info.............
    let certificateProviderRef = db
      .collection("financialLpaCertificateProviderInfo")
      .doc(uid);
    let certificateProviderDoc = await certificateProviderRef.get();
    if (!certificateProviderDoc.exists) {
      // console.log("No such certificateProviderDocument!");
    } else {
      stepNo = 7;
      this.props.setFinancialLpaCertificateProvider({
        ...certificateProviderDoc.data(),
      });
    }

    //reading person to notify................

    let personToNotifyRef = db
      .collection("financialLpaPersonToNotifyInfo")
      .doc(uid);
    let personToNotifyDoc = await personToNotifyRef.get();
    if (!personToNotifyDoc.exists) {
      // console.log("No such attorneyDoc document!");
    } else {
      stepNo = 8;
      let data = personToNotifyDoc.data().person_to_notify;
      // console.log(data);
      this.props.setFinancialLpaPersonToNotify(data);
    }

    //reading preferences and instructions...........
    let preferencesAndInstructionsRef = db
      .collection("financialLpaPreferencesAndInstructions")
      .doc(uid);
    let preferencesAndInstructionsDoc = await preferencesAndInstructionsRef.get();
    if (!preferencesAndInstructionsDoc.exists) {
      // console.log("No such preferencesAndInstructionsDoc!");
    } else {
      stepNo = 9;
      let data = {
        preferences: preferencesAndInstructionsDoc.data().preferences,
        instructions: preferencesAndInstructionsDoc.data().instructions,
      };
      // // console.log(data);
      this.props.setFinancialLpaPreferencesAndInstructions(data);
    }

    //reading registration info
    let registrationRef = db
      .collection("financialLpaRegistrationInfo")
      .doc(uid);
    let registrationDoc = await registrationRef.get();
    if (!registrationDoc.exists) {
      // console.log("No such registrationDoc!");
    } else {
      stepNo = 10;
      let data = {
        selected: registrationDoc.data().selected,
        attorney: registrationDoc.data().attorney,
      };
      // // console.log(data);
      this.props.setFinancialLpaRegistration(data);
    }

    //reading correspondent info
    let correspondentRef = db
      .collection("financialLpaCorrespondentInfo")
      .doc(uid);
    let correspondentDoc = await correspondentRef.get();
    if (!correspondentDoc.exists) {
      // console.log("No such correspondentDoc!");
    } else {
      stepNo = 11;
      this.props.setFinancialLpaCorrespondent(correspondentDoc.data());
    }

    //reading repeat info
    let repeatRef = db.collection("financialLpaRepeatInfo").doc(uid);
    let repeatDoc = await repeatRef.get();
    if (!repeatDoc.exists) {
      // console.log("No such repeatDoc document!");

      this.props.setFinancialLpaStep(stepNo);
      this.setState({ dataLoading: false });
    } else {
      stepNo = 12;
      let data = repeatDoc.data().case;
      // console.log(data);
      this.props.setFinancialLpaRepeat(data);
      this.props.setFinancialLpaStep(stepNo);
      this.setState({ dataLoading: false });
    }
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const db = firebase.firestore();
    var user = { ...firebase.auth().currentUser };
    // console.log(user.providerData);
    if (user !== null && user !== undefined && user.providerData !== undefined)
      user.providerData.forEach((item) => {
        if (item.providerId === "facebook.com") user.emailVerified = true;
      });
    if (user === null || !user.emailVerified) {
      this.props.history.push("/login");
    } else if (firebase.auth().currentUser !== null) {
      const userSnapshot = await db
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .get();
      // this.props.setUser({ currentUser: userSnapshot.data() });
      if (
        userSnapshot.exists &&
        userSnapshot.data()["financial_lpa_id"] !== null &&
        userSnapshot.data()["financial_lpa_id"] !== undefined &&
        userSnapshot.data()["financial_lpa_id"].length > 0
      ) {
        this.setState({ loadingUser: false });
        this.readData(firebase.auth().currentUser.uid);
      } else {
        this.props.history.push("/financial-affairs-info");
      }
    } else {
      this.props.history.push("/login");
    }
  }
  render() {
    if (this.state.loadingUser || this.state.dataLoading === true) {
      window.scrollTo(0, 0);
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    } else
      return (
        <div style={{ height: "100vh" }}>
          <Header />
          <FinancialAffairsBody stepNo={this.props.stepNo} />
          <Footer />
        </div>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaDonor: (donor) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_DONOR,
        payload: {
          donor: donor,
        },
      }),
    setFinancialLpaWhenCanBeUsed: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_WHEN_CAN_BE_USED,
        payload: {
          whenCanBeUsed: data,
        },
      }),
    setFinancialLpaAttorney: (attorney) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_ATTORNEY,
        payload: {
          attorney: attorney,
        },
      }),
    setFinancialLpaAttorneysMakeDecision: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_ATTORNEYS_MAKE_DECISION,
        payload: {
          attorneysMakeDecision: data,
        },
      }),
    setFinancialLpaReplacementAttorney: (replacementAttorney) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEY,
        payload: {
          replacementAttorney: replacementAttorney,
        },
      }),
    setFinancialLpaReplacemnetAttorneysMakeDecision: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION,
        payload: {
          replacementAttorneysMakeDecision: data,
        },
      }),
    setFinancialLpaCertificateProvider: (certificateProvider) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_CERTIFICATE_PROVIDER,
        payload: {
          certificateProvider: certificateProvider,
        },
      }),
    setFinancialLpaPersonToNotify: (personToNotify) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_PERSON_TO_NOTIFY,
        payload: {
          personToNotify: personToNotify,
        },
      }),
    setFinancialLpaPreferencesAndInstructions: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_PREFERENCES_AND_INSTRUCTIONS,
        payload: {
          preferencesAndInstructions: data,
        },
      }),
    setFinancialLpaRegistration: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_REGISTRATION,
        payload: {
          registration: data,
        },
      }),
    setFinancialLpaCorrespondent: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_CORRESPONDENT,
        payload: {
          correspondent: data,
        },
      }),
    setFinancialLpaRepeat: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_REPEAT,
        payload: {
          repeat: data,
        },
      }),
    setFinancialLpaStep: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_STEP,
        payload: {
          stepNo: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    donor: state.financial_lpa.donor,
    currentUser: state.user.currentUser,
    stepNo: state.financial_lpa.stepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialAffairs);
