import React, { Component } from "react";
import Modal from "./Modal";
import * as actionTypes from "../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import { Divider } from "semantic-ui-react";
import * as PartnerHelpTextList from "../PartnerHelpTextList";

class GeneralConcernInfo extends Component {
  state = {
    losing_mental_health: false,
    losing_home: false,
    bankruptcy: false,
    divorce: false,
    paying_inheritance_tax: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.partnerStepNo < 6) this.props.setPartnerStepNo(6);
    if (this.props.generalConcernInfo !== null) {
      this.setState(this.props.generalConcernInfo);
    }
  }

  onClickYes = (e) => {
    this.setState({ [e.target.name]: true });
  };
  onClickNo = (e) => {
    // console.log(e.target.name);
    this.setState({ [e.target.name]: false });
    // this.setState({ needGuardian: false });
    // this.props.setCurrentView("liquidAssetInfo");
  };
  onClickNext = () => {
    let db = firebase.firestore();
    let generalConcernInfo = {
      ...this.state,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    db.collection("partnerGeneralConcernInfo")
      .doc(this.props.currentUser.uid)
      .set(generalConcernInfo)
      .then(() => {
        // console.log("general concern info in firestore");
      })
      .catch(() => {
        // console.log("general concern info in firestore failed");
      });
    this.props.setPartnerGeneralConcernInfo(this.state);
    this.props.setCurrentView("giftInfo");
  };

  onClickSame = () => {
    if (this.props.userGeneralConcernInfo !== null) {
      let db = firebase.firestore();
      let generalConcernInfo = {
        ...this.props.userGeneralConcernInfo,
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };
      db.collection("partnerGeneralConcernInfo")
        .doc(this.props.currentUser.uid)
        .set(generalConcernInfo)
        .then(() => {
          // console.log("general concern info in firestore");
        })
        .catch(() => {
          // console.log("general concern info in firestore failed");
        });
      this.props.setPartnerGeneralConcernInfo({
        ...this.props.userGeneralConcernInfo,
      });
      this.props.setCurrentView("giftInfo");
    }
  };

  render() {
    return (
      <div className="">
        <div style={{ textAlign: "center", marginBottom: "3rem" }}>
          <h1>General Concern</h1>

          <div style={{ width: "100%", textAlign: "center" }}>
            <button
              className="will-button blue-will-button"
              style={{
                width: "70%",
                letterSpacing: "0.1rem",
                wordSpacing: ".2rem",
                fontSize: "1.2rem",
              }}
              onClick={this.onClickSame}
            >
              Same as Partner
            </button>
          </div>
          <Divider horizontal>Or</Divider>
        </div>
        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: "0.7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Loss of your mental health?
            </span>
            <span>
              <button
                className={
                  this.state.losing_mental_health
                    ? "will-button-selected"
                    : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="losing_mental_health"
              >
                Yes
              </button>
              <button
                className={
                  this.state.losing_mental_health
                    ? "will-button"
                    : "will-button-selected"
                }
                name="losing_mental_health"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: ".7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Being unable to cover care home fees?
            </span>
            <span>
              <button
                className={
                  this.state.losing_home
                    ? "will-button-selected"
                    : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="losing_home"
              >
                Yes
              </button>
              <button
                className={
                  this.state.losing_home
                    ? "will-button"
                    : "will-button-selected"
                }
                name="losing_home"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: ".7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Protecting your assets in case of divorce?
            </span>
            <span>
              <button
                className={
                  this.state.divorce ? "will-button-selected" : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="divorce"
              >
                Yes
              </button>
              <button
                className={
                  this.state.divorce ? "will-button" : "will-button-selected"
                }
                name="divorce"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: "0.7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Protecting your assets in case of bankruptcy?
            </span>
            <span>
              <button
                className={
                  this.state.bankruptcy ? "will-button-selected" : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="bankruptcy"
              >
                Yes
              </button>
              <button
                className={
                  this.state.bankruptcy ? "will-button" : "will-button-selected"
                }
                name="bankruptcy"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: "0.7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Your Beneficiaries having to pay inheritance tax?
            </span>
            <span>
              <button
                className={
                  this.state.paying_inheritance_tax
                    ? "will-button-selected"
                    : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="paying_inheritance_tax"
              >
                Yes
              </button>
              <button
                className={
                  this.state.paying_inheritance_tax
                    ? "will-button"
                    : "will-button-selected"
                }
                name="paying_inheritance_tax"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>
        <button
          className=" will-next-button"
          style={{ marginTop: "1rem" }}
          onClick={this.onClickNext}
        >
          Next
        </button>

        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="General Concern Info"
          body={PartnerHelpTextList.generalConcernText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerGeneralConcernInfo: (generalConcernInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GENERAL_CONCERN_INFO,
        payload: {
          partnerGeneralConcernInfo: generalConcernInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userGeneralConcernInfo: state.user.generalConcernInfo,
    generalConcernInfo: state.partner.generalConcernInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConcernInfo);
