import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../../css/footer.css";
import neologo from "../../images/Neo-Logo.png";

class Footer extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = { activeClass: "" };
  // }

  render() {
    return (
      <div className="myfooter">
        <div className="footer-container ">
          <div className="row">
            <div className="col-lg-2 footer-about-us">
              <div className="footer-title">
                <h5>About Us</h5>
              </div>
              <p className="footer-paragraph" style={{ textAlign: "justify" }}>
                Neolexical Ltd is one of the most respected Will Writing and
                Trust Formation Company in the UK. We cover the whole of England
                and Wales. You can trust us for excellent tailored advice at a
                very competitive price.
              </p>
            </div>
            <div className="col-lg-2">
              <div className="footer-title">
                <h5>Quick Links</h5>
              </div>
              <div className="quicklinks-list">
                <ul style={{ margin: "0", padding: "0" }}>
                  <li>
                    <HashLink to="/aboutus">About Us</HashLink>
                  </li>

                  <li>
                    <Link to="/tnc">Terms of Business</Link>
                  </li>
                  <li>
                    <Link to="/contactus">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing Information</Link>
                  </li>
                  <li>
                    <Link to="/complaints">Complaints Procedure</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-title">
                <h5>Follow Us</h5>
              </div>
              <div className="footer-follow-list">
                <div className="row">
                  <div className="col-2">
                    <Link to="#link-to-go">
                      <i
                        aria-hidden="true"
                        className={`facebook f large icon  `}
                      ></i>
                    </Link>
                  </div>
                  <div className="col-2">
                    <a href="#link-to-go">
                      <i
                        aria-hidden="true"
                        className={`twitter large icon  `}
                      ></i>
                    </a>
                  </div>
                  <div className="col-2">
                    <a href="#link-to-go">
                      <i
                        aria-hidden="true"
                        className={`instagram large icon  `}
                      ></i>
                    </a>
                  </div>
                  <div className="col-2">
                    <a href="#link-to-go">
                      <i
                        aria-hidden="true"
                        className={`linkedin square large icon  `}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="sra-container">
                <iframe title="sra" frameborder="0" scrolling="no" allowTransparency="true" src="https://cdn.yoshki.com/iframe/55845r.html" className="sra-iframe"></iframe>
              </div>

            </div>
            <div className="col-lg footer-logo">
              <img
                src={neologo}
                className="img-fluid aboutus-image"
                alt="neologo"
              />
            </div>
          </div>
          <div className="footer-border"></div>
          <div className="footer-paragraph-copyright ">
            Copyright ©2020, Neolexical Ltd, 28 Scholars Way, Dagenham
            London, England, RM8 2FL. All rights reserved.
            <br />
            England and Wales company registration number 12608981.
            <br />
            Authorized and regulated by the Solicitors Regulatory Authority
            under SRA No. 808157
          </div>
        </div>
      </div >
    );
  }
}

export default Footer;
