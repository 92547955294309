import React, { Component } from "react";
import RemainingEstateAddBeneficiary from "./RemainingEstateAddBeneficiary";
import RemainingEstateInfoSteps from "./RemainingEstateInfoSteps";
import RemainingEstateSelect from "./RemainingEstateSelect";
import RemainingEstateSubBeneficiary from "./RemainingEstateSubBeneficiary";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import Modal from "../Modal";
import * as helpTextList from "../../HelpTextList";

class RemainingEstateInfo extends Component {
  state = { remainingEstateInfoCurrentView: "select" };
  setRemainingEstateInfoView = (view) => {
    this.setState({ remainingEstateInfoCurrentView: view });
  };
  componentDidMount() {
    if (this.props.stepNo < 8) this.props.setStepNo(8);
  }
  render() {
    let currentViewComponent;
    if (this.state.remainingEstateInfoCurrentView === "select")
      currentViewComponent = (
        <RemainingEstateSelect
          setCurrentView={this.setRemainingEstateInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.remainingEstateInfoCurrentView === "add")
      currentViewComponent = (
        <RemainingEstateAddBeneficiary
          setCurrentView={this.setRemainingEstateInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.remainingEstateInfoCurrentView === "substitute")
      currentViewComponent = (
        <RemainingEstateSubBeneficiary
          setCurrentView={this.setRemainingEstateInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.remainingEstateInfoCurrentView === "next") {
      this.props.setCurrentView("executorInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Your Remaining Estate</h1>
        <RemainingEstateInfoSteps
          setCurrentView={this.setRemainingEstateInfoView}
          currentView={this.state.remainingEstateInfoCurrentView}
        />
        {currentViewComponent}
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="Remaining Estate Info"
          body={helpTextList.remainingEstateText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    remainingEstateInfo: state.user.remainingEstateInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemainingEstateInfo);
