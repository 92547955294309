import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";

class LiquidAssetInfoForm extends Component {
  state = {
    error_cash: null,
    cash: null,

    error_investment_count: null,
    investment_count: null,
    investment_count_change: false,
    investment_details: [],

    error_life_insurance_count: null,
    life_insurance_count: null,
    life_insurance_count_change: false,
    life_insurance_details: [],

    error_pension_count: null,
    pension_count: null,
    pension_count_change: false,
    pension_details: [],

    error_personal_asset_count: null,
    personal_asset_count: null,
    personal_asset_count_change: false,
    personal_assets: [],

    primaryDataProvided: false,
  };
  investmentTypeList = [
    "Savings",
    "Cash account",
    "ISA",
    "Bond",
    "Shares",
    "Other",
  ];

  onChangeHandler = (e) => {
    let targetName = [e.target.name];

    if (e.target.name === "cash") {
      this.setState({ [e.target.name]: parseFloat(e.target.value) });
    } else {
      if (
        this.props.liquidAssetInfo !== null &&
        parseInt(e.target.value) !== this.props.liquidAssetInfo[targetName]
      ) {
        let key = e.target.name + "_change";
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (
        this.props.liquidAssetInfo !== null &&
        parseInt(e.target.value) === this.props.liquidAssetInfo[targetName]
      ) {
        let key = e.target.name + "_change";
        this.setState({
          [key]: false,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (this.props.liquidAssetInfo === null) {
        let key = e.target.name + "_change";
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      }
      // this.setState({ [e.target.name]: parseInt(e.target.value) });
    }
  };

  onSubmitHandleInfoForm = (e) => {
    e.preventDefault();
    let tempState = { ...this.state };
    delete tempState.primaryDataProvided;
    delete tempState.error_cash;
    delete tempState.error_investment_count;
    delete tempState.error_life_insurance_count;
    delete tempState.error_pension_count;
    delete tempState.error_personal_asset_count;
    delete tempState.pension_count_change;
    delete tempState.investment_count_change;
    delete tempState.life_insurance_count_change;
    delete tempState.personal_asset_count_change;
    let db = firebase.firestore();
    let liquidAssetInfo = {
      ...tempState,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("liquidAssetInfo")
      .doc(this.props.currentUser.uid)
      .set(liquidAssetInfo)
      .then(() => {
        // console.log("liquidasset info in firestore");
      })
      .catch(() => {
        // console.log("liquidasset info in firestore failed");
      });

    this.props.setLiquidAssetInfo(tempState);
    this.props.setCurrentView("realAssetInfo");
  };
  onClickSkip = () => {
    this.props.setCurrentView("realAssetInfo");
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.stepNo < 4) this.props.setStepNo(4);
    if (this.props.liquidAssetInfo !== null)
      this.setState({
        primaryDataProvided: false,
        ...this.props.liquidAssetInfo,
      });
    else {
      this.setState({
        primaryDataProvided: false,
      });
    }
  }

  render() {
    let pensions = [];
    for (let index = 0; index < this.state.pension_count; index++) {
      pensions.push(
        <div key={index} style={{ marginBottom: "3.5rem" }}>
          <h4 style={{ marginBottom: "0rem" }}>
            Information of Pension no.{index + 1}
          </h4>
          <label
            htmlFor={"pensionname" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Pension Name
          </label>

          <input
            type="text"
            className="form-control"
            id={"pensionname" + index}
            placeholder="Name"
            onChange={(e) => {
              let pension_details = [...this.state.pension_details];
              pension_details[index].name = e.target.value;
              this.setState({ pension_details: pension_details });
            }}
            value={this.state.pension_details[index].name || ""}
            required
          />

          <label
            htmlFor={"pensionvalue" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            £ Pension Value
          </label>

          <input
            type="number"
            step="any"
            className="form-control"
            id={"pensionvalue" + index}
            placeholder="£"
            onChange={(e) => {
              let pension_details = [...this.state.pension_details];
              pension_details[index].value = parseFloat(e.target.value);
              this.setState({ pension_details: pension_details });
            }}
            value={
              this.state.pension_details[index].value === 0
                ? 0
                : this.state.pension_details[index].value || ""
            }
            required
          />
        </div>
      );
    }

    let lifeInsurance = [];
    for (let index = 0; index < this.state.life_insurance_count; index++) {
      lifeInsurance.push(
        <div key={index} style={{ marginBottom: "3.5rem" }}>
          <h4 style={{ marginBottom: "0rem" }}>
            Information of Life Insurance no.{index + 1}
          </h4>
          <label
            htmlFor={"insurancename" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Life Insurance Name
          </label>

          <input
            type="text"
            className="form-control"
            id={"insurancename" + index}
            placeholder="Name"
            onChange={(e) => {
              let life_insurance_details = [
                ...this.state.life_insurance_details,
              ];
              life_insurance_details[index].name = e.target.value;
              this.setState({
                life_insurance_details: life_insurance_details,
              });
            }}
            value={this.state.life_insurance_details[index].name || ""}
            required
          />

          <label
            htmlFor={"insurancevalue" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            £ Life Insurance Value
          </label>

          <input
            type="number"
            step="any"
            className="form-control"
            id={"insurancevalue" + index}
            placeholder="£"
            onChange={(e) => {
              let life_insurance_details = [
                ...this.state.life_insurance_details,
              ];
              life_insurance_details[index].value = parseFloat(e.target.value);
              this.setState({
                life_insurance_details: life_insurance_details,
              });
            }}
            value={
              this.state.life_insurance_details[index].value === 0
                ? 0
                : this.state.life_insurance_details[index].value || ""
            }
            required
          />
        </div>
      );
    }

    let investments = [];
    for (let index = 0; index < this.state.investment_count; index++) {
      investments.push(
        <div
          key={index}
          style={{
            marginBottom: "3.5rem",
          }}
        >
          <h4 style={{ marginBottom: "0rem" }}>
            Information of Investment no.{index + 1}
          </h4>
          <label
            htmlFor={"type" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Investment Type
          </label>

          <select
            id={"type" + index}
            className="form-control"
            value={this.state.investment_details[index].type || ""}
            onChange={(e) => {
              let investment_details = [...this.state.investment_details];
              investment_details[index].type = e.target.value;
              this.setState({ investment_details: investment_details });
            }}
            required
          >
            <option value="" disabled hidden>
              Type
            </option>
            {this.investmentTypeList.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
          <label
            htmlFor={"investname" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Investment Name
          </label>

          <input
            type="text"
            className="form-control"
            id={"investname" + index}
            placeholder="Name"
            onChange={(e) => {
              let investment_details = [...this.state.investment_details];
              investment_details[index].name = e.target.value;
              this.setState({
                investment_details: investment_details,
              });
            }}
            value={this.state.investment_details[index].name || ""}
            required
          />

          <label
            htmlFor={"investvalue" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            £ Investment Value
          </label>

          <input
            type="number"
            step="any"
            className="form-control"
            id={"investvalue" + index}
            placeholder="£"
            onChange={(e) => {
              let investment_details = [...this.state.investment_details];
              investment_details[index].value = parseFloat(e.target.value);
              this.setState({
                investment_details: investment_details,
              });
            }}
            value={
              this.state.investment_details[index].value === 0
                ? 0
                : this.state.investment_details[index].value || ""
            }
            required
          />
        </div>
      );
    }

    let personalAssets = [];
    for (let index = 0; index < this.state.personal_asset_count; index++) {
      personalAssets.push(
        <div key={index} style={{ marginBottom: "3.5rem" }}>
          <h4 style={{ marginBottom: "0rem" }}>
            Information of Personal Item no.{index + 1}
          </h4>
          <label
            htmlFor={"pAssetname" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Personal Item Name
          </label>

          <input
            type="text"
            className="form-control"
            id={"pAssetname" + index}
            placeholder="Name"
            onChange={(e) => {
              let personal_assets = [...this.state.personal_assets];
              personal_assets[index].name = e.target.value;
              this.setState({
                personal_assets: personal_assets,
              });
            }}
            value={this.state.personal_assets[index].name || ""}
            required
          />

          <label
            htmlFor={"pAssetvalue" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            £ Personal Item Value
          </label>

          <input
            type="number"
            step="any"
            className="form-control"
            id={"pAssetvalue" + index}
            placeholder="£"
            onChange={(e) => {
              let personal_assets = [...this.state.personal_assets];
              personal_assets[index].value = parseFloat(e.target.value);
              this.setState({
                personal_assets: personal_assets,
              });
            }}
            value={
              this.state.personal_assets[index].value === 0
                ? 0
                : this.state.personal_assets[index].value || ""
            }
            required
          />
        </div>
      );
    }

    return (
      <div>
        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div>
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              You can skip this section!
            </span>
            <button
              className="will-button blue-will-button"
              style={{ marginLeft: "1rem" }}
              onClick={this.onClickSkip}
            >
              skip
            </button>
          </div>
        </div>

        <form onSubmit={this.onSubmitHandleInfoForm}>
          {this.state.pension_count === 0 ? (
            <span></span>
          ) : (
            <div style={{ borderBottom: "1px dashed black" }}>
              <h3 style={{ marginBottom: "2rem" }}>Pension Details</h3>
              {pensions}
            </div>
          )}

          {this.state.life_insurance_count === 0 ? (
            <span></span>
          ) : (
            <div style={{ borderBottom: "1px dashed black" }}>
              <h3 style={{ marginBottom: "2rem", marginTop: "2rem" }}>
                Life Insurance Details
              </h3>
              {lifeInsurance}
            </div>
          )}

          {this.state.investment_count === 0 ? (
            <span></span>
          ) : (
            <div style={{ borderBottom: "1px dashed black" }}>
              <h3 style={{ marginBottom: "2rem", marginTop: "2rem" }}>
                Investment Details
              </h3>
              {investments}
            </div>
          )}
          {this.state.personal_asset_count === 0 ? (
            <span></span>
          ) : (
            <div>
              <h3 style={{ marginBottom: "2rem", marginTop: "2rem" }}>
                Personal Item Details
              </h3>
              {personalAssets}
            </div>
          )}
          <button className=" will-next-button" style={{ marginTop: "1rem" }}>
            Next
          </button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLiquidAssetInfo: (liquidAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_LIQUID_ASSET_INFO,
        payload: {
          userLiquidAssetInfo: liquidAssetInfo,
        },
      }),
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    liquidAssetInfo: state.user.liquidAssetInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiquidAssetInfoForm);
