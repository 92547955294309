import React, { Component } from "react";
import { connect } from "react-redux";

import ChildrenCount from "./ChildrenCount";
import ChildrenInfoForm from "./ChildrenInfoForm";
import ChildrenInfoSteps from "./ChildrenInfoSteps";
import * as actionTypes from "../../../../actions/types";

class ChildrenInfo extends Component {
  state = { giftInfoCurrentView: "childrenCount" };
  setChildrenInfoCurrentView = (view) => {
    this.setState({ giftInfoCurrentView: view });
  };
  componentDidMount() {}
  render() {
    let currentViewComponent;
    if (this.state.giftInfoCurrentView === "childrenCount")
      currentViewComponent = (
        <ChildrenCount
          setCurrentView={this.setChildrenInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "childrenInfo")
      currentViewComponent = (
        <ChildrenInfoForm
          setCurrentView={this.setChildrenInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "liquidAssetInfo") {
      this.props.setCurrentView("liquidAssetInfo");
    } else if (this.state.giftInfoCurrentView === "guardianInfo") {
      this.props.setCurrentView("guardianInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Partner's Children</h1>
        <ChildrenInfoSteps
          setCurrentView={this.setChildrenInfoCurrentView}
          currentView={this.state.giftInfoCurrentView}
        />
        {currentViewComponent}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_CHILDREN_INFO,
        payload: {
          partnerChildrenInfo: childrenInfo,
        },
      }),
    setPartnerGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GUARDIAN_INFO,
        payload: {
          partnerGuardianInfo: guardianInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userChildrenInfo: state.user.childrenInfo,
    childrenInfo: state.partner.childrenInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenInfo);
