import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
const style = {
  width: "80%",
  height: "35rem",
  left: "10vw",
};

class MapContainer extends Component {
  render() {
    return (
      <div>
        <div className="ourServices-heading"> Find Us</div>
        <div className="map-container ">
          <Map
            google={this.props.google}
            style={style}
            initialCenter={{
              lat: 51.5500157,
              lng: 0.109207,
            }}
            zoom={15}
            onClick={this.onMapClicked}
          >
            <Marker onClick={this.onMarkerClick} name={"Current location"} />

            {/* <InfoWindow onClose={this.onInfoWindowClose}>
              <div>
          <h1>{this.state.selectedPlace.name}</h1>
        </div>
            </InfoWindow> */}
          </Map>
        </div>
      </div>
    );
  }
}

//todo have a more centralized config variable for api keys.
const apiKey =
  process.env.REACT_APP_ENV === "development"
    ? "AIzaSyD7OyvLJ_V1MuyvvZqEodXTvRtXQu4Xpqs" //neolexical-demo key
    : "AIzaSyAVYUrJq4D3gox_YVmHxViSfR4VEAC78O8"; //neolexical-app key

export default GoogleApiWrapper({
  apiKey: apiKey,
})(MapContainer);
