import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import BenificiaryType from "./BeneficiaryType";
import CharityGift from "./CharityGift";
import FamilyGift from "./FamilyGift";
import GiftInfoSteps from "./GiftInfoSteps";
import firebase from "../../../../config/firebase";
import * as actionTypes from "../../../../actions/types";

class GiftInfo extends Component {
  state = { giftInfoCurrentView: "beneficiaryType" };
  setGiftInfoCurrentView = (view) => {
    this.setState({ giftInfoCurrentView: view });
  };
  componentDidMount() {
    if (this.props.partnerStepNo < 7) this.props.setPartnerStepNo(7);
  }
  onClickSame = () => {
    let db = firebase.firestore();

    db.collection("partnerGiftInfo")
      .doc(this.props.currentUser.uid)
      .set({
        ...this.props.userGiftInfo,
      })
      .then(() => {
        // console.log("partner gift in firestore");
      })
      .catch(() => {
        // console.log("partner gift in firestore failed");
      });

    this.props.setPartnerGiftInfo({
      ...this.props.userGiftInfo,
    });

    this.props.setCurrentView("remainingEstateInfo");
  };

  render() {
    let currentViewComponent;
    if (this.state.giftInfoCurrentView === "beneficiaryType")
      currentViewComponent = (
        <BenificiaryType
          setCurrentView={this.setGiftInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "familyGift")
      currentViewComponent = (
        <FamilyGift
          setCurrentView={this.setGiftInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "charityGift")
      currentViewComponent = (
        <CharityGift
          setCurrentView={this.setGiftInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "next") {
      this.props.setCurrentView("remainingEstateInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Gift Info</h1>

        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            className="will-button blue-will-button"
            style={{
              width: "70%",
              letterSpacing: "0.1rem",
              wordSpacing: ".2rem",
              fontSize: "1.2rem",
            }}
            onClick={this.onClickSame}
          >
            Same as Partner
          </button>
        </div>
        <Divider horizontal>Or</Divider>

        <GiftInfoSteps
          setCurrentView={this.setGiftInfoCurrentView}
          currentView={this.state.giftInfoCurrentView}
        />
        {currentViewComponent}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerGiftInfo: (giftInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GIFT_INFO,
        payload: {
          partnerGiftInfo: giftInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userGiftInfo: state.user.giftInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftInfo);
