import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Icon } from "semantic-ui-react";
import * as Lists from "../Lists";
import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";

class Attorney extends Component {
  state = {
    attorney: [],
    company_attorney_number: 0,
    individual_attorney_number: 0,
    error: "",
    field_error: {
      field: "",
      msg: "",
    },
    change: false,
  };
  countryList = Lists.countryList;
  titleList = Lists.titleList;

  clearAttorneyMakeDecision = async () => {
    let db = firebase.firestore();
    this.props.setFinancialLpaAttorneysMakeDecision(null); // clearing prev attoreney make decision info

    db.collection("financialLpaAttorneyMakeDecision")
      .doc(this.props.currentUser.uid)
      .delete()
      .then(() => {
        // console.log("financialLpaAttorneyMakeDecision info cleared");
      })
      .catch(() => {
        // console.log("no financialLpaAttorneyMakeDecision info to delete");
      });
  };

  isFormFilled = () => {
    let formFilled = true;
    let temp_field_error = {
      field: "",
      msg: "",
    };
    this.state.attorney.forEach((item, index) => {
      if (item.reg_no === undefined) {
        if (item.title === "") {
          temp_field_error.field = "title" + index;
          temp_field_error.msg = "Please select one from list";
          this.setState({ field_error: temp_field_error });
          document.getElementById("title" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.first_name === "") {
          temp_field_error.field = "first_name" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("first_name" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.last_name === "") {
          temp_field_error.field = "last_name" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("last_name" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.street_or_house_no === "") {
          temp_field_error.field = "street_or_house_no" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document
            .getElementById("street_or_house_no" + index)
            .scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.city === "") {
          temp_field_error.field = "city" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("city" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.postal_code === "") {
          // console.log("no postal code" + index);
          temp_field_error.field = "postal_code" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("postal_code" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.country === "") {
          temp_field_error.field = "country" + index;
          temp_field_error.msg = "Please select one from list";
          this.setState({ field_error: temp_field_error });
          document.getElementById("country" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.date_of_birth === "") {
          temp_field_error.field = "date_of_birth" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("date_of_birth" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.date_of_birth !== "") {
          let birthDate = new Date(item.date_of_birth);
          let currentDate = new Date();
          let age = Math.floor(
            (currentDate - birthDate) / (1000 * 60 * 60 * 24 * 365)
          );
          // console.log(age);
          if (age < 18) {
            temp_field_error.field = "date_of_birth" + index;
            temp_field_error.msg = "Minimum age is 18";
            this.setState({ field_error: temp_field_error });
            document.getElementById("date_of_birth" + index).scrollIntoView();
            formFilled = false;
            return false;
          }
        }
      } else {
        if (item.company_name === "") {
          temp_field_error.field = "company_name" + index;
          temp_field_error.msg = "Please select one from list";
          this.setState({ field_error: temp_field_error });
          document.getElementById("company_name" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.reg_no === "") {
          temp_field_error.field = "reg_no" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("reg_no" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.street_or_house_no === "") {
          temp_field_error.field = "street_or_house_no" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document
            .getElementById("street_or_house_no" + index)
            .scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.city === "") {
          temp_field_error.field = "city" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("city" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.postal_code === "") {
          // console.log("no postal code" + index);
          temp_field_error.field = "postal_code" + index;
          temp_field_error.msg = "Required fields can not be empty ";
          this.setState({ field_error: temp_field_error });
          document.getElementById("postal_code" + index).scrollIntoView();
          formFilled = false;
          return false;
        } else if (item.address.country === "") {
          temp_field_error.field = "country" + index;
          temp_field_error.msg = "Please select one from list";
          this.setState({ field_error: temp_field_error });
          document.getElementById("country" + index).scrollIntoView();
          formFilled = false;
          return false;
        }
      }
    });
    return formFilled;
  };

  onSubmitHandle = (e) => {
    e.preventDefault();

    if (!this.isFormFilled()) {
      // console.log("form not filled");
      return;
    }
    if (this.state.attorney.length === 0) {
      this.setState({ error: "Add Atleast One Attorney" });
      return;
    }

    let db = firebase.firestore();
    let financialLpaAttorneyInfo = {
      attorney: this.state.attorney,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("financialLpaAttorneyInfo")
      .doc(this.props.currentUser.uid)
      .set(financialLpaAttorneyInfo)
      .then(() => {
        // console.log("FinancialLpaAttorney in firestore");
      })
      .catch(() => {
        // console.log("FinancialLpaAttorney in firestore failed");
      });

    this.props.setFinancialLpaAttorney(this.state.attorney);
    this.props.setFinancialLpaDisable(this.state.change);
    if (this.state.attorney.length > 1)
      this.props.setCurrentView(
        FinancialAffairsStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
      );
    else {
      this.clearAttorneyMakeDecision();
      this.props.setCurrentView(FinancialAffairsStepsEnum.REPLACEMENT_ATTORNEY); // SET IT REPLACEMENT_ATTORNEY
    }
  };

  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.attorney !== null) {
      let isCompanyAdded = false;
      this.props.attorney.forEach((item) => {
        if (item.reg_no !== undefined) isCompanyAdded = true;
      });
      if (isCompanyAdded)
        this.setState({
          company_attorney_number: 1,
        });
      this.setState({ attorney: this.props.attorney });
    }
    if (this.props.replacementAttorney !== null) {
      let isCompanyAdded = false;
      this.props.replacementAttorney.forEach((item) => {
        if (item.reg_no !== undefined) isCompanyAdded = true;
      });
      if (isCompanyAdded)
        this.setState({
          company_attorney_number: 1,
        });
    }
  }

  onClickAddCompany = () => {
    let attorney = [...this.state.attorney];
    for (
      let index = this.state.attorney.length;
      index < this.state.attorney.length + 1;
      index++
    ) {
      attorney[index] = {
        company_name: "",
        reg_no: "",
        address: {
          street_or_house_no: "",
          city: "",
          postal_code: "",
          country: "",
        },

        email: "",
      };
    }
    this.setState({
      company_attorney_number: this.state.company_attorney_number + 1,
      attorney: attorney,
      error: "",
    });
  };

  onClickAddNeolexical = () => {
    this.setState({ change: true });
    let attorney = [...this.state.attorney];
    for (
      let index = this.state.attorney.length;
      index < this.state.attorney.length + 1;
      index++
    ) {
      attorney[index] = {
        company_name: "Neolexical Ltd",
        reg_no: "12608981",
        address: {
          street_or_house_no: "28 Scholars Way",
          city: "London",
          postal_code: "RM8 2FL",
          country: Lists.countryList[0],
        },

        email: "info@neolexical.com",
      };
    }
    this.setState({
      company_attorney_number: this.state.company_attorney_number + 1,
      attorney: attorney,
      error: "",
    });
  };

  onClickAddIndividual = () => {
    let attorney = [...this.state.attorney];
    for (
      let index = this.state.attorney.length;
      index < this.state.attorney.length + 1;
      index++
    ) {
      attorney[index] = {
        title: "",
        first_name: "",

        last_name: "",
        address: {
          street_or_house_no: "",
          city: "",
          postal_code: "",
          country: "",
        },

        email: "",
        date_of_birth: "",
      };
    }
    this.setState({
      individual_attorney_number: this.state.individual_attorney_number + 1,
      attorney: attorney,
      error: "",
    });
  };
  onChangeHandler = (e) => {
    this.setState({ change: true });
    if (e.target.name === "street_or_house_no") {
      let tempAddress = {
        ...this.state.address,
        street_or_house_no: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "city") {
      let tempAddress = {
        ...this.state.address,
        city: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "postal_code") {
      let tempAddress = {
        ...this.state.address,
        postal_code: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "country") {
      let tempAddress = {
        ...this.state.address,
        country: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else this.setState({ [e.target.name]: e.target.value });
  };

  onClickNext = (e) => {
    e.preventDefault();
  };

  onClickRemove = (index) => {
    let attorney = [...this.state.attorney];
    let isCompany = false;
    if (attorney[index].title === undefined) isCompany = true;

    attorney.splice(index, 1);

    if (!isCompany)
      this.setState({
        individual_attorney_number: this.state.individual_attorney_number - 1,
        attorney: attorney,
      });
    else
      this.setState({
        company_attorney_number: this.state.company_attorney_number - 1,
        attorney: attorney,
      });
    this.setState({ change: true });
  };

  render() {
    let attorney_rows = [];

    for (let index = 0; index < this.state.attorney.length; index++) {
      attorney_rows.push(
        <div
          key={index}
          style={{
            marginBottom: "3.5rem",
          }}
        >
          <span>
            <h3 style={{ marginBottom: "1rem", marginTop: "1rem" }}>
              {this.state.attorney[index].title === undefined ? (
                <span>Information of Trust Corporation</span>
              ) : (
                <span>Information of Attorney no.{index + 1}</span>
              )}
              <button
                type="button"
                className="remove-form"
                style={{ float: "right" }}
                onClick={() => this.onClickRemove(index)}
              >
                <Icon name="x icon"></Icon>
              </button>
            </h3>
          </span>
          {this.state.attorney[index].title === undefined ? (
            <div>
              <label
                htmlFor={"company_name" + index}
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                id={"company_name" + index}
                placeholder="Company Name"
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].company_name = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                value={this.state.attorney[index].company_name}
                name="company_name"
                required
              />
              {this.state.field_error.field === "company_name" + index ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {this.state.field_error.msg}
                </div>
              ) : (
                <span></span>
              )}
              <label
                htmlFor={"reg_no" + index}
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Registration Number
              </label>
              <input
                type="text"
                className="form-control"
                id={"reg_no" + index}
                placeholder="Registration Number"
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].reg_no = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                value={this.state.attorney[index].reg_no}
                name="reg_no"
                required
              />
              {this.state.field_error.field === "reg_no" + index ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {this.state.field_error.msg}
                </div>
              ) : (
                <span></span>
              )}
              <label
                htmlFor={"email" + index}
                className=" col-form-label col-form-label"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id={"email" + index}
                placeholder="email"
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].email = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                value={this.state.attorney[index].email}
                name="email"
              />

              <label
                htmlFor={"address" + index}
                className="required-field col-form-label col-form-label"
                style={{
                  marginTop: "2.8rem",
                  marginBottom: "0.5rem",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                Address:
              </label>

              <div
                id={"address" + index}
                style={{ marginLeft: "0rem", marginBottom: "4rem" }}
              >
                <label
                  htmlFor={"street_or_house_no" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0rem", fontWeight: "bold" }}
                >
                  Street/House No.
                </label>

                <input
                  type="text"
                  className="form-control"
                  id={"street_or_house_no" + index}
                  placeholder="Street/House No"
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.street_or_house_no = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  required
                  value={
                    this.state.attorney[index].address.street_or_house_no || ""
                  }
                  name="street_or_house_no"
                />
                {this.state.field_error.field ===
                "street_or_house_no" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}

                <label
                  htmlFor={"city" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  City
                </label>

                <input
                  type="text"
                  className="form-control"
                  id={"city" + index}
                  placeholder="City"
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.city = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  required
                  value={this.state.attorney[index].address.city || ""}
                  name="city"
                />
                {this.state.field_error.field === "city" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}

                <label
                  htmlFor={"postal_code" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  Postal Code
                </label>
                <div>
                  {this.state.attorney[index].error_postal_code === "" ? (
                    <span></span>
                  ) : (
                    <span style={{ color: "red" }}>
                      {this.state.attorney[index].error_postal_code}
                    </span>
                  )}
                </div>

                <input
                  type="text"
                  className="form-control"
                  id={"postal_code" + index}
                  placeholder="Postal Code"
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.postal_code = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  required
                  value={this.state.attorney[index].address.postal_code || ""}
                  name="postal_code"
                />
                {this.state.field_error.field === "postal_code" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}

                <label
                  htmlFor={"country" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  Country
                </label>

                <select
                  id={"country" + index}
                  className="form-control"
                  value={this.state.attorney[index].address.country}
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.country = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  name="country"
                  required
                >
                  <option value="" disabled hidden>
                    Country
                  </option>
                  {this.countryList.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                {this.state.field_error.field === "country" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}
              </div>

              {this.state.attorney.length === index + 1 ? (
                <span></span>
              ) : (
                <Divider />
              )}
            </div>
          ) : (
            <div>
              <label
                htmlFor="title"
                className="col-form-label col-form-label required-field"
                style={{ fontWeight: "bold" }}
              >
                Title
              </label>
              <select
                id={"title" + index}
                className="form-control"
                value={this.state.attorney[index].title}
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].title = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                name={"title" + index}
                required
              >
                <option value="" disabled hidden>
                  Title
                </option>
                {this.titleList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {this.state.field_error.field === "title" + index ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {this.state.field_error.msg}
                </div>
              ) : (
                <span></span>
              )}

              <label
                htmlFor={"first_name" + index}
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id={"first_name" + index}
                placeholder="First Name"
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].first_name = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                value={this.state.attorney[index].first_name}
                name="first_name"
                required
              />
              {this.state.field_error.field === "first_name" + index ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {this.state.field_error.msg}
                </div>
              ) : (
                <span></span>
              )}
              <label
                htmlFor={"last_name" + index}
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id={"last_name" + index}
                placeholder="Last Name"
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].last_name = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                value={this.state.attorney[index].last_name}
                name="last_name"
                required
              />
              {this.state.field_error.field === "last_name" + index ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {this.state.field_error.msg}
                </div>
              ) : (
                <span></span>
              )}
              <label
                htmlFor={"address" + index}
                className="required-field col-form-label col-form-label"
                style={{
                  marginTop: "2.8rem",
                  marginBottom: "0.5rem",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                Address:
              </label>

              <div id={"address" + index} style={{ marginLeft: "0rem" }}>
                <label
                  htmlFor={"street_or_house_no" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0rem", fontWeight: "bold" }}
                >
                  Street/House No.
                </label>

                <input
                  type="text"
                  className="form-control"
                  id={"street_or_house_no" + index}
                  placeholder="Street/House No"
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.street_or_house_no = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  required
                  value={
                    this.state.attorney[index].address.street_or_house_no || ""
                  }
                  name="street_or_house_no"
                />

                {this.state.field_error.field ===
                "street_or_house_no" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}

                <label
                  htmlFor={"city" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  City
                </label>

                <input
                  type="text"
                  className="form-control"
                  id={"city" + index}
                  placeholder="City"
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.city = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  required
                  value={this.state.attorney[index].address.city || ""}
                  name="city"
                />
                {this.state.field_error.field === "city" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}
                <label
                  htmlFor={"postal_code" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  Postal Code
                </label>
                <div>
                  {this.state.attorney[index].error_postal_code === "" ? (
                    <span></span>
                  ) : (
                    <span style={{ color: "red" }}>
                      {this.state.attorney[index].error_postal_code}
                    </span>
                  )}
                </div>

                <input
                  type="text"
                  className="form-control"
                  id={"postal_code" + index}
                  placeholder="Postal Code"
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.postal_code = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  required
                  value={this.state.attorney[index].address.postal_code || ""}
                  name="postal_code"
                />
                {this.state.field_error.field === "postal_code" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}

                <label
                  htmlFor={"country" + index}
                  className="col-form-label col-form-label"
                  style={{ marginTop: "0.5rem", fontWeight: "bold" }}
                >
                  Country
                </label>

                <select
                  id={"country" + index}
                  className="form-control"
                  value={this.state.attorney[index].address.country}
                  onChange={(e) => {
                    this.setState({ change: true });
                    let attorney = [...this.state.attorney];
                    attorney[index].address.country = e.target.value;
                    this.setState({ attorney: attorney });
                  }}
                  name="country"
                  required
                >
                  <option value="" disabled hidden>
                    Country
                  </option>
                  {this.countryList.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                {this.state.field_error.field === "country" + index ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.field_error.msg}
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
              <label
                htmlFor={"email" + index}
                className=" col-form-label col-form-label"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id={"email" + index}
                placeholder="email"
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].email = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                value={this.state.attorney[index].email}
                name="email"
              />
              <label
                htmlFor={"date_of_birth" + index}
                className="required-field col-form-label col-form-label"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Birthdate
              </label>
              <input
                type="date"
                className="form-control"
                id={"date_of_birth" + index}
                placeholder="Birthdate"
                name="date_of_birth"
                onChange={(e) => {
                  this.setState({ change: true });
                  let attorney = [...this.state.attorney];
                  attorney[index].date_of_birth = e.target.value;
                  this.setState({ attorney: attorney });
                }}
                // onClick={onClick}
                value={this.state.attorney[index].date_of_birth}
                required
              />
              {this.state.field_error.field === "date_of_birth" + index ? (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {this.state.field_error.msg}
                </div>
              ) : (
                <span></span>
              )}
              <div style={{ height: "4rem" }}></div>
              {this.state.attorney.length === index + 1 ? (
                <span></span>
              ) : (
                <Divider />
              )}
            </div>
          )}
        </div>
      );
    }
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Who are the attorney?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
            position: "relative",
          }}
        >
          Attorneys are people the donor appoints to make decisions on their
          behalf.
          <ul style={{ margin: "2rem 0rem" }}>
            <li>A donor can have 1 or more attorney</li>
            <li>Attorney must be 18 or over</li>
            <li>
              Attorneys must have mental capacity – the ability to make
              decisions
            </li>
            <li>
              An attorney must not be bankrupt or subject to a debt relief order
            </li>
          </ul>
          <p>
            The most important thing is that the attorney are people the donor
            can trust to respect their wishes and act in their best interests.
          </p>
          <p>
            You will be asked if the donor would like to add replacement
            attorney in a separate question. These would step in if the original
            attorney are unable or unwilling to continue.
          </p>
        </div>
        <Divider />
        <form onSubmit={this.onSubmitHandle} noValidate>
          {attorney_rows}
          {/* {this.state.attorney[index].percentage_error === null ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              {this.state.percentage_error}
            </div>
          )} */}
          {this.state.company_attorney_number === 1 ? (
            <div>
              <button
                type="button"
                className=" will-button-selected-with-hover"
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  fontSize: "1.2rem",
                }}
                onClick={this.onClickAddIndividual}
              >
                Add Individual Attorney
              </button>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <div>
                <button
                  type="button"
                  className=" will-button-selected-with-hover"
                  style={{
                    width: "70%",
                    fontSize: "1.1rem",
                  }}
                  onClick={this.onClickAddIndividual}
                >
                  Add Individual Attorney
                </button>
              </div>
              {/* <div>
                <button
                  type="button"
                  className=" will-button-selected-with-hover"
                  style={{
                    width: "70%",
                    fontSize: "1.1rem",
                  }}
                  onClick={this.onClickAddNeolexical}
                >
                  Add Neolexical Ltd.
                </button>
              </div>*/}
              <div>
                <button
                  type="button"
                  className=" will-button-selected-with-hover"
                  style={{
                    width: "70%",
                    fontSize: "1.1rem",
                  }}
                  onClick={this.onClickAddCompany}
                >
                  Add Trust Corporation
                </button>
              </div>
            </div>
          )}

          {this.state.error === "" ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                marginTop: "3rem",
              }}
            >
              {this.state.error}
            </div>
          )}
          <button className=" will-next-button" style={{ marginTop: "2rem" }}>
            Save And Proceed
          </button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaAttorney: (attorney) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_ATTORNEY,
        payload: {
          attorney: attorney,
        },
      }),
    setFinancialLpaAttorneysMakeDecision: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_ATTORNEYS_MAKE_DECISION,
        payload: {
          attorneysMakeDecision: data,
        },
      }),
    setFinancialLpaDisable: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_DISABLE_REG_COR,
        payload: {
          disable: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    attorney: state.financial_lpa.attorney,
    replacementAttorney: state.financial_lpa.replacementAttorney,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attorney);
