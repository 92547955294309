import React, { Component } from "react";

import * as actionTypes from "../../../actions/types";
import "../../../css/will.css";
import Modal from "./Modal";

import { connect } from "react-redux";
import firebase from "../../../config/firebase";

export class PartnerInfo extends Component {
  state = {
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    previous_last_name: "",
    gender: "",
    address: "",
    mobile: "",
    email: "",
    date_of_birth: "",
  };

  titleList = ["Mr", "Mrs", "Ms", "Miss", "Professor", "Dr"];
  genderList = ["Male", "Female", "Non-binary"];
  maritalStatusList = [
    "Single",
    "Married",
    "Widowed",
    "Divorced",
    "Separated",
    "Living with Partner",
    "Partner (Living Separately)",
    "Civil Partnership",
  ];

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandle = (e) => {
    e.preventDefault();
    let db = firebase.firestore();
    let partnerInfo = {
      ...this.state,
      date_of_birth: firebase.firestore.Timestamp.fromDate(
        new Date(this.state.date_of_birth)
      ),
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("partnerInfo")
      .doc(this.props.currentUser.uid)
      .set(partnerInfo)
      .then(() => {
        // console.log("partner info in firestore");
      })
      .catch(() => {
        // console.log("partner info in firestore failed");
      });
    this.props.setPartnerInfo(this.state);
    this.props.setCurrentView("childrenInfo");
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState(this.props.partnerInfo);
  }
  render() {
    return (
      <div className="col-6">
        <h1 style={{ textAlign: "center" }}>Partner Info</h1>
        <form onSubmit={this.onSubmitHandle}>
          <label
            htmlFor="title"
            className="col-form-label col-form-label required-field"
            style={{ fontWeight: "bold" }}
          >
            Title
          </label>
          <select
            id="title"
            className="form-control"
            value={this.state.title}
            onChange={this.onChangeHandler}
            name="title"
            required
          >
            <option value="" disabled hidden selected>
              Title
            </option>
            {this.titleList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>

          <label
            htmlFor="firstName"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="First Name"
            onChange={this.onChangeHandler}
            value={this.state.first_name}
            name="first_name"
            required
          />

          <label
            htmlFor="middleName"
            className="col-form-label col-form-label "
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Middle Name
          </label>

          <input
            type="text"
            className="form-control"
            id="middleName"
            placeholder="Middle Name"
            onChange={this.onChangeHandler}
            value={this.state.middle_name}
            name="middle_name"
          />

          <label
            htmlFor="surname"
            className="col-form-label col-form-label  required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Surname
          </label>

          <input
            type="text"
            className="form-control"
            id="surname"
            placeholder="Surname"
            onChange={this.onChangeHandler}
            value={this.state.last_name}
            name="last_name"
            required
          />

          <label
            htmlFor="prevSurname"
            className="col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Previous Surname
          </label>

          <input
            type="text"
            className="form-control"
            id="prevSurname"
            placeholder="Previous Surname"
            onChange={this.onChangeHandler}
            value={this.state.previous_last_name}
            name="previous_last_name"
          />

          <label
            htmlFor="gender"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Gender
          </label>

          <select
            id="gender"
            className="form-control"
            value={this.state.gender}
            onChange={this.onChangeHandler}
            name="gender"
            required
          >
            <option value="" disabled hidden selected>
              Gender
            </option>
            {this.genderList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>

          <label
            htmlFor="address"
            className=" col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Address
          </label>

          <textarea
            type="textarea"
            className="form-control"
            id="address"
            placeholder="Address"
            onChange={this.onChangeHandler}
            value={this.state.address}
            name="address"
          />

          <label
            htmlFor="mobile"
            className=" col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Mobile
          </label>

          <input
            type="text"
            className="form-control"
            id="mobile"
            placeholder="Mobile"
            onChange={this.onChangeHandler}
            value={this.state.mobile}
            name="mobile"
          />

          <label
            htmlFor="email"
            className="col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Email
          </label>

          <input
            type="text"
            className="form-control"
            id="email"
            placeholder="email"
            onChange={this.onChangeHandler}
            value={this.state.email}
            name="email"
          />

          <label
            htmlFor="birthdate"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Birthdate
          </label>

          <input
            type="date"
            className="form-control"
            id="birthdate"
            placeholder="Birthdate"
            name="date_of_birth"
            onChange={this.onChangeHandler}
            // onClick={onClick}
            value={this.state.date_of_birth}
            required
          />

          <button className=" will-next-button">Next</button>
        </form>

        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal title="Partner Info" body="Input Partner Info" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerInfo: (partnerInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PARTNER_INFO,
        payload: {
          userPartnerInfo: partnerInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    partnerInfo: state.user.partnerInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerInfo);
