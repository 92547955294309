import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";

class Registration extends Component {
  state = {
    selected: "",
    attorney: [],
    error: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let attorney = [];
    this.props.attorney.forEach((item, index) => {
      attorney.push(false);
    });
    // this.setState({ attorney: attorney });
    if (this.props.registration !== null) {
      if (this.props.registration.selected === "donor") {
        this.setState({ selected: "donor" });
      } else if (this.props.registration.selected === "neolexical") {
        this.setState({ selected: "neolexical" });
      } else {
        this.props.registration.attorney.forEach((item) => {
          if (item.title === undefined) {
            this.props.attorney.forEach((item2, index) => {
              if (item2.title === undefined && item2.reg_no === item.reg_no)
                attorney[index] = true;
            });
          } else {
            this.props.attorney.forEach((item2, index) => {
              if (
                item2.title !== undefined &&
                item2.title === item.title &&
                item2.first_name === item.first_name &&
                item2.last_name === item.last_name &&
                item2.date_of_birth === item.date_of_birth
              )
                attorney[index] = true;
            });
          }
        });
        this.setState({ selected: "attorney", attorney: attorney });
      }
    }
  }

  isOneAttorneyAndCompany = () => {
    if (
      this.props.attorney.length === 1 &&
      this.props.attorney[0].title === undefined
    ) {
      // console.log("company");
      return true;
    }
    return false;
  };

  isOneAttorneyAndIndividual = () => {
    if (
      this.props.attorney.length === 1 &&
      this.props.attorney[0].title !== undefined
    ) {
      // console.log("individual");
      return true;
    }
    return false;
  };

  getAttorneyName = (index) => {
    let item = this.props.attorney[index];
    return item.title === undefined
      ? item.company_name
      : item.title + " " + item.first_name + " " + item.last_name;
  };
  dbValidAction = (selected, attorney) => {
    let db = firebase.firestore();
    let financialLpaRegistration = {
      selected: selected,
      attorney: attorney,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    db.collection("financialLpaRegistrationInfo")
      .doc(this.props.currentUser.uid)
      .set(financialLpaRegistration)
      .then(() => {
        // console.log("FinancialLpaRegistration in firestore");
      })
      .catch(() => {
        // console.log("FinancialLpaRegistration in firestore failed");
      });
  };

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (this.state.selected === "") {
      this.setState({ error: "Select One" });
      return;
    }
    if (this.state.selected === "donor") {
      this.setState({ error: "" });
      this.props.setFinancialLpaRegistration({
        selected: this.state.selected,
        attorney: [],
      });
      this.dbValidAction(this.state.selected, []);
    } else if (this.state.selected === "neolexical") {
      let isNeoAttorney = true; //no condition checking for  neo attorney or not
      // this.props.attorney.forEach((item) => {
      //   if (
      //     item.reg_no !== undefined &&
      //     item.reg_no !== null &&
      //     item.reg_no === "12608981"
      //   )
      //     isNeoAttorney = true;
      // });
      if (!isNeoAttorney) {
        this.setState({
          error:
            "If you want us to register your LPA, go to Attorney Section and add Neolexical As Atttorney",
        });
        return;
      } else {
        this.setState({ error: "" });
        this.props.setFinancialLpaRegistration({
          selected: this.state.selected,
          attorney: [],
        });
        this.dbValidAction(this.state.selected, []);
      }
    } else {
      let tempAttorney = [];
      this.state.attorney.forEach((item, index) => {
        if (item) {
          tempAttorney.push(this.props.attorney[index]);
        }
      });
      if (tempAttorney.length === 0) {
        this.setState({ error: "Select One" });
        return;
      }
      this.setState({ error: "" });
      this.props.setFinancialLpaRegistration({
        selected: this.state.selected,
        attorney: tempAttorney,
      });
      this.dbValidAction(this.state.selected, tempAttorney);
    }
    this.props.setCurrentView(FinancialAffairsStepsEnum.CORRESPONDENT);
  };

  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Who’s applying to register the LPA?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
            position: "relative",
          }}
        >
          Either the donor or one or more of the attorneys can apply to register
          the LPA.
        </div>
        <Divider />

        <form onSubmit={this.onSubmitHandle}>
          <h2>This LPA is being registered by</h2>
          <div style={{ position: "relative", marginTop: "2rem" }}>
            <input
              type="radio"
              className=""
              id="donor"
              placeholder="First Name"
              checked={this.state.selected === "donor" ? 1 : 0}
              onChange={() => {
                this.setState({ selected: "donor" });
                let attorney = [];
                this.props.attorney.forEach((item, index) => {
                  attorney.push(false);
                });
                this.setState({ attorney: attorney });
              }}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="donor"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              The Donor -{" "}
              {this.props.donor.title +
                " " +
                this.props.donor.first_name +
                " " +
                this.props.donor.last_name}
            </label>
          </div>

          <div style={{ position: "relative", marginTop: "2rem" }}>
            <input
              type="radio"
              className=""
              id="neolexical"
              placeholder="First Name"
              checked={this.state.selected === "neolexical" ? 1 : 0}
              onChange={() => {
                this.setState({ selected: "neolexical" });
                let attorney = [];
                this.props.attorney.forEach((item, index) => {
                  attorney.push(false);
                });
                this.setState({ attorney: attorney });
              }}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="neolexical"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              Neolexical Ltd.
            </label>
          </div>

          <div style={{ position: "relative", marginTop: "1.6rem" }}>
            <input
              type="radio"
              className=""
              id="attorney"
              placeholder="First Name"
              checked={this.state.selected === "attorney" ? 1 : 0}
              onChange={() => {
                this.setState({ details: "", selected: "attorney" });
                let attorney = [];
                this.props.attorney.forEach((item, index) => {
                  attorney.push(true);
                });
                this.setState({ attorney: attorney });
              }}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="attorney"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              The Attorney -{" "}
              {this.isOneAttorneyAndCompany() ? (
                <span>{this.props.attorney[0].company_name}</span>
              ) : (
                <span>
                  {this.props.attorney[0].title === undefined ? (
                    <span>{this.props.attorney[0].company_name}</span>
                  ) : (
                    <span></span>
                  )}
                </span>
              )}
              {this.isOneAttorneyAndIndividual() ? (
                <span>
                  {this.props.attorney[0].title +
                    " " +
                    this.props.attorney[0].first_name +
                    " " +
                    this.props.attorney[0].last_name}
                </span>
              ) : (
                <span></span>
              )}
            </label>
          </div>
          {this.state.selected === "attorney" ? (
            <div style={{ marginLeft: "2rem", marginTop: "1rem" }}>
              {this.props.attorney.map((item, index) => (
                <div style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    className=""
                    id={index}
                    placeholder="First Name"
                    checked={this.state.attorney[index]}
                    onChange={(e) => {
                      let attorney = this.state.attorney;
                      attorney[index] = !attorney[index];
                      this.setState(attorney);
                    }}
                    style={{
                      width: "2rem",
                      height: "2rem",
                      display: "inline-block",
                    }}
                    name="first_name"
                  />
                  <label
                    htmlFor={index}
                    // className="col-form-label col-form-label required-field"
                    style={{
                      // fontWeight: "bold",
                      fontSize: "1.4rem",
                      position: "absolute",
                      top: "15%",
                      paddingLeft: "1rem",
                      display: "inline-block",
                      marginBottom: "0",
                    }}
                  >
                    {this.getAttorneyName(index)}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <span></span>
          )}
          <span
            style={{
              fontSize: "1.2rem",
              paddingLeft: "1rem",
              marginLeft: ".75rem",
              marginBottom: "2rem",
              marginTop: "1rem",
              borderLeft: "0.5rem solid grey",
              display: "block",
            }}
          ></span>
          {this.state.error === "" ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{ fontSize: "1.1rem", fontWeight: "bold" }}
            >
              {this.state.error}
            </div>
          )}
          <button className=" will-next-button">Save And Proceed</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaRegistration: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_REGISTRATION,
        payload: {
          registration: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    donor: state.financial_lpa.donor,
    attorney: state.financial_lpa.attorney,
    registration: state.financial_lpa.registration,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
