import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import firebase from "../../../config/firebase";
import * as actionTypes from "../../../actions/types";

class SubmitSingleWill extends Component {
  state = {
    disableSubmit: false,
    visitHome: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onClickYes = (e) => {
    this.setState({ [e.target.name]: true });
  };
  onClickNo = (e) => {
    // console.log(e.target.name);
    this.setState({ [e.target.name]: false });
    // this.setState({ needGuardian: false });
    // this.props.setCurrentView("liquidAssetInfo");
  };

  onFormSubmit = async (e) => {
    this.setState({ disableSubmit: true, submitted: false });
    e.preventDefault();
    if (
      this.props.userInfo.userDoc === null ||
      this.props.userInfo.userDoc.profile_photo === null ||
      this.props.userInfo.userDoc.government_id === null ||
      this.props.userInfo.userDoc.proof_of_address === null ||
      this.props.userInfo.userDoc.profile_photo === undefined ||
      this.props.userInfo.userDoc.government_id === undefined ||
      this.props.userInfo.userDoc.proof_of_address === undefined
    ) {
      document.getElementById("modalON").click();
      return;
    }
    let db = firebase.firestore();

    // let collectionRef = await db.collection("users");

    // let userRef = (await collectionRef.get()).docs;
    // let userDoc = userRef.find((item) => {
    //   return item.id === firebase.auth().currentUser.uid;
    // });
    let personalInfo = null,
      partnerInfo = null,
      childrenInfo = null,
      guardianInfo = null,
      giftInfo = null,
      executorInfo = null,
      generalConcernInfo = null,
      liquidAssetInfo = null,
      realAssetInfo = null,
      remainingEstateInfo = null,
      requestInfo = null;

    //...........

    personalInfo = {
      ...this.props.userInfo.personalInfo,
      date_of_birth: firebase.firestore.Timestamp.fromDate(
        new Date(this.props.userInfo.personalInfo.date_of_birth)
      ),
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };
    if (this.props.userInfo.partnerInfo !== null) {
      partnerInfo = {
        ...this.props.userInfo.partnerInfo,
        date_of_birth: firebase.firestore.Timestamp.fromDate(
          new Date(this.props.userInfo.partnerInfo.date_of_birth)
        ),
        user_id: db.doc("users/" + firebase.auth().currentUser.uid),
      };
    }

    childrenInfo = {
      children_count: parseInt(this.props.userInfo.childrenInfo.number),
      children_details: this.props.userInfo.childrenInfo.info,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };

    if (this.props.userInfo.guardianInfo !== null) {
      guardianInfo = {
        ...this.props.userInfo.guardianInfo,
        user_id: db.doc("users/" + firebase.auth().currentUser.uid),
      };
    }

    liquidAssetInfo = {
      ...this.props.userInfo.liquidAssetInfo,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };

    realAssetInfo = {
      ...this.props.userInfo.realAssetInfo,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };

    generalConcernInfo = {
      ...this.props.userInfo.generalConcernInfo,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };

    giftInfo = {
      ...this.props.userInfo.giftInfo,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };
    remainingEstateInfo = {
      ...this.props.userInfo.remainingEstateInfo,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };
    executorInfo = {
      ...this.props.userInfo.executorInfo,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };
    requestInfo = {
      ...this.props.userInfo.requestInfo,
      user_id: db.doc("users/" + firebase.auth().currentUser.uid),
    };

    let ref = (
      await db.collection("users").doc(firebase.auth().currentUser.uid).get()
    ).data().single_will_id[0];
    // console.log(ref);
    await ref.set(
      {
        personal_info: personalInfo,
        partner_info: partnerInfo,
        children_info: childrenInfo,
        guardian_info: guardianInfo,
        gift_info: giftInfo,
        executor_info: executorInfo,
        general_concern_info: generalConcernInfo,
        liquid_asset_info: liquidAssetInfo,
        real_asset_info: realAssetInfo,
        remaining_estate_info: remainingEstateInfo,
        request_info: requestInfo,
        status: "payment_pending",
        time_stamp: firebase.firestore.Timestamp.now(),
        user_id: db.doc("users/" + firebase.auth().currentUser.uid),
        visit_home: this.state.visitHome,
      },
      { merge: true }
    );

    let userInfo = {
      children_info_id: db.doc(
        "childrenInfo/" + firebase.auth().currentUser.uid
      ),
      executor_info_id: db.doc(
        "executorInfo/" + firebase.auth().currentUser.uid
      ),
      general_concern_info_id: db.doc(
        "generalConcernInfo/" + firebase.auth().currentUser.uid
      ),
      gift_info_id: db.doc("giftInfo/" + firebase.auth().currentUser.uid),
      guardian_info_id: db.doc(
        "guardianInfo/" + firebase.auth().currentUser.uid
      ),

      liquid_asset_info_id: db.doc(
        "liquidAssetInfo/" + firebase.auth().currentUser.uid
      ),
      partner_info_id: db.doc("partnerInfo/" + firebase.auth().currentUser.uid),
      personal_info_id: db.doc(
        "personalInfo/" + firebase.auth().currentUser.uid
      ),
      real_asset_info_id: db.doc(
        "realAssetInfo/" + firebase.auth().currentUser.uid
      ),
      remaining_estate_info_id: db.doc(
        "remainingEstateInfo/" + firebase.auth().currentUser.uid
      ),
      request_info_id: db.doc("requestInfo/" + firebase.auth().currentUser.uid),
    };
    await db
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .set(userInfo, { merge: true });

    this.setState({ submitted: true });
    this.props.setSingleWillVisitHome(this.state.visitHome);

    this.props.history.push("/checkout/payment/singlewill/" + ref.id);
  };

  render() {
    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Submit </h1>
        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "justify" }}
        >
          <div>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                margin: "2rem 1.3rem",
                display: "block",
              }}
            >
              <div className="row justify-content-around">
                <div className="col-md-auto d-flex justify-content-center">
                  <Icon name="hand point left" size="huge"></Icon>
                </div>
                <div className="col-md-9">
                  Please check that all the information you have provided is
                  correct before submitting. Users can do this easily from the
                  left navigation panel.
                </div>
              </div>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <span
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              margin: "2rem 1.3rem",
              marginBottom: "1rem",
              display: "block",
            }}
          >
            Will you need a home visit from us for signing the will?
          </span>

          <div
            class="alert alert-info ml-4 mr-4"
            role="alert"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
              width: "auto",
            }}
          >
            <Icon name="info circle" />
            If yes, it will cost extra 50 GBP
          </div>

          <span
            style={{
              display: "block",
              marginBottom: "1rem",
            }}
          >
            <button
              type="button"
              className={
                this.state.visitHome ? "will-button-selected" : "will-button"
              }
              style={{ marginRight: "2rem", width: "30%" }}
              onClick={this.onClickYes}
              name="visitHome"
            >
              Yes
            </button>
            <button
              type="button"
              className={
                this.state.visitHome ? "will-button" : "will-button-selected"
              }
              name="visitHome"
              onClick={this.onClickNo}
              style={{ width: "30%" }}
            >
              No
            </button>
          </span>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "justify" }}
        >
          <span
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              margin: "2rem 1.3rem",
              display: "block",
            }}
          >
            <form onSubmit={this.onFormSubmit}>
              <div style={{ marginBottom: "1.5rem" }}>
                <input id="terms" type="checkbox" required></input>
                <label
                  htmlFor="terms"
                  style={{
                    paddingLeft: "1.5rem",
                    display: "inline",
                    textAlign: "justify",
                  }}
                >
                  By ticking this box I accept the Neolexical Ltd.{" "}
                  <Link to="/tnc">Terms & Conditions</Link>
                </label>
              </div>

              <div>
                <input id="terms2" type="checkbox" required></input>
                <label
                  htmlFor="terms2"
                  style={{
                    paddingLeft: "1.5rem",
                    display: "inline",
                    textAlign: "justify",
                  }}
                >
                  I consent to you using Special Category Personal Data (e.g.
                  racial or ethnic origin, religious and philosophical beliefs,
                  data concerning health, sex life and sexual orientation) in
                  use of the Neolexical app and its services, and in accordance
                  with the Neolexical Privacy Policy.
                </label>
              </div>

              <button
                disabled={this.state.disableSubmit}
                type="submit"
                className=" will-next-button"
              >
                Submit
              </button>
            </form>
          </span>
        </div>

        <button
          type="button"
          id="modalON"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          style={{ display: "none" }}
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Warning
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                class="modal-body"
                style={{
                  fontSize: "1.1rem",
                  textAlign: "justify",
                  lineHeight: "1.5rem",
                  padding: "2rem",
                }}
              >
                Before we can accept any submission from you, we need to verify
                your authenticity. For this reason we need your photo, image of
                governement id and image of proof of address. Please upload
                these from the <a href="/user">Personal Info</a> section.
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={() => {
                    this.props.history.push("/user");
                  }}
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  Go to Personal Info
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSingleWillVisitHome: (data) =>
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_VISIT_HOME,
        payload: {
          visitHome: data,
        },
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};
const SubmitSingleWillWithRouter = withRouter(SubmitSingleWill);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitSingleWillWithRouter);
