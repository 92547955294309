//input every paragraph as an item of array..
//todo: no guardian text
export const childrenInfoText = [
  "This section asks you about whether you have a child or children. By children we mean adult children as well as minors and step-children and those who you consider to be your children by birth or adoption. We need to know their full names and addresses (if different from yours) and their date of birth.",
  "If under the age of 18, we advise you to consider appointing Guardians unless their other parent is or will be alive when you die. Appointing Guardians is always a good idea in case for very young children you both perish together. It is also recommended if you are separated or divorced and the other parent is incapaciated or has relinquished parental responsibility or there is a Court order to that effect. ",
  "If you require further help or information, you can use the enquiry form on this Website and send your enquiry to us, stating that you have completed this questionaire for the Single Will and would like further advice. We may ask you to make an appointment and this can be arranged via Zoom or Google Meet or Microsoft Teams, whichever is your personal preference..",
];

export const guardianText = ["nothing"];

export const liquidAssetText = [
  "This section is optional but you can always mention a global figure of how much readily available cash assets you have in bank and savings accounts. This also includes your investments in stocks and shares",
  "If you wish to give a specific amount of money or cash to one of your beneficiaries, then we recommend completing this section to the best of your ability. You do not have fill in with your bank account details nor your sort-code. We will never ask you to deluge this information unless you wish to nominate a particular asset in your Will to a beneficiary. ",
  "Usually just the name of the bank or building society where you have your account and a rough figure of how much funds you have  in credit. If you have none, state none. If the latter is the case, then you may not be able to leave any specific legacies of cash to any beneficiary you chose, however we leave to your discretion if that remains your wish in your Will.",
  "You can skip this section. We act as Will drafters and therefore cannot give you Financial Advice if you have substantial cash assets. We can always recommend you financial advisors or recommend you seek financial advice from your own Financial Advisor or Accountant if you have one. One of the advantages of making a Will is for prudent Inheritance Tax planning. If you require Estate Planning service, please tick the relevant box or use the Enquiry form on the Website below to request such advice.",
  "There may well be a separate fee charge for giving you such advice outside the service to draft your Will. If you use the services of a Financial advisor they may charge you a fee for their advice and time, in addition to and separate from our fees..",
];

export const realAssetText = [
  "Again this section is entirely optional but should be completed in the event that your assets are substantial or you want to give your house or property to a specific individual beneficiary such as your spouse, civil Partner or child or children or to a friend or a charity. Again if you wish to seek Estate Planning services please complete this section to the best of your ability. We can then tailor your Will to reflect your wishes as to who is to inherit your assets in your Will, and/or whether it is to be shared among your beneficiaries or sold in order to realise the asset value upon your death and the sale money shared among those of your beneficiaries (inheritors) in your Will.",
];

export const generalConcernText = [
  "If you have specific concerns regarding Inheritance Tax, funeral costs and care home fees, then again use this form to indicate which of these concerns you may have generally and we shall get back to you with an appointment separate from drafting your will. Alternatively you are welcome to use the enquiry form to seek further advice.",
];

export const giftText = [
  "In your Will you can specify two types of Gifts. One is gifts of personal items or chattels. This can include for example jewellry or family heirlooms such as furniture, clocks, watches, cars - anything of value that belongs to you and that you wish to leave behind to someone.You can specify which specific gifts are to left for a specific individual beneficiary. For example you can state that your “Wedding Ring is to go to my daughter” or “My car is to go to my son, or wife or child or friend, etc My ming vase or grandfather clock is to go to my Brother”",
  "The second class of specific gifts that you can make in your Will is to leave behind a specific amount of money to a named individual person or to a charity. These are commonly known as pecuniary Legacy or legacies.",
  "For example ‘I leave a gift of £1,000 to my friend Dorothy’ or ‘I leave a gift of £1,000 to my church’ or ‘I leave £1,000 to the Battersea Dogs Trust’.",
];

export const remainingEstateText = [
  "After the payment of any gifts of item (chattels) or gifts of specific amounts of money as in above, and after the payment of all your debts, liabilities, any taxes arising from the fact of your death (if any), testamentary expenses and your funeral bill, the remainder of your all your assets and chattels and money is called the Residue and is often known as your Residuary Estate.",
  "You should tell us how you wish to divide your Residuary estate, by specifying whom and in how many shares each beneficiary is to inherit from your remaining assets. For Example:",
  "“I leave everything to my Wife/Husband and afterwards to my children when he/she passes away”. ",
  "Or “I leave my residue to be shared by all my children equally” ",
  "Or ‘I leave everything in my residue to the following charities”",
];

export const executorText = [
  "Your Executors are the people you nominate to carry out your wishes in your Will. They are solely and lawfully responsible to faithfully and honestly carry out your instructions in your Will and they are the ones who take out the Grant of Administration on your behalf after you die.",
  "You can name an individual such as your spouse or child or friend. You can have up to and including a maximum of 4 Executors. Sometimes it is better to have a professional Executor such as a Solicitor or law firm or Legal company. If that is your wish then Neolexical Ltd trading as Neolexical can provide  this service for you. If that is your wish you can nominate Neolexical Ltd to act as your Professional Executors and tick that option here in this section.",
];

export const requestText = [
  "Please mention your funeral preferences in this section, including wether you would like to be buried or cremated, as well as any other preferences you might have.",
  "Under English law, all adults are automatically enrolled to become organ donors when they die, unless they have made it known that they do not wish to donate. If you would prefer to opt out to organ donation, mention it in this section.",
];
