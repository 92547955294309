import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";

class WhenCanBeUsed extends Component {
  state = {
    selected: "",
    error: "",
  };
  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.whenCanBeUsed !== null)
      this.setState({ selected: this.props.whenCanBeUsed });
  }

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (this.state.selected === "") {
      // console.log("hello");
      this.setState({ error: "Please Select One" });
      return;
    } else {
      let db = firebase.firestore();
      let financialLpaWhenCanBeUsed = {
        when_can_be_used: this.state.selected,
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };

      db.collection("financialLpaWhenCanBeUsed")
        .doc(this.props.currentUser.uid)
        .set(financialLpaWhenCanBeUsed)
        .then(() => {
          // console.log("FinancialLpaWhenCanBeUsed in firestore");
        })
        .catch(() => {
          // console.log("FinancialLpaWhenCanBeUsed in firestore failed");
        });

      this.props.setFinancialLpaWhenCanBeUsed(this.state.selected);
      this.props.setCurrentView(FinancialAffairsStepsEnum.ATTORNEY);
    }
  };
  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          When can the LPA be used?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
            position: "relative",
          }}
        >
          <div className="row">
            <div>
              <p>
                If the donor wants help straight away with managing their
                property and finances, they can say that their LPA can be used
                as soon as it's registered.
              </p>
              <p>
                Alternatively, they can say that their LPA can only be used if
                they do not have mental capacity.
              </p>
            </div>
          </div>
        </div>
        <Divider />

        <form onSubmit={this.onSubmitHandle}>
          <h2>The LPA can be used</h2>
          <div style={{ position: "relative" }}>
            <input
              type="radio"
              className=""
              id="asSoonAs"
              placeholder="First Name"
              checked={this.state.selected === "asSoonAs" ? 1 : 0}
              onChange={() => this.setState({ selected: "asSoonAs" })}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="asSoonAs"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              as soon as it's registered (with the donor's consent)
            </label>
          </div>
          <span
            style={{
              fontSize: "1.2rem",
              paddingLeft: "1rem",
              marginLeft: ".75rem",
              marginBottom: "2rem",
              marginTop: "2.5rem",
              borderLeft: "0.5rem solid grey",
              display: "block",
            }}
          >
            <p>
              Most donors choose this option because it is the most practical.
              While the donor still has mental capacity, their attorneys can
              only act with the donor's permission. The donor will still be in
              control of all decisions affecting them.
            </p>
            <p>
              This option is useful if the donor is able to make their own
              decisions but there’s another reason they want their attorneys'
              help – for example, if the donor is away on holiday, or if they
              have a physical condition that makes it difficult to visit the
              bank.
            </p>
          </span>
          <div style={{ position: "relative" }}>
            <input
              type="radio"
              className=""
              id="only"
              placeholder="First Name"
              checked={this.state.selected === "only" ? 1 : 0}
              onChange={() => this.setState({ details: "", selected: "only" })}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="only"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              only if the donor does not have mental capacity
            </label>
          </div>

          <span
            style={{
              fontSize: "1.2rem",
              paddingLeft: "1rem",
              marginLeft: ".75rem",
              marginBottom: "2rem",
              marginTop: "2.5rem",
              borderLeft: "0.5rem solid grey",
              display: "block",
            }}
          >
            <p>
              This means the attorneys can only act if the donor does not have
              mental capacity to make certain decisions themselves. But be
              careful – this option can make the LPA a lot less useful. Every
              time the attorneys try to use the LPA, they might be asked to
              prove that the donor does not have mental capacity.
            </p>
          </span>
          {this.state.error === "" ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{ fontSize: "1.1rem", fontWeight: "bold" }}
            >
              {this.state.error}
            </div>
          )}
          <button className=" will-next-button">Save And Proceed</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaWhenCanBeUsed: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_WHEN_CAN_BE_USED,
        payload: {
          whenCanBeUsed: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    whenCanBeUsed: state.financial_lpa.whenCanBeUsed,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhenCanBeUsed);
