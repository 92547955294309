import React, { Component } from "react";
import Header from "../landingPage/header/Header";
import Footer from "../footer/Footer";
import ComplaintsBody from "./ComplaintsBody";
import "../../css/tnc.css";

export class Complaints extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <ComplaintsBody />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Complaints;
