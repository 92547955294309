import React, { Component } from "react";
import { Step } from "semantic-ui-react";

class ExecutorInfoSteps extends Component {
  disableAdd = true;

  render() {
    if (this.props.currentView === "select") this.disableAdd = true;
    if (this.props.currentView === "add") this.disableAdd = false;
    return (
      <Step.Group widths={2} size="small">
        <Step
          active={this.props.currentView === "select" ? true : false}
          link
          onClick={() => this.props.setCurrentView("select")}
        >
          <Step.Content>
            <Step.Title>Select</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableAdd}
          active={this.props.currentView === "add" ? true : false}
          onClick={() => this.props.setCurrentView("add")}
        >
          <Step.Content>
            <Step.Title>Add Executor</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

export default ExecutorInfoSteps;
