import React, { Component } from "react";
import { Icon, Step } from "semantic-ui-react";

const ProfileOptions = {
  BASIC_INFO: "basicInfo",
  MY_WILLS: "myWills",
  MY_LPAS: "myLPAs",
  COMPLETED_PRODUCTS: "completedProducts",
};

class UserProfileSteps extends Component {
  render() {
    return (
      <div className="col-md-3 col-2">
        <Step.Group vertical>
          <Step
            className={"step-mini"}
            active={this.props.currentView === ProfileOptions.BASIC_INFO}
            link
            onClick={() => this.props.setCurrentView(ProfileOptions.BASIC_INFO)}
          >
            <Icon name="user circle" className="mt-1 mb-1 " />
            <Step.Content className="d-none d-md-flex ">
              <Step.Title>Personal Info</Step.Title>
            </Step.Content>
          </Step>
          <Step
            className={"step-mini"}
            active={this.props.currentView === ProfileOptions.MY_WILLS}
            link
            onClick={() => this.props.setCurrentView(ProfileOptions.MY_WILLS)}
          >
            <Icon name="newspaper" className="mt-1 mb-1 " />
            <Step.Content className="d-none d-md-flex ">
              <Step.Title>My Wills</Step.Title>
            </Step.Content>
          </Step>
          <Step
            className={"step-mini"}
            active={this.props.currentView === ProfileOptions.MY_LPAS}
            link
            onClick={() => this.props.setCurrentView(ProfileOptions.MY_LPAS)}
          >
            <Icon name="file alternate" className="mt-1 mb-1 " />
            <Step.Content className="d-none d-md-flex ">
              <Step.Title>My LPAs</Step.Title>
            </Step.Content>
          </Step>

          <Step
            className={"step-mini"}
            active={
              this.props.currentView === ProfileOptions.COMPLETED_PRODUCTS
            }
            link
            onClick={() =>
              this.props.setCurrentView(ProfileOptions.COMPLETED_PRODUCTS)
            }
          >
            <Icon name="check circle" className="mt-1 mb-1 " />
            <Step.Content className="d-none d-md-flex ">
              <Step.Title>Completed Wills & LPAs</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      </div>
    );
  }
}

export default UserProfileSteps;
export { ProfileOptions };
