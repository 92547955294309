import React, { Component } from "react";
import Modal from "./Modal";
import * as actionTypes from "../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import * as helpTextList from "../HelpTextList";

class GeneralConcernInfo extends Component {
  state = {
    losing_mental_health: false,
    losing_home: false,
    bankruptcy: false,
    divorce: false,
    paying_inheritance_tax: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.stepNo < 6) this.props.setStepNo(6);
    if (this.props.generalConcernInfo !== null) {
      this.setState(this.props.generalConcernInfo);
    }
  }

  onClickYes = (e) => {
    this.setState({ [e.target.name]: true });
  };
  onClickNo = (e) => {
    // console.log(e.target.name);
    this.setState({ [e.target.name]: false });
    // this.setState({ needGuardian: false });
    // this.props.setCurrentView("liquidAssetInfo");
  };
  onClickNext = () => {
    let db = firebase.firestore();
    let generalConcernInfo = {
      ...this.state,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    db.collection("generalConcernInfo")
      .doc(this.props.currentUser.uid)
      .set(generalConcernInfo)
      .then(() => {
        // console.log("general concern info in firestore");
      })
      .catch(() => {
        // console.log("general concern info in firestore failed");
      });
    this.props.setGeneralConcernInfo(this.state);
    this.props.setCurrentView("giftInfo");
  };

  render() {
    return (
      <div className="">
        <div style={{ textAlign: "center", marginBottom: "3rem" }}>
          <h1 style={{ display: "inline" }}>General Concern</h1>
        </div>
        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: "0.7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Loss of your mental health?
            </span>
            <span>
              <button
                className={
                  this.state.losing_mental_health
                    ? "will-button-selected"
                    : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="losing_mental_health"
              >
                Yes
              </button>
              <button
                className={
                  this.state.losing_mental_health
                    ? "will-button"
                    : "will-button-selected"
                }
                name="losing_mental_health"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: ".7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Being unable to cover care home fees?
            </span>
            <span>
              <button
                className={
                  this.state.losing_home
                    ? "will-button-selected"
                    : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="losing_home"
              >
                Yes
              </button>
              <button
                className={
                  this.state.losing_home
                    ? "will-button"
                    : "will-button-selected"
                }
                name="losing_home"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: ".7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Protecting your assets in case of divorce?
            </span>
            <span>
              <button
                className={
                  this.state.divorce ? "will-button-selected" : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="divorce"
              >
                Yes
              </button>
              <button
                className={
                  this.state.divorce ? "will-button" : "will-button-selected"
                }
                name="divorce"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: "0.7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Protecting your assets in case of bankruptcy?
            </span>
            <span>
              <button
                className={
                  this.state.bankruptcy ? "will-button-selected" : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="bankruptcy"
              >
                Yes
              </button>
              <button
                className={
                  this.state.bankruptcy ? "will-button" : "will-button-selected"
                }
                name="bankruptcy"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: "0.7rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "0rem",
              }}
            >
              Your Beneficiaries having to pay inheritance tax?
            </span>
            <span>
              <button
                className={
                  this.state.paying_inheritance_tax
                    ? "will-button-selected"
                    : "will-button"
                }
                style={{ marginRight: "2rem" }}
                onClick={this.onClickYes}
                name="paying_inheritance_tax"
              >
                Yes
              </button>
              <button
                className={
                  this.state.paying_inheritance_tax
                    ? "will-button"
                    : "will-button-selected"
                }
                name="paying_inheritance_tax"
                onClick={this.onClickNo}
              >
                No
              </button>
            </span>
          </div>
        </div>
        <button
          className=" will-next-button"
          style={{ marginTop: "1rem" }}
          onClick={this.onClickNext}
        >
          Next
        </button>

        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="General Concern Info"
          body={helpTextList.generalConcernText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGeneralConcernInfo: (generalConcernInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GENERAL_CONCERN_INFO,
        payload: {
          userGeneralConcernInfo: generalConcernInfo,
        },
      }),
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    generalConcernInfo: state.user.generalConcernInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConcernInfo);
