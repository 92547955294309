import React, { Component } from "react";

import "../../../css/ourServices.css";
import OurServicesCard from "./OurServicesCard";
import {Link} from "react-router-dom";

// const ourServicesArray = [
//   [
//     {
//       icon: "home",
//       headingText: "LPA",
//       description: " Experts help at difficult time",
//       link: "/",
//       linkDescription: "See Our LPA Services",
//     },
//     {
//       icon: "edit",
//       headingText: "Making a Will",
//       description: "We Make Writing a Will Easy",
//       link: "/",
//       linkDescription: "See Our Will Writing Services",
//     },
//   ],
// ];

class OurServices extends Component {
  render() {
    return (
      <div className="section-container" id="our-services-container">
        <div className="ourServices-heading ">Our Services</div>
        <div className="ourServices-subheading alert-info">
        You can create, submit and track the progress of your Will and LPA completely online through our website and Mobile Application (Coming soon). It only takes 15 minutes to fill out the information needed to create your own will or LPA. You can also come back later and continue where you left off. Our expert solicitors will handle your case and are always here to assist you if you have any questions or want to set up a face to face appointment. Just <a href="/#consulting"> contact us</a>!
        </div>

        <div className="row justify-content-around">
          <div className="col-lg-5 ourServices-item-container mb-5 mb-lg-0">
            <div
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.472)" }}
              className="ourServices-subsection-heading"
            >
              <div className="ourServicesCard-heading">
                <i className={"huge icon home "}></i>

                <span className="ourServicesCard-heading-text">LPA</span>
              </div>
              <div className="ourServicesCard-description">
                Online Lasting Power of Attorney services
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <OurServicesCard
                  {...{
                    icon: "money bill alternate",
                    headingText: "Financial Affairs",
                    description:
                      "This LPA covers the donor’s money and property.",
                    link: "/financial-affairs-info",
                    linkDescription: "See Our Financial LPA Services",
                  }}
                />
              </div>
              <div className="col-6">
                <OurServicesCard
                  {...{
                    icon: "heartbeat",
                    headingText: "Health & Welfare",
                    description:
                      "This LPA covers the donor’s health care and personal welfare.",
                    link: "/health-welfare-info",
                    linkDescription: "See Our Health LPA Services",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 ourServices-item-container ">
            <div
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.472)" }}
              className="ourServices-subsection-heading"
            >
              <div className="ourServicesCard-heading">
                <i className={"huge icon edit "}></i>

                <span className="ourServicesCard-heading-text">
                  Will Writing
                </span>
              </div>
              <div className="ourServicesCard-description">
                Online Will drafting services
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <OurServicesCard
                  {...{
                    icon: "user",
                    headingText: "Single Will",
                    description: "Draft a will for yourself",
                    link: "/single-will-info",
                    linkDescription: "See Our Single Will Writing Services",
                  }}
                />
              </div>
              <div className="col-6">
                <OurServicesCard
                  {...{
                    icon: "users",
                    headingText: "Double Will",
                    description: "Draft a will for you and your partner",
                    link: "/double-will-info",
                    linkDescription: "See Our Double Will Writing Services",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-11 ourServices-item-container" id="additional-services"
               style={{
                 // marginTop: "50px",
               }}>
            <div
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.472)",
                }}
                className="ourServices-subsection-heading"
            >
              <div className="ourServicesCard-heading">
                <i className={"huge icon edit "}></i>

                <span className="ourServicesCard-heading-text">
                  Additional Services
                </span>
              </div>
              <div className="ourServicesCard-description">
                We provide a wide range of services that you can avail by booking an appointment. Some of these services are mentioned in this section. <br/>
                  Price for these services is on a hourly basis (£267/hour). <Link to="/contactus"> Contact us</Link> for an appointment. Further price information available <Link to="/pricing"> here</Link>.
              </div>
            </div>
            <div className="row justify-content-center">

              <div className="col-lg-4 col-md-6">
                <OurServicesCard
                    {...{
                      icon: "shield alternate",
                      headingText: "Trust Services ",
                      description: "Create a standalone trust or one alongside a will",
                      link: "/contactus",
                      linkDescription: "Contact us for appointment",
                    }}
                />
              </div>

              <div className="col-lg-4 col-md-6">
                <OurServicesCard
                    {...{
                      icon: "balance scale",
                      headingText: "Probate Services",
                      description: "Services for Grant of Probate or Letters of Administration",
                      link: "/contactus",
                      linkDescription: "Contact us for appointment",
                    }}
                />
              </div>

              <div className="col-lg-4 col-md-6">
                <OurServicesCard
                    {...{
                      icon: "user circle",
                      headingText: "Inheritance Disputes",
                      description: "Services for inheritence disputes",
                      link: "/contactus",
                      linkDescription: "Contact us for appointment",
                    }}
                />
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default OurServices;
