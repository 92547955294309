import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Icon } from "semantic-ui-react";
import * as Lists from "../Lists";
import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";

class PersonToNotify extends Component {
  state = {
    person_to_notify: [],
    error: "",
  };
  countryList = Lists.countryList;
  titleList = Lists.titleList;

  onSubmitHandle = (e) => {
    e.preventDefault();

    let db = firebase.firestore();
    let financialLpaPersonToNotifyInfo = {
      person_to_notify: this.state.person_to_notify,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("financialLpaPersonToNotifyInfo")
      .doc(this.props.currentUser.uid)
      .set(financialLpaPersonToNotifyInfo)
      .then(() => {
        // console.log("FinancialLpaAttorney in firestore");
      })
      .catch(() => {
        // console.log("FinancialLpaAttorney in firestore failed");
      });

    this.props.setFinancialLpaPersonToNotify(this.state.person_to_notify);

    this.props.setCurrentView(
      FinancialAffairsStepsEnum.PREFERENCES_N_INSTRUCTIONS
    );
  };

  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.personToNotify !== null) {
      this.setState({ person_to_notify: this.props.personToNotify });
    }
  }

  onClickAdd = () => {
    if (this.state.person_to_notify.length === 5) {
      this.setState({ error: "Maximum Person To Notify is 5" });
      return;
    }
    let person_to_notify = [...this.state.person_to_notify];
    //todo: maximum can be added 5
    for (
      let index = this.state.person_to_notify.length;
      index < this.state.person_to_notify.length + 1;
      index++
    ) {
      person_to_notify[index] = {
        title: "",
        first_name: "",

        last_name: "",

        address: {
          street_or_house_no: "",
          city: "",
          postal_code: "",
          country: "",
        },
      };
    }
    this.setState({
      person_to_notify: person_to_notify,
    });
  };
  onClickNext = (e) => {
    e.preventDefault();
  };

  onClickRemove = (index) => {
    let person_to_notify = [...this.state.person_to_notify];

    person_to_notify.splice(index, 1);
    this.setState({ person_to_notify: person_to_notify });
  };
  render() {
    let person_to_notify_rows = [];

    for (let index = 0; index < this.state.person_to_notify.length; index++) {
      person_to_notify_rows.push(
        <div
          key={index}
          style={{
            marginBottom: "3.5rem",
          }}
        >
          <span>
            <h3 style={{ marginBottom: "1rem", marginTop: "1rem" }}>
              Information of Person to Notify no.{index + 1}
              <button
                type="button"
                className="remove-form"
                style={{ float: "right" }}
                onClick={() => this.onClickRemove(index)}
              >
                <Icon name="x icon"></Icon>
              </button>
            </h3>
          </span>

          <div>
            <label
              htmlFor="title"
              className="col-form-label col-form-label required-field"
              style={{ fontWeight: "bold" }}
            >
              Title
            </label>
            <select
              id={"title" + index}
              className="form-control"
              value={this.state.person_to_notify[index].title}
              onChange={(e) => {
                let person_to_notify = [...this.state.person_to_notify];
                person_to_notify[index].title = e.target.value;
                this.setState({ person_to_notify: person_to_notify });
              }}
              name={"title" + index}
              required
            >
              <option value="" disabled hidden>
                Title
              </option>
              {this.titleList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>

            <label
              htmlFor={"firstName" + index}
              className="col-form-label col-form-label required-field"
              style={{ marginTop: "1rem", fontWeight: "bold" }}
            >
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id={"firstName" + index}
              placeholder="First Name"
              onChange={(e) => {
                let person_to_notify = [...this.state.person_to_notify];
                person_to_notify[index].first_name = e.target.value;
                this.setState({ person_to_notify: person_to_notify });
              }}
              value={this.state.person_to_notify[index].first_name}
              name="first_name"
              required
            />
            <label
              htmlFor={"lastName" + index}
              className="col-form-label col-form-label required-field"
              style={{ marginTop: "1rem", fontWeight: "bold" }}
            >
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id={"lastName" + index}
              placeholder="Last Name"
              onChange={(e) => {
                let person_to_notify = [...this.state.person_to_notify];
                person_to_notify[index].last_name = e.target.value;
                this.setState({ person_to_notify: person_to_notify });
              }}
              value={this.state.person_to_notify[index].last_name}
              name="last_name"
              required
            />
            <label
              htmlFor={"address" + index}
              className="required-field col-form-label col-form-label"
              style={{
                marginTop: "2.8rem",
                marginBottom: "0.5rem",
                fontWeight: "bold",
                fontSize: "1.3rem",
              }}
            >
              Address:
            </label>

            <div
              id={"address" + index}
              style={{ marginLeft: "0rem", marginBottom: "4rem" }}
            >
              <label
                htmlFor="streetOrHouseNo"
                className="col-form-label col-form-label"
                style={{ marginTop: "0rem", fontWeight: "bold" }}
              >
                Street/House No.
              </label>

              <input
                type="text"
                className="form-control"
                id="streetOrHouseNo"
                placeholder="Street/House No"
                onChange={(e) => {
                  let person_to_notify = [...this.state.person_to_notify];
                  person_to_notify[index].address.street_or_house_no =
                    e.target.value;
                  this.setState({ person_to_notify: person_to_notify });
                }}
                required
                value={
                  this.state.person_to_notify[index].address
                    .street_or_house_no || ""
                }
                name="street_or_house_no"
              />

              <label
                htmlFor={"city" + index}
                className="col-form-label col-form-label"
                style={{ marginTop: "0.5rem", fontWeight: "bold" }}
              >
                City
              </label>

              <input
                type="text"
                className="form-control"
                id={"city" + index}
                placeholder="City"
                onChange={(e) => {
                  let person_to_notify = [...this.state.person_to_notify];
                  person_to_notify[index].address.city = e.target.value;
                  this.setState({ person_to_notify: person_to_notify });
                }}
                required
                value={this.state.person_to_notify[index].address.city || ""}
                name="city"
              />

              <label
                htmlFor={"postalCode" + index}
                className="col-form-label col-form-label"
                style={{ marginTop: "0.5rem", fontWeight: "bold" }}
              >
                Postal Code
              </label>
              <div>
                {this.state.person_to_notify[index].error_postal_code === "" ? (
                  <span></span>
                ) : (
                  <span style={{ color: "red" }}>
                    {this.state.person_to_notify[index].error_postal_code}
                  </span>
                )}
              </div>

              <input
                type="text"
                className="form-control"
                id={"postalCode" + index}
                placeholder="Postal Code"
                onChange={(e) => {
                  let person_to_notify = [...this.state.person_to_notify];
                  person_to_notify[index].address.postal_code = e.target.value;
                  this.setState({ person_to_notify: person_to_notify });
                }}
                required
                value={
                  this.state.person_to_notify[index].address.postal_code || ""
                }
                name="postal_code"
              />

              <label
                htmlFor={"country" + index}
                className="col-form-label col-form-label"
                style={{ marginTop: "0.5rem", fontWeight: "bold" }}
              >
                Country
              </label>

              <select
                id={"country" + index}
                className="form-control"
                value={this.state.person_to_notify[index].address.country}
                onChange={(e) => {
                  let person_to_notify = [...this.state.person_to_notify];
                  person_to_notify[index].address.country = e.target.value;
                  this.setState({ person_to_notify: person_to_notify });
                }}
                name="country"
                required
              >
                <option value="" disabled hidden>
                  Country
                </option>
                {this.countryList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            {this.state.person_to_notify.length === index + 1 ? (
              <span></span>
            ) : (
              <Divider />
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        {" "}
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Who should be notified about the LPA?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            display: "block",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
          }}
        >
          <p>
            The donor can choose up to 5 people to be notified about the LPA
            application. These people have a chance to raise any concerns before
            the LPA is registered.
          </p>

          <p>
            Having ‘people to notify’ is optional, though many donors choose
            family members or close friends.
          </p>
          <p>
            You will be given a form to download and send to any people to
            notify once you have finished making the LPA.
          </p>
        </div>
        <Divider />
        <form onSubmit={this.onSubmitHandle}>
          {person_to_notify_rows}

          {this.state.error === "" ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{ fontSize: "1.1rem", fontWeight: "bold" }}
            >
              {this.state.error}
            </div>
          )}
          <button
            type="button"
            className=" will-button-selected-with-hover"
            style={{
              marginTop: "2rem",
              width: "100%",
              fontSize: "1.2rem",
            }}
            onClick={this.onClickAdd}
          >
            Add Person to Notify
          </button>

          <button className=" will-next-button" style={{ marginTop: "2rem" }}>
            {this.state.person_to_notify.length === 0 ? (
              <span>Skip</span>
            ) : (
              <span>Save And Proceed</span>
            )}
          </button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaPersonToNotify: (personToNotify) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_PERSON_TO_NOTIFY,
        payload: {
          personToNotify: personToNotify,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    personToNotify: state.financial_lpa.personToNotify,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonToNotify);
