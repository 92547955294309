import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "../../config/firebase";
import { Dimmer, Loader } from "semantic-ui-react";
import { connect } from "react-redux";

class Verification extends Component {
  state = {
    isVerified: null,
    isResetPassword: null,
    password: null,
    confirmPassword: null,
    passwordResetComplete: null,
    passwordResetError: "",
  };

  stringPair = {};
  email = "";

  componentDidMount() {
    // // console.log(this.props);
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.props.clearUser();
      });

    let searchString = this.props.location.search;
    searchString = searchString.slice(1);
    let stringArray = searchString.split("&");

    stringArray.forEach((item) => {
      item = item.split("=");
      this.stringPair[item[0]] = item[1];
    });
    // console.log(this.stringPair);
    if (this.stringPair["mode"] === "verifyEmail")
      firebase
        .auth()
        .applyActionCode(this.stringPair["oobCode"])
        .then((res) => {
          this.setState({ isVerified: true });
        })
        .catch((err) => {
          this.setState({ isVerified: false });
        });
    else {
      firebase
        .auth()
        .verifyPasswordResetCode(this.stringPair["oobCode"])
        .then((email) => {
          this.email = email;
          this.setState({ isResetPassword: true });
        })
        .catch(() => {
          this.setState({ isResetPassword: false });
        });
    }
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onClickResetPassword = () => {
    if (this.state.password.length < 6) {
      this.setState({
        passwordResetError: "Password length must be greater than 6",
      });
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        passwordResetError: "Password and Confirm Password do not match",
      });
      return;
    }
    firebase
      .auth()
      .confirmPasswordReset(this.stringPair["oobCode"], this.state.password)
      .then((resp) => {
        // Password reset has been confirmed and new password updated.
        this.setState({ passwordResetComplete: true });
      })
      .catch((error) => {
        this.setState({
          passwordResetComplete: false,
          passwordResetError: error.message,
        });
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
  };
  render() {
    if (this.state.isVerified === null && this.state.isResetPassword === null)
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    else if (this.state.isVerified)
      return (
        <React.Fragment>
          {/* <Header /> */}
          <div
            className="body-container"
            style={{ height: "20rem", marginBottom: "20rem" }}
          >
            <div
              className="card w-50 center-verify-card"
              style={{ flexDirection: "column" }}
            >
              <div className="card-header">
                <h2>Email Verification</h2>
              </div>
              <div className="card-body">
                <div className="alert alert-success card-text" role="alert">
                  <h4>Your email is now verified</h4>
                  You can log in now
                </div>

                <div style={{ textAlign: "center" }}>
                  <Link to="/login">
                    <button className="blue-will-button will-button">
                      Log in
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      );
    else if (this.state.isVerified === false)
      return (
        <React.Fragment>
          {/* <Header /> */}
          <div
            className="body-container"
            style={{ height: "20rem", marginBottom: "20rem" }}
          >
            <div
              className="card w-50 center-verify-card"
              style={{ flexDirection: "column" }}
            >
              <div className="card-header">
                <h2>Email Verification</h2>
              </div>
              <div className="card-body">
                <div className="alert alert-danger card-text" role="alert">
                  <h4>Your email can not be verified</h4>
                  Something went wrong ,try again
                </div>

                <div style={{ textAlign: "center" }}>
                  <Link to="/">
                    <button className="blue-will-button will-button">
                      Go Home
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      );
    else if (
      this.state.isResetPassword &&
      this.state.passwordResetComplete === null
    )
      return (
        <React.Fragment>
          {/* <Header /> */}
          <div className="body-container" style={{ height: "20rem" }}>
            <div
              className="card w-50 center-verify-card"
              style={{ flexDirection: "column" }}
            >
              <div className="card-header">
                <h2>Password Reset</h2>
              </div>
              <div className="card-body">
                <p>
                  Reset password for account Email:{" "}
                  <strong>{this.email}</strong>
                </p>
                <label
                  htmlFor="password"
                  className="col-form-label col-form-label "
                  style={{ marginTop: "1rem", fontWeight: "bold" }}
                >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={this.onChangeHandler}
                  value={this.state.password}
                  name="password"
                  required
                />

                <label
                  htmlFor="confirmPassword"
                  className="col-form-label col-form-label "
                  style={{ marginTop: "1rem", fontWeight: "bold" }}
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={this.onChangeHandler}
                  value={this.state.confirmPassword}
                  name="confirmPassword"
                  required
                />
                {this.state.passwordResetError !== "" && (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {this.state.passwordResetError}
                  </span>
                )}
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <button
                    onClick={this.onClickResetPassword}
                    className="blue-will-button will-button"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      );
    else if (
      this.state.isResetPassword === false &&
      this.state.passwordResetComplete === null
    )
      return (
        <React.Fragment>
          {/* <Header /> */}
          <div
            className="body-container"
            style={{ height: "20rem", marginBottom: "20rem" }}
          >
            <div
              className="card w-50 center-verify-card"
              style={{ flexDirection: "column" }}
            >
              <div className="card-header">
                <h2>Reset Password</h2>
              </div>
              <div className="card-body">
                <div className="alert alert-danger card-text" role="alert">
                  <h4>Can not reset your password</h4>
                  Try again later
                </div>

                <div style={{ textAlign: "center" }}>
                  <Link to="/">
                    <button className="blue-will-button will-button">
                      Go Home
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      );
    else if (this.state.passwordResetComplete)
      return (
        <React.Fragment>
          {/* <Header /> */}
          <div
            className="body-container"
            style={{ height: "20rem", marginBottom: "20rem" }}
          >
            <div
              className="card w-50 center-verify-card"
              style={{ flexDirection: "column" }}
            >
              <div className="card-header">
                <h2>Reset Password</h2>
              </div>
              <div className="card-body">
                <div className="alert alert-success card-text" role="alert">
                  <h4>Your password has been reset</h4>
                  You can log in now
                </div>

                <div style={{ textAlign: "center" }}>
                  <Link to="/login">
                    <button className="blue-will-button will-button">
                      Log in
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      );
    else if (this.state.passwordResetComplete === false)
      return (
        <React.Fragment>
          {/* <Header /> */}
          <div
            className="body-container"
            style={{ height: "20rem", marginBottom: "20rem" }}
          >
            <div
              className="card w-50 center-verify-card"
              style={{ flexDirection: "column" }}
            >
              <div className="card-header">
                <h2>Reset Password</h2>
              </div>
              <div className="card-body">
                <div className="alert alert-danger card-text" role="alert">
                  <h4>Can not reset your password</h4>
                  Try again later
                </div>

                <div style={{ textAlign: "center" }}>
                  <Link to="/">
                    <button className="blue-will-button will-button">
                      Go Home
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearUser: () =>
      dispatch({
        type: "CLEAR_USER",
        payload: {
          currentUser: null,
        },
      }),
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
  };
};

export default connect(null, mapDispatchToProps)(Verification);
