export const SET_USER = "SET_USER";
export const SET_USER_DOC = "SET_USER_DOC";
export const CLEAR_USER = "CLEAR_USER";
export const SET_USER_PERSONAL_INFO = "SET_USER_PERSONAL_INFO";
export const SET_USER_PARTNER_INFO = "SET_USER_PARTNER_INFO";
export const SET_USER_CHILDREN_INFO = "SET_USER_CHILDREN_INFO";
export const SET_USER_GUARDIAN_INFO = "SET_USER_GUARDIAN_INFO";
export const SET_USER_LIQUID_ASSET_INFO = "SET_USER_LIQUID_ASSET_INFO";
export const SET_USER_REAL_ASSET_INFO = "SET_USER_REAL_ASSET_INFO";
export const SET_USER_GENERAL_CONCERN_INFO = "SET_USER_GENERAL_CONCERN_INFO";
export const SET_USER_GIFT_INFO = "SET_USER_GIFT_INFO";
export const SET_USER_REMAINING_ESTATE_INFO = "SET_USER_REMAINING_ESTATE_INFO";
export const SET_USER_EXECUTOR_INFO = "SET_USER_EXECUTOR_INFO";
export const SET_USER_REQUEST_INFO = "SET_USER_REQUEST_INFO";
export const SET_SINGLE_WILL_STEP_NO = "SET_SINGLE_WILL_STEP_NO";
export const SET_PERSONAL_INFO_DONE = "SET_PERSONAL_INFO_DONE";

export const SET_PARTNER_CHILDREN_INFO = "SET_PARTNER_CHILDREN_INFO";
export const SET_PARTNER_GUARDIAN_INFO = "SET_PARTNER_GUARDIAN_INFO";
export const SET_PARTNER_LIQUID_ASSET_INFO = "SET_PARTNER_LIQUID_ASSET_INFO";
export const SET_PARTNER_REAL_ASSET_INFO = "SET_PARTNER_REAL_ASSET_INFO";
export const SET_PARTNER_GENERAL_CONCERN_INFO =
  "SET_PARTNER_GENERAL_CONCERN_INFO";
export const SET_PARTNER_GIFT_INFO = "SET_PARTNER_GIFT_INFO";
export const SET_PARTNER_REMAINING_ESTATE_INFO =
  "SET_PARTNER_REMAINING_ESTATE_INFO";
export const SET_PARTNER_EXECUTOR_INFO = "SET_PARTNER_EXECUTOR_INFO";
export const SET_PARTNER_REQUEST_INFO = "SET_PARTNER_REQUEST_INFO";
export const SET_PARTNER_STEP_NO = "SET_PARTNER_STEP_NO";
export const SET_PARTNER_INFO_DONE = "SET_PARTNER_INFO_DONE";

export const SET_FINANCIAL_LPA_DONOR = "SET_FINANCIAL_LPA_DONOR";
export const SET_FINANCIAL_LPA_WHEN_CAN_BE_USED =
  "SET_FINANCIAL_LPA_WHEN_CAN_BE_USED";
export const SET_FINANCIAL_LPA_ATTORNEY = "SET_FINANCIAL_LPA_ATTORNEY";
export const SET_FINANCIAL_LPA_ATTORNEYS_MAKE_DECISION =
  "SET_FINANCIAL_LPA_ATTORNEYS_MAKE_DECISION";
export const SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEY =
  "SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEY";
export const SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION =
  "SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION";
export const SET_FINANCIAL_LPA_CERTIFICATE_PROVIDER =
  "SET_FINANCIAL_LPA_CERTIFICATE_PROVIDER";
export const SET_FINANCIAL_LPA_PERSON_TO_NOTIFY =
  "SET_FINANCIAL_LPA_PERSON_TO_NOTIFY";
export const SET_FINANCIAL_LPA_PREFERENCES_AND_INSTRUCTIONS =
  "SET_FINANCIAL_LPA_PREFERENCES_AND_INSTRUCTIONS";
export const SET_FINANCIAL_LPA_REGISTRATION = "SET_FINANCIAL_LPA_REGISTRATION";
export const SET_FINANCIAL_LPA_CORRESPONDENT =
  "SET_FINANCIAL_LPA_CORRESPONDENT";
export const SET_FINANCIAL_LPA_REPEAT = "SET_FINANCIAL_LPA_REPEAT";
export const SET_FINANCIAL_LPA_STEP = "SET_FINANCIAL_LPA_STEP";
export const SET_FINANCIAL_LPA_DISABLE_REG_COR =
  "SET_FINANCIAL_LPA_DISABLE_REG_COR";

export const SET_HEALTH_LPA_DONOR = "SET_HEALTH_LPA_DONOR";
export const SET_HEALTH_LPA_LIFE_SUSTAINING_TREATMENT =
  "SET_HEALTH_LPA_LIFE_SUSTAINING_TREATMENT";
export const SET_HEALTH_LPA_ATTORNEY = "SET_HEALTH_LPA_ATTORNEY";
export const SET_HEALTH_LPA_ATTORNEYS_MAKE_DECISION =
  "SET_HEALTH_LPA_ATTORNEYS_MAKE_DECISION";
export const SET_HEALTH_LPA_REPLACEMENT_ATTORNEY =
  "SET_HEALTH_LPA_REPLACEMENT_ATTORNEY";
export const SET_HEALTH_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION =
  "SET_HEALTH_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION";
export const SET_HEALTH_LPA_CERTIFICATE_PROVIDER =
  "SET_HEALTH_LPA_CERTIFICATE_PROVIDER";
export const SET_HEALTH_LPA_PERSON_TO_NOTIFY =
  "SET_HEALTH_LPA_PERSON_TO_NOTIFY";
export const SET_HEALTH_LPA_PREFERENCES_AND_INSTRUCTIONS =
  "SET_HEALTH_LPA_PREFERENCES_AND_INSTRUCTIONS";
export const SET_HEALTH_LPA_REGISTRATION = "SET_HEALTH_LPA_REGISTRATION";
export const SET_HEALTH_LPA_CORRESPONDENT = "SET_HEALTH_LPA_CORRESPONDENT";
export const SET_HEALTH_LPA_REPEAT = "SET_HEALTH_LPA_REPEAT";
export const SET_HEALTH_LPA_STEP = "SET_HEALTH_LPA_STEP";
export const SET_HEALTH_LPA_DISABLE_REG_COR = "SET_HEALTH_LPA_DISABLE_REG_COR";

export const SET_DOUBLE_WILL_VISIT_HOME = "SET_DOUBLE_WILL_VISIT_HOME";
export const SET_SINGLE_WILL_VISIT_HOME = "SET_SINGLE_WILL_VISIT_HOME";
