import React, { Component } from "react";
import firebase from "../../config/firebase";

class ContactUsBody extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    message: "",
    msgSent: null,
    error: "",
  };
  componentDidMount() {
    window.scroll(0, 0);
  }
  sendContactMessage = async (e) => {
    e.preventDefault();
    const { name, email, phone, message } = this.state;
    const data = { name, email, phone, message };
    // console.log(data);
    var phoneno = /^\+?([0-9]+)$/;
    // // console.log(this.state.phoneNumber.match(phoneno));
    if (this.state.phone.match(phoneno) === null) {
      this.setState({
        error:
          "Please give a valid phone number ( e.g. +4407804388055 or 07804388055 . Do not use any space, dot or other special characters)",
      });
      return;
    } else {
      this.setState({ error: "" });
    }
    if (
      message != null &&
      message.length > 0 &&
      email != null &&
      email.length > 0
    ) {
      try {
        const addMessage = firebase
          .app()
          .functions("europe-west2")
          .httpsCallable("handleWebsiteContactUsMessage");

        this.setState({
          msgSent: true,
        });

        await addMessage(data);
      } catch (error) {
        this.setState({
          msgSent: false,
        });
        console.error(error);
      }
    } else {
      // console.log("Invalid input!");
    }
  };
  render() {
    return (
      <div className="section-container">
        <div
          className="aboutus-section-heading"
          style={{ marginBottom: "2rem" }}
        >
          Contact Us
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.3rem",
            fontStyle: "italic",
            marginBottom: "4rem",
          }}
        >
          Want to meet us in office? Our Office hours are 9AM to 4PM. You can also call us during office hours.
        </div>
        <div className="row  align-items-center">
          <div className="col-lg-5">
            <a href="#map">
              {" "}
              <div className="row contactus-item">
                <div className="col-lg-2 contactus-item-icon">
                  <i className="map marker big alternate icon"></i>
                </div>
                <div className="col-lg-9">
                  <div className="row contactus-item-heading">Address</div>
                  <div className="row contactus-item-text">
                    28 Scholars Way, Dagenham, London, RM8 2FL 
                  </div>
                </div>
              </div>
            </a>

            <a href="tel:07804-388-055">
              <div className="row contactus-item">
                <div className="col-lg-2 contactus-item-icon">
                  <i className="phone big  icon"></i>
                </div>
                <div className="col-lg-9">
                  <div className="row contactus-item-heading">Phone</div>
                  <div className="row contactus-item-text">07804-388-055</div>
                </div>
              </div>
            </a>

            <a href="mailto:info@neolexical.com">
              <div className="row contactus-item">
                <div className="col-lg-2 contactus-item-icon">
                  <i className="envelope  big icon"></i>
                </div>
                <div className="col-lg-9">
                  <div className="row contactus-item-heading">Email</div>
                  <div className="row contactus-item-text">
                    info@neolexical.com
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-5 offset-lg-2 contactus-card-container">
            <div className="contactus-card">
              <form onSubmit={this.sendContactMessage}>
                <div className="row form-group">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      placeholder="Name"
                    ></input>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-6">
                    <input
                      type="email"
                      className="form-control"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="Email"
                      required
                    ></input>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.phone}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      placeholder="Phone"
                    ></input>
                    {this.state.error === "" ? (
                      <span></span>
                    ) : (
                      <span style={{ color: "red" }}>{this.state.error}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    value={this.state.message}
                    onChange={(e) => this.setState({ message: e.target.value })}
                    rows="4"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
                {this.state.msgSent == null && (
                  <button
                    type="submit"
                    className="btn btn-outline-primary appointment-btn px-5"
                  >
                    Send
                  </button>
                )}

                {this.state.msgSent != null && this.state.msgSent && (
                  <div className="alert alert-success alert-dismissible fade show">
                    <strong>Success!</strong> Your message has been sent
                    successfully.
                    <button
                      type="button"
                      class="close"
                      onClick={(e) => this.setState({ msgSent: null })}
                    >
                      &times;
                    </button>
                  </div>
                )}

                {this.state.msgSent != null && !this.state.msgSent && (
                  <div class="alert alert-danger alert-dismissible fade show">
                    <strong>Error!</strong>
                    Failed to send your message. Please try again!
                    <button
                      type="button"
                      class="close"
                      onClick={(e) => this.setState({ msgSent: null })}
                    >
                      &times;
                    </button>
                  </div>
                )}
              </form>

            </div>
            <div
              style={{
                fontSize: "0.5rem",
                fontStyle: "italic",
                fontWeight: "bold",
                marginTop: "2rem",
                marginLeft: "2rem",
                marginRight: "2rem"
              }}
            >
              Please note if your enquiry is made outside office hours, we aim to contact you on the next available business day the office is open.
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsBody;
