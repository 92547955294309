import React, { Component } from "react";
import { Step } from "semantic-ui-react";

class RemainingEstateInfoSteps extends Component {
  disableAdd = true;
  disableSub = true;

  render() {
    if (this.props.currentView === "select") {
      this.disableAdd = true;
      this.disableSub = true;
    }
    if (this.props.currentView === "add") this.disableAdd = false;
    if (this.props.currentView === "substitute") this.disableSub = false;
    return (
      <Step.Group widths={3} size="small">
        <Step
          active={this.props.currentView === "select" ? true : false}
          link
          onClick={() => this.props.setCurrentView("select")}
        >
          <Step.Content>
            <Step.Title>Select Beneficiary</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableAdd}
          active={this.props.currentView === "add" ? true : false}
          onClick={() => this.props.setCurrentView("add")}
        >
          <Step.Content>
            <Step.Title>Add Beneficiary</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableSub}
          active={this.props.currentView === "substitute" ? true : false}
          onClick={() => this.props.setCurrentView("substitute")}
        >
          <Step.Content>
            <Step.Title>Substitute Beneficiary</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

export default RemainingEstateInfoSteps;
