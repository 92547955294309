import React, { Component } from "react";
import Header from "../../landingPage/header/Header";
import VizSensor from "react-visibility-sensor";

import Footer from "../../footer/Footer";

import { Dimmer, Loader } from "semantic-ui-react";

import DoubleWillPersonalBody from "./DoubleWillPersonalBody";
import DoubleWillPartnerBody from "./DoubleWillPartnerBody";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import * as bodyTypes from "./DoubleWillEnums";
import * as actionTypes from "../../../actions/types";

class DoubleWill extends Component {
  state = {
    visibleFooter: "float-button",
    currentView: "info",
    stepNo: null,
    isLogged: false,
    loadingUser: true,
    dataLoading: true,
    doubleWillBody: bodyTypes.PERSONAL_BODY,
    completedPersonalStep: 0,
    completedPartnerStep: 0,
  };

  readData = async (uid) => {
    let db = firebase.firestore();
    let stepNo = 0;
    //reading personal info..........
    let personalInfoRef = db.collection("personalInfo").doc(uid);
    let personalDoc = await personalInfoRef.get();
    if (!personalDoc.exists) {
      // this.props.history.push("/double-will-info");
      // console.log("No such personalDocument!");
      // return;
    } else {
      stepNo = 1;
      this.props.setPersonalInfo({
        ...personalDoc.data(),
        date_of_birth: personalDoc
          .data()
          .date_of_birth.toDate()
          .toLocaleDateString("fr-CA"),
      });
    }
    //end reading personal info..........

    //reading partner info.......
    let partnerInfoRef = db.collection("partnerInfo").doc(uid);
    let partnerDoc = await partnerInfoRef.get();
    if (!partnerDoc.exists) {
      // console.log("No such partnerDocument!");
    } else {
      stepNo = 2;
      this.props.setPartnerInfo({
        ...partnerDoc.data(),
        date_of_birth: partnerDoc
          .data()
          .date_of_birth.toDate()
          .toLocaleDateString("fr-CA"),
      });
    }
    //end reading partner info.......

    //reading children info.......
    let childrenInfoRef = db.collection("childrenInfo").doc(uid);
    let childrenDoc = await childrenInfoRef.get();
    if (!childrenDoc.exists) {
      // console.log("No such childrenDocument!");
    } else {
      stepNo = 3;
      this.props.setChildrenInfo({
        number: childrenDoc.data().children_count,
        info: childrenDoc.data().children_details,
      });
    }
    //end reading children info.......

    //reading guardian info.......
    let guardianInfoRef = db.collection("guardianInfo").doc(uid);
    let guardianDoc = await guardianInfoRef.get();
    if (!guardianDoc.exists) {
      // console.log("No such guardianDocument!");
    } else {
      stepNo = 4;
      // console.log("found guardian doc");
      this.props.setGuardianInfo({
        ...guardianDoc.data(),
      });
    }
    //end reading guardian info.......

    //reading liquidAsset info.......
    let liquidAssetInfoRef = db.collection("liquidAssetInfo").doc(uid);
    let liquidAssetDoc = await liquidAssetInfoRef.get();
    if (!liquidAssetDoc.exists) {
      // console.log("No such liquidAssetDocument!");
    } else {
      stepNo = 5;
      // console.log("found liquidAsset doc");
      this.props.setLiquidAssetInfo({
        ...liquidAssetDoc.data(),
      });
    }
    //end reading liquidAsset info.......

    //reading realAsset info.......
    let realAssetInfoRef = db.collection("realAssetInfo").doc(uid);
    let realAssetDoc = await realAssetInfoRef.get();
    if (!realAssetDoc.exists) {
      // console.log("No such realAssetDocument!");
    } else {
      stepNo = 6;
      // console.log("found realAsset doc");
      this.props.setRealAssetInfo({
        ...realAssetDoc.data(),
      });
    }
    //end reading realAsset info.......

    //reading generalConcern info.......
    let generalConcernInfoRef = db.collection("generalConcernInfo").doc(uid);
    let generalConcernDoc = await generalConcernInfoRef.get();
    if (!generalConcernDoc.exists) {
      // console.log("No such generalConcernDocument!");
    } else {
      stepNo = 7;
      // console.log("found generalConcern doc");
      this.props.setGeneralConcernInfo({
        ...generalConcernDoc.data(),
      });
    }
    //end reading generalConcern info.......

    //reading gift info.......
    let giftInfoRef = db.collection("giftInfo").doc(uid);
    let giftDoc = await giftInfoRef.get();
    if (!giftDoc.exists) {
      // console.log("No such giftDocument!");
    } else {
      stepNo = 8;
      // console.log("found gift doc");
      this.props.setGiftInfo({
        ...giftDoc.data(),
      });
    }
    //end reading gift info.......

    //reading remainingEstate info.......
    let remainingEstateInfoRef = db.collection("remainingEstateInfo").doc(uid);
    let remainingEstateDoc = await remainingEstateInfoRef.get();
    if (!remainingEstateDoc.exists) {
      // console.log("No such remainingEstateDocument!");
    } else {
      stepNo = 9;
      // console.log("found remainingEstate doc");
      this.props.setRemainingEstateInfo({
        ...remainingEstateDoc.data(),
      });
    }
    //end reading remainingEstate info.......

    //reading executor info.......
    let executorInfoRef = db.collection("executorInfo").doc(uid);
    let executorDoc = await executorInfoRef.get();
    if (!executorDoc.exists) {
      // console.log("No such executorDocument!");
    } else {
      stepNo = 10;
      // console.log("found executor doc");
      this.props.setExecutorInfo({
        ...executorDoc.data(),
      });
    }
    //end reading executor info.......

    //reading request info.......
    let requestInfoRef = db.collection("requestInfo").doc(uid);
    let requestDoc = await requestInfoRef.get();
    if (!requestDoc.exists) {
      this.props.setStepNo(stepNo);
      this.setState({ completedPersonalStep: stepNo });
      this.props.history.push("/doublewill");
      // console.log("No such requestDocument!");
    } else {
      stepNo = 11;
      this.props.setStepNo(stepNo);
      this.setState({ completedPersonalStep: stepNo });
      // console.log("found request doc");
      this.props.setRequestInfo({
        ...requestDoc.data(),
      });
      this.props.history.push("/doublewill");
    }
    //end reading request info.......
    // this.setState({ dataLoading: false });

    //........................................................partner doc reading....................................
    stepNo = 2;
    //reading children info.......
    let childrenPartnerInfoRef = db.collection("partnerChildrenInfo").doc(uid);
    let partnerChildrenDoc = await childrenPartnerInfoRef.get();
    if (!partnerChildrenDoc.exists) {
      // console.log("No such partnerChildrenDocument!");
    } else {
      stepNo = 3;
      this.props.setPartnerChildrenInfo({
        number: partnerChildrenDoc.data().children_count,
        info: partnerChildrenDoc.data().children_details,
      });
    }
    //end reading children info.......

    //reading guardian info.......
    let guardianPartnerInfoRef = db.collection("partnerGuardianInfo").doc(uid);
    let partnerGuardianDoc = await guardianPartnerInfoRef.get();
    if (!partnerGuardianDoc.exists) {
      // console.log("No such partnerGuardianDocument!");
    } else {
      stepNo = 4;
      // console.log("found guardian doc");
      this.props.setPartnerGuardianInfo({
        ...partnerGuardianDoc.data(),
      });
    }
    //end reading guardian info.......

    //reading liquidAsset info.......
    let liquidAssetPartnerInfoRef = db
      .collection("partnerLiquidAssetInfo")
      .doc(uid);
    let partnerLiquidAssetDoc = await liquidAssetPartnerInfoRef.get();
    if (!partnerLiquidAssetDoc.exists) {
      // console.log("No such partnerLiquidAssetDocument!");
    } else {
      stepNo = 5;
      // console.log("found liquidAsset doc");
      this.props.setPartnerLiquidAssetInfo({
        ...partnerLiquidAssetDoc.data(),
      });
    }
    //end reading liquidAsset info.......

    //reading realAsset info.......
    let realAssetPartnerInfoRef = db
      .collection("partnerRealAssetInfo")
      .doc(uid);
    let partnerRealAssetDoc = await realAssetPartnerInfoRef.get();
    if (!partnerRealAssetDoc.exists) {
      // console.log("No such partnerRealAssetDocument!");
    } else {
      stepNo = 6;
      // console.log("found realAsset doc");
      this.props.setPartnerRealAssetInfo({
        ...partnerRealAssetDoc.data(),
      });
    }
    //end reading realAsset info.......

    //reading generalConcern info.......
    let generalConcernPartnerInfoRef = db
      .collection("partnerGeneralConcernInfo")
      .doc(uid);
    let partnerGeneralConcernDoc = await generalConcernPartnerInfoRef.get();
    if (!partnerGeneralConcernDoc.exists) {
      // console.log("No such partnerGeneralConcernDocument!");
    } else {
      stepNo = 7;
      // console.log("found generalConcern doc");
      this.props.setPartnerGeneralConcernInfo({
        ...partnerGeneralConcernDoc.data(),
      });
    }
    //end reading generalConcern info.......

    //reading gift info.......
    let giftPartnerInfoRef = db.collection("partnerGiftInfo").doc(uid);
    let partnerGiftDoc = await giftPartnerInfoRef.get();
    if (!partnerGiftDoc.exists) {
      // console.log("No such partnerGiftDocument!");
    } else {
      stepNo = 8;
      // console.log("found gift doc");
      this.props.setPartnerGiftInfo({
        ...partnerGiftDoc.data(),
      });
    }
    //end reading gift info.......

    //reading remainingEstate info.......
    let remainingEstatePartnerInfoRef = db
      .collection("partnerRemainingEstateInfo")
      .doc(uid);
    let partnerRemainingEstateDoc = await remainingEstatePartnerInfoRef.get();
    if (!partnerRemainingEstateDoc.exists) {
      // console.log("No such partnerRemainingEstateDocument!");
    } else {
      stepNo = 9;
      // console.log("found remainingEstate doc");
      this.props.setPartnerRemainingEstateInfo({
        ...partnerRemainingEstateDoc.data(),
      });
    }
    //end reading remainingEstate info.......

    //reading executor info.......
    let executorPartnerInfoRef = db.collection("partnerExecutorInfo").doc(uid);
    let partnerExecutorDoc = await executorPartnerInfoRef.get();
    if (!partnerExecutorDoc.exists) {
      // console.log("No such partnerExecutorDocument!");
    } else {
      stepNo = 10;
      // console.log("found executor doc");
      this.props.setPartnerExecutorInfo({
        ...partnerExecutorDoc.data(),
      });
    }
    //end reading executor info.......

    //reading request info.......
    let requestPartnerInfoRef = db.collection("partnerRequestInfo").doc(uid);
    let partnerRequestDoc = await requestPartnerInfoRef.get();
    if (!partnerRequestDoc.exists) {
      this.props.setPartnerStepNo(stepNo);
      this.setState({ completedPartnerStep: stepNo });
      this.setState({ dataLoading: false });
      // this.props.history.push("/doublewill");
      // console.log("No such partnerRequestDocument!");
    } else {
      stepNo = 11;
      this.props.setPartnerStepNo(stepNo);
      this.setState({ completedPartnerStep: stepNo });
      // console.log("found request doc");
      this.props.setPartnerRequestInfo({
        ...partnerRequestDoc.data(),
      });
      this.setState({ dataLoading: false });
      // this.props.history.push("/doublewill");
    }
    //end reading request info.......
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    var user = { ...firebase.auth().currentUser };
    // console.log(user);
    const db = firebase.firestore();
    user.providerData.forEach((item) => {
      if (item.providerId === "facebook.com") user.emailVerified = true;
    });
    if (user === null || !user.emailVerified) {
      this.props.history.push("/login");
    } else if (firebase.auth().currentUser !== null) {
      const userSnapshot = await db
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .get();
      // this.props.setUser({ currentUser: userSnapshot.data() });
      // console.log(userSnapshot.data()["double_will_id"]);
      if (
        userSnapshot.exists &&
        userSnapshot.data()["double_will_id"] !== null &&
        userSnapshot.data()["double_will_id"] !== undefined &&
        userSnapshot.data()["double_will_id"].length > 0
      ) {
        this.setState({ loadingUser: false });
        this.readData(firebase.auth().currentUser.uid);
      } else {
        this.props.history.push("/double-will-info");
      }
    } else {
      this.props.history.push("/login");
    }
  }
  setCurrentView = (view) => {
    this.setState({ currentView: view });
  };

  setStepNo = (no) => {
    this.setState({ stepNo: no });
  };

  render() {
    if (this.state.loadingUser || this.state.dataLoading) {
      window.scrollTo(0, 0);
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Header />
          <div
            className="body-container"
            style={{ marginBottom: "0", paddingBottom: "0" }}
          >
            {" "}
            <div className="row justify-content-center">
              <div className="col-auto col-md-4">
                <button
                  className={
                    this.state.doubleWillBody === bodyTypes.PERSONAL_BODY
                      ? "will-button-selected "
                      : "will-button"
                  }
                  style={{ marginRight: "1rem", width: "9.5rem" }}
                  onClick={() => {
                    this.setState({ doubleWillBody: bodyTypes.PERSONAL_BODY });
                  }}
                >
                  Personal
                </button>
                <button
                  className={
                    this.state.doubleWillBody === bodyTypes.PARTNER_BODY
                      ? "will-button-selected "
                      : "will-button"
                  }
                  style={{ width: "9.5rem" }}
                  onClick={() => {
                    this.setState({ doubleWillBody: bodyTypes.PARTNER_BODY });
                  }}
                >
                  Partner
                </button>
              </div>
              <div className="col-0 offset-0 offset-md-6"></div>
            </div>
          </div>
          {this.state.doubleWillBody === bodyTypes.PERSONAL_BODY ? (
            <DoubleWillPersonalBody
              visibleFooter={this.state.visibleFooter}
              stepNo={this.props.stepNo}
            />
          ) : (
            <DoubleWillPartnerBody
              visibleFooter={this.state.visibleFooter}
              stepNo={this.props.partnerStepNo}
            />
          )}

          <VizSensor
            onChange={(isVisible) => {
              if (isVisible) this.setState({ visibleFooter: "fixed-button" });
              else this.setState({ visibleFooter: "float-button" });
            }}
          >
            <div style={{ height: "1rem" }}></div>
          </VizSensor>
          <Footer />
        </React.Fragment>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
    setPersonalInfo: (personalInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PERSONAL_INFO,
        payload: {
          userPersonalInfo: personalInfo,
        },
      }),
    setPartnerInfo: (partnerInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PARTNER_INFO,
        payload: {
          userPartnerInfo: partnerInfo,
        },
      }),
    setChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_USER_CHILDREN_INFO,
        payload: {
          userChildrenInfo: childrenInfo,
        },
      }),
    setGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GUARDIAN_INFO,
        payload: {
          userGuardianInfo: guardianInfo,
        },
      }),
    setLiquidAssetInfo: (liquidAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_LIQUID_ASSET_INFO,
        payload: {
          userLiquidAssetInfo: liquidAssetInfo,
        },
      }),
    setRealAssetInfo: (realAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REAL_ASSET_INFO,
        payload: {
          userRealAssetInfo: realAssetInfo,
        },
      }),
    setGeneralConcernInfo: (generalConcernInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GENERAL_CONCERN_INFO,
        payload: {
          userGeneralConcernInfo: generalConcernInfo,
        },
      }),
    setGiftInfo: (giftInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GIFT_INFO,
        payload: {
          userGiftInfo: giftInfo,
        },
      }),
    setRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REMAINING_ESTATE_INFO,
        payload: {
          userRemainingEstateInfo: remainingEstateInfo,
        },
      }),
    setExecutorInfo: (executorInfo) =>
      dispatch({
        type: actionTypes.SET_USER_EXECUTOR_INFO,
        payload: {
          userExecutorInfo: executorInfo,
        },
      }),
    setRequestInfo: (requestInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REQUEST_INFO,
        payload: {
          userRequestInfo: requestInfo,
        },
      }),

    setPartnerChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_CHILDREN_INFO,
        payload: {
          partnerChildrenInfo: childrenInfo,
        },
      }),
    setPartnerGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GUARDIAN_INFO,
        payload: {
          partnerGuardianInfo: guardianInfo,
        },
      }),
    setPartnerLiquidAssetInfo: (liquidAssetInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_LIQUID_ASSET_INFO,
        payload: {
          partnerLiquidAssetInfo: liquidAssetInfo,
        },
      }),
    setPartnerRealAssetInfo: (realAssetInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REAL_ASSET_INFO,
        payload: {
          partnerRealAssetInfo: realAssetInfo,
        },
      }),
    setPartnerGeneralConcernInfo: (generalConcernInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GENERAL_CONCERN_INFO,
        payload: {
          partnerGeneralConcernInfo: generalConcernInfo,
        },
      }),
    setPartnerGiftInfo: (giftInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GIFT_INFO,
        payload: {
          partnerGiftInfo: giftInfo,
        },
      }),
    setPartnerRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REMAINING_ESTATE_INFO,
        payload: {
          partnerRemainingEstateInfo: remainingEstateInfo,
        },
      }),
    setPartnerExecutorInfo: (executorInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_EXECUTOR_INFO,
        payload: {
          partnerExecutorInfo: executorInfo,
        },
      }),
    setPartnerRequestInfo: (requestInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REQUEST_INFO,
        payload: {
          partnerRequestInfo: requestInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    stepNo: state.user.singleWillStepNo,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoubleWill);

// this.state.currentView === "info" ? (
//   <DoubleWillInfo
//     setCurrentView={this.setCurrentView}
//     setStepNo={this.setStepNo}
//   />
// ) : (
