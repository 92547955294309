import React, { Component } from "react";

import firebase from "../../config/firebase";

import { Dimmer, Loader } from "semantic-ui-react";
import { connect } from "react-redux";

import UserProfileSteps, { ProfileOptions } from "./UserProfileSteps";

import BasicInfo from "./BasicInfo";
import MyWills from "./MyWills";
import MyLPAs from "./MyLPAs";
import CompletedProducts from "./CompletedProducts";

class UserProfileBody extends Component {
  state = {
    currentView: "basicInfo",
    addClass: "",
    loading: true,

    singleWillLoading: true,
    singleWillData: null,
    singleWillDocID: null,
    doubleWillLoading: true,
    doubleWillData: null,
    doubleWillDocID: null,

    healthLpaLoading: true,
    healthLpaData: null,
    healthLpaDocID: null,
    financialLpaLoading: true,
    financialLpaData: null,
    financialLpaDocID: null,
  };

  setCurrentView = (view) => {
    window.scrollTo(0, 0);
    this.setState({ currentView: view });
  };

  readData = async () => {
    let db = firebase.firestore();
    //reading single will
    let singleWillRef = (
      await db.collection("users").doc(this.props.currentUser.uid).get()
    ).data()?.single_will_id;

    // if (singleWillRef === undefined) {
    //   this.readData();
    //   return;
    // }

    // singleWillRef = singleWillRef.single_will_id;

    if (singleWillRef !== undefined && singleWillRef !== null) {
      // console.log(singleWillRef[0].id);

      let completedSingleWillRef = db
        .collection("completedProducts")
        .doc(firebase.auth().currentUser.uid)
        .collection("singleWills")
        .doc(singleWillRef[0].id);
      let completedSingleWillDoc = await completedSingleWillRef.get();
      if (!completedSingleWillDoc.exists) {
        // console.log("No such completedSingleWillDocument!");
      } else if (
        completedSingleWillDoc.exists &&
        !this.state.iscomponentUnmounted
      ) {
        this.setState({
          singleWillData: completedSingleWillDoc.data(),
          singleWillDocID: singleWillRef[0].id,
        });
      }
    }

    //reading double will
    let doubleWillRef = (
      await db.collection("users").doc(this.props.currentUser.uid).get()
    ).data()?.double_will_id;

    if (doubleWillRef !== undefined && doubleWillRef !== null) {
      // console.log(doubleWillRef[0].id);

      let completedDoubleWillRef = db
        .collection("completedProducts")
        .doc(firebase.auth().currentUser.uid)
        .collection("doubleWills")
        .doc(doubleWillRef[0].id);
      let completedDoubleWillDoc = await completedDoubleWillRef.get();
      if (!completedDoubleWillDoc.exists) {
        // console.log("No such completedDoubleWillDocument!");
      } else if (
        completedDoubleWillDoc.exists &&
        !this.state.iscomponentUnmounted
      ) {
        this.setState({
          doubleWillData: completedDoubleWillDoc.data(),
          doubleWillDocID: doubleWillRef[0].id,
        });
      }
    }

    //reading health LPA
    let healthLpaRef = (
      await db.collection("users").doc(this.props.currentUser.uid).get()
    ).data()?.health_lpa_id;

    if (healthLpaRef !== undefined && healthLpaRef !== null) {
      // console.log(healthLpaRef[0].id);

      let completedHealthLpaRef = db
        .collection("completedProducts")
        .doc(firebase.auth().currentUser.uid)
        .collection("healthLPAs")
        .doc(healthLpaRef[0].id);
      let completedHealthLpaDoc = await completedHealthLpaRef.get();
      if (!completedHealthLpaDoc.exists) {
        // console.log("No such completedHealthLpaDocument!");
      } else {
        this.setState({
          healthLpaData: completedHealthLpaDoc.data(),
          healthLpaDocID: healthLpaRef[0].id,
        });
      }
    }

    //reading financial LPa
    let financialLpaRef = (
      await db.collection("users").doc(this.props.currentUser.uid).get()
    ).data()?.financial_lpa_id;

    if (financialLpaRef !== undefined && financialLpaRef !== null) {
      // console.log(financialLpaRef[0].id);

      let completedFinancialLpaRef = db
        .collection("completedProducts")
        .doc(firebase.auth().currentUser.uid)
        .collection("financialLPAs")
        .doc(financialLpaRef[0].id);
      let completedFinancialLpaDoc = await completedFinancialLpaRef.get();
      if (!completedFinancialLpaDoc.exists) {
        // console.log("No such completedFinancialLpaDocument!");
      } else {
        this.setState({
          financialLpaData: completedFinancialLpaDoc.data(),
          financialLpaDocID: financialLpaRef[0].id,
        });
      }
    }

    this.setState({
      loading: false,
      singleWillLoading: false,
      doubleWillLoading: false,
      healthLpaLoading: false,
      financialLpaLoading: false,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.readData();
  }

  onClickHandler = (e) => {
    this.setState({ current: e.target.name });
  };
  handleSignout = () => {
    this.setState({ loading: true });
    firebase
      .auth()
      .signOut()
      .then(() => {
        // console.log("sign out");
        this.props.clearUser();
        this.props.history.push("/");
      });
  };
  render() {
    let currentViewComponent;
    if (this.state.currentView === ProfileOptions.BASIC_INFO)
      currentViewComponent = (
        <BasicInfo
          setCurrentView={this.setCurrentView}
          user={this.props.currentUser}
        />
      );
    else if (this.state.currentView === ProfileOptions.MY_WILLS) {
      let data = {
        singleWillLoading: this.state.singleWillLoading,
        singleWillData: this.state.singleWillData,
        singleWillDocID: this.state.singleWillDocID,
        doubleWillLoading: this.state.doubleWillLoading,
        doubleWillData: this.state.doubleWillData,
        doubleWillDocID: this.state.doubleWillDocID,
      };
      currentViewComponent = (
        <MyWills data={data} setCurrentView={this.setCurrentView} />
      );
    } else if (this.state.currentView === ProfileOptions.MY_LPAS) {
      let data = {
        healthLpaLoading: this.state.healthLpaLoading,
        healthLpaData: this.state.healthLpaData,
        healthLpaDocID: this.state.healthLpaDocID,
        financialLpaLoading: this.state.financialLpaLoading,
        financialLpaData: this.state.financialLpaData,
        financialLpaDocID: this.state.financialLpaDocID,
      };
      currentViewComponent = (
        <MyLPAs data={data} setCurrentView={this.setCurrentView} />
      );
    } else if (this.state.currentView === ProfileOptions.COMPLETED_PRODUCTS)
      currentViewComponent = (
        <CompletedProducts setCurrentView={this.setCurrentView} />
      );

    if (this.state.loading || this.props.userDocLoading)
      return (
        <div style={{ height: "100vh" }}>
          <Dimmer active>
            <Loader size="massive">Loading</Loader>
          </Dimmer>
        </div>
      );
    else
      return (
        <div className="body-container">
          <div className="row">
            <UserProfileSteps
              setCurrentView={this.setCurrentView}
              currentView={this.state.currentView}
            />
            {currentViewComponent}
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    userDocLoading: state.user.userDocLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUser: () =>
      dispatch({
        type: "CLEAR_USER",
        payload: {
          currentUser: null,
        },
      }),
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileBody);
