import React, { Component } from "react";
import firebase from "../../config/firebase";
import Footer from "../footer/Footer";
import Header from "../landingPage/header/Header";

class VerifyEmail extends Component {
  state = {
    sendVerification: false,
    error: null,
  };
  onClick = () => {
    var user = firebase.auth().currentUser;
    user
      .sendEmailVerification()
      .then(() => {
        this.setState({ sendVerification: true, error: null });
        // Email sent.
      })
      .catch((error) => {
        // console.log(error);
        this.setState({ error: error.message });
        // An error happened.
      });
  };
  render() {
    return (
      <React.Fragment>
        <Header />
        <div
          className="body-container"
          style={{ height: "20rem", marginBottom: "20rem" }}
        >
          <div
            className="card w-50 center-verify-card"
            style={{ flexDirection: "column" }}
          >
            <div className="card-header">
              <h2>Email Verification</h2>
            </div>
            <div className="card-body">
              <div className="alert alert-danger card-text" role="alert">
                <h4>Your email is not verified</h4>
                Please verify your email address.
              </div>
              {this.state.sendVerification ? (
                <div style={{ margin: "1rem 0rem", fontSize: "1.1rem" }}>
                  {" "}
                  We have sent verification mail to{" "}
                  <i>{firebase.auth().currentUser.email}</i>. Please check Your
                  inbox. If you did not receive the verification mail, please
                  click resend button.{" "}
                </div>
              ) : (
                <span></span>
              )}

              <div style={{ textAlign: "center" }}>
                {this.state.sendVerification ? (
                  <button
                    className="blue-will-button will-button"
                    onClick={this.onClick}
                  >
                    Resend Verification Link
                  </button>
                ) : (
                  <button
                    className="blue-will-button will-button"
                    onClick={this.onClick}
                  >
                    Send Verification Link
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default VerifyEmail;

// https://neolexical-demo.firebaseapp.com/__/auth/action
