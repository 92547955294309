import React, { Component } from "react";
import axios from "axios";

import * as actionTypes from "../../../actions/types";
import "../../../css/will.css";
import * as helpTextList from "../HelpTextList";
import Modal from "./Modal";

import { connect } from "react-redux";
import firebase from "../../../config/firebase";

export class PersonalInfo extends Component {
  state = {
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    previous_last_name: "",
    gender: "",
    marital_status: "",
    address: {
      street_or_house_no: "",
      city: "",
      postal_code: "",
      country: "",
    },
    mobile: "",
    email: "",
    date_of_birth: "",
    error_postal_code: "",
    error_mobile: "",
  };

  titleList = ["Mr", "Mrs", "Ms", "Miss", "Professor", "Dr"];
  genderList = ["Male", "Female", "Non-binary"];
  maritalStatusList = [
    "Single",
    "Married",
    "Widowed",
    "Divorced",
    "Separated",
    "Living with Partner",
    "Partner (Living Separately)",
    "Civil Partnership",
  ];

  countryList = [
    "United Kingdom",
    "Wales",
    "Afganistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua & Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bonaire",
    "Bosnia & Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Ter",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Canary Islands",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Channel Islands",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos Island",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote DIvoire",
    "Croatia",
    "Cuba",
    "Curaco",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Ter",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Great Britain",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guyana",
    "Haiti",
    "Hawaii",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "Indonesia",
    "India",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea North",
    "Korea Sout",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malaysia",
    "Malawi",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Midway Islands",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Nambia",
    "Nauru",
    "Nepal",
    "Netherland Antilles",
    "Netherlands",
    "Nevis",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau Island",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Phillipines",
    "Pitcairn Island",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of Montenegro",
    "Republic of Serbia",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "St Barthelemy",
    "St Eustatius",
    "St Helena",
    "St Kitts-Nevis",
    "St Lucia",
    "St Maarten",
    "St Pierre & Miquelon",
    "St Vincent & Grenadines",
    "Saipan",
    "Samoa",
    "Samoa American",
    "San Marino",
    "Sao Tome & Principe",
    "Saudi Arabia",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tahiti",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks & Caicos Is",
    "Tuvalu",
    "Uganda",

    "Ukraine",
    "United Arab Erimates",
    "United States of America",
    "Uraguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (Brit)",
    "Virgin Islands (USA)",
    "Wake Island",
    "Wallis & Futana Is",
    "Yemen",
    "Zaire",
    "Zambia",
    "Zimbabwe",
  ];

  onChangeHandler = (e) => {
    if (e.target.name === "street_or_house_no") {
      let tempAddress = {
        ...this.state.address,
        street_or_house_no: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "city") {
      let tempAddress = {
        ...this.state.address,
        city: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "postal_code") {
      let tempAddress = {
        ...this.state.address,
        postal_code: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "country") {
      let tempAddress = {
        ...this.state.address,
        country: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else this.setState({ [e.target.name]: e.target.value });
  };

  ageRequirement = () => {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    // console.log(date.toDateString());
    return date.toLocaleDateString("fr-CA");
  };

  isPostalCodeValid = async () => {
    // console.log(`api.postcodes.io/postcodes/${this.state.address.postal_code}`);
    // let res = await axios.get(
    //   `https://api.postcodes.io/postcodes/${this.state.address.postal_code}`
    // );
    // // console.log(res);
    // return res;
    // axios
    //   .get(
    //     `https://api.postcodes.io/postcodes/${this.state.address.postal_code}`
    //   )
    //   .then((res) => {
    //     // console.log(res.data.status === 200);
    //     return true;
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //     return false;
    //   });
  };

  dbValidAction = () => {
    this.setState({ error_postal_code: "" });
    let db = firebase.firestore();
    let personalInfo = {
      ...this.state,
      date_of_birth: firebase.firestore.Timestamp.fromDate(
        new Date(this.state.date_of_birth)
      ),
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    delete personalInfo.error_postal_code;
    delete personalInfo.error_mobile;
    db.collection("personalInfo")
      .doc(this.props.currentUser.uid)
      .set(personalInfo)
      .then(() => {
        // console.log("personal info in firestore");
      })
      .catch(() => {
        // console.log("personal info in firestore failed");
      });
    this.props.setPersonalInfo(this.state);

    if (this.state.marital_status === "Single") {
      // clearing prev partner info
      this.props.setPartnerInfo(null);

      db.collection("partnerInfo")
        .doc(this.props.currentUser.uid)
        .delete()
        .then(() => {
          // console.log("partner info cleared");
        })
        .catch(() => {
          // console.log("no partner info to delete");
        });
      this.props.setCurrentView("childrenInfo");
    } else this.props.setCurrentView("partnerInfo");
  };

  onSubmitHandle = async (e) => {
    e.preventDefault();
    // let res = await this.isPostalCodeValid();
    // // console.log(res);
    // if (res || true) {
    //   return;
    // }
    var phoneno = /^\+?([0-9]+)$/;
    if (this.state.mobile.match(phoneno) === null) {
      this.setState({
        error_mobile:
          "Please give a valid phone number ( e.g. +4407804388055 or 07804388055 . Do not use any space, dot or other special characters)",
      });
      return;
    } else {
      this.setState({ error_mobile: "" });
    }

    if (
      this.state.address.country === "United Kingdom" ||
      this.state.address.country === "Wales"
    )
      axios
        .get(
          `https://api.postcodes.io/postcodes/${this.state.address.postal_code.trim()}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            let resultCountry =
              res.data.result.country === "England"
                ? "United Kingdom"
                : res.data.result.country;

            if (this.state.address.country === resultCountry)
              this.dbValidAction();
            else {
              this.setState({ error_postal_code: "Invalid Post Code" });
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          this.setState({ error_postal_code: "Invalid Post Code" });
        });
    else this.dbValidAction();
    // let db = firebase.firestore();
    // let personalInfo = {
    //   ...this.state,
    //   date_of_birth: firebase.firestore.Timestamp.fromDate(
    //     new Date(this.state.date_of_birth)
    //   ),
    //   user_id: db.doc("users/" + this.props.currentUser.uid),
    // };

    // db.collection("personalInfo")
    //   .doc(this.props.currentUser.uid)
    //   .set(personalInfo)
    //   .then(() => {
    //     // console.log("personal info in firestore");
    //   })
    //   .catch(() => {
    //     // console.log("personal info in firestore failed");
    //   });
    // this.props.setPersonalInfo(this.state);

    // if (this.state.marital_status === "Single") {
    //   // clearing prev partner info
    //   this.props.setPartnerInfo(null);

    //   db.collection("partnerInfo")
    //     .doc(this.props.currentUser.uid)
    //     .delete()
    //     .then(() => {
    //       // console.log("partner info cleared");
    //     })
    //     .catch(() => {
    //       // console.log("no partner info to delete");
    //     });
    //   this.props.setCurrentView("childrenInfo");
    // } else this.props.setCurrentView("partnerInfo");
  };

  getPersonalInfoDoc = async () => {
    let db = firebase.firestore();
    let personalInfoRef = db
      .collection("personalInfo")
      .doc(this.props.currentUser.uid);
    let doc = await personalInfoRef.get();
    // console.log(doc);
    return doc;
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    // let doc = this.getPersonalInfoDoc();
    // while (this.props.currentUser === null);
    // let db = firebase.firestore();
    // let personalInfoRef = db
    //   .collection("personalInfo")
    //   .doc(this.props.currentUser.uid);
    // let doc = await personalInfoRef.get();
    // if (!doc.exists) {
    //   // console.log("No such document!");
    // } else {
    //   // console.log(
    //     doc.data().date_of_birth.toDate().toLocaleDateString("fr-CA")
    //   );
    //   // {{doc.data().date_of_birth.toDate() | date: 'dd MMM hh:mm' }}
    //   // this.setState(doc.data);

    //   this.props.setPersonalInfo({
    //     ...doc.data(),
    //     date_of_birth: doc
    //       .data()
    //       .date_of_birth.toDate()
    //       .toLocaleDateString("fr-CA"),
    //   });
    // }
    this.setState({ ...this.props.personalInfo, error_postal_code: "" });
  }

  render() {
    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Personal Info</h1>
        <form onSubmit={this.onSubmitHandle}>
          <label
            htmlFor="title"
            className="col-form-label col-form-label required-field"
            style={{ fontWeight: "bold" }}
          >
            Title
          </label>
          <select
            id="title"
            className="form-control"
            value={this.state.title}
            onChange={this.onChangeHandler}
            name="title"
            required
          >
            <option value="" disabled hidden>
              Title
            </option>
            {this.titleList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>

          <label
            htmlFor="firstName"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="First Name"
            onChange={this.onChangeHandler}
            value={this.state.first_name}
            name="first_name"
            required
          />

          <label
            htmlFor="middleName"
            className="col-form-label col-form-label "
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Middle Name
          </label>

          <input
            type="text"
            className="form-control"
            id="middleName"
            placeholder="Middle Name"
            onChange={this.onChangeHandler}
            value={this.state.middle_name}
            name="middle_name"
          />

          <label
            htmlFor="surname"
            className="col-form-label col-form-label  required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Surname
          </label>

          <input
            type="text"
            className="form-control"
            id="surname"
            placeholder="Surname"
            onChange={this.onChangeHandler}
            value={this.state.last_name}
            name="last_name"
            required
          />

          <label
            htmlFor="prevSurname"
            className="col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Previous Surname
          </label>

          <input
            type="text"
            className="form-control"
            id="prevSurname"
            placeholder="Previous Surname"
            onChange={this.onChangeHandler}
            value={this.state.previous_last_name}
            name="previous_last_name"
          />

          <label
            htmlFor="gender"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Gender
          </label>

          <select
            id="gender"
            className="form-control"
            value={this.state.gender || ""}
            onChange={this.onChangeHandler}
            name="gender"
            required
          >
            <option value="" disabled hidden>
              Gender
            </option>
            {this.genderList.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>

          <label
            htmlFor="maritalStatus"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Marital Status
          </label>

          <select
            id="maritalStatus"
            className="form-control"
            value={this.state.marital_status || ""}
            onChange={this.onChangeHandler}
            name="marital_status"
            required
          >
            <option value="" disabled hidden>
              Marital Status
            </option>
            {this.maritalStatusList.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>

          <label
            htmlFor="address"
            className="required-field col-form-label col-form-label"
            style={{
              marginTop: "2.8rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            Address:
          </label>

          {/* <textarea
            type="textarea"
            className="form-control"
            id="address"
            placeholder="Address"
            onChange={this.onChangeHandler}
            value={this.state.address}
            name="address"
            required
          /> */}
          <div id="address" style={{ marginLeft: "0rem" }}>
            <label
              htmlFor="streetOrHouseNo"
              className="col-form-label col-form-label"
              style={{ marginTop: "0rem", fontWeight: "bold" }}
            >
              Street/House No.
            </label>

            <input
              type="text"
              className="form-control"
              id="streetOrHouseNo"
              placeholder="Street/House No"
              onChange={this.onChangeHandler}
              required
              value={this.state.address.street_or_house_no || ""}
              name="street_or_house_no"
            />

            <label
              htmlFor="city"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              City
            </label>

            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="City"
              onChange={this.onChangeHandler}
              required
              value={this.state.address.city || ""}
              name="city"
            />

            <label
              htmlFor="postalCode"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              Postal Code
            </label>
            <div>
              {this.state.error_postal_code === "" ? (
                <span></span>
              ) : (
                <span style={{ color: "red" }}>
                  {this.state.error_postal_code}
                </span>
              )}
            </div>

            <input
              type="text"
              className="form-control"
              id="postalCode"
              placeholder="Postal Code"
              onChange={this.onChangeHandler}
              required
              value={this.state.address.postal_code || ""}
              name="postal_code"
            />

            <label
              htmlFor="country"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              Country
            </label>

            <select
              id="country"
              className="form-control"
              value={this.state.address.country}
              onChange={this.onChangeHandler}
              name="country"
              required
            >
              <option value="" disabled hidden>
                Country
              </option>
              {this.countryList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <label
            htmlFor="mobile"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "2.8rem", fontWeight: "bold" }}
          >
            Mobile
          </label>
          <div>
            {this.state.error_mobile === "" ? (
              <span></span>
            ) : (
              <span style={{ color: "red" }}>{this.state.error_mobile}</span>
            )}
          </div>

          <input
            type="text"
            className="form-control"
            id="mobile"
            placeholder="Mobile"
            onChange={this.onChangeHandler}
            value={this.state.mobile}
            name="mobile"
            required
          />

          <label
            htmlFor="email"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Email
          </label>

          <input
            type="text"
            className="form-control"
            id="email"
            placeholder="email"
            onChange={this.onChangeHandler}
            value={this.state.email}
            name="email"
            required
          />

          <label
            htmlFor="birthdate"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Birthdate
          </label>

          <input
            type="date"
            className="form-control"
            id="birthdate"
            placeholder="Birthdate"
            name="date_of_birth"
            onChange={this.onChangeHandler}
            // onClick={onClick}
            value={this.state.date_of_birth}
            max={this.ageRequirement()}
            required
          />

          <button className=" will-next-button">Next</button>
        </form>
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal title="Personal Info" body={helpTextList.personalInfoText} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalInfo: (personalInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PERSONAL_INFO,
        payload: {
          userPersonalInfo: personalInfo,
        },
      }),
    setPartnerInfo: (partnerInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PARTNER_INFO,
        payload: {
          userPartnerInfo: partnerInfo,
        },
      }),
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    personalInfo: state.user.personalInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
