import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import * as Lists from "../Lists";
import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";
import axios from "axios";

class Donor extends Component {
  state = {
    title: "",
    first_name: "",
    last_name: "",
    other_name: "",

    address: {
      street_or_house_no: "",
      city: "",
      postal_code: "",
      country: "",
    },
    errors: {},
    email: "",
    date_of_birth: "",
    error_postal_code: "",
    unableToSign: false,
  };

  countryList = Lists.countryList;
  titleList = Lists.titleList;

  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.donor !== null) this.setState({ ...this.props.donor });
  }

  onChangeHandler = (e) => {
    if (e.target.name === "street_or_house_no") {
      let tempAddress = {
        ...this.state.address,
        street_or_house_no: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "city") {
      let tempAddress = {
        ...this.state.address,
        city: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "postal_code") {
      let tempAddress = {
        ...this.state.address,
        postal_code: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "country") {
      let tempAddress = {
        ...this.state.address,
        country: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else this.setState({ [e.target.name]: e.target.value });
  };

  validateForm = () => {
    if (this.state.title === "") {
      let errors = { ...this.state.errors, title: "Select One From List" };

      this.setState({ errors: errors });
      return false;
    }
    return true;
  };

  dbValidAction = () => {
    this.setState({ error_postal_code: "" });
    let db = firebase.firestore();
    let financialLpaDonor = {
      ...this.state,
      date_of_birth: firebase.firestore.Timestamp.fromDate(
        new Date(this.state.date_of_birth)
      ),
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    delete financialLpaDonor.error_postal_code;
    delete financialLpaDonor.errors;
    db.collection("financialLpaDonorInfo")
      .doc(this.props.currentUser.uid)
      .set(financialLpaDonor)
      .then(() => {
        // console.log("FinancialLpaDonor in firestore");
      })
      .catch(() => {
        // console.log("FinancialLpaDonor in firestore failed");
      });
    this.props.setFinancialLpaDonor(this.state);
    this.props.setCurrentView(FinancialAffairsStepsEnum.WHEN_CAN_BE_USED); //SET IT WHEN CAN BE USED
  };

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (!this.validateForm()) return;

    if (
      this.state.address.country === "United Kingdom" ||
      this.state.address.country === "Wales"
    )
      axios
        .get(
          `https://api.postcodes.io/postcodes/${this.state.address.postal_code.trim()}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            let resultCountry =
              res.data.result.country === "England"
                ? "United Kingdom"
                : res.data.result.country;

            if (this.state.address.country === resultCountry)
              this.dbValidAction();
            else {
              this.setState({ error_postal_code: "Invalid Post Code" });
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          this.setState({ error_postal_code: "Invalid Post Code" });
        });
    else this.dbValidAction();
  };

  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>Donor</h1>

        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
            position: "relative",
          }}
        >
          <div className="row">
            <div>
              The 'donor' is the person appointing other people to make
              decisions on their behalf. The donor:
              <ul style={{ margin: "2rem 0rem", padding: "0rem 3rem" }}>
                <li>must be 18 or over</li>
                <li>
                  must be able to make their own decisions at the time their LPA
                  is made – this is known as having mental capacity
                </li>
              </ul>
              Only the donor can make decisions about their LPA and the people
              to involve.
            </div>
          </div>
        </div>

        <Divider />
        <form onSubmit={this.onSubmitHandle}>
          <label
            htmlFor="title"
            className="col-form-label col-form-label required-field"
            style={{ fontWeight: "bold" }}
          >
            Title
          </label>
          <select
            id="title"
            className="form-control"
            value={this.state.title}
            onChange={this.onChangeHandler}
            name="title"
            required
            // noValidate
          >
            <option value="" disabled hidden>
              Title
            </option>
            {this.titleList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>

          <label
            htmlFor="firstName"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="First Name"
            onChange={this.onChangeHandler}
            value={this.state.first_name}
            name="first_name"
            required
            // noValidate
          />

          <label
            htmlFor="lastname"
            className="col-form-label col-form-label  required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Last Name
          </label>

          <input
            type="text"
            className="form-control"
            id="lastname"
            placeholder="Last Name"
            onChange={this.onChangeHandler}
            value={this.state.last_name}
            name="last_name"
            required
            // noValidate
          />

          <label
            htmlFor="other_name"
            className="col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Other Names
          </label>

          <input
            type="text"
            className="form-control"
            id="other_name"
            placeholder="Other Names"
            onChange={this.onChangeHandler}
            value={this.state.other_name}
            name="other_name"
          />

          <label
            htmlFor="address"
            className="required-field col-form-label col-form-label"
            style={{
              marginTop: "2.8rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            Address:
          </label>

          {/* <textarea
            type="textarea"
            className="form-control"
            id="address"
            placeholder="Address"
            onChange={this.onChangeHandler}
            value={this.state.address}
            name="address"
            required
           
          /> */}
          <div id="address" style={{ marginLeft: "0rem" }}>
            <label
              htmlFor="streetOrHouseNo"
              className="col-form-label col-form-label"
              style={{ marginTop: "0rem", fontWeight: "bold" }}
            >
              Street/House No.
            </label>

            <input
              type="text"
              className="form-control"
              id="streetOrHouseNo"
              placeholder="Street/House No"
              onChange={this.onChangeHandler}
              required
              // noValidate
              value={this.state.address.street_or_house_no || ""}
              name="street_or_house_no"
            />

            <label
              htmlFor="city"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              City
            </label>

            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="City"
              onChange={this.onChangeHandler}
              required
              // noValidate
              value={this.state.address.city || ""}
              name="city"
            />

            <label
              htmlFor="postalCode"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              Postal Code
            </label>
            <div>
              {this.state.error_postal_code === "" ? (
                <span></span>
              ) : (
                <span style={{ color: "red" }}>
                  {this.state.error_postal_code}
                </span>
              )}
            </div>

            <input
              type="text"
              className="form-control"
              id="postalCode"
              placeholder="Postal Code"
              onChange={this.onChangeHandler}
              required
              value={this.state.address.postal_code || ""}
              name="postal_code"
            />

            <label
              htmlFor="country"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              Country
            </label>

            <select
              id="country"
              className="form-control"
              value={this.state.address.country}
              onChange={this.onChangeHandler}
              name="country"
              required
            >
              <option value="" disabled hidden>
                Country
              </option>
              {this.countryList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <label
            htmlFor="email"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Email
          </label>

          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="email"
            onChange={this.onChangeHandler}
            value={this.state.email}
            name="email"
            required
          />

          <label
            htmlFor="birthdate"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Birthdate
          </label>

          <input
            type="date"
            className="form-control"
            id="birthdate"
            placeholder="Birthdate"
            name="date_of_birth"
            onChange={this.onChangeHandler}
            // onClick={onClick}
            value={this.state.date_of_birth}
            required
          />

          <div style={{ position: "relative", marginTop: "2rem" }}>
            <input
              type="checkbox"
              className=""
              id={"unableTosign"}
              placeholder="First Name"
              checked={this.state.unableToSign}
              onChange={(e) => {
                this.setState({
                  unableToSign: !this.state.unableToSign,
                });
              }}
              style={{
                width: "2rem",
                height: "2rem",
                display: "inline-block",
              }}
              name="first_name"
            />
            <label
              htmlFor={"unableTosign"}
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.3rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",

                marginBottom: "0",
              }}
            >
              The donor is unable to sign or make a mark on the LPA
            </label>
          </div>
          <span
            style={{
              fontSize: "1.2rem",
              display: "block",
              margin: "0rem 0rem",
              marginTop: "2rem",
              color: "grey",
            }}
          >
            We'll add an extra sheet to the LPA so someone can sign on the
            donor's behalf
          </span>

          <button className=" will-next-button">Save And Proceed</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaDonor: (donor) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_DONOR,
        payload: {
          donor: donor,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    donor: state.financial_lpa.donor,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Donor);
