import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";

const firebaseConfigDev = {
  apiKey: "AIzaSyD7OyvLJ_V1MuyvvZqEodXTvRtXQu4Xpqs",
  authDomain: "neolexical-demo.firebaseapp.com",
  databaseURL: "https://neolexical-demo.firebaseio.com",
  projectId: "neolexical-demo",
  storageBucket: "neolexical-demo.appspot.com",
  messagingSenderId: "250394340133",
  appId: "1:250394340133:web:a270a5e2f2d72ce94dc8cc",
  measurementId: "G-01Z0ZD4YZS",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyAVYUrJq4D3gox_YVmHxViSfR4VEAC78O8",
  authDomain: "neolexical-app.firebaseapp.com",
  databaseURL: "https://neolexical-app.firebaseio.com",
  projectId: "neolexical-app",
  storageBucket: "neolexical-app.appspot.com",
  messagingSenderId: "1008741216242",
  appId: "1:1008741216242:web:bb3df9c4be25267001056b",
  measurementId: "G-5WGNN0MBQG",
};

// Initialize Firebase
firebase.initializeApp(
  process.env.REACT_APP_ENV === "development"
    ? firebaseConfigDev
    : firebaseConfigProd
);

export default firebase;
