import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import priceJson from "../../../config/price.json";
import { Dimmer, Loader } from "semantic-ui-react";
import * as actionTypes from "../../../actions/types";
import Header from "../../landingPage/header/Header";
import Footer from "../../footer/Footer";

class SingleWillInfo extends Component {
  state = { loading: false, dataLoading: null, plan: "website" };

  unsubscribe;
  async componentDidMount() {
    window.scrollTo(0, 0);
    // firebase.auth().onAuthStateChanged((signedInUser) => {
    //   if (signedInUser) {
    //     // console.log(signedInUser);
    //     this.props.setUser(signedInUser);
    //   }
    //   this.setState({ loading: false });
    // });
    // if (this.props.currentUser === null && this.props.isLoading) {
    //   //   this.props.history.push("/login");
    // } else {
    //   // this.readData();
    // }
    // console.log(priceJson);
    if (
      firebase.auth().currentUser === null ||
      firebase.auth().currentUser === undefined
    )
      return;
    this.setState({ dataLoading: true });
    let db = firebase.firestore();
    let ref = (
      await db.collection("users").doc(this.props.currentUser.uid).get()
    ).data().single_will_id;
    if (ref !== undefined && ref !== null) {
      this.setState({ dataLoading: false });
      this.props.history.push("/singlewill");
    } else this.setState({ dataLoading: false });
  }

  componentWillUnmount() {
    // this.unsubscribe();
  }

  onClickStartNowWebsite = async () => {
    if (this.props.currentUser === null) {
      this.props.history.push("/login");
    } else {
      // this.setState({ dataLoading: true });

      // this.readData();
      const db = firebase.firestore();
      const singleWillsRef = await db
        .collection("completedProducts")
        .doc(this.props.currentUser.uid)
        .collection("singleWills")
        .add({
          status: "created",
          time_stamp: firebase.firestore.Timestamp.now(),
          user_id: db.doc("users/" + this.props.currentUser.uid),
        });
      await db
        .collection("users")
        .doc(this.props.currentUser.uid)
        .set({ single_will_id: [singleWillsRef] }, { merge: true });

      this.props.history.push("/singlewill");
    }
  };

  onClickStartNowF2F = () => {
    this.props.history.push("/contactus");
  };

  onClickStartNow = async () => {
    if (this.props.currentUser === null) {
      this.props.history.push("/login");
    } else if (this.state.plan === "website") {
      // this.setState({ dataLoading: true });

      // this.readData();
      const db = firebase.firestore();
      const singleWillsRef = await db
        .collection("completedProducts")
        .doc(this.props.currentUser.uid)
        .collection("singleWills")
        .add({
          status: "created",
          time_stamp: firebase.firestore.Timestamp.now(),
          user_id: db.doc("users/" + this.props.currentUser.uid),
        });
      await db
        .collection("users")
        .doc(this.props.currentUser.uid)
        .set({ single_will_id: [singleWillsRef] }, { merge: true });

      this.props.history.push("/singlewill");
    } else {
      this.props.history.push("/contactus");
    }
    // this.props.setCurrentView("will");
  };

  render() {
    if (this.state.loading || this.state.dataLoading === true) {
      window.scrollTo(0, 0);
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Header />

          <div style={{ marginBottom: "5rem" }} className="body-container">
            <h1 className="aboutus-section-heading">Single Will</h1>
            <div className="row justify-content-center">
              {" "}
              <div
                className="alert alert-info aboutus-section-text will-start-info-text"
                role="alert"
                style={{ fontWeight: "bold" }}
              >
                Your will lets you decide what happens to your money, property and possessions after your death.
                If you make a will you can also make sure you do not pay more Inheritance Tax than you need to.
                <br /><br />
                Our online service makes the proces of creating a will and keeping it up to date effortless.
              </div>
            </div>

            {/* <div
              // className="aboutus-section-text"
              style={{
                fontWeight: "bold",
              }}
            >
            
              Some 66% of adults in the United Kingdom fail to make or do not
              have a Will. If you have assets over the Inheritance Tax threshold
              of £325,000 then your children or spouse or both could be left
              with a hefty tax bill. That's another good reason to make a Will.
            </div> */}
            <div
              className="row justify-content-center "
              style={{
                marginBottom: "2rem",
                marginTop: "3rem",
                textAlign: "center",
              }}
            >
              <div className="col-auto" style={{ padding: "0" }}>
                <div className={"price-card"}>
                  <div className="price-card-head">
                    <div className="price-card-head-text">
                      <p style={{ marginBottom: "0.9rem" }}>Standard</p>
                      <span style={{ fontSize: "2rem" }}>
                        £{priceJson.single_will}.00
                      </span>
                      <span style={{ fontSize: "1rem" }}>/vat included</span>
                      <div className="price-card-head-popular-text">
                        {" "}
                        Most Popular
                      </div>
                    </div>
                  </div>
                  <div className="price-card-body">
                    <div className="price-card-body-text">
                      Create a standard single will through our website or app. Draft, Edit and Finalize your will from the comfort of your home.
                    </div>

                    <div>
                      <button
                        className={"will-button blue-will-button"}
                        style={{ width: "70%", fontSize: "1.5rem" }}
                        onClick={this.onClickStartNowWebsite}
                      >
                        Start Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-auto"
                style={{ padding: "0" }}
              // style={{ backgroundColor: "blue", height: "1rem" }}
              >
                <div className={"price-card"}>
                  <div className="price-card-head">
                    <div className="price-card-head-text-premium">Premium</div>
                  </div>
                  <div className="price-card-body">
                    <div className="price-card-body-text">
                      Create a highly customizable will for special needs. <br /> <br />

                      Face to face appointments (including via
                      Video-conferencing Apps such as Zoom or Google or Teams
                      Meeting) and taking your instructions over them or over
                      the telephone or in the our offices
                    </div>

                    <div>
                      <button
                        className={"will-button blue-will-button"}
                        style={{ width: "70%", fontSize: "1.5rem" }}
                        onClick={this.onClickStartNowF2F}
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{ textAlign: "center" }}>
              <button
                className="will-button blue-will-button will-start-button"
                style={{ fontSize: "1.8rem" }}
                onClick={this.onClickStartNow}
              >
                Start Now
              </button>
            </div> */}

            <div style={{ marginTop: "6rem" }}>
              <h1 className="aboutus-section-heading">
                Frequently Ask Questions
              </h1>
              <div className="row">
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">What is a Will?</h2>
                    <span className="aboutus-section-text">
                      A will is a document which contains a person’s intentions
                      on the distribution of his or her assets at death.
                    </span>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Do I have to list all my assets in my Will??
                    </h2>
                    <span className="aboutus-section-text">
                      You are not required to specifically list all your assets
                      in your Will; e.g. “all my real property” or “all my bank
                      accounts” would be sufficient.
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  {" "}
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Can my beneficiary witness my Will?
                    </h2>
                    <span className="aboutus-section-text">
                      No, a beneficiary will not be eligible to receive any
                      benefit from the estate if he/she or his/her spouse signs
                      as a witness to the Will.
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  {" "}
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Can my Will include foreign property?
                    </h2>
                    <span className="aboutus-section-text">
                      Yes! But In order to enforce your will overseas, your
                      executor may need to re-seal the grant of probate in a
                      court of the foreign jurisdiction.
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      How long will my Will be valid for?
                    </h2>
                    <span className="aboutus-section-text">
                      Once executed, your Will is valid until it is replaced by
                      a New Will, revoked in writing or destroyed intentionally.
                      Marriage will automatically revoke any previous will ,
                      unless it was expressly made in contemplation of that
                      marriage. If you think this may have been your case,talk
                      to us to receive specific advice
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Do I need to appoint a guardian in my Will?
                    </h2>
                    <span className="aboutus-section-text">
                      You should specify the appointment of a guardian in your
                      will if you have children below 18 years.
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      What is the role of my executor and trustee?
                    </h2>
                    <span className="aboutus-section-text">
                      The role of your executor is to ensure that your wishes in
                      your will are fulfilled.
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Should I rewrite my Will?
                    </h2>
                    <span className="aboutus-section-text">
                      The decision depends on the change in your circumstances
                      and whether such change is adequately provided for in your
                      will.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </React.Fragment>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
    setPersonalInfo: (personalInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PERSONAL_INFO,
        payload: {
          userPersonalInfo: personalInfo,
        },
      }),
    setPartnerInfo: (partnerInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PARTNER_INFO,
        payload: {
          userPartnerInfo: partnerInfo,
        },
      }),
    setChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_USER_CHILDREN_INFO,
        payload: {
          userChildrenInfo: childrenInfo,
        },
      }),
    setGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GUARDIAN_INFO,
        payload: {
          userGuardianInfo: guardianInfo,
        },
      }),
    setLiquidAssetInfo: (liquidAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_LIQUID_ASSET_INFO,
        payload: {
          userLiquidAssetInfo: liquidAssetInfo,
        },
      }),
    setRealAssetInfo: (realAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REAL_ASSET_INFO,
        payload: {
          userRealAssetInfo: realAssetInfo,
        },
      }),
    setGeneralConcernInfo: (generalConcernInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GENERAL_CONCERN_INFO,
        payload: {
          userGeneralConcernInfo: generalConcernInfo,
        },
      }),
    setGiftInfo: (giftInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GIFT_INFO,
        payload: {
          userGiftInfo: giftInfo,
        },
      }),
    setRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REMAINING_ESTATE_INFO,
        payload: {
          userRemainingEstateInfo: remainingEstateInfo,
        },
      }),
    setExecutorInfo: (executorInfo) =>
      dispatch({
        type: actionTypes.SET_USER_EXECUTOR_INFO,
        payload: {
          userExecutorInfo: executorInfo,
        },
      }),
    setRequestInfo: (requestInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REQUEST_INFO,
        payload: {
          userRequestInfo: requestInfo,
        },
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    isLoading: state.user.isLoading,
  };
};

// const SingleWillInfoWithRouter = withRouter(SingleWillInfo);
export default connect(mapStateToProps, mapDispatchToProps)(SingleWillInfo);
