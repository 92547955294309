import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";

class CharityGift extends Component {
  state = {
    primaryDataProvided: false,

    error_charity_gift_count: null,
    charity_gift_count: null,
    charity_gift_count_change: false,
    charity_gifts: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      primaryDataProvided: false,
      charity_gift_count: this.props.giftInfo.charity_gift_count,
      charity_gifts: this.props.giftInfo.charity_gifts,
    });
  }

  isFormValid = () => {
    if (this.state.charity_gift_count < 0) {
      this.setState({ error_charity_gift_count: "invalid input" });
      return false;
    } else this.setState({ error_charity_gift_count: null });

    return true;
  };

  onChangeHandler = (e) => {
    let targetName = e.target.name;
    let key = e.target.name + "_change";
    if (
      this.props.giftInfo[targetName] !== null &&
      this.props.giftInfo[targetName] !== parseInt(e.target.value)
    ) {
      this.setState({
        [key]: true,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (
      this.props.giftInfo[targetName] !== null &&
      this.props.giftInfo[targetName] === parseInt(e.target.value)
    ) {
      this.setState({
        [key]: false,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (this.props.giftInfo[targetName] === null) {
      this.setState({
        [key]: true,
        [e.target.name]: parseInt(e.target.value),
      });
    }
  };
  onClickChange = () => {
    this.setState({ primaryDataProvided: false });
  };

  onSubmitHandleCountForm = (e) => {
    e.preventDefault();

    let charity_gifts = [];
    if (this.isFormValid()) {
      window.scrollTo(0, 0);
      if (
        this.props.giftInfo.charity_gifts === null ||
        this.state.charity_gift_count_change
      ) {
        for (let index = 0; index < this.state.charity_gift_count; index++) {
          charity_gifts[index] = {
            charity_name: "",
            registered_charity_number: "",
            amount: null,
          };
        }

        this.setState({
          charity_gifts: charity_gifts,
          charity_gift_count_change: false,
        });
        this.props.setGiftInfo({
          ...this.props.giftInfo,
          charity_gifts: charity_gifts,
          charity_gift_count: this.state.charity_gift_count,
        });
      }

      this.setState({ primaryDataProvided: true });
    }
  };

  onSubmitHandleInfoForm = (e) => {
    e.preventDefault();
    let db = firebase.firestore();
    let tempState = { ...this.state };
    delete tempState.primaryDataProvided;
    delete tempState.error_charity_gift_count;
    delete tempState.charity_gift_count_change;

    db.collection("giftInfo")
      .doc(this.props.currentUser.uid)
      .set({
        ...this.props.giftInfo,
        user_id: db.doc("users/" + this.props.currentUser.uid),
        charity_gift_count: tempState.charity_gift_count,
        charity_gifts: tempState.charity_gifts,
      })
      .then(() => {
        // console.log("charity gift in firestore");
      })
      .catch(() => {
        // console.log("charity gift in firestore failed");
      });

    this.props.setGiftInfo({
      ...this.props.giftInfo,
      charity_gift_count: tempState.charity_gift_count,
      charity_gifts: tempState.charity_gifts,
    });

    this.props.setCurrentView("next");
  };

  render() {
    if (!this.state.primaryDataProvided)
      return (
        <div>
          <div
            style={{
              textAlign: "center",
              marginTop: "2.5rem",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                display: "block",
                marginBottom: "2rem",
              }}
            >
              Charity Gift Count
            </span>
          </div>
          <form onSubmit={this.onSubmitHandleCountForm}>
            <label
              htmlFor="charity_gift_count"
              className="col-form-label col-form-label required-field"
              style={{ marginTop: "1rem", fontWeight: "bold" }}
            >
              How many gifts do you want to leave for your charity?
            </label>
            <input
              type="number"
              step="1"
              className="form-control"
              id="charity_gift_count"
              placeholder="Number Of General Gifts"
              onChange={this.onChangeHandler}
              value={
                this.state.charity_gift_count === 0
                  ? 0
                  : this.state.charity_gift_count || ""
              }
              name="charity_gift_count"
              required
            />
            {this.state.error_charity_gift_count === null ? (
              <span></span>
            ) : (
              <div className="invalid-input">
                {this.state.error_charity_gift_count}
              </div>
            )}
            <button className=" will-next-button">Next</button>
          </form>
        </div>
      );
    else {
      let charity_gifts_rows = [];
      for (let index = 0; index < this.state.charity_gift_count; index++) {
        charity_gifts_rows.push(
          <div
            key={index}
            style={{
              marginBottom: "3.5rem",
            }}
          >
            <h4 style={{ marginBottom: "0rem", marginTop: "1rem" }}>
              Information of Charity gift no.{index + 1}
            </h4>
            <label
              htmlFor={"charityName" + index}
              className="col-form-label col-form-label required-field"
              style={{ marginTop: "1rem", fontWeight: "bold" }}
            >
              Charity's Name
            </label>
            <input
              type="text"
              className="form-control"
              id={"charityName" + index}
              placeholder="Charity's Name"
              onChange={(e) => {
                let charity_gifts = [...this.state.charity_gifts];
                charity_gifts[index].charity_name = e.target.value;
                this.setState({ charity_gifts: charity_gifts });
              }}
              value={this.state.charity_gifts[index].charity_name || ""}
              name="first_name"
              required
            />

            <label
              htmlFor={"registration" + index}
              className="col-form-label col-form-label "
              style={{ marginTop: "1rem", fontWeight: "bold" }}
            >
              Charity's Registration Number
            </label>
            <input
              type="text"
              className="form-control"
              id={"registration" + index}
              placeholder="Registration number"
              onChange={(e) => {
                let charity_gifts = [...this.state.charity_gifts];
                charity_gifts[index].registered_charity_number = e.target.value;
                this.setState({ charity_gifts: charity_gifts });
              }}
              value={
                this.state.charity_gifts[index].registered_charity_number || ""
              }
              name="middle_name"
            />

            <label
              htmlFor={"amount" + index}
              className="col-form-label col-form-label required-field"
              style={{ marginTop: "1rem", fontWeight: "bold" }}
            >
              Amount
            </label>

            <input
              type="number"
              step="any"
              className="form-control"
              id={"amount" + index}
              placeholder="amount"
              onChange={(e) => {
                let charity_gifts = [...this.state.charity_gifts];
                charity_gifts[index].amount = parseFloat(e.target.value);
                this.setState({ charity_gifts: charity_gifts });
              }}
              value={this.state.charity_gifts[index].amount || ""}
              required
            />
          </div>
        );
      }

      return (
        <div>
          <div
            className="skip-card"
            style={{ width: "100%", textAlign: "center" }}
          >
            <div>
              <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Change Your Charity Gift Numbers
              </span>
              <button
                className="will-button"
                style={{ marginLeft: "1rem" }}
                onClick={this.onClickChange}
              >
                Change!
              </button>
            </div>
          </div>
          <form onSubmit={this.onSubmitHandleInfoForm}>
            <div>
              <h3 style={{ textAlign: "center", marginBottom: "1.8rem" }}>
                Charity General Gift Information
              </h3>
              {charity_gifts_rows}
            </div>
            <button className=" will-next-button" style={{ marginTop: "1rem" }}>
              Next
            </button>
          </form>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGiftInfo: (giftInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GIFT_INFO,
        payload: {
          userGiftInfo: giftInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    giftInfo: state.user.giftInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CharityGift);
