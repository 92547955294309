import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import priceJson from "../../../config/price.json";
import { Dimmer, Loader } from "semantic-ui-react";
import Header from "../../landingPage/header/Header";
import Footer from "../../footer/Footer";

class HealthAndWelfairInfo extends Component {
  state = { loading: false, dataLoading: null, plan: "website" };

  unsubscribe;
  async componentDidMount() {
    window.scrollTo(0, 0);
    // firebase.auth().onAuthStateChanged((signedInUser) => {
    //   if (signedInUser) {
    //     // console.log(signedInUser);
    //     this.props.setUser(signedInUser);
    //   }
    //   this.setState({ loading: false });
    // });
    // if (this.props.currentUser === null && this.props.isLoading) {
    //   //   this.props.history.push("/login");
    // } else {
    //   // this.readData();
    // }
    if (
      firebase.auth().currentUser === null ||
      firebase.auth().currentUser === undefined
    )
      return;
    this.setState({ dataLoading: true });
    let db = firebase.firestore();
    let ref = (
      await db.collection("users").doc(this.props.currentUser.uid).get()
    ).data().health_lpa_id;
    if (ref !== undefined && ref !== null) {
      this.setState({ dataLoading: false });
      this.props.history.push("/healthnwelfare");
    } else this.setState({ dataLoading: false });
  }

  componentWillUnmount() {
    // this.unsubscribe();
  }

  onClickStartNowWebsite = async () => {
    if (this.props.currentUser === null) {
      this.props.history.push("/login");
    } else {
      // this.setState({ dataLoading: true });

      // this.readData();
      const db = firebase.firestore();
      const healthLPAsRef = await db
        .collection("completedProducts")
        .doc(this.props.currentUser.uid)
        .collection("healthLPAs")
        .add({
          status: "created",
          time_stamp: firebase.firestore.Timestamp.now(),
          user_id: db.doc("users/" + this.props.currentUser.uid),
        });
      await db
        .collection("users")
        .doc(this.props.currentUser.uid)
        .set({ health_lpa_id: [healthLPAsRef] }, { merge: true });

      this.props.history.push("/healthnwelfare");
    }
  };

  onClickStartNowF2F = () => {
    this.props.history.push("/contactus");
  };

  render() {
    if (this.state.loading || this.state.dataLoading === true) {
      window.scrollTo(0, 0);
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Header />

          <div style={{ marginBottom: "5rem" }} className="body-container">
            <h1 className="aboutus-section-heading">Health And Welfare LPA</h1>
            <div className="row justify-content-center">
              {" "}
              <div
                className="alert alert-info aboutus-section-text will-start-info-text"
                role="alert"
                style={{ fontWeight: "bold" }}
              >
                A Health & Welfare Lasting Power of Attorney (LPA) is a legal document that enables you (known as the Donor) to appoint another person (known as the Attorney) to make decisions on their behalf in relation to health and welfare matters. A Health & Welfare LPA allows the Attorney to make decisions on medical treatment and the provision of care, but also includes wider decisions such as who the Donor has contact with and where they live. The Attorney can make decisions on life-sustaining medical treatment if the Donor has provided for this in the LPA.

                <br /> <br />

                If you or your loved ones are facing incapacity or infirmity and need a trusted caregiver to make decisions concerning your health, then you should strongly consider having a Lasting Power of Attorney for Health & Welfare.
              </div>
            </div>

            {/* <div
              // className="aboutus-section-text"
              style={{
                fontWeight: "bold",
              }}
            >
            
              Some 66% of adults in the United Kingdom fail to make or do not
              have a Will. If you have assets over the Inheritance Tax threshold
              of £325,000 then your children or spouse or both could be left
              with a hefty tax bill. That's another good reason to make a Will.
            </div> */}
            <div
              className="row justify-content-center "
              style={{
                marginBottom: "2rem",
                marginTop: "3rem",
                textAlign: "center",
              }}
            >
              <div className="col-auto" style={{ padding: "0" }}>
                <div className={"price-card"}>
                  <div className="price-card-head">
                    <div className="price-card-head-text">
                      <p style={{ marginBottom: "0.9rem" }}>Standard</p>
                      <span style={{ fontSize: "2rem" }}>
                        £{priceJson.health_lpa}.00
                      </span>
                      <span style={{ fontSize: "1rem" }}>/vat included</span>
                      <div className="price-card-head-popular-text">
                        {" "}
                        Most Popular
                      </div>
                    </div>
                  </div>
                  <div className="price-card-body">
                    <div className="price-card-body-text">
                      Create a standard LPA through our website or app. Draft, Edit and Finalize your LPA from the comfort of your home.
                    </div>

                    <div>
                      <button
                        className={"will-button blue-will-button"}
                        style={{ width: "70%", fontSize: "1.5rem" }}
                        onClick={this.onClickStartNowWebsite}
                      >
                        Start Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-auto"
                style={{ padding: "0" }}
              // style={{ backgroundColor: "blue", height: "1rem" }}
              >
                <div className={"price-card"}>
                  <div className="price-card-head">
                    <div className="price-card-head-text-premium">Premium</div>
                  </div>
                  <div className="price-card-body">
                    <div className="price-card-body-text">
                      Create a highly customizable LPA for special needs. <br /> <br />

                      Face to face appointments (including via
                      Video-conferencing Apps such as Zoom or Google or Teams
                      Meeting) and taking your instructions over them or over
                      the telephone or in the our offices
                    </div>

                    <div>
                      <button
                        className={"will-button blue-will-button"}
                        style={{ width: "70%", fontSize: "1.5rem" }}
                        onClick={this.onClickStartNowF2F}
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{ textAlign: "center" }}>
              <button
                className="will-button blue-will-button will-start-button"
                style={{ fontSize: "1.8rem" }}
                onClick={this.onClickStartNow}
              >
                Start Now
              </button>
            </div> */}

            <div style={{ marginTop: "6rem" }}>
              <h1 className="aboutus-section-heading">
                Frequently Ask Questions
              </h1>
              <div className="row">
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">Why make a Health & Welfare LPA?</h2>
                    <span className="aboutus-section-text">
                      If you are ill and or age is preventing you from making your own decisions or suffer from a disease that will eventually take away your mental capacity to make your own health choices and welfare decisions, then you should make a LPA for Health and Welfare.
                    </span>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      When can I use the LPA?
                    </h2>
                    <span className="aboutus-section-text">
                      Once it is registered. However, the Health & Welfare LPA is only active once you lose your mental capacity and not before.
                    </span>
                  </div>
                </div>
              </div>
              
              
              <div className="row">
                <div className="col-lg-6">
                  {" "}
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      What is an LPA?
                    </h2>
                    <span className="aboutus-section-text">
                      An LPA or Lasting Power of Attorney is a legal document that specifies who you have appointed to act as your Attorney when you are no longer able to make decisions or act. They will make decisions as if they are you. They must make these decisions in your best interest.
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  {" "}
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Who should I appoint as an Attorney?
                    </h2>
                    <span className="aboutus-section-text">
                      You may appoint anyone over 18 years old and of sound mind who is known to you and is trustworthy. You should take care when appointing your attorneys as they will have enormous power in making decisions that affect your health, welfare, money and property when you are unable to act because you are either homebound or have lost mental capacity. They should always act in your best interests.
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      How many attorneys can you appoint?
                    </h2>
                    <span className="aboutus-section-text">
                      You can have up to 4 attorneys named in your LPA. You can appoint one attorney or several, up to 4. If you appoint only 1 Attorney, make sure you have a replacement attorney, in case the person you have appointed dies before you or becomes incapacitated himself or is unable or unwilling to act and renounces.
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      How should my attorneys act?

                    </h2>
                    <span className="aboutus-section-text">
                      If you appoint one attorney, s/he will act on their own individually. If you appoint two or more attorneys you can appoint them to always act together (known as jointly) or together and individually (known as jointly and severally).

                      In some cases, you can even specify that your attorneys act in some cases jointly and in other instances severally, but make sure you take proper legal advice before you decide, as this may cause confusion among your attorneys and make your LPA less effective.

                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Can I have a replacement Attorney?
                    </h2>
                    <span className="aboutus-section-text">
                      Yes. You are encouraged to appoint a replacement attorney especially if you have appointed a single attorney in your LPA.

                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Does or can a replacement attorney act when my appointed attorney(s) are acting according to the LPA?

                    </h2>
                    <span className="aboutus-section-text">
                      No, they do not have the power to act unless your appointed Attorneys have renounced or refused to act and they have notified the Office of the Public Guardian of their desire to no longer act as your Attorney.

                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div data-aos="fade-up" style={{ marginBottom: "3rem" }}>
                    <h2 className="section-subtitle">
                      Can I revoke my LPA?

                    </h2>
                    <span className="aboutus-section-text">
                      Yes, you may revoke your LPA at any time before Registration. You can also revoke your LPA after Registration as long as you have the mental capacity to do so. Once you lose mental capacity, you are no longer able to revoke your LPA.


                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </React.Fragment>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    isLoading: state.user.isLoading,
  };
};

// const SingleWillInfoWithRouter = withRouter(SingleWillInfo);
export default connect(mapStateToProps)(HealthAndWelfairInfo);
