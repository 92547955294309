import React, { Component } from "react";
import Attorney from "./Attorney";
import AttorneysMakeDecision from "./AttorneysMakeDecision";
import CertificateProvider from "./CertificateProvider";
import Correspondent from "./Correspondent";
import Donor from "./Donor";
import FinancialAffairsSteps from "./FinancialAffairsSteps";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import PersonToNotify from "./PersonToNotify";
import PreferencesAndInstructions from "./PreferencesAndInstructions";
import Registration from "./Registration";
import RepeatApplication from "./RepeatApplication";
import ReplacementAttorney from "./ReplacementAttorney";
import ReplacementAttorneysMakeDecision from "./ReplacementAttorneysMakeDecision";
import SubmitFinancialAffairs from "./SubmitFinancialAffairs";
import WhenCanBeUsed from "./WhenCanBeUsed";

class FinancialAffairsBody extends Component {
  state = {
    currentView: FinancialAffairsStepsEnum.DONOR,
  };

  setCurrentView = (view) => {
    this.setState({ currentView: view });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.goToStep();
  }

  goToStep = () => {
    if (this.props.stepNo === 1) {
      this.setState({ currentView: FinancialAffairsStepsEnum.DONOR });
    } else if (this.props.stepNo === 2) {
      this.setState({
        currentView: FinancialAffairsStepsEnum.WHEN_CAN_BE_USED,
      });
    } else if (this.props.stepNo === 3) {
      this.setState({ currentView: FinancialAffairsStepsEnum.ATTORNEY });
    } else if (this.props.stepNo === 4) {
      this.setState({
        currentView: FinancialAffairsStepsEnum.HOW_ATTORNEYS_MAKE_DECISION,
      });
    } else if (this.props.stepNo === 5) {
      this.setState({
        currentView: FinancialAffairsStepsEnum.REPLACEMENT_ATTORNEY,
      });
    } else if (this.props.stepNo === 6) {
      this.setState({
        currentView:
          FinancialAffairsStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION,
      });
    } else if (this.props.stepNo === 7) {
      this.setState({
        currentView: FinancialAffairsStepsEnum.CERTIFICATE_PROVIDER,
      });
    } else if (this.props.stepNo === 8) {
      this.setState({
        currentView: FinancialAffairsStepsEnum.PERSON_TO_NOTIFY,
      });
    } else if (this.props.stepNo === 9) {
      this.setState({
        currentView: FinancialAffairsStepsEnum.PREFERENCES_N_INSTRUCTIONS,
      });
    } else if (this.props.stepNo === 10) {
      this.setState({ currentView: FinancialAffairsStepsEnum.REGISTRATION });
    } else if (this.props.stepNo === 11) {
      this.setState({ currentView: FinancialAffairsStepsEnum.CORRESPONDENT });
    } else if (this.props.stepNo === 12) {
      this.setState({ currentView: FinancialAffairsStepsEnum.REPEAT });
    } else if (this.props.stepNo === 13) {
      this.setState({ currentView: FinancialAffairsStepsEnum.SUBMIT });
    }
  };

  render() {
    let currentViewComponent;
    if (this.state.currentView === FinancialAffairsStepsEnum.DONOR)
      currentViewComponent = (
        <Donor
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView === FinancialAffairsStepsEnum.WHEN_CAN_BE_USED
    )
      currentViewComponent = (
        <WhenCanBeUsed
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === FinancialAffairsStepsEnum.ATTORNEY)
      currentViewComponent = (
        <Attorney
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView ===
      FinancialAffairsStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
    )
      currentViewComponent = (
        <AttorneysMakeDecision
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView === FinancialAffairsStepsEnum.REPLACEMENT_ATTORNEY
    )
      currentViewComponent = (
        <ReplacementAttorney
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView ===
      FinancialAffairsStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
    )
      currentViewComponent = (
        <ReplacementAttorneysMakeDecision
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView === FinancialAffairsStepsEnum.CERTIFICATE_PROVIDER
    )
      currentViewComponent = (
        <CertificateProvider
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView === FinancialAffairsStepsEnum.PERSON_TO_NOTIFY
    )
      currentViewComponent = (
        <PersonToNotify
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView ===
      FinancialAffairsStepsEnum.PREFERENCES_N_INSTRUCTIONS
    )
      currentViewComponent = (
        <PreferencesAndInstructions
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === FinancialAffairsStepsEnum.REGISTRATION)
      currentViewComponent = (
        <Registration
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === FinancialAffairsStepsEnum.CORRESPONDENT)
      currentViewComponent = (
        <Correspondent
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === FinancialAffairsStepsEnum.CORRESPONDENT)
      currentViewComponent = (
        <Correspondent
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === FinancialAffairsStepsEnum.REPEAT)
      currentViewComponent = (
        <RepeatApplication
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === FinancialAffairsStepsEnum.SUBMIT)
      currentViewComponent = (
        <SubmitFinancialAffairs
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );

    return (
      <div className="body-container" style={{ marginTop: "1rem" }}>
        <div className="row justify-content-center row justify-content-between justify-content-sm-center">
          <div className="col-2 col-md-4 step-mini-container">
            <FinancialAffairsSteps
              setCurrentView={this.setCurrentView}
              currentView={this.state.currentView}
              stepNo={this.props.stepNo}
            />
          </div>
          <div className="col-10  col-md-6">{currentViewComponent}</div>
        </div>
        {/* <ViewFinancialLPA /> */}
      </div>
    );
  }
}

export default FinancialAffairsBody;
