import React, { Component } from "react";
import Modal from "../Modal";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import firebase from "../../../../config/firebase";
import * as PartnerHelpTextList from "../../PartnerHelpTextList";

class RemainingEstateAddBeneficiary extends Component {
  state = {
    beneficiary_number: 0,
    beneficiaries: [],
    percentage_error: null,
  };

  relationshipList = [
    "Wife",
    "Husband",
    "Civil Partner",
    "Friend",
    "Daughter",
    "Son",
    "Mother",
    "Father",
    "Sister",
    "Brother",
    "Step-Daughter",
    "Step-Son",
    "Mother-in-law",
    "Father-in-law",
    "Sister-in-law",
    "Brother-in-law",
    "Ex-Wife",
    "Ex-Husband",
    "Ex-Partner",
    "Niece",
    "Nephew",
    "Grandson",
    "Granddaughter",
    "Cousin",
    "Aunt",
    "Uncle",
  ];

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      beneficiary_number: this.props.remainingEstateInfo.beneficiaries.length,
      beneficiaries: this.props.remainingEstateInfo.beneficiaries,
    });
  }

  onClickAdd = () => {
    let beneficiaries = [...this.state.beneficiaries];
    for (
      let index = this.state.beneficiary_number;
      index < this.state.beneficiary_number + 1;
      index++
    ) {
      beneficiaries[index] = {
        first_name: "",
        middle_name: "",
        last_name: "",
        relationship: "",
        percentage: "",
      };
    }
    this.setState({
      beneficiary_number: this.state.beneficiary_number + 1,
      beneficiaries: beneficiaries,
    });
  };
  isPercentageOK() {
    let amount = 0;
    if (this.state.beneficiaries.length === 0) {
      this.setState({ percentage_error: null });
      return true;
    }
    for (let index = 0; index < this.state.beneficiaries.length; index++) {
      amount += this.state.beneficiaries[index].percentage;
    }
    if (amount === 100) {
      this.setState({ percentage_error: null });
      return true;
    }
    this.setState({ percentage_error: "Total Percentage Must Be 100" });
    return false;
  }

  onClickNext = (e) => {
    e.preventDefault();
    if (this.isPercentageOK()) {
      let db = firebase.firestore();
      db.collection("partnerRemainingEstateInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.remainingEstateInfo,
          user_id: db.doc("users/" + this.props.currentUser.uid),
          beneficiaries: this.state.beneficiaries,
        })
        .then(() => {
          // console.log("remaining estate info in firestore");
        })
        .catch(() => {
          // console.log("remaining estate info in firestore failed");
        });
      this.props.setPartnerRemainingEstateInfo({
        ...this.props.remainingEstateInfo,
        beneficiaries: this.state.beneficiaries,
      });
      this.props.setCurrentView("substitute");
    }
  };

  onClickRemove = (index) => {
    let beneficiaries = [...this.state.beneficiaries];
    beneficiaries.splice(index, 1);
    this.setState({
      beneficiary_number: this.state.beneficiary_number - 1,
      beneficiaries: beneficiaries,
    });
  };

  render() {
    let beneficiaries_rows = [];

    for (let index = 0; index < this.state.beneficiary_number; index++) {
      beneficiaries_rows.push(
        <div
          key={index}
          style={{
            marginBottom: "3.5rem",
          }}
        >
          <span>
            <h4 style={{ marginBottom: "0rem", marginTop: "1rem" }}>
              Information of Beneficiary no.{index + 1}
              <button
                type="button"
                className="remove-form"
                style={{ float: "right" }}
                onClick={() => this.onClickRemove(index)}
              >
                <Icon name="x icon"></Icon>
              </button>
            </h4>
          </span>

          <label
            htmlFor={"firstName" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id={"firstName" + index}
            placeholder="First Name"
            onChange={(e) => {
              let beneficiaries = [...this.state.beneficiaries];
              beneficiaries[index].first_name = e.target.value;
              this.setState({ beneficiaries: beneficiaries });
            }}
            value={this.state.beneficiaries[index].first_name}
            name="first_name"
            required
          />

          <label
            htmlFor={"middleName" + index}
            className="col-form-label col-form-label "
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Middle Name
          </label>
          <input
            type="text"
            className="form-control"
            id={"middleName" + index}
            placeholder="Middle Name"
            onChange={(e) => {
              let beneficiaries = [...this.state.beneficiaries];
              beneficiaries[index].middle_name = e.target.value;
              this.setState({ beneficiaries: beneficiaries });
            }}
            value={this.state.beneficiaries[index].middle_name}
            name="middle_name"
          />

          <label
            htmlFor={"lastName" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Last Name
          </label>
          <input
            type="text"
            className="form-control"
            id={"lastName" + index}
            placeholder="Last Name"
            onChange={(e) => {
              let beneficiaries = [...this.state.beneficiaries];
              beneficiaries[index].last_name = e.target.value;
              this.setState({ beneficiaries: beneficiaries });
            }}
            value={this.state.beneficiaries[index].last_name}
            name="last_name"
            required
          />

          <label
            htmlFor={"relationship" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Relationship
          </label>

          <select
            id={"relationship" + index}
            className="form-control"
            value={this.state.beneficiaries[index].relationship}
            onChange={(e) => {
              let beneficiaries = [...this.state.beneficiaries];
              beneficiaries[index].relationship = e.target.value;
              this.setState({ beneficiaries: beneficiaries });
            }}
            required
          >
            <option value="" disabled hidden selected>
              Relationship
            </option>
            {this.relationshipList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>

          <label
            htmlFor={"percentage" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            percentage
          </label>

          <input
            type="number"
            step="any"
            className="form-control"
            id={"percentage" + index}
            placeholder="Value"
            onChange={(e) => {
              let beneficiaries = [...this.state.beneficiaries];
              beneficiaries[index].percentage = parseFloat(e.target.value);
              this.setState({ beneficiaries: beneficiaries });
            }}
            value={this.state.beneficiaries[index].percentage}
            required
          />
        </div>
      );
    }

    return (
      <div>
        <form onSubmit={this.onClickNext}>
          {beneficiaries_rows}
          {this.state.percentage_error === null ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              {this.state.percentage_error}
            </div>
          )}
          <button
            type="button"
            className=" will-button-selected-with-hover"
            style={{ marginTop: "2rem", width: "100%", fontSize: "1.2rem" }}
            onClick={this.onClickAdd}
          >
            Add Beneficiary
          </button>

          <button className=" will-next-button" style={{ marginTop: "2rem" }}>
            Next
          </button>
        </form>
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="Remaining Estate Info"
          body={PartnerHelpTextList.remainingEstateText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REMAINING_ESTATE_INFO,
        payload: {
          partnerRemainingEstateInfo: remainingEstateInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    remainingEstateInfo: state.partner.remainingEstateInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemainingEstateAddBeneficiary);
