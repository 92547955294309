import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../../config/firebase";

class UploadPhoneNumberModal extends Component {
  state = {
    phoneNumber: "",
    error: "",
  };
  onChange = (e) => {
    this.setState({ phoneNumber: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    var phoneno = /^\+?([0-9]+)$/;
    // console.log(this.state.phoneNumber.match(phoneno));
    if (this.state.phoneNumber.match(phoneno) === null) {
      this.setState({
        error:
          "Please give a valid phone number ( e.g. +4407804388055 or 07804388055 . Do not use any space, dot or other special characters)",
      });
      return;
    } else {
      this.setState({ error: "" });
    }

    let db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user.uid)
      .set({ phone: this.state.phoneNumber }, { merge: true })
      .then(() => {
        // console.log("phone Number in firestore");
        this.props.setPhoneNumber(this.state.phoneNumber);
        this.props.setUser({
          ...this.props.currentUser,
          phone: this.state.phoneNumber,
        });
        document.getElementById("closeButton").click();
      })
      .catch(() => {
        // console.log("phone Number in firestore failed");
      });
  };
  render() {
    return (
      <div
        className="modal fade"
        id="uploadPhoneNumberModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Give Your Phone Number
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.onSubmit}>
                <input
                  className="form-control"
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={this.onChange}
                  value={this.state.phoneNumber}
                />
                {this.state.error !== "" && (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{
                      marginTop: "2rem",
                      fontSize: "1rem",

                      fontWeight: "bold",
                    }}
                  >
                    {this.state.error}
                  </div>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="closeButton"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSubmit}
                // data-dismiss={this.state.error === "" ? "modal" : ""}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadPhoneNumberModal);
