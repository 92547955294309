import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import * as actionTypes from "../../../../actions/types";
import RealAssetInfoCount from "./RealAssetInfoCount";
import * as realAssetInfoStepEnum from "./RealAssetInfoEnum";
import RealAssetInfoForm from "./RealAssetInfoForm";
import RealAssetInfoSteps from "./RealAssetInfoSteps";
import * as helpTextList from "../../HelpTextList";

class RealAssetInfo extends Component {
  state = {
    realAssetInfoCurrentView: realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_COUNT,
  };
  setRealAsssetInfoCurrentView = (view) => {
    this.setState({ realAssetInfoCurrentView: view });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.stepNo < 5) this.props.setStepNo(5);
    // if (this.props.realAssetInfo !== null)
    //   this.setState({
    //     primaryDataProvided: false,
    //     property_count_change: false,
    //     property_count: this.props.realAssetInfo.property_count,
    //     total_property_value: this.props.realAssetInfo.total_property_value,
    //     property_details: this.props.realAssetInfo.property_details,
    //   });
  }
  render() {
    let currentViewComponent;
    if (
      this.state.realAssetInfoCurrentView ===
      realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_COUNT
    )
      currentViewComponent = (
        <RealAssetInfoCount
          setCurrentView={this.setRealAsssetInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.realAssetInfoCurrentView ===
      realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_FORM
    )
      currentViewComponent = (
        <RealAssetInfoForm
          setCurrentView={this.setRealAsssetInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.realAssetInfoCurrentView === "generalConcernInfo") {
      this.props.setCurrentView("generalConcernInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Your Real Asset</h1>
        <RealAssetInfoSteps
          setCurrentView={this.setRealAsssetInfoCurrentView}
          currentView={this.state.realAssetInfoCurrentView}
        />
        {currentViewComponent}
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal title="Real Asset Info" body={helpTextList.realAssetText} />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setRealAssetInfo: (realAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REAL_ASSET_INFO,
        payload: {
          userRealAssetInfo: realAssetInfo,
        },
      }),
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    realAssetInfo: state.user.realAssetInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RealAssetInfo);
