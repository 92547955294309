import React, { Component } from "react";
import { Divider } from "semantic-ui-react";

export class TNCBody extends Component {
  regNo = "808157";
  VATregNo = "391138396";

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="body-container">
        <div
          className="aboutus-section-heading"
          style={{ marginBottom: "5rem", wordSpacing: "1.1rem" }}
        >
          TERMS OF BUSINESS
        </div>
        <div className="tnc-list">
          <ol>
            {/* 1 */}
            <li>
              Our Commitment to Quality and Service
              <span className="tnc-list-description">
                The key objective of Neolexical Ltd is to provide the highest
                possible standard of service and timely advice to our clients.
                To that end you have been provided with Client Care Information
                (“CCI”) and with the Terms of Business. Together these form the
                terms and conditions of the contract between us as Solicitors
                and you as client during the course of the transaction. They
                will apply as soon as we receive any instructions from you when
                you will be deemed to have accepted them as applying to your
                transaction.
              </span>
            </li>
            {/* 1 end*/}

            {/* 2 */}
            <li>
              Standards of Service
              <ol>
                {/* 2.1 */}
                <li>
                  Standards
                  <span className="tnc-list-description">
                    This is your transaction and we wish to provide an
                    informative helpful level of service which will include:
                  </span>
                  <ul>
                    <li>
                      During the course of the transaction to explain to you the
                      legal work required as the matter progresses.
                    </li>
                    <li>
                      To communicate with you in plain language. If there is
                      anything which you do not understand please advise us so
                      that we can help you with it and explain.
                    </li>
                    <li>
                      As appropriate to provide advice whether the likely
                      outcome still justifies the likely risks and costs
                      associated with your matter whenever there is a material
                      change in the circumstances.
                    </li>
                    <li>
                      As the matter progresses to provide likely timescales for
                      each stage of the transaction and again any important
                      changes in those estimates.
                    </li>
                    <li>
                      During the course of the transaction to keep you regularly
                      informed. Normally your file will have constant conduct
                      and you will be made aware of appropriate information and
                      circumstances. Where there is a period of quiet and no
                      action occurs the file will be reviewed at least
                      bi-monthly.
                    </li>
                    <li>
                      For the purposes of our Lexcel Quality Mark each current
                      file will be subject at least every six months to a review
                      when you will be clearly informed in writing as to what is
                      happening with the matter, an assessment of what needs to
                      be achieved, the costs so far incurred, the likely future
                      costs, a review of alternative methods of funding (if any)
                      the risks involved and the timeframe with a further date
                      provided for the next six month review.
                    </li>
                  </ul>
                </li>
                {/* 2.1 end */}

                {/* 2.2 */}
                <li>
                  Communication – Core Value of the Firm
                  <ol>
                    <li>
                      <span className="tnc-list-description d-inline">
                        Communication between us will be through letters,
                        telephone calls and emails. Priority must inevitably be
                        given to urgent matters providing a swift response but
                        all communication will be dealt with as soon as
                        reasonably possible. If any person is unavailable a
                        message can be left.
                      </span>
                    </li>
                    <li>
                      <span className="tnc-list-description d-inline">
                        If you send an email or leave a message on voicemail you
                        cannot assume we will receive it immediately. If either
                        message is urgent you should telephone our Reception to
                        leave a message to that effect in addition.
                      </span>
                    </li>
                    <li>
                      <span className="tnc-list-description d-inline">
                        With emails we are entitled to assume that the purported
                        sender is the actual sender and that any express or
                        implied approval or authority referred to in any email
                        has been validly given. You acknowledge that emails may
                        not be secure and that delivery of an email is
                        uncertain. Incoming emails are subject to screening for
                        spam, viruses and other undesirable content. Such emails
                        will be quarantined or deleted and therefore in our
                        discretion not read.
                      </span>
                    </li>
                  </ol>
                </li>
                {/* 2.2 end */}
              </ol>
            </li>
            {/* 2 end */}

            {/* 3 */}
            <li>
              Responsibility for Your Transaction
              <ol>
                {/* 3.1 */}
                <li>
                  We will :
                  <ul>
                    <li>
                      Review your matter regularly and keep you advised of
                      progress.{" "}
                    </li>
                    <li>
                      Advise you of any relevant changes in the law or
                      regulations which may relate to your transaction.{" "}
                    </li>
                    <li>
                      {" "}
                      Advise you of any circumstances and risks of which we
                      become aware or consider to be reasonably foreseeable that
                      could affect the outcome of your transaction.{" "}
                    </li>
                  </ul>
                </li>
                {/* 3.1 end */}

                {/* 3.2 */}
                <li>
                  To assist us in complying with our stated responsibilities and
                  in providing you with the standard of service we expect you as
                  the client:
                  <ul>
                    <li>
                      To provide us with clear, timely and accurate
                      instructions.
                    </li>
                    <li>
                      To seek help and clarification about anything that you do
                      not understand.{" "}
                    </li>
                    <li>
                      {" "}
                      To provide, sign or deliver all documentation required to
                      complete the transaction in a timely manner.{" "}
                    </li>
                    <li>
                      To safeguard any documents which are likely to be required
                      (for example in any formal process of “disclosure” through
                      the Courts.){" "}
                    </li>
                    <li>
                      To advise us of times when you are not likely to be
                      available or away.
                    </li>
                  </ul>
                </li>
                {/* 3.2 end */}

                {/* 3.3 */}
                <li>
                  <span className="tnc-list-description d-inline">
                    In the CCI the name and status of the Solicitor or other
                    adviser having conduct of your transaction will be
                    identified at the outset. While every effort will be made to
                    ensure continuity sometimes changes in personnel cannot be
                    avoided. Where such changes are required we will notify you
                    promptly of this.{" "}
                  </span>
                </li>
                {/* 3.3 end */}

                {/* 3.4 */}
                <li>
                  <span className="tnc-list-description d-inline">
                    In the CCI you will also be advised of the Solicitor/Partner
                    of the Firm who will have overall responsibility for the
                    Department handling this matter. Such responsible Partner
                    will normally be available to speak to you as necessary, or
                    make an arrangement to do so accordingly.{" "}
                  </span>
                </li>
                {/* 3.4 end */}

                {/* 3.5 */}
                <li>
                  <span className="tnc-list-description d-inline">
                    In the conduct of your transaction and with your consent we
                    may engage other professionals (e.g. barristers,
                    accountants, experts). Whilst we will do so with care we
                    cannot be held responsible for any act or omission of such
                    professionals. Also in instructing them on your behalf you
                    will be responsible for their fees and expenses and we will
                    not so instruct them until you are aware of what these may
                    be.{" "}
                  </span>
                </li>
                {/* 3.5 end */}

                {/* 3.6 */}
                <li>
                  <span className="tnc-list-description d-inline">
                    In providing our commitment of service to you as client,
                    knowledge gained by any person at the Firm may be shared
                    with others within the Firm. You authorise us therefore to
                    retain and distribute within the Firm the contents of all
                    papers relating to those matters which we have dealt with on
                    your behalf. Such contents will be kept confidential within
                    the Firm at all times. Internal file reviews are regularly
                    undertaken as a matter of quality control.
                  </span>
                </li>
                {/* 3.6 end */}

                {/* 3.7 */}
                <li>
                  <span className="tnc-list-description d-inline">
                    Where we appropriately receive instructions from a
                    commercial lender relating to your transaction we have a
                    duty equally to that lender as a client of this firm and
                    therefore fully to reveal to your lender all relevant facts
                    about any purchase and the mortgage which may include:
                  </span>
                  <ul>
                    <li>
                      Any differences between your mortgage application and
                      information we receive during the transaction{" "}
                    </li>
                    <li>
                      Any cash-back payments or discount schemes incentives or
                      other provisions that a seller may be giving you.{" "}
                    </li>
                    <li>
                      Any special or unusual arrangements concerning the
                      financing of the transaction{" "}
                    </li>
                  </ul>
                  <span className="tnc-list-description d-inline">
                    You duly authorise us to make such disclosures. If a
                    conflict of interest arises we may have to cease
                    representing you and / or the lender in the transaction.{" "}
                  </span>
                </li>
                {/* 3.7 end */}
              </ol>
            </li>
            {/* 3 end */}

            {/* 4 */}
            <li>
              Confidentiality and Data Protection
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    Confidentiality will be assured throughout your transaction.
                    We use information you provide primarily for the provision
                    of legal services to you but also for related purposes
                    including:
                  </span>
                  <ul>
                    <li>Updating and enhancing client records </li>
                    <li>Analysis to help us manage our Practice. </li>
                    <li>Statutory returns </li>
                    <li>Legal and regulatory compliance </li>
                  </ul>
                  <span className="tnc-list-description d-inline">
                    Our use of such information is subject to your instructions,
                    the General Data Protection Regulations 2018 and our duty of
                    confidentiality under the Solicitors Code of Practice. This
                    provides a high professional obligation upon us. You have a
                    right of access under data protection legislation to the
                    personal data which we hold about you.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Please note that our work for you may require us to pass on
                    such information to third parties such as expert witnesses
                    and other professional advisers, including sometimes
                    advisers appointed by another party to your matter. We may
                    also give such information to others who perform services
                    for us such as, but not limited to, typing or photocopying.
                    Our practice may be audited or checked by our accountants or
                    our regulator, or by other organisations. We do not normally
                    copy such information to anyone outside the European
                    Economic Area, however we may do so when the particular
                    circumstances of your matter so require. All such third
                    parties are required to maintain confidentiality in relation
                    to your files. You have a right of access under data
                    protection law to the personal data that we hold about you.
                    We seek to keep that personal data correct and up to date.
                    You should let us know if you believe the information we
                    hold about you needs to be corrected or updated.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Data Protection in Respect of Money Laundering Checks We may
                    receive personal data from you for the purposes of our money
                    laundering checks, such as a copy of your passport. These
                    will be processed only for the purposes of preventing money
                    laundering and terrorist financing or as otherwise permitted
                    by law or with your express consent. You consent to us
                    retaining such data for longer than the five year statutory
                    period unless you tell us otherwise.
                  </span>
                </li>

                <li>
                  <span className="tnc-list-description d-inline">
                    Data Protection – Your Obligations If you send us personal
                    data about anyone other than yourself you will ensure you
                    have any appropriate consents and notices in place to enable
                    you to transfer that personal data to us, and so that we may
                    use it for the purposes for which you provide it to us.
                  </span>
                </li>

                <li>
                  <span className="tnc-list-description d-inline">
                    You allow us to disclose information and discuss it as
                    reasonably necessary and appropriate:-
                  </span>
                  <ul>
                    <li>Within the Firm as mentioned in clause 3.6. </li>
                    <li>
                      In conjunction with other advisers (as mentioned in Clause
                      3.5) or with outsourcing (as mentioned in Clause 4.3).
                    </li>
                    <li>
                      To your commercial Lender as mentioned in Clause 3.7.{" "}
                    </li>
                    <li>
                      To our Accountants who audit and must submit a Certificate
                      of Compliance with the Solicitors’ Accounts Rules to the
                      Law Society (but our Accountants will have similar
                      obligations of confidentiality to their clients as we do
                      ourselves).{" "}
                    </li>
                    <li>
                      To any independent inspector as may be required in
                      compliance with any quality or “flag” accreditation.{" "}
                    </li>
                    <li>
                      To comply with the requirements of the process of
                      “disclosure” in litigation.{" "}
                    </li>
                    <li>
                      To comply with Court or other tribunal rules all
                      documentation relevant to any litigation issues, however
                      potentially damaging to your case, have to be retained and
                      may be required to be made available to the other side.
                      This is known as “disclosure”. (See Clause 3.2){" "}
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            {/* 4 end */}

            {/* 5            */}
            <li>
              Documents, Papers and Storage
              <ol>
                <li>
                  {" "}
                  <span className="tnc-list-description d-inline">
                    Our working papers, draft documents and communications will
                    remain our property over which we have copyright. We expect
                    you as the client also to respect the confidentiality of
                    these papers and not to misuse them.{" "}
                  </span>
                </li>
                <li>
                  {" "}
                  <span className="tnc-list-description d-inline">
                    At the conclusion of your transaction we will return to you
                    any documents that you might wish to retain or at your
                    request store deeds or other documents in our safe keeping
                    (for which at present no charge is made) and in our closure
                    correspondence we will again advise you of a timeframe for
                    the retention of the file in storage which will not be less
                    than seven years. After that time we have your authority
                    without further reference to you to destroy all papers
                    (other than those retained in safe custody).
                  </span>
                </li>
              </ol>
            </li>
            {/* 5 end         */}

            {/* 6 */}
            <li>
              Your Identity and Address
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    {" "}
                    We are required by law to obtain and retain satisfactory
                    evidence of your identity and address and regularly update
                    the evidence in respect of existing clients. This may
                    include persons having ownership in shares in a Limited
                    Company and the beneficiaries of a Trust.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    {" "}
                    The CCI will detail the evidence of identity and address
                    which we require from you and your help and co-operation in
                    providing this swiftly would be appreciated please.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We are entitled to refuse to act for you if you fail to
                    supply suitable proof of identity and address for yourself
                    or for any principal you may represent.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Money Laundering Regulations override our duty of
                    confidentiality to our clients where there may be suspicion
                    of involvement in handling proceeds of crime. If we report
                    such a matter to the authority we are not then permitted to
                    take any further action for a period of time on your behalf
                    and as we are then acting within the requirements of the law
                    you accept that we will not then be responsible for any loss
                    or inconvenience that may then occur to you. If we report a
                    matter we are not permitted to alert you to that fact
                    because the law prohibits “tipping off”.{" "}
                  </span>
                </li>
              </ol>
            </li>
            {/* 6 end */}

            {/* 7 */}
            <li>
              Client Money and Banking
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    Very particular and strict provisions apply pursuant to the
                    Solicitors’ Accounts Rules (as may be amended from time to
                    time) to the holding of clients’ money. This is money which
                    we receive from you or from a third party on your behalf.
                    Such money will be held in a separate clients’ bank account.
                    Be assured that it is a serious breach of the Rules for the
                    individual Clients Ledger to be overdrawn.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Sometimes money received from a third party but in
                    connection with your transaction is held subject to
                    conditions such as a Solicitor’s undertaking which we must
                    obey.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    A payment of interest on money held to your credit in
                    Clients Account will be made in accordance with the Rules.
                    Payment will be made without deduction of tax. It is your
                    responsibility to declare the sum so received for tax
                    purposes.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    No payment of interest will be made where the amount is less
                    than £20 or such greater sum as is prescribed by the Rules.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We will account to you for any commission that we receive
                    for business undertaken on your behalf unless you agree that
                    we may retain it. We will not account to you with any
                    commission which is less than £20 or such greater sum is
                    prescribed by the Rules.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    With regard to our banking arrangements we specifically
                    mention:-{" "}
                  </span>
                  <ol>
                    <li>
                      <span className="tnc-list-description d-inline">
                        We bank with Royal Bank of Scotland (RBS) Group plc.
                        Money held in our Clients Account remains your money and
                        belongs to you as the client. The Government has
                        currently guaranteed deposits with Banks up to a certain
                        figure. This guarantee may not subsist for all time. If
                        you already hold monies with the Banks mentioned then
                        clients’ money held by us must be aggregated with it. As
                        our professional expertise is not banking we cannot
                        accept responsibility for discerning risks involved in
                        maintaining accounts with these Banks provided that in
                        all circumstances we have acted reasonably.{" "}
                      </span>
                    </li>
                    <li>
                      <span className="tnc-list-description d-inline">
                        We shall be not responsible in any way for any loss that
                        might arise as a result of the insolvency of any bank
                        (as defined in Section 87 of the Solicitors Act 1974) or
                        any building society (within the meaning of the Building
                        Societies Act 1986) which holds client money in a client
                        account in the name of this Firm or the failure of any
                        such bank or building society generally to repay monies
                        on demand.
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We may require evidence of the source of funds paid to us
                    and where monies are paid by a third party we may also
                    require the identity of that third party for which the
                    provisions of clause 6 shall apply.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Payment to us (whether money paid in the course of the
                    transaction or of our Bill) may be made by cheque, banker’s
                    draft, BACS or CHAPS or by credit or debit card. Do be aware
                    that payments by cheque, credit or debit card require up to
                    six working days to clear through the banking system. We
                    will only accept payment in cash at our discretion and then
                    only for modest sums.{" "}
                  </span>
                </li>
              </ol>
            </li>
            {/* 7 end */}

            {/* 8 */}
            <li>
              Basis of charging
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    Where possible we
                    shall endeavour to agree a fixed fee for the work you have
                    instructed us to do for you, subject to the provisions of
                    the CCI. The basis for a fixed fee involves a consideration
                    of the time element, the value of the transaction, the
                    urgency but also market forces.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    {" "}
                    We will provide the best information possible
                    about the likely costs including a breakdown of our fee,
                    giving a forecast with a possible range of costs where a
                    more specific estimate is not possible. The following
                    factors may be taken into account in assessing our charges:
                  </span>
                  <ul>
                    <li>
                      The time actually spent in dealing with the matter.{" "}
                    </li>
                    <li>
                      The rate of hourly charge for the time spent. These rates
                      are reviewed at least once a year. You will be notified of
                      any change.{" "}
                    </li>
                    <li>The complexity or novelty of the matter. </li>
                    <li>
                      Value and importance of the matter and consequently the
                      responsibility placed upon the Firm in undertaking it.{" "}
                    </li>
                    <li>
                      The urgency of the matter, perhaps requiring our attention
                      outside usual hours.
                    </li>
                    <li>
                      Any other special considerations that might apply to your
                      transaction.
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We shall give a clear explanation of the time likely to be
                    spent in bringing the matter to conclusion or where this
                    cannot realistically be estimated at the outset an
                    indication of the stage when we will be likely to be able to
                    provide such a clear estimate.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Our “best estimate” shall as relevant refer to the
                    assessment of “complexity”, “value” and “urgency” factors
                    and how they might affect the overall cost. If the
                    transaction does prove to be substantially more complex or
                    time consuming than expected additional costs may be
                    charged. If these factors arise subsequently during the
                    course of the transaction you will be notified.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    In addition to our fees VAT will be payable at the
                    prevailing rate together with any payments made or to be
                    made to third parties for fees and expenses required. So far
                    as possible you will be notified of these at the
                    commencement of the transaction but in your interests it may
                    be necessary to incur additional fees to third parties
                    during the course of the transaction.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Occasionally it is necessary to incur additional expenses
                    for example where large quantities of photocopying are
                    undertaken by an outside provider or courier services are
                    required or special postal provision or insurance of items.
                    If such are likely to be incurred you will be notified but
                    this is exceptional.
                  </span>
                </li>
              </ol>
            </li>
            {/* 8 end */}

            {/* 9 */}
            <li>
              Billing arrangements and payment
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    In lieu of a bill a clear request for payment may be
                    submitted to you instead. A request for payment is not a VAT
                    invoice. In the interpretation of these Terms the word
                    “bill” shall be deemed to include a request for payment.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Bills are due for payment immediately on presentation. We
                    reserve the right to add interest charged at 8% per year
                    from the date of the bill on any bill remaining unpaid.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We will be entitled to render interim bills on account of
                    our fees prior to the conclusion of the matter.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    As payments to third parties (called disbursements) arise we
                    will require payment of those sums in advance and we may
                    also require a retainer to be paid before commencing work on
                    your behalf. Failure to make such payment may result in our
                    stopping work.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    If a transaction is abortive a charge will be made for such
                    work in proportion to the quantity and responsibility of the
                    work undertaken in relation to the provision of the estimate
                    provided at its commencement.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    If at any time you believe that you will have difficulty in
                    meeting future costs, you should immediately inform us so
                    that we together may consider and decide the best course of
                    action for you. It must be appreciated however that in the
                    case of failure to pay or to provide reasonable sums on
                    account we may need, with regret, to terminate your
                    representation.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    You should advise us immediately if you are expecting
                    payment to be made by a third party or that you are making a
                    claim under a relevant Legal Expenses Insurance Policy or
                    payment is coming from some other source.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    When accepting instructions to act on behalf of a Limited
                    Company we may require a Director and/or a controlling
                    Shareholder to sign a form of personal guarantee in respect
                    of the charges and expenses of this Firm. If such a request
                    is refused we will be entitled to stop acting and to require
                    immediate payment of any charges previously incurred.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Pending payment of our costs, expenses and disbursements in
                    full we are entitled to retain any money, papers or other
                    property belonging to you which properly came into our
                    possession whether or not the property is acquired in
                    connection with the matter for which costs were incurred.
                    This is known as “a lien”. We are not entitled to sell such
                    property but we are entitled to hold it even if the value of
                    it greatly exceeds the amount due to us in respect of costs
                    etc.{" "}
                  </span>
                </li>
              </ol>
            </li>
            {/* 9 end */}

            {/* 10 */}
            <li>
              Exclusions and Limitations of Liability
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    We can only limit our liability to the extent that the law
                    allows. In particular we cannot limit our liability for
                    death or personal injury caused by our negligence.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Unless we agree otherwise our liability in respect of any
                    matter arising in relation to our engagement shall be
                    limited to the sum of £3,000,000 per claim. This limitation
                    shall apply to our aggregate liability to you and any
                    connected person or persons in respect of any one claim.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We will not be liable for any loss arising directly or
                    indirectly:-{" "}
                  </span>
                  <ul>
                    <li>
                      For failure to take any action that falls outside the
                      scope of the work defined in the CCI.
                    </li>
                    <li>Events and circumstances beyond our control. </li>
                    <li>
                      Failure to take action in circumstances where you have
                      failed to pay us any monies properly requested by us.{" "}
                    </li>
                    <li>
                      Arising either directly or indirectly out of compliance
                      with overriding obligation imposed on us by law or by our
                      profession.{" "}
                    </li>
                    <li>
                      Your failure to act entirely in accordance with our advice
                      or your omission to take any action properly required of
                      you in the course of the matter.{" "}
                    </li>
                    <li>
                      Any act or omission of other parties engaged by us on your
                      behalf.{" "}
                    </li>
                    <li>
                      The quarantining (and thus the omission to read) of any
                      email communication which may contain a virus or other
                      undesirable content.{" "}
                    </li>
                    <li>
                      Contamination by viruses of any email sent by us to you.{" "}
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            {/* 10 end */}

            {/* 11 */}
            <li>
              Termination
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    We expect to continue to represent you in the matter on
                    which you have provided instructions. You can bring the
                    engagement to an end at any time. We can only stop acting
                    for you with good reason. We must give you reasonable notice
                    that we will stop acting for you. Such good reason may
                    include (but is not limited to) the following:-{" "}
                  </span>
                  <ul>
                    <li>Where a conflict of interest arises. </li>
                    <li>
                      Where we consider it would not be in your best interests
                      for us to continue to represent you.
                    </li>
                    <li>
                      Where there is a breakdown in trust and/or confidence
                      between us.{" "}
                    </li>
                    <li>
                      Where you fail to provide us with instructions or
                      documentation we need to represent you properly.{" "}
                    </li>
                    <li> Where the cost limit, if any, has been reached.</li>
                    <li>
                      Where we are unable to agree a revised fee for our work in
                      accordance with these instructions.{" "}
                    </li>
                    <li>
                      Where our bill(s) is not settled promptly or you have
                      failed to make appropriate payments on account as
                      requested to do.{" "}
                    </li>
                    <li>
                      {" "}
                      Where your instructions might conflict with our rules of
                      professional conduct or our ethical policy or might
                      otherwise in our opinion bring us into disrepute.{" "}
                    </li>
                    <li>
                      Where a statement of truth for which you are directly or
                      indirectly responsible is found to be erroneous or where
                      you fail to meet a third party order for costs within the
                      stipulated period.{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    {" "}
                    If you or we decide that we should stop acting for you, you
                    will pay our charges up to that point.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    Under the Consumer Protection (Distance Selling) Regulations
                    2000 and amendment Regulations of 2005 in a situation where
                    we are supplying the service without face to face contact
                    (such as through telephone and email contact) you have the
                    right to withdraw without charge within the period of seven
                    working days from the date on which you asked us to act for
                    you. However if we start to work with your consent within
                    that period then you lose the right to withdraw. Your
                    acceptance of these terms of business will amount to such
                    consent. If you decide to withdraw instructions then you
                    should give notice by telephone, email or letter to the
                    person responsible for dealing with your work. These
                    Regulations require us to inform you, as we do here, that
                    the work involved is likely to take more than thirty days to
                    accomplish. You proceed with notice of this statement.
                  </span>
                </li>
              </ol>
            </li>
            {/* 11 end */}

            {/* 12 */}
            <li>
              {" "}
              Complaints
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    We expect to provide you with the service that you require.
                    If you are concerned or dissatisfied you are free to
                    complain at any time. Subject to 12.2 it would be helpful to
                    follow this internal procedure as we will always endeavour
                    to put things right for you:
                  </span>
                  <ul>
                    <li>
                      Please contact the person handling your matter and discuss
                      it freely.
                    </li>
                    <li>
                      If you prefer not to discuss the matter with a person
                      handling your matter then please speak to the relevant
                      Complaints Manager who will specialise in the same area of
                      law and be pleased to help you.
                    </li>
                    <li>
                      If you prefer not to speak to the Head of Department then
                      please speak to the Complaints Director.
                    </li>
                  </ul>
                  <span className="tnc-list-description d-inline">
                    Usually the Authorities mentioned in 12.2 would expect you
                    to exhaust the internal complaints procedure first but this
                    is not essential{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    If you are unhappy or concerned about the service you have received or the progress of your
                    transaction, then at any time (without using the internal complaints
                    procedure if you wish) you may complain either to:
                  </span>

                  <ol>
                    <li>
                      <span className="tnc-list-description d-inline">
                        The Solicitors Regulation Authority of Ipsley Court,
                        Berrington Close, Redditch, B98 0TD which is the
                        independent regulatory arm of the Law Society of The Law
                        Society’s Hall, 113 Chancery Lane, London, WC2A 1PL;
                      </span>
                    </li>
                    <li>
                      <span className="tnc-list-description d-inline">
                        The Legal Ombudsman PO Box 6806 Wolverhampton WV1 9WJ
                        which is the independent complaints handling arm of the
                        Law Society of the address mentioned in clause 12.2.1
                        above.{" "}
                      </span>
                    </li>
                    <span className="tnc-list-description d-inline">
                      You may raise your concerns with either of these bodies.{" "}
                    </span>
                  </ol>
                </li>
              </ol>
            </li>
            {/* 12 end */}

            {/* 13 */}
            <li>
              Financial Services
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    We are not authorised by the Financial Conduct Authority but
                    in accordance with the Solicitors’ Financial Services
                    (Conduct of Business) Rules 2001 we advise you that:-
                  </span>
                  <ol>
                    <li>
                      <span className="tnc-list-description d-inline">
                        If while acting for you advice is needed on investments
                        or mortgages we may have to refer you to someone who is
                        authorised to provide the necessary advice. However we
                        may provide certain limited investment advice services
                        where these are closely linked to the legal work we are
                        doing for you. This is because we are members of the Law
                        Society of England and Wales which is a designated
                        professional body for the purposes of the Financial
                        Services and Markets Act 2000. We refer clients who
                        require financial advice outside the scope of this
                        limited exemption to a firm of independent financial
                        advisers.
                      </span>
                    </li>

                    {/* <li>   // Previous 13.1.2 (removed)
                      <span className="tnc-list-description d-inline">  
                        However we are included on the register maintained by
                        the Financial Conduct Authority so that we may carry on
                        insurance and mediation activity which is broadly the
                        advising on selling and administration of insurance
                        contracts. This part of our business including
                        arrangements for complaints or redress if something goes
                        wrong is also regulated by the Solicitors Regulation
                        Authority. The register can be accessed via the
                        Financial Services website at{" "}
                        <a href="http://www.fca.gov.uk/register">
                          {" "}
                          www.fca.gov.uk/register
                        </a>
                        .
                      </span>
                    </li> */}
                    <li>
                      <span className="tnc-list-description d-inline">
                        The Law Society is the designated professional body for
                        the purposes of the Financial Services and Markets Act
                        2000. The Solicitors’ Regulation Authority is the
                        independent regulatory arm of the Law Society. The Legal
                        Ombudsman is the independent complaints handling arm for
                        the Law Society. If you are unhappy with any investment
                        advice or any insurance advice you receive from us you
                        should raise your concerns with either of these bodies.
                        The addresses are included in Clause 12.{" "}
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            {/* 13 end */}

            {/* 14 */}
            <li>
              Professional and Other Matters
              <ol>
                <li>
                  <span className="tnc-list-description d-inline">
                    We have an Equality and Diversity Policy for preventing
                    discrimination and harassment and for promoting equality and
                    diversity. If required by you our Policy is available to you
                    for consideration.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We will be aware of and alert to communication challenges
                    such as hearing or learning disabilities, language barrier
                    and any cross-culture issues.{" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We are registered with the Solicitors Regulation Authority
                    pursuant to No. {this.regNo}.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We are registered for Value Add Tax under registration
                    number {this.VATregNo}.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    {" "}
                    As required by the Solicitors Conduct Rules this firm is
                    covered by a Professional Indemnity Insurance so that should
                    we be negligent a claim can be made. This applies throughout
                    the geographical area of the UK. Reference to details of the
                    current PII provider is mentioned in your CCI and can be
                    found on our website and there is a notice provided on our premises. We refer also to Clause 10. If you require
                    further information about our cover then please let us know.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    We are qualified to advise on matters of English Law only
                    and English Law will govern the terms of our contract with
                    you.
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    The normal hours of business are Monday to Friday
                    inclusive 9.30am to 4.30pm. By
                    special arrangement appointments can be made outside these
                    working hours. In addition to normal office hours, Neolexical is pursuing an innovative business model whereby you can contact us via the Website and upload your will and lasting power of Attorney instructions via both our Website and our unique Mobile phone App know as Will-Smart 24 hours a day and 7 days a week. However, our physical office hours are the usual office hours. {" "}
                  </span>
                </li>
                <li>
                  <span className="tnc-list-description d-inline">
                    The words “working day” excludes all Saturdays and Sundays
                    and Bank or other Public Holidays.{" "}
                  </span>
                </li>
              </ol>
            </li>
            {/* 14 end */}
          </ol>
          <div
            style={{
              marginTop: "5rem",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            <Divider horizontal>
              {" "}
              <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                END OF THE TERMS OF BUSINESS
              </span>{" "}
            </Divider>
            <span
              style={{
                display: "block",
                marginTop: "3rem",
                wordSpacing: "0.3rem",
              }}
            >
              NEOLEXICAL Ltd a company Registered in England and Wales (Company
              Registration No. 12608981) trading as Neolexical
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default TNCBody;
