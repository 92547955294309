import React, { Component } from "react";

import Header from "../landingPage/header/Header";
import Footer from "../footer/Footer";
import firebase from "../../config/firebase";
import "firebase/firestore";
import { Link } from "react-router-dom";

import image from "../../images/Neo-Logo.png";
import googleSignInImage1x from "../../images/btn_google_signin_dark_normal_web.png";
import { connect } from "react-redux";
import VerifyEmail from "./VerifyEmail";

class Register extends Component {
  state = {
    email: "",
    password: "",
    username: "",
    addClass: "",
    loading: false,
    error: "",
    usersRef: firebase.database().ref("users"),
    emailVerified: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    var user = firebase.auth().currentUser;
    // console.log(user);
    if (user !== null && user.emailVerified) {
      this.props.history.push("/user");
    } else if (user !== null && !user.emailVerified) {
      this.setState({ emailVerified: false });
    }
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  signUpWithGoogle = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(async (result) => {
        var user = result.user;
        // console.log(user.uid);

        this.saveUser(user)
          .then(() => {
            // console.log("google user saved");
          })
          .catch(() => {
            // console.log("google user couldnt saved");
          });
        this.props.setUser({ ...user });

        this.props.history.push("/user");
        // ...
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      this.setState({ error: "", loading: true });
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then((user) => {
          // console.log(user.user.email);
          user.user
            .updateProfile({
              displayName: this.state.username,
              photoURL: `https://api.adorable.io/avatars/285/${user.user.email}`,
            })
            .then(() => {
              this.saveUser(user)
                .then(() => {
                  this.setState({ loading: false });
                  this.props.setUser(user.user);
                  // console.log("user saved");
                  this.props.history.push("/user");
                })
                .catch((err) => {
                  // console.log(err);
                  this.setState({ error: err.message, loading: false });
                });
            })
            .catch((err) => {
              // console.log(err);
              this.setState({ error: err.message, loading: false });
            });
        })
        .catch((err) => {
          // console.log(err);
          this.setState({ error: err.message, loading: false });
        });
    } else {
      // console.log(this.state.error);
    }
  };
  isFormValid = () => {
    if (this.state.password < 6) {
      this.setState({ error: "Password length must  be greater than 6" });
      return false;
    }
    return true;
  };
  saveUser = async (user) => {
    // return this.state.usersRef.child(user.user.uid).set({
    //   name: user.user.displayName,
    //   avatar: user.user.photoURL,
    // });
    const db = firebase.firestore();
    const data = {
      name: user.user.displayName,
      avatar: user.user.photoURL,
    };

    // Add a new document in collection "cities" with ID 'LA'
    const res = await db
      .collection("users")
      .doc(user.user.uid)
      .set(data, { merge: true });
    return res;
  };
  render() {
    if (this.state.emailVerified !== true)
      return <VerifyEmail user={this.state.user} />;
    else
      return (
        <React.Fragment>
          <Header />
          <div className="body-container" style={{ height: "55rem" }}>
            <div className="auth-card " style={{ height: "51rem" }}>
              <div className="auth-card-avatar">
                <img src={image} alt="profile"></img>
              </div>

              <span className="auth-text-heading">Sign Up</span>

              <div className="auth-form">
                <form onSubmit={this.onSubmitHandle}>
                  <div className="auth-input-container mb-4">
                    <span className="input-text">Username</span>
                    <input
                      type="text"
                      value={this.state.username}
                      name="username"
                      onChange={this.onChangeHandler}
                      required
                    ></input>
                  </div>
                  <div className="auth-input-container mb-4">
                    <span className="input-text">Email</span>
                    <input
                      type="email"
                      value={this.state.email}
                      name="email"
                      onChange={this.onChangeHandler}
                      required
                    ></input>
                  </div>
                  <div className="auth-input-container mb-4">
                    <span className="input-text">Password</span>
                    <input
                      type="password"
                      value={this.state.password}
                      name="password"
                      onChange={this.onChangeHandler}
                      required
                    ></input>
                  </div>
                  <button disabled={this.state.loading}>Sign Up</button>
                </form>
                {this.state.error === "" ? (
                  <div style={{ height: "20px" }}></div>
                ) : (
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.error}
                  </div>
                )}
                <span style={{ fontSize: "1.04rem" }}>
                  Already have an account?<Link to="/login"> Login here</Link>
                </span>
                <div style={{ marginTop: "0.3rem" }}>
                  <button
                    type="button"
                    class="help-reg-btn"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Why do I need to register?
                  </button>
                </div>
                <div
                  className="ui horizontal divider "
                  style={{ background: "none" }}
                >
                  Or
                </div>
                <div className="socialmedia-icon">
                  <Link
                    to="#"
                    onClick={this.signUpWithGoogle}
                    className="socialmedia-icon-google"
                  >
                    <img src={googleSignInImage1x} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="exampleModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Why do I need to register?
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {/* todo: why reg*/}
                  If you wish to make a will or lasting power of attorney (LPA) online we require you to register. Once you've signed up, you may wish to proceed to making your Will or LPA. 
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </React.Fragment>
      );
  }
}

const mapStateToDispatch = (dispatch) => {
  return {
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
  };
};

export default connect(null, mapStateToDispatch)(Register);
