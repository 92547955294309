import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";

class BeneficiaryType extends Component {
  state = {
    gift_beneficiary_type: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.giftInfo.gift_beneficiary_type !== null)
      this.setState({
        gift_beneficiary_type: this.props.giftInfo.gift_beneficiary_type,
      });
  }
  onClick = (e) => {
    this.setState({
      gift_beneficiary_type: e.target.name,
    });
    let db = firebase.firestore();
    if (e.target.name === "None") {
      db.collection("giftInfo")
        .doc(this.props.currentUser.uid)
        .set({
          user_id: db.doc("users/" + this.props.currentUser.uid),
          gift_beneficiary_type: e.target.name,
          charity_gift_count: null,
          charity_gifts: null,
          family_cash_gift_count: null,
          family_cash_gifts: null,
          family_general_gift_count: null,
          family_general_gifts: null,
        })
        .then(() => {
          // console.log("beneficiary type in firestore");
        })
        .catch(() => {
          // console.log("beneficiary type in firestore failed");
        });
      this.props.setGiftInfo({
        gift_beneficiary_type: e.target.name,
        charity_gift_count: null,
        charity_gifts: null,
        family_cash_gift_count: null,
        family_cash_gifts: null,
        family_general_gift_count: null,
        family_general_gifts: null,
      });
      this.props.setCurrentView("next");
    } else if (e.target.name === "Family") {
      if (
        this.props.giftInfo.charity_gift_count !== null ||
        this.props.giftInfo.charity_gifts !== null
      ) {
        db.collection("giftInfo")
          .doc(this.props.currentUser.uid)
          .set({
            ...this.props.giftInfo,
            user_id: db.doc("users/" + this.props.currentUser.uid),
            gift_beneficiary_type: e.target.name,
            charity_gift_count: null,
            charity_gifts: null,
          })
          .then(() => {
            // console.log("beneficiary type in firestore");
          })
          .catch(() => {
            // console.log("beneficiary type in firestore failed");
          });

        this.props.setGiftInfo({
          ...this.props.giftInfo,
          gift_beneficiary_type: e.target.name,
          charity_gift_count: null,
          charity_gifts: null,
        });
      } else {
        db.collection("giftInfo")
          .doc(this.props.currentUser.uid)
          .set({
            ...this.props.giftInfo,
            user_id: db.doc("users/" + this.props.currentUser.uid),
            gift_beneficiary_type: e.target.name,
          })
          .then(() => {
            // console.log("beneficiary type in firestore");
          })
          .catch(() => {
            // console.log("beneficiary type in firestore failed");
          });

        this.props.setGiftInfo({
          ...this.props.giftInfo,
          gift_beneficiary_type: e.target.name,
        });
      }
      this.props.setCurrentView("familyGift");
    } else if (e.target.name === "Charity") {
      if (
        this.props.giftInfo.family_cash_gift_count !== null ||
        this.props.giftInfo.family_cash_gifts !== null ||
        this.props.giftInfo.family_general_gift_count !== null ||
        this.props.giftInfo.family_general_gifts !== null
      ) {
        db.collection("giftInfo")
          .doc(this.props.currentUser.uid)
          .set({
            ...this.props.giftInfo,
            user_id: db.doc("users/" + this.props.currentUser.uid),
            family_cash_gift_count: null,
            family_cash_gifts: null,
            family_general_gift_count: null,
            family_general_gifts: null,
            gift_beneficiary_type: e.target.name,
          })
          .then(() => {
            // console.log("beneficiary type in firestore");
          })
          .catch(() => {
            // console.log("beneficiary type in firestore failed");
          });

        this.props.setGiftInfo({
          ...this.props.giftInfo,
          family_cash_gift_count: null,
          family_cash_gifts: null,
          family_general_gift_count: null,
          family_general_gifts: null,
          gift_beneficiary_type: e.target.name,
        });
      } else {
        db.collection("giftInfo")
          .doc(this.props.currentUser.uid)
          .set({
            ...this.props.giftInfo,
            user_id: db.doc("users/" + this.props.currentUser.uid),
            gift_beneficiary_type: e.target.name,
          })
          .then(() => {
            // console.log("beneficiary type in firestore");
          })
          .catch(() => {
            // console.log("beneficiary type in firestore failed");
          });
        this.props.setGiftInfo({
          ...this.props.giftInfo,
          gift_beneficiary_type: e.target.name,
        });
      }
      this.props.setCurrentView("charityGift");
    } else if (e.target.name === "Both") {
      db.collection("giftInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.giftInfo,
          user_id: db.doc("users/" + this.props.currentUser.uid),
          gift_beneficiary_type: e.target.name,
        })
        .then(() => {
          // console.log("beneficiary type in firestore");
        })
        .catch(() => {
          // console.log("beneficiary type in firestore failed");
        });

      this.props.setGiftInfo({
        ...this.props.giftInfo,
        gift_beneficiary_type: e.target.name,
      });
      this.props.setCurrentView("familyGift");
    }
  };

  render() {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginTop: "2.5rem",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              display: "block",
              marginBottom: "4rem",
            }}
          >
            Who would you like to leave gifts for?
          </span>
          <div>
            <button
              className={
                this.state.gift_beneficiary_type === "None"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="None"
            >
              None
            </button>
          </div>

          <div>
            <button
              className={
                this.state.gift_beneficiary_type === "Family"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="Family"
            >
              Family
            </button>
          </div>

          <div>
            <button
              className={
                this.state.gift_beneficiary_type === "Charity"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="Charity"
            >
              Charity
            </button>
          </div>

          <div>
            <button
              className={
                this.state.gift_beneficiary_type === "Both"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="Both"
            >
              Both Family And Charity
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGiftInfo: (giftInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GIFT_INFO,
        payload: {
          userGiftInfo: giftInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    giftInfo: state.user.giftInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryType);
