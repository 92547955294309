import React, { Component } from "react";
import { connect } from "react-redux";
import { Step, Icon } from "semantic-ui-react";
import * as HealthAndWelfareStepsEnum from "./HealthAndWelfareStepsEnum";
import * as actionTypes from "../../../actions/types";

class HealthAndWelfareSteps extends Component {
  disableLifeSustainingTreatment = true;
  disableAttorney = true;
  disableMakeDecision = true;
  disableReplacementAttorney = true;
  disableReplacementAttorneysMakeDecision = true;
  disableCertificateProvider = true;
  disablePersonToNotify = true;
  disablePreferences = true;
  disableRegistration = true;
  disableCorrespondent = true;
  disableRepeat = true;
  disableSubmit = true;

  componentDidMount() {
    this.goToStep();
  }
  goToStep = () => {
    // console.log("hello");
    for (let index = 1; index <= this.props.stepNo; index++) {
      if (index === 2) {
        this.disableLifeSustainingTreatment = false;
      } else if (index === 3) {
        this.disableAttorney = false;
      } else if (index === 4) {
        this.disableMakeDecision = false;
      } else if (index === 5) {
        this.disableReplacementAttorney = false;
      } else if (index === 6) {
        this.disableReplacementAttorneysMakeDecision = false;
      } else if (index === 7) {
        this.disableCertificateProvider = false;
      } else if (index === 8) {
        this.disablePersonToNotify = false;
      } else if (index === 9) {
        this.disablePreferences = false;
      } else if (index === 10) {
        this.disableRegistration = false;
      } else if (index === 11) {
        this.disableCorrespondent = false;
      } else if (index === 12) {
        this.disableRepeat = false;
      } else if (index === 13) {
        this.disableSubmit = false;
      }
    }
  };
  isMultipleAttorney = () => {
    if (this.props.attorney.length > 1) return true;
    return false;
  };
  isMultipleReplacementAttorney = () => {
    if (this.props.replacementAttorney.length > 1) return true;
    return false;
  };

  render() {
    if (this.props.disable) {
      this.disableRegistration = true;
      this.disableCorrespondent = true;
      this.disableRepeat = true;
      this.props.setHealthLpaDisable(false);
    }
    if (
      this.props.currentView ===
      HealthAndWelfareStepsEnum.LIFE_SUSTAINING_TREATMENT
    )
      this.disableLifeSustainingTreatment = false;
    else if (this.props.currentView === HealthAndWelfareStepsEnum.ATTORNEY)
      this.disableAttorney = false;
    else if (
      this.props.currentView ===
      HealthAndWelfareStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
    )
      this.disableMakeDecision = false;
    else if (
      this.props.currentView === HealthAndWelfareStepsEnum.REPLACEMENT_ATTORNEY
    )
      this.disableReplacementAttorney = false;
    else if (
      this.props.currentView ===
      HealthAndWelfareStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
    )
      this.disableReplacementAttorneysMakeDecision = false;
    else if (
      this.props.currentView === HealthAndWelfareStepsEnum.CERTIFICATE_PROVIDER
    )
      this.disableCertificateProvider = false;
    else if (
      this.props.currentView === HealthAndWelfareStepsEnum.PERSON_TO_NOTIFY
    )
      this.disablePersonToNotify = false;
    else if (
      this.props.currentView ===
      HealthAndWelfareStepsEnum.PREFERENCES_N_INSTRUCTIONS
    )
      this.disablePreferences = false;
    else if (this.props.currentView === HealthAndWelfareStepsEnum.REGISTRATION)
      this.disableRegistration = false;
    else if (this.props.currentView === HealthAndWelfareStepsEnum.CORRESPONDENT)
      this.disableCorrespondent = false;
    else if (this.props.currentView === HealthAndWelfareStepsEnum.REPEAT)
      this.disableRepeat = false;
    else if (this.props.currentView === HealthAndWelfareStepsEnum.SUBMIT)
      this.disableSubmit = false;
    return (
      <Step.Group vertical>
        <Step
          link
          className={"step-mini"}
          active={
            this.props.currentView === HealthAndWelfareStepsEnum.DONOR
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(HealthAndWelfareStepsEnum.DONOR)
          }
        >
          <Icon name="user" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Donor</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableLifeSustainingTreatment}
          active={
            this.props.currentView ===
            HealthAndWelfareStepsEnum.LIFE_SUSTAINING_TREATMENT
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              HealthAndWelfareStepsEnum.LIFE_SUSTAINING_TREATMENT
            )
          }
        >
          <Icon name="heartbeat" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Life sustaining treatment</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableAttorney}
          active={
            this.props.currentView === HealthAndWelfareStepsEnum.ATTORNEY
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(HealthAndWelfareStepsEnum.ATTORNEY)
          }
        >
          <Icon name="handshake" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Attorney </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableMakeDecision || !this.isMultipleAttorney()}
          active={
            this.props.currentView ===
            HealthAndWelfareStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              HealthAndWelfareStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
            )
          }
        >
          <Icon name="exclamation" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Attorneys make decisions</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableReplacementAttorney}
          active={
            this.props.currentView ===
            HealthAndWelfareStepsEnum.REPLACEMENT_ATTORNEY
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              HealthAndWelfareStepsEnum.REPLACEMENT_ATTORNEY
            )
          }
        >
          <Icon name="handshake" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Replacement Attorney </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={
            this.disableReplacementAttorneysMakeDecision ||
            !this.isMultipleReplacementAttorney()
          }
          active={
            this.props.currentView ===
            HealthAndWelfareStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              HealthAndWelfareStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
            )
          }
        >
          <Icon name="exclamation" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Replacement attorneys make decisions</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableCertificateProvider}
          active={
            this.props.currentView ===
            HealthAndWelfareStepsEnum.CERTIFICATE_PROVIDER
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              HealthAndWelfareStepsEnum.CERTIFICATE_PROVIDER
            )
          }
        >
          <Icon name="file" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Certificate Provider </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disablePersonToNotify}
          active={
            this.props.currentView ===
            HealthAndWelfareStepsEnum.PERSON_TO_NOTIFY
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              HealthAndWelfareStepsEnum.PERSON_TO_NOTIFY
            )
          }
        >
          <Icon name="bell" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Person to Notify </Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disablePreferences}
          active={
            this.props.currentView ===
            HealthAndWelfareStepsEnum.PREFERENCES_N_INSTRUCTIONS
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              HealthAndWelfareStepsEnum.PREFERENCES_N_INSTRUCTIONS
            )
          }
        >
          <Icon name="tasks" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Preferences & Instructions</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableRegistration}
          active={
            this.props.currentView === HealthAndWelfareStepsEnum.REGISTRATION
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(HealthAndWelfareStepsEnum.REGISTRATION)
          }
        >
          <Icon name="registered" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Registration</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableCorrespondent}
          active={
            this.props.currentView === HealthAndWelfareStepsEnum.CORRESPONDENT
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(HealthAndWelfareStepsEnum.CORRESPONDENT)
          }
        >
          <Icon name="user" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Correspondent</Step.Title>
          </Step.Content>
        </Step>
        <Step
          link
          className={"step-mini"}
          disabled={this.disableRepeat}
          active={
            this.props.currentView === HealthAndWelfareStepsEnum.REPEAT
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(HealthAndWelfareStepsEnum.REPEAT)
          }
        >
          <Icon name="copy" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Repeat Application</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          className={"step-mini"}
          disabled={this.disableSubmit}
          active={
            this.props.currentView === HealthAndWelfareStepsEnum.SUBMIT
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(HealthAndWelfareStepsEnum.SUBMIT)
          }
        >
          <Icon name="check" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Submit</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHealthLpaDisable: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_DISABLE_REG_COR,
        payload: {
          disable: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    attorney: state.health_lpa.attorney,
    replacementAttorney: state.health_lpa.replacementAttorney,
    disable: state.health_lpa.disable,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthAndWelfareSteps);
