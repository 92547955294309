import React, { Component } from "react";
import ProductCard from "./ProductCard";
import { connect } from "react-redux";

class MyWills extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="col-9">
        <h1 className="aboutus-section-heading">My Wills</h1>
        <div className="basicinfo-container">
          <ProductCard
            {...{
              icon: "user",
              headingText: "Single Will",
              loading: this.props.data.singleWillLoading,
              link:
                this.props.data.singleWillData !== null &&
                this.props.data.singleWillData.status !== null
                  ? "/singlewill"
                  : "/single-will-info",
              paymentLink:
                this.props.data.singleWillDocID !== null
                  ? "/checkout/payment/singlewill/" +
                    this.props.data.singleWillDocID
                  : "",

              data: this.props.data.singleWillData,
            }}
          />
          <ProductCard
            {...{
              icon: "users",
              headingText: "Double Will",
              loading: this.props.data.doubleWillLoading,
              link:
                this.props.data.doubleWillData !== null &&
                this.props.data.doubleWillData.status !== null
                  ? "/doublewill"
                  : "/double-will-info",
              paymentLink:
                this.props.data.doubleWillDocID !== null
                  ? "/checkout/payment/doublewill/" +
                    this.props.data.doubleWillDocID
                  : "",
              data: this.props.data.doubleWillData,
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps)(MyWills);
