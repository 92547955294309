import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionTypes from "../../../../actions/types";
import LiquidAssetInfoCount from "./LiquidAssetInfoCount";
import * as liquidAssetInfoStepEnum from "./LiquidAssetInfoEnums";
import LiquidAssetInfoForm from "./LiquidAssetInfoForm";
import LiquidAssetInfoSteps from "./LiquidAssetInfoSteps";

class LiquidAssetInfo extends Component {
  state = {
    liquidAssetInfoView: liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_COUNT,
  };
  setLiquidAssetInfoCurrentView = (view) => {
    this.setState({ liquidAssetInfoView: view });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.partnerStepNo < 4) this.props.setPartnerStepNo(4);
    // if (this.props.liquidAssetInfo !== null)
    //   this.setState({

    //     ...this.props.liquidAssetInfo,
    //   });
  }
  render() {
    let currentViewComponent;
    if (
      this.state.liquidAssetInfoView ===
      liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_COUNT
    )
      currentViewComponent = (
        <LiquidAssetInfoCount
          setCurrentView={this.setLiquidAssetInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.liquidAssetInfoView ===
      liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_FORM
    )
      currentViewComponent = (
        <LiquidAssetInfoForm
          setCurrentView={this.setLiquidAssetInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.liquidAssetInfoView === "realAssetInfo") {
      this.props.setCurrentView("realAssetInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Your Liquid Asset</h1>
        <LiquidAssetInfoSteps
          setCurrentView={this.setLiquidAssetInfoCurrentView}
          currentView={this.state.liquidAssetInfoView}
        />
        {currentViewComponent}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerLiquidAssetInfo: (liquidAssetInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_LIQUID_ASSET_INFO,
        payload: {
          partnerLiquidAssetInfo: liquidAssetInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userLiquidAssetInfo: state.user.liquidAssetInfo,
    liquidAssetInfo: state.partner.liquidAssetInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquidAssetInfo);
