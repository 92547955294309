import React, { Component } from "react";
import Attorney from "./Attorney";
import AttorneysMakeDecision from "./AttorneysMakeDecision";
import CertificateProvider from "./CertificateProvider";
import Correspondent from "./Correspondent";
import Donor from "./Donor";
import HealthAndWelfareSteps from "./HealthAndWelfareSteps";
import * as HealthAndWelfareStepsEnum from "./HealthAndWelfareStepsEnum";
import LifeSustainingTreatment from "./LifeSustainingTreatment";
import PersonToNotify from "./PersonToNotify";
import PreferencesAndInstructions from "./PreferencesAndInstructions";
import Registration from "./Registration";
import RepeatApplication from "./RepeatApplication";
import ReplacementAttorney from "./ReplacementAttorney";
import ReplacementAttorneysMakeDecision from "./ReplacementAttorneysMakeDecision";
import SubmitHealthLPA from "./SubmitHealthLPA";

class HealthAndWelfareBody extends Component {
  state = {
    currentView: HealthAndWelfareStepsEnum.DONOR,
  };

  setCurrentView = (view) => {
    this.setState({ currentView: view });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.goToStep();
  }

  goToStep = () => {
    if (this.props.stepNo === 1) {
      this.setState({ currentView: HealthAndWelfareStepsEnum.DONOR });
    } else if (this.props.stepNo === 2) {
      this.setState({
        currentView: HealthAndWelfareStepsEnum.LIFE_SUSTAINING_TREATMENT,
      });
    } else if (this.props.stepNo === 3) {
      this.setState({ currentView: HealthAndWelfareStepsEnum.ATTORNEY });
    } else if (this.props.stepNo === 4) {
      this.setState({
        currentView: HealthAndWelfareStepsEnum.HOW_ATTORNEYS_MAKE_DECISION,
      });
    } else if (this.props.stepNo === 5) {
      this.setState({
        currentView: HealthAndWelfareStepsEnum.REPLACEMENT_ATTORNEY,
      });
    } else if (this.props.stepNo === 6) {
      this.setState({
        currentView:
          HealthAndWelfareStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION,
      });
    } else if (this.props.stepNo === 7) {
      this.setState({
        currentView: HealthAndWelfareStepsEnum.CERTIFICATE_PROVIDER,
      });
    } else if (this.props.stepNo === 8) {
      this.setState({
        currentView: HealthAndWelfareStepsEnum.PERSON_TO_NOTIFY,
      });
    } else if (this.props.stepNo === 9) {
      this.setState({
        currentView: HealthAndWelfareStepsEnum.PREFERENCES_N_INSTRUCTIONS,
      });
    } else if (this.props.stepNo === 10) {
      this.setState({ currentView: HealthAndWelfareStepsEnum.REGISTRATION });
    } else if (this.props.stepNo === 11) {
      this.setState({ currentView: HealthAndWelfareStepsEnum.CORRESPONDENT });
    } else if (this.props.stepNo === 12) {
      this.setState({ currentView: HealthAndWelfareStepsEnum.REPEAT });
    } else if (this.props.stepNo === 13) {
      this.setState({ currentView: HealthAndWelfareStepsEnum.SUBMIT });
    }
  };

  render() {
    let currentViewComponent;
    if (this.state.currentView === HealthAndWelfareStepsEnum.DONOR)
      currentViewComponent = (
        <Donor
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView ===
      HealthAndWelfareStepsEnum.LIFE_SUSTAINING_TREATMENT
    )
      currentViewComponent = (
        <LifeSustainingTreatment
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === HealthAndWelfareStepsEnum.ATTORNEY)
      currentViewComponent = (
        <Attorney
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView ===
      HealthAndWelfareStepsEnum.HOW_ATTORNEYS_MAKE_DECISION
    )
      currentViewComponent = (
        <AttorneysMakeDecision
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView === HealthAndWelfareStepsEnum.REPLACEMENT_ATTORNEY
    )
      currentViewComponent = (
        <ReplacementAttorney
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView ===
      HealthAndWelfareStepsEnum.HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION
    )
      currentViewComponent = (
        <ReplacementAttorneysMakeDecision
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView === HealthAndWelfareStepsEnum.CERTIFICATE_PROVIDER
    )
      currentViewComponent = (
        <CertificateProvider
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView === HealthAndWelfareStepsEnum.PERSON_TO_NOTIFY
    )
      currentViewComponent = (
        <PersonToNotify
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.currentView ===
      HealthAndWelfareStepsEnum.PREFERENCES_N_INSTRUCTIONS
    )
      currentViewComponent = (
        <PreferencesAndInstructions
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === HealthAndWelfareStepsEnum.REGISTRATION)
      currentViewComponent = (
        <Registration
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === HealthAndWelfareStepsEnum.CORRESPONDENT)
      currentViewComponent = (
        <Correspondent
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === HealthAndWelfareStepsEnum.CORRESPONDENT)
      currentViewComponent = (
        <Correspondent
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === HealthAndWelfareStepsEnum.REPEAT)
      currentViewComponent = (
        <RepeatApplication
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === HealthAndWelfareStepsEnum.SUBMIT)
      currentViewComponent = (
        <SubmitHealthLPA
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );

    return (
      <div className="body-container" style={{ marginTop: "1rem" }}>
        <div className="row justify-content-center row justify-content-between justify-content-sm-center">
          <div className="col-2 col-md-4 step-mini-container">
            <HealthAndWelfareSteps
              setCurrentView={this.setCurrentView}
              currentView={this.state.currentView}
              stepNo={this.props.stepNo}
            />
          </div>
          <div className="col-10  col-md-6">
            {currentViewComponent}

            {/* <SubmitHealthLPA /> */}
          </div>
        </div>
        {/* <ViewFinancialLPA /> */}
      </div>
    );
  }
}

export default HealthAndWelfareBody;
