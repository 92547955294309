import React, { Component } from "react";
import "../../css/aboutus.css";

class BasicInfo extends Component {
  render() {
    return (
      <div className="aboutus-section-container" id="basic-info">
        <h1 className="aboutus-section-heading">Who We Are</h1>
        <div className="alert-info" >
          <div className="aboutus-section-text ourServices-subheading">
            We are an innovative practically virtual Law Firm offering services online on this Website and we hope to Launch very soon (by February 2021) our own proprietary Mobile App called WILL SMART (™ Pending) to make Single Wills, Double Wills, and LPAS for both Health and Financial Affairs. We are creating further services in the areas of Probate, Estate and Funeral Planning, Estate and Trust administration in the very near future. We also offer these services if instructed over the telephone or in person.
          <br /><br />
          Your Will shall be always drafted by a solicitor once the instructions and payment are received via our Website or via the soon to be launched Mobile Application on Android and Apple mobile phones/services. If the lawyer feels he or she needs to contact you to further confirm or verify your instructions, this can be done via Zoom or another Video-conferencing application such as Goole Meet or Microsoft Teams. On the occasion, it cannot be avoided an appointment can be made to visit our offices.
          <br /><br />
            Use the <a href="/contactus">enquiry box</a>  to ask or make enquiries if you have one, and one of our case handlers will revert to you. You can also leave your mobile number or email and we shall endeavour to call or email you if you prefer.
          </div>


        </div>
      </div>
    );
  }
}

export default BasicInfo;
