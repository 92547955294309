import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";
import * as realAssetInfoStepEnum from "./RealAssetInfoEnum";

class RealAssetInfoCount extends Component {
  state = {
    primaryDataProvided: false,
    property_count_change: false,
    property_count: null,
    error_property_count: null,
    total_property_value: null,
    error_total_property_value: null,
    property_details: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.realAssetInfo !== null)
      this.setState({
        primaryDataProvided: false,
        property_count_change: false,
        property_count: this.props.realAssetInfo.property_count,
        total_property_value: this.props.realAssetInfo.total_property_value,
        property_details: this.props.realAssetInfo.property_details,
      });
  }

  onChangeHandler = (e) => {
    if (e.target.name === "property_count") {
      let targetName = [e.target.name];
      let key = e.target.name + "_change";
      if (
        this.props.realAssetInfo !== null &&
        parseInt(e.target.value) !== this.props.realAssetInfo[targetName]
      ) {
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (
        this.props.realAssetInfo !== null &&
        parseInt(e.target.value) === this.props.realAssetInfo[targetName]
      ) {
        this.setState({
          [key]: false,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (this.props.realAssetInfo === null) {
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      }
    } else this.setState({ [e.target.name]: parseFloat(e.target.value) });
  };

  isFormValid = () => {
    if (this.state.property_count < 0) {
      this.setState({ error_property_count: "invalid input" });
      return false;
    } else this.setState({ error_property_count: null });

    if (this.state.total_property_value < 0) {
      this.setState({ error_total_property_value: "invalid input" });
      return false;
    } else this.setState({ error_total_property_value: null });

    return true;
  };

  onSubmitHandleCountForm = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      window.scrollTo(0, 0);
      if (this.state.property_count === 0) {
        let db = firebase.firestore();
        let realAssetInfo = {
          total_property_value: this.state.total_property_value,
          property_count: 0,
          property_details: [],
          user_id: db.doc("users/" + this.props.currentUser.uid),
        };

        db.collection("realAssetInfo")
          .doc(this.props.currentUser.uid)
          .set(realAssetInfo)
          .then(() => {
            // console.log("realasset info in firestore");
          })
          .catch(() => {
            // console.log("realasset info in firestore failed");
          });

        this.props.setRealAssetInfo({
          ...this.props.realAssetInfo,
          total_property_value: this.state.total_property_value,
          property_count: 0,
          property_details: [],
        });
        this.props.setCurrentView("generalConcernInfo");
      } else if (this.props.realAssetInfo === null) {
        let property_details = [];
        for (let index = 0; index < this.state.property_count; index++) {
          property_details[index] = { location: "", type: "", value: null };
        }

        let tempState = { ...this.state, property_details: property_details };
        delete tempState.primaryDataProvided;
        delete tempState.error_property_count;
        delete tempState.error_total_property_value;
        delete tempState.property_count_change;
        this.props.setRealAssetInfo(tempState);
        this.setState({
          primaryDataProvided: true,
          property_count_change: false,
          property_details: property_details,
        });
        this.props.setCurrentView(
          realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_FORM
        );
      } else {
        let tempState = {
          ...this.state,
        };

        if (this.state.property_count_change) {
          let property_details = [];
          for (let index = 0; index < this.state.property_count; index++) {
            property_details[index] = { location: "", type: "", value: null };
          }
          tempState = { ...tempState, property_details: property_details };
          this.setState({ property_details: property_details });
        }
        this.setState({
          primaryDataProvided: true,
          property_count_change: false,
        });
        delete tempState.primaryDataProvided;
        delete tempState.error_property_count;
        delete tempState.error_total_property_value;
        delete tempState.property_count_change;
        this.props.setRealAssetInfo(tempState);
        this.props.setCurrentView(
          realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_FORM
        );
      }
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.onSubmitHandleCountForm}>
          <label
            htmlFor="property_count"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How many properties/reaal assets(Land, Apartment, House etc.) do you
            have?
          </label>
          <input
            type="number"
            className="form-control"
            id="property_count"
            placeholder="Number Of Properties"
            onChange={this.onChangeHandler}
            value={
              this.state.property_count === 0
                ? 0
                : this.state.property_count || ""
            }
            name="property_count"
            required
          />
          {this.state.error_property_count === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">
              {this.state.error_property_count}
            </div>
          )}

          <label
            htmlFor="total_property_value"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            What is the approximate value of your properties/real assets
            combined?
          </label>
          <input
            type="number"
            step="any"
            className="form-control"
            id="total_property_value"
            placeholder="Total Property Value £"
            onChange={this.onChangeHandler}
            value={
              this.state.total_property_value === 0
                ? 0
                : this.state.total_property_value || ""
            }
            name="total_property_value"
            required
          />
          {this.state.error_total_property_value === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">
              {this.state.error_total_property_value}
            </div>
          )}
          <button className=" will-next-button">Next</button>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setRealAssetInfo: (realAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REAL_ASSET_INFO,
        payload: {
          userRealAssetInfo: realAssetInfo,
        },
      }),
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    realAssetInfo: state.user.realAssetInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RealAssetInfoCount);
