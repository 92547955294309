import React from "react";
import Navbar from "./Navbar";

class Header extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: "7rem" }}>
        <Navbar />
        {/* <Linkbar /> */}
      </div>
    );
  }
}

export default Header;
