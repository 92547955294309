import React, { Component } from "react";
import Header from "../landingPage/header/Header";

import "../../css/contactus.css";
import Footer from "../footer/Footer";
import ContactUsBody from "./ContactUsBody";
import MapContainer from "./MapContainer";

class ContactUs extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <ContactUsBody />

        <div id="map">
          <MapContainer />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default ContactUs;
