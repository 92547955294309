import React, { Component } from "react";

import ChildrenInfo from "../PartnerComponent/ChildrenInfo/ChildrenInfo";
import ExecutorInfo from "../PartnerComponent/ExecutorInfo/ExecutorInfo";
import GeneralConcernInfo from "../PartnerComponent/GeneralConcernInfo";
import GiftInfo from "../PartnerComponent/GiftInfo/GiftInfo";
import GuardianInfo from "../PartnerComponent/GuardianInfo";
import LiquidAssetInfo from "../PartnerComponent/LiquidAssetInfo/LiquidAssetInfo";
import PartnerInfo from "../PartnerComponent/PartnerInfo";

import PersonalInfo from "../PartnerComponent/PersonalInfo";
import RealAssetInfo from "../PartnerComponent/RealAssetInfo/RealAssetInfo";
import RemainingEstateInfo from "../PartnerComponent/RemainingEstateInfo/RemainingEstateInfo";
import RequestInfo from "../PartnerComponent/RequestInfo";

import DoubleWillPartnerSteps from "./DoubleWillPartnerSteps";
import SubmitDoubleWill from "./SubmitDoubleWill";

class DoubleWillPersonalBody extends Component {
  state = {
    currentView: "childrenInfo",
  };

  setCurrentView = (view) => {
    this.setState({ currentView: view });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.goToStep();
  }

  goToStep = () => {
    if (this.props.stepNo === 0) {
      this.setState({ currentView: "personalInfo" });
    } else if (this.props.stepNo === 1) {
      this.setState({ currentView: "partnerInfo" });
    } else if (this.props.stepNo === 2) {
      this.setState({ currentView: "childrenInfo" });
    } else if (this.props.stepNo === 3) {
      this.setState({ currentView: "guardianInfo" });
    } else if (this.props.stepNo === 4) {
      this.setState({ currentView: "liquidAssetInfo" });
    } else if (this.props.stepNo === 5) {
      this.setState({ currentView: "realAssetInfo" });
    } else if (this.props.stepNo === 6) {
      this.setState({ currentView: "generalConcernInfo" });
    } else if (this.props.stepNo === 7) {
      this.setState({ currentView: "giftInfo" });
    } else if (this.props.stepNo === 8) {
      this.setState({ currentView: "remainingEstateInfo" });
    } else if (this.props.stepNo === 9) {
      this.setState({ currentView: "executorInfo" });
    } else if (this.props.stepNo === 10) {
      this.setState({ currentView: "requestInfo" });
    } else if (this.props.stepNo === 11) {
      this.setState({ currentView: "submit" });
    }
  };

  render() {
    let currentViewComponent;
    if (this.state.currentView === "personalInfo")
      currentViewComponent = (
        <PersonalInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "partnerInfo")
      currentViewComponent = (
        <PartnerInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "childrenInfo")
      currentViewComponent = (
        <ChildrenInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "guardianInfo")
      currentViewComponent = (
        <GuardianInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "liquidAssetInfo")
      currentViewComponent = (
        <LiquidAssetInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "realAssetInfo")
      currentViewComponent = (
        <RealAssetInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "generalConcernInfo")
      currentViewComponent = (
        <GeneralConcernInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "giftInfo")
      currentViewComponent = (
        <GiftInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "remainingEstateInfo")
      currentViewComponent = (
        <RemainingEstateInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "executorInfo")
      currentViewComponent = (
        <ExecutorInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "requestInfo")
      currentViewComponent = (
        <RequestInfo
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.currentView === "submit")
      currentViewComponent = (
        <SubmitDoubleWill
          setCurrentView={this.setCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    return (
      <div className="body-container" style={{ marginTop: "1rem" }}>
        <div className="row justify-content-between justify-content-sm-center">
          <div className="col-2 col-md-4 step-mini-container">
            <DoubleWillPartnerSteps
              setCurrentView={this.setCurrentView}
              currentView={this.state.currentView}
              stepNo={this.props.stepNo}
            />
          </div>
          <div className="col-10  col-md-6">{currentViewComponent}</div>
        </div>
      </div>
    );
  }
}

export default DoubleWillPersonalBody;
