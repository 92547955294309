import React, {Component} from "react";
import {Divider} from "semantic-ui-react";
import {Link} from "react-router-dom";

export class ComplaintsBody extends Component {


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="body-container">
                <div
                    className="aboutus-section-heading"
                    style={{marginBottom: "5rem", wordSpacing: "1.1rem"}}
                >
                    Complaints Procedure
                </div>
                <div className="tnc-list">
                    <span className="tnc-list-description">
If you have any complaints, you should first raise the complaint informally with the caseworker you are dealing with. If this fails to resolve the issue to your satisfaction, you can formally make a complaint in writing. We will respond in writing to you within two weeks.
  <p/>
                        If you are not satisfied with our response or matter has not been resolved satisfactorily, then you can bring a complaint to the <a
                        href="https://www.legalombudsman.org.uk/">  Legal Ombudsman</a> in the following timeline:  <ul>
                        <li>Within 6 months of our final letter trying to resolve your complaint.</li>
                        <li>No more than six years from the date of act/omission OR No more than three years from when you should reasonably have known there was cause for complaint.
</li>

                    </ul>

                        Contact information of the Legal Ombudsman is available <a href="https://www.legalombudsman.org.uk/contact-us/"> here</a>.
                        <p/>
                        The Solicitors Regulation Authority can help if you are concerned about our behaviour. This could be for things like dishonesty, taking or losing your money or treating you unfairly because of your age, a disability or other characteristic.

<a
    href="https://www.sra.org.uk/"> Visit their website </a> to see how you can raise your concerns with the Solicitors Regulation Authority.
                          <p/>
                        For further information on complaints, please refer to our <Link to="/tnc">Terms & Conditions</Link>.
                    </span>
                </div>
            </div>
        );
    }
}

export default ComplaintsBody;
