import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";

import logo from "../../../../images/logo.png";
import firebase from "../../../../config/firebase";

class SelectExecutor extends Component {
  state = {
    executor_type: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.executorInfo.executor_type !== null)
      this.setState({
        executor_type: this.props.executorInfo.executor_type,
      });
  }

  onClick = (e) => {
    this.setState({ executor_type: e.target.name });
    if (e.target.name === "neolexical") {
      this.props.setExecutorInfo({ ...this.props.executorInfo, executors: [] });
      let db = firebase.firestore();
      db.collection("executorInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.executorInfo,
          user_id: db.doc("users/" + this.props.currentUser.uid),
          executors: [],
          executor_type: e.target.name,
        })
        .then(() => {
          // console.log("executor info in firestore");
        })
        .catch(() => {
          // console.log("executor info in firestore failed");
        });
    } else {
      let db = firebase.firestore();
      db.collection("executorInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.executorInfo,
          user_id: db.doc("users/" + this.props.currentUser.uid),
          executor_type: e.target.name,
        })
        .then(() => {
          // console.log("executor info in firestore");
        })
        .catch(() => {
          // console.log("executor info in firestore failed");
        });
    }

    this.props.setExecutorInfo({
      ...this.props.executorInfo,
      executor_type: e.target.name,
    });
    if (e.target.name === "both" || e.target.name === "family") {
      this.props.setCurrentView("add");
    } else {
      this.props.setCurrentView("next");
    }
  };

  render() {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginTop: "2.5rem",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              display: "block",
              marginBottom: "4rem",
            }}
          >
            Select Your Executor
          </span>

          <div>
            <button
              className={
                this.state.executor_type === "neolexical"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="neolexical"
            >
              <img src={logo} alt="logo" style={{ height: "1.5rem" }}></img>{" "}
              Neolexical LTD.
            </button>
          </div>

          <div>
            <button
              className={
                this.state.executor_type === "family"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="family"
            >
              Family
            </button>
          </div>

          <div>
            <button
              className={
                this.state.executor_type === "both"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="both"
            >
              Both
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setExecutorInfo: (executorInfo) =>
      dispatch({
        type: actionTypes.SET_USER_EXECUTOR_INFO,
        payload: {
          userExecutorInfo: executorInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    executorInfo: state.user.executorInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectExecutor);
