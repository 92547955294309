import React, { Component } from "react";
import { Step } from "semantic-ui-react";
import * as liquidAssetInfoStepEnum from "./LiquidAssetInfoEnums";

export class LiquidAssetInfoSteps extends Component {
  disableLiquidAssetInfo = true;

  setDisable = (stepName) => {
    this[stepName] = true;
  };
  componentDidMount() {}

  render() {
    if (
      this.props.currentView ===
      liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_COUNT
    ) {
      this.disableLiquidAssetInfo = true;
    } else if (
      this.props.currentView ===
      liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_FORM
    )
      this.disableLiquidAssetInfo = false;

    return (
      <Step.Group widths={3} size="small">
        <Step
          active={
            this.props.currentView ===
            liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_COUNT
              ? true
              : false
          }
          link
          onClick={() =>
            this.props.setCurrentView(
              liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_COUNT
            )
          }
        >
          <Step.Content>
            <Step.Title>Liquid Asset Count</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableLiquidAssetInfo}
          active={
            this.props.currentView ===
            liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_FORM
              ? true
              : false
          }
          onClick={() =>
            this.props.setCurrentView(
              liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_FORM
            )
          }
        >
          <Step.Content>
            <Step.Title>Liquid Asset Info</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

export default LiquidAssetInfoSteps;
