import React, { Component } from "react";
import AddExecutor from "./AddExecutor";
import ExecutorInfoSteps from "./ExecutorInfoSteps";
import SelectExecutor from "./SelectExecutor";
import firebase from "../../../../config/firebase";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

class ExecutorInfo extends Component {
  state = { executorInfoCurrentView: "select" };
  setExecutorInfoView = (view) => {
    this.setState({ executorInfoCurrentView: view });
  };
  componentDidMount() {
    if (this.props.partnerStepNo < 9) this.props.setPartnerStepNo(9);
  }

  onClickSame = () => {
    if (this.props.userExecutorInfo !== null) {
      let db = firebase.firestore();
      db.collection("partnerExecutorInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.userExecutorInfo,
        })
        .then(() => {
          // console.log("executor info in firestore");
        })
        .catch(() => {
          // console.log("executor info in firestore failed");
        });

      this.props.setPartnerExecutorInfo({
        ...this.props.userExecutorInfo,
      });
      this.props.setCurrentView("requestInfo");
    }
  };

  render() {
    let currentViewComponent;
    if (this.state.executorInfoCurrentView === "select")
      currentViewComponent = (
        <SelectExecutor
          setCurrentView={this.setExecutorInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.executorInfoCurrentView === "add")
      currentViewComponent = (
        <AddExecutor
          setCurrentView={this.setExecutorInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.executorInfoCurrentView === "next") {
      this.props.setCurrentView("requestInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Partner's Executors</h1>

        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            className="will-button blue-will-button"
            style={{
              width: "70%",
              letterSpacing: "0.1rem",
              wordSpacing: ".2rem",
              fontSize: "1.2rem",
            }}
            onClick={this.onClickSame}
          >
            Same as Partner
          </button>
        </div>
        <Divider horizontal>Or</Divider>

        <ExecutorInfoSteps
          setCurrentView={this.setExecutorInfoView}
          currentView={this.state.executorInfoCurrentView}
        />
        {currentViewComponent}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerExecutorInfo: (executorInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_EXECUTOR_INFO,
        payload: {
          partnerExecutorInfo: executorInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userExecutorInfo: state.user.executorInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutorInfo);
