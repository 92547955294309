import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import "../../../../css/will.css";
import Modal from "../Modal";

import { connect } from "react-redux";
import firebase from "../../../../config/firebase";

import * as PartnerHelpTextList from "../../PartnerHelpTextList";

class ChildrenInfoForm extends Component {
  state = {
    children: null,
    childrenNumberProvided: false,

    error: null,
    childrenInfo: [],
  };

  genderList = ["Male", "Female", "Non-binary"];
  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.childrenInfo != null) {
      this.setState({
        children: this.props.childrenInfo.number,
        childrenInfo: [...this.props.childrenInfo.info],
      });
    }
  }

  onClickSkip = () => {
    this.props.setCurrentView("liquidAssetInfo");
    this.props.setPartnerChildrenInfo({
      number: this.state.children,
      info: this.state.childrenInfo,
    });

    let db = firebase.firestore();

    let childrenInfo = {
      children_count: parseInt(this.state.children),
      children_details: this.state.childrenInfo,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("partnerChildrenInfo")
      .doc(this.props.currentUser.uid)
      .set(childrenInfo)
      .then(() => {
        // console.log("children info in firestore");
      })
      .catch(() => {
        // console.log("children info in firestore failed");
      });

    this.props.setPartnerGuardianInfo({ guardianInfo: null }); // clearing prev guardian info

    db.collection("partnerGuardianInfo")
      .doc(this.props.currentUser.uid)
      .delete()
      .then(() => {
        // console.log("guardian info cleared");
      })
      .catch(() => {
        // console.log("no guardian info to delete");
      });
  };

  clearGueardianInfo = async () => {
    let db = firebase.firestore();
    this.props.setPartnerGuardianInfo(null); // clearing prev guardian info

    db.collection("partnerGuardianInfo")
      .doc(this.props.currentUser.uid)
      .delete()
      .then(() => {
        // console.log("guardian info cleared");
      })
      .catch(() => {
        // console.log("no guardian info to delete");
      });
  };

  onSubmitHandleChildrenInfoForm = (e) => {
    if (e !== undefined) e.preventDefault();
    // // console.log(this.state.childrenInfo);
    let isMinor = false;
    let db = firebase.firestore();

    let childrenInfo = {
      children_count: parseInt(this.state.children),
      children_details: this.state.childrenInfo,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("partnerChildrenInfo")
      .doc(this.props.currentUser.uid)
      .set(childrenInfo)
      .then(() => {
        // console.log("children info in firestore");
      })
      .catch(() => {
        // console.log("children info in firestore failed");
      });

    this.props.setPartnerChildrenInfo({
      number: this.state.children,
      info: this.state.childrenInfo,
    });
    for (let index = 0; index < this.state.children; index++) {
      if (this.state.childrenInfo[index].age < 18) {
        isMinor = true;
        break;
      }
    }
    if (isMinor) {
      this.props.setCurrentView("guardianInfo");
    } else {
      this.clearGueardianInfo();
      this.props.setCurrentView("liquidAssetInfo");
    }
  };

  render() {
    let rows = [];
    for (let index = 0; index < this.state.children; index++) {
      rows.push(
        <div key={index} style={{ marginBottom: "3.5rem" }}>
          <h4 style={{ marginBottom: "0rem" }}>
            {" "}
            Information of Child no.{index + 1}
          </h4>
          <label
            htmlFor="name"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Name
          </label>

          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Name"
            onChange={(e) => {
              let childrenInfo = [...this.state.childrenInfo];
              childrenInfo[index].name = e.target.value;
              this.setState({ childrenInfo: childrenInfo });
            }}
            value={this.state.childrenInfo[index].name || ""}
            name="name"
            required
          />
          <label
            htmlFor="age"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Age
          </label>

          <input
            type="number"
            className="form-control"
            id="age"
            placeholder="Age"
            onChange={(e) => {
              let childrenInfo = [...this.state.childrenInfo];
              childrenInfo[index].age = e.target.value;
              this.setState({ childrenInfo: childrenInfo });
            }}
            value={this.state.childrenInfo[index].age || ""}
            name="age"
            required
          />
          <label
            htmlFor="gender"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Gender
          </label>

          <select
            id="gender"
            className="form-control"
            value={this.state.childrenInfo[index].gender}
            onChange={(e) => {
              let childrenInfo = [...this.state.childrenInfo];
              childrenInfo[index].gender = e.target.value;
              this.setState({ childrenInfo: childrenInfo });
            }}
            name="gender"
            required
          >
            <option value="" disabled hidden selected>
              Gender
            </option>
            {this.genderList.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      );
    }
    return (
      <div>
        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div>
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              You can skip this section!
            </span>
            <button
              className="will-button blue-will-button"
              style={{ marginLeft: "1rem" }}
              onClick={this.onClickSkip}
            >
              skip
            </button>
          </div>
        </div>

        <form onSubmit={this.onSubmitHandleChildrenInfoForm}>
          {rows}
          <button className=" will-next-button" style={{ marginTop: "1rem" }}>
            Next
          </button>
        </form>
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="Children Info"
          body={PartnerHelpTextList.childrenInfoText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_CHILDREN_INFO,
        payload: {
          partnerChildrenInfo: childrenInfo,
        },
      }),
    setPartnerGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GUARDIAN_INFO,
        payload: {
          partnerGuardianInfo: guardianInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userChildrenInfo: state.user.childrenInfo,
    childrenInfo: state.partner.childrenInfo,
    currentUser: state.user.currentUser,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChildrenInfoForm);
