import React from "react";

function TestimonialCard({text, name}) {
  return (
    <>
      <div className="testimonial-card">
        <div className="testimonial-card-primary">
          "{text}"
        </div>
        <div className="testimonial-card-writer">{name}</div>
      </div>
    </>
  );
}

export default TestimonialCard;
