import React, { Component } from "react";
import VizSensor from "react-visibility-sensor";

import AboutUsBody from "./AboutUsBody";
import Header from "../landingPage/header/Header";
import Footer from "../footer/Footer";

class AboutUs extends Component {
  state = {
    isVisible: "",
  };
  render() {
    return (
      <React.Fragment>
        <Header />
        <AboutUsBody navDisplay={this.state.isVisible} />
        <VizSensor
          onChange={(isVisible) => {
            if (isVisible) this.setState({ isVisible: "doNotDisplay" });
            else this.setState({ isVisible: "" });
          }}
        >
          <div style={{ height: "1rem" }}></div>
        </VizSensor>
        <Footer />
      </React.Fragment>
    );
  }
}

export default AboutUs;
