import React, { Component } from "react";

import Modal from "../Modal";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";
import * as PartnerHelpTextList from "../../PartnerHelpTextList";

class RealAssetInfoForm extends Component {
  state = {
    primaryDataProvided: false,
    property_count_change: false,
    property_count: null,
    error_property_count: null,
    total_property_value: null,
    error_total_property_value: null,
    property_details: [],
  };
  realAssetTypeList = ["Land", "House", "Apartment", "Other"];

  onChangeHandler = (e) => {
    if (e.target.name === "property_count") {
      let targetName = [e.target.name];
      let key = e.target.name + "_change";
      if (
        this.props.realAssetInfo !== null &&
        parseInt(e.target.value) !== this.props.realAssetInfo[targetName]
      ) {
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (
        this.props.realAssetInfo !== null &&
        parseInt(e.target.value) === this.props.realAssetInfo[targetName]
      ) {
        this.setState({
          [key]: false,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (this.props.realAssetInfo === null) {
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      }
    } else this.setState({ [e.target.name]: parseFloat(e.target.value) });
  };

  onSubmitHandleInfoForm = (e) => {
    e.preventDefault();
    let tempState = { ...this.state };
    delete tempState.primaryDataProvided;
    delete tempState.error_property_count;
    delete tempState.error_total_property_value;
    delete tempState.property_count_change;

    let db = firebase.firestore();
    let realAssetInfo = {
      ...tempState,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("partnerRealAssetInfo")
      .doc(this.props.currentUser.uid)
      .set(realAssetInfo)
      .then(() => {
        // console.log("realasset info in firestore");
      })
      .catch(() => {
        // console.log("realasset info in firestore failed");
      });

    this.props.setPartnerRealAssetInfo(tempState);
    this.props.setCurrentView("generalConcernInfo");
  };
  onClickSkip = () => {
    this.props.setCurrentView("generalConcernInfo");
  };
  onClickSame = () => {
    let db = firebase.firestore();
    let realAssetInfo = {
      ...this.props.userRealAssetInfo,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("partnerRealAssetInfo")
      .doc(this.props.currentUser.uid)
      .set(realAssetInfo)
      .then(() => {
        // console.log("realasset info in firestore");
      })
      .catch(() => {
        // console.log("realasset info in firestore failed");
      });

    this.props.setPartnerRealAssetInfo({ ...this.props.userRealAssetInfo });
    this.props.setCurrentView("generalConcernInfo");
  };

  onClickSkip = () => {
    this.props.setCurrentView("generalConcernInfo");
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.realAssetInfo !== null)
      this.setState({
        primaryDataProvided: false,
        property_count_change: false,
        property_count: this.props.realAssetInfo.property_count,
        total_property_value: this.props.realAssetInfo.total_property_value,
        property_details: this.props.realAssetInfo.property_details,
      });
  }

  createRealAssetInfoForm = () => {
    let realAsset = [];
    for (let index = 0; index < this.state.property_count; index++) {
      realAsset.push(
        <div
          key={index}
          style={{
            marginBottom: "3.5rem",
          }}
        >
          <h4 style={{ marginBottom: "0rem" }}>
            Information of Real Asset no.{index + 1}
          </h4>
          <label
            htmlFor={"type" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Property Type
          </label>

          <select
            id={"type" + index}
            className="form-control"
            value={this.state.property_details[index].type}
            onChange={(e) => {
              let property_details = [...this.state.property_details];
              property_details[index].type = e.target.value;
              this.setState({ property_details: property_details });
            }}
            required
          >
            <option value="" disabled hidden selected>
              Type
            </option>
            {this.realAssetTypeList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
          <label
            htmlFor={"location" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Property Location
          </label>

          <input
            type="text"
            className="form-control"
            id={"location" + index}
            placeholder="Location"
            onChange={(e) => {
              let property_details = [...this.state.property_details];
              property_details[index].location = e.target.value;
              this.setState({
                property_details: property_details,
              });
            }}
            value={this.state.property_details[index].location}
            required
          />

          <label
            htmlFor={"value" + index}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Investment Value
          </label>

          <input
            type="number"
            step="any"
            className="form-control"
            id={"value" + index}
            placeholder="£"
            onChange={(e) => {
              let property_details = [...this.state.property_details];
              property_details[index].value = parseFloat(e.target.value);
              this.setState({
                property_details: property_details,
              });
            }}
            value={this.state.property_details[index].value}
            required
          />
        </div>
      );
    }
    return realAsset;
  };
  render() {
    let realAsset = this.createRealAssetInfoForm();
    return (
      <div>
        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div>
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              You can skip this section!
            </span>
            <button
              className="will-button blue-will-button"
              style={{ marginLeft: "1rem" }}
              onClick={this.onClickSkip}
            >
              skip
            </button>
          </div>
        </div>

        <form onSubmit={this.onSubmitHandleInfoForm}>
          {this.state.property_count === 0 ? (
            <span></span>
          ) : (
            <div>{realAsset}</div>
          )}

          <button className=" will-next-button" style={{ marginTop: "1rem" }}>
            Next
          </button>
        </form>
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="Real Asset Info"
          body={PartnerHelpTextList.realAssetText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerRealAssetInfo: (realAssetInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REAL_ASSET_INFO,
        payload: {
          partnerRealAssetInfo: realAssetInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userRealAssetInfo: state.user.realAssetInfo,
    realAssetInfo: state.partner.realAssetInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RealAssetInfoForm);
