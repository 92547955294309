import React, { Component } from "react";

import RealAssetInfoCount from "./RealAssetInfoCount";
import * as realAssetInfoStepEnum from "./RealAssetInfoEnum";
import RealAssetInfoForm from "./RealAssetInfoForm";
import RealAssetInfoSteps from "./RealAssetInfoSteps";

class RealAssetInfo extends Component {
  state = {
    realAssetInfoCurrentView: realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_COUNT,
  };
  setRealAsssetInfoCurrentView = (view) => {
    this.setState({ realAssetInfoCurrentView: view });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.stepNo < 5) this.props.setStepNo(5);
    // if (this.props.realAssetInfo !== null)
    //   this.setState({
    //     primaryDataProvided: false,
    //     property_count_change: false,
    //     property_count: this.props.realAssetInfo.property_count,
    //     total_property_value: this.props.realAssetInfo.total_property_value,
    //     property_details: this.props.realAssetInfo.property_details,
    //   });
  }
  render() {
    let currentViewComponent;
    if (
      this.state.realAssetInfoCurrentView ===
      realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_COUNT
    )
      currentViewComponent = (
        <RealAssetInfoCount
          setCurrentView={this.setRealAsssetInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (
      this.state.realAssetInfoCurrentView ===
      realAssetInfoStepEnum.REAL_ASSET_INFO_TYPE_FORM
    )
      currentViewComponent = (
        <RealAssetInfoForm
          setCurrentView={this.setRealAsssetInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.realAssetInfoCurrentView === "generalConcernInfo") {
      this.props.setCurrentView("generalConcernInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Partener's Real Asset</h1>
        <RealAssetInfoSteps
          setCurrentView={this.setRealAsssetInfoCurrentView}
          currentView={this.state.realAssetInfoCurrentView}
        />
        {currentViewComponent}
      </div>
    );
  }
}

export default RealAssetInfo;
