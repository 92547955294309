import React, { Component } from "react";
import Header from "../landingPage/header/Header";
import Footer from "../footer/Footer";
import TNCBody from "./TNCBody";
import "../../css/tnc.css";
export class TermsNConditions extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <TNCBody />
        <Footer />
      </React.Fragment>
    );
  }
}

export default TermsNConditions;
