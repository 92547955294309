import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "../../../css/testimonial.css";

import TestimonialCard from "./TestimonialCard";

const responsive_map = {
  0: {
    items: 1,
  },
  400: {
    items: 1,
  },
  600: {
    items: 2,
  },
  700: {
    items: 3,
  },
  1000: {
    items: 5,
  },
};

const testimonials = [
  {
    text: `I wish to convey my sincere, heartfelt gratitude and appreciation to MR AHMED ALEF .
    My case was very complicated and difficult but you supported me all the way through to the end.You were available each time I needed to discuss any issues , you gave clear and concise advice .
    Once again I would like to thank you for your excellent work," I had the best Solicitor ", to fight my case , because of your hard work and dedication I had a satisfactory outcome .
    I would be only too happy to recommend your firm to all my friends, family and colleagues.`,
    name: "Kauser",
  },
  {
    text: "I would like to thank Mr Ahmed Alef for his professionalism and hardwork which ensured a highly sucessful outcome to my case . I have NO reservations in recommending his firm Neolexical Ltd to my frends and family .",
    name: "Feltham",
  },
];

class Consulting extends React.Component {
  render() {
    return (
      <div>
        <div
          className="section-container "
          style={{
            borderBottom: "0.14rem dotted rgba(0, 0, 0, 0.561)",
          }}
        >
          <div className="ourServices-heading ">Our Happy Customers</div>
          <OwlCarousel
            className="owl-theme"
            // nav
            loop
            margin={10}
            items={1}
            autoplay
            autoplayTimeout={6000}
            autoplaySpeed={1000}
            autoplayHoverPause={true}
          
            // responsive={{ responsive_map }}
          >
            {testimonials.map((testimonial, index) => (
              <div class="item" key={index}>
                <TestimonialCard {...testimonial} />
              </div>
            ))}
            {/* <div class="item">
              <TestimonialCard {...testimonials[0]} />
            </div>
            <div class="item">
              <TestimonialCard {...testimonials[1]} />
            </div> */}
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default Consulting;
