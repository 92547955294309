import React, { Component } from "react";
import Header from "../landingPage/header/Header";
import Footer from "../footer/Footer";
import PricingBody from "./PricingBody";
import "../../css/tnc.css";

export class Pricing extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <PricingBody />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Pricing;
