export const DONOR = "DONOR";
export const LIFE_SUSTAINING_TREATMENT = "LIFE_SUSTAINING_TREATMENT";
export const ATTORNEY = "ATTORNEY";
export const HOW_ATTORNEYS_MAKE_DECISION = "HOW_ATTORNEYS_MAKE_DECISION";
export const REPLACEMENT_ATTORNEY = "REPLACEMENT_ATTORNEY";
export const HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION =
  "HOW_REPLACEMENT_ATTORNEYS_MAKE_DECISION";
export const CERTIFICATE_PROVIDER = "CERTIFICATE_PROVIDER";
export const PERSON_TO_NOTIFY = "PERSON_TO_NOTIFY";
export const PREFERENCES_N_INSTRUCTIONS = "PREFERENCES_N_INSTRUCTIONS";
export const REGISTRATION = "REGISTRATION";
export const CORRESPONDENT = "CORRESPONDENT";
export const REPEAT = "REPEAT";
export const SUBMIT = "SUBMIT";
