import React, { Component } from "react";

import Header from "../landingPage/header/Header";
import Footer from "../footer/Footer";
import firebase from "../../config/firebase";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import image from "../../images/Neo-Logo.png";
import googleSignInImage1x from "../../images/btn_google_signin_dark_normal_web.png";
import "../../css/auth.css";
import VerifyEmail from "./VerifyEmail";
import { ResetPasswordModal } from "./ResetPasswordModal";

class Login extends Component {
  state = {
    email: "",
    password: "",
    addClass: "",
    errors: "",
    loading: false,
    user: null,
    emailVerified: true,
  };
  unsubscribe;
  componentDidMount() {
    window.scrollTo(0, 0);
    var firebaseUser = firebase.auth().currentUser;
    var tempUser = { ...firebase.auth().currentUser };
    // // console.log(user);
    if (firebaseUser !== null)
      tempUser.providerData.forEach((item) => {
        if (item.providerId === "facebook.com") tempUser.emailVerified = true;
      });
    if (firebaseUser !== null && tempUser.emailVerified) {
      this.props.history.push("/user");
    } else if (firebaseUser !== null && !tempUser.emailVerified) {
      this.setState({ emailVerified: false });
    }
  }

  signInWithGoogle = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(async (result) => {
        var user = result.user;
        // console.log(user.uid);

        this.saveUser(user)
          .then(() => {
            // console.log("google user saved");
          })
          .catch(() => {
            // console.log("google user couldnt saved");
          });
        this.props.setUser({ ...user });

        this.props.history.push("/user");
        // ...
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  onSubmitHandle = (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((signedInUser) => {
        this.setState({ loading: false, errors: "" });

        if (!signedInUser.user.emailVerified) {
          // console.log(signedInUser.user.emailVerified);
          this.setState({
            emailVerified: signedInUser.user.emailVerified,
            user: signedInUser,
          });
        } else {
          // console.log(signedInUser.user);
          this.props.setUser({ ...signedInUser.user });

          this.props.history.push("/user");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          errors: "Wrong Credentials",
        });

        this.setState({ loading: false, password: "" });
      });
  };

  saveUser = async (user) => {
    // console.log("inside save");
    const db = firebase.firestore();
    const data = { name: "sun" };

    // Add a new document in collection "cities" with ID 'LA'
    const res = await db
      .collection("users")
      .doc(user.uid)
      .set(data, { merge: true });
    return res;
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    if (this.state.emailVerified !== true)
      return <VerifyEmail user={this.state.user} />;
    else
      return (
        <React.Fragment>
          <Header />
          <div className="body-container" style={{ height: "50rem" }}>
            <div className="auth-card " style={{}}>
              <div className="auth-card-avatar">
                <img src={image} alt="profile"></img>
              </div>

              <span className="auth-text-heading">Log in</span>
              <div className="auth-form ">
                <form onSubmit={this.onSubmitHandle}>
                  <div className="auth-input-container mb-4">
                    <span className="input-text">Email</span>
                    <input
                      type="email"
                      value={this.state.email}
                      name="email"
                      onChange={this.onChangeHandler}
                      required
                    ></input>
                  </div>
                  <div
                    className="auth-input-container"
                    style={{ marginBottom: "1rem" }}
                  >
                    <span className="input-text">Password</span>
                    <input
                      type="password"
                      value={this.state.password}
                      name="password"
                      onChange={this.onChangeHandler}
                      required
                    ></input>
                  </div>
                  <div style={{ marginBottom: "0rem" }}>
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#resetPasswordModal"
                      style={{
                        backgroundColor: "Transparent",
                        backgroundRepeat: "no-repeat",
                        border: "none",
                        boxShadow: "none",
                        cursor: "pointer",
                        overflow: "hidden",
                        outline: "none",
                        color: "black",
                        fontSize: "1.05rem",
                        textDecoration: "underline",
                      }}
                    >
                      Forgot your Password?
                    </button>
                  </div>

                  <button>Login</button>
                </form>
                {this.state.errors === "" ? (
                  <div style={{ height: "25px" }}></div>
                ) : (
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.errors}
                  </div>
                )}
                Don't have an account?<Link to="/register"> Register here</Link>
                <div
                  className="ui horizontal divider "
                  style={{ background: "none" }}
                >
                  Or
                </div>
                <div className="socialmedia-icon">
                  <Link
                    to="#"
                    onClick={this.signInWithGoogle}
                    className="socialmedia-icon-google"
                  >
                    <img src={googleSignInImage1x} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ResetPasswordModal />
          <Footer />
        </React.Fragment>
      );
  }
}
const mapStateToDispatch = (dispatch) => {
  return {
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
  };
};

export default connect(null, mapStateToDispatch)(Login);
