import React, { Component } from "react";
import Header from "../../landingPage/header/Header";
import VizSensor from "react-visibility-sensor";

import Footer from "../../footer/Footer";

import { Dimmer, Loader } from "semantic-ui-react";

import SingleWillBody from "./SingleWillBody";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";

import * as actionTypes from "../../../actions/types";

class SingleWill extends Component {
  state = {
    visibleFooter: "float-button",
    currentView: "info",
    stepNo: null,
    isLogged: false,
    loadingUser: true,
    dataLoading: true,
  };

  readData = async (uid) => {
    let db = firebase.firestore();
    let stepNo = 1;
    //reading personal info..........
    let personalInfoRef = db.collection("personalInfo").doc(uid);
    let personalDoc = await personalInfoRef.get();
    if (!personalDoc.exists) {
      // this.props.history.push("/single-will-info");
      // console.log("No such personalDocument!");
      this.setState({ dataLoading: false });
      return;
    } else {
      stepNo = 1;
      this.props.setPersonalInfo({
        ...personalDoc.data(),
        date_of_birth: personalDoc
          .data()
          .date_of_birth.toDate()
          .toLocaleDateString("fr-CA"),
      });
    }
    //end reading personal info..........

    //reading partner info.......
    let partnerInfoRef = db.collection("partnerInfo").doc(uid);
    let partnerDoc = await partnerInfoRef.get();
    if (!partnerDoc.exists) {
      // console.log("No such partnerDocument!");
    } else {
      stepNo = 2;
      this.props.setPartnerInfo({
        ...partnerDoc.data(),
        date_of_birth: partnerDoc
          .data()
          .date_of_birth.toDate()
          .toLocaleDateString("fr-CA"),
      });
    }
    //end reading partner info.......

    //reading children info.......
    let childrenInfoRef = db.collection("childrenInfo").doc(uid);
    let childrenDoc = await childrenInfoRef.get();
    if (!childrenDoc.exists) {
      // console.log("No such childrenDocument!");
    } else {
      stepNo = 3;
      this.props.setChildrenInfo({
        number: childrenDoc.data().children_count,
        info: childrenDoc.data().children_details,
      });
    }
    //end reading children info.......

    //reading guardian info.......
    let guardianInfoRef = db.collection("guardianInfo").doc(uid);
    let guardianDoc = await guardianInfoRef.get();
    if (!guardianDoc.exists) {
      // console.log("No such guardianDocument!");
    } else {
      stepNo = 4;
      // console.log("found guardian doc");
      this.props.setGuardianInfo({
        ...guardianDoc.data(),
      });
    }
    //end reading guardian info.......

    //reading liquidAsset info.......
    let liquidAssetInfoRef = db.collection("liquidAssetInfo").doc(uid);
    let liquidAssetDoc = await liquidAssetInfoRef.get();
    if (!liquidAssetDoc.exists) {
      // console.log("No such liquidAssetDocument!");
    } else {
      stepNo = 5;
      // console.log("found liquidAsset doc");
      this.props.setLiquidAssetInfo({
        ...liquidAssetDoc.data(),
      });
    }
    //end reading liquidAsset info.......

    //reading realAsset info.......
    let realAssetInfoRef = db.collection("realAssetInfo").doc(uid);
    let realAssetDoc = await realAssetInfoRef.get();
    if (!realAssetDoc.exists) {
      // console.log("No such realAssetDocument!");
    } else {
      stepNo = 6;
      // console.log("found realAsset doc");
      this.props.setRealAssetInfo({
        ...realAssetDoc.data(),
      });
    }
    //end reading realAsset info.......

    //reading generalConcern info.......
    let generalConcernInfoRef = db.collection("generalConcernInfo").doc(uid);
    let generalConcernDoc = await generalConcernInfoRef.get();
    if (!generalConcernDoc.exists) {
      // console.log("No such generalConcernDocument!");
    } else {
      stepNo = 7;
      // console.log("found generalConcern doc");
      this.props.setGeneralConcernInfo({
        ...generalConcernDoc.data(),
      });
    }
    //end reading generalConcern info.......

    //reading gift info.......
    let giftInfoRef = db.collection("giftInfo").doc(uid);
    let giftDoc = await giftInfoRef.get();
    if (!giftDoc.exists) {
      // console.log("No such giftDocument!");
    } else {
      stepNo = 8;
      // console.log("found gift doc");
      this.props.setGiftInfo({
        ...giftDoc.data(),
      });
    }
    //end reading gift info.......

    //reading remainingEstate info.......
    let remainingEstateInfoRef = db.collection("remainingEstateInfo").doc(uid);
    let remainingEstateDoc = await remainingEstateInfoRef.get();
    if (!remainingEstateDoc.exists) {
      // console.log("No such remainingEstateDocument!");
    } else {
      stepNo = 9;
      // console.log("found remainingEstate doc");
      this.props.setRemainingEstateInfo({
        ...remainingEstateDoc.data(),
      });
    }
    //end reading remainingEstate info.......

    //reading executor info.......
    let executorInfoRef = db.collection("executorInfo").doc(uid);
    let executorDoc = await executorInfoRef.get();
    if (!executorDoc.exists) {
      // console.log("No such executorDocument!");
    } else {
      stepNo = 10;
      // console.log("found executor doc");
      this.props.setExecutorInfo({
        ...executorDoc.data(),
      });
    }
    //end reading executor info.......

    //reading request info.......
    let requestInfoRef = db.collection("requestInfo").doc(uid);
    let requestDoc = await requestInfoRef.get();
    if (!requestDoc.exists) {
      this.props.setStepNo(stepNo);
      this.setState({ dataLoading: false });
      this.props.history.push("/singlewill");
      // console.log("No such requestDocument!");
    } else {
      stepNo = 11;
      this.props.setStepNo(stepNo);
      this.setState({ dataLoading: false });

      // console.log("found request doc");
      this.props.setRequestInfo({
        ...requestDoc.data(),
      });
      this.props.history.push("/singlewill");
    }
    //end reading request info.......
    // this.setState({ dataLoading: false });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    // firebase.auth().onAuthStateChanged((signedInUser) => {
    //   if (signedInUser) {
    //     // console.log(signedInUser);
    //     this.setState({ loadingUser: false });
    //     this.readData(signedInUser.uid);
    //     // this.props.setUser(signedInUser);
    //     this.setState({ isLogged: true });
    //   } else {
    //     this.setState({ isLogged: false });
    //     this.props.history.push("/login");
    //   }
    // });
    const db = firebase.firestore();
    var user = { ...firebase.auth().currentUser };
    // console.log(user);
    if (user !== null && user !== undefined && user.providerData !== undefined)
      user.providerData.forEach((item) => {
        if (item.providerId === "facebook.com") user.emailVerified = true;
      });
    if (user === null || !user.emailVerified) {
      this.props.history.push("/login");
    } else if (firebase.auth().currentUser !== null) {
      // TODO: if possible fetch data from store
      const userSnapshot = await db
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .get();
      // this.props.setUser({ currentUser: userSnapshot.data() });
      if (
        userSnapshot.exists &&
        userSnapshot.data()["single_will_id"] !== null &&
        userSnapshot.data()["single_will_id"] !== undefined &&
        userSnapshot.data()["single_will_id"].length > 0
      ) {
        this.setState({ loadingUser: false });
        this.readData(firebase.auth().currentUser.uid);
      } else {
        this.props.history.push("/single-will-info");
      }
    } else {
      this.props.history.push("/login");
    }
  }

  setCurrentView = (view) => {
    this.setState({ currentView: view });
  };

  setStepNo = (no) => {
    this.setState({ stepNo: no });
  };

  render() {
    if (this.state.loadingUser || this.state.dataLoading === true) {
      window.scrollTo(0, 0);
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Header />

          <SingleWillBody
            visibleFooter={this.state.visibleFooter}
            stepNo={this.props.stepNo}
          />

          <VizSensor
            onChange={(isVisible) => {
              if (isVisible) this.setState({ visibleFooter: "fixed-button" });
              else this.setState({ visibleFooter: "float-button" });
            }}
          >
            <div style={{ height: "1rem" }}></div>
          </VizSensor>
          <Footer />
        </React.Fragment>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
    setPersonalInfo: (personalInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PERSONAL_INFO,
        payload: {
          userPersonalInfo: personalInfo,
        },
      }),
    setPartnerInfo: (partnerInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PARTNER_INFO,
        payload: {
          userPartnerInfo: partnerInfo,
        },
      }),
    setChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_USER_CHILDREN_INFO,
        payload: {
          userChildrenInfo: childrenInfo,
        },
      }),
    setGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GUARDIAN_INFO,
        payload: {
          userGuardianInfo: guardianInfo,
        },
      }),
    setLiquidAssetInfo: (liquidAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_LIQUID_ASSET_INFO,
        payload: {
          userLiquidAssetInfo: liquidAssetInfo,
        },
      }),
    setRealAssetInfo: (realAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REAL_ASSET_INFO,
        payload: {
          userRealAssetInfo: realAssetInfo,
        },
      }),
    setGeneralConcernInfo: (generalConcernInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GENERAL_CONCERN_INFO,
        payload: {
          userGeneralConcernInfo: generalConcernInfo,
        },
      }),
    setGiftInfo: (giftInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GIFT_INFO,
        payload: {
          userGiftInfo: giftInfo,
        },
      }),
    setRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REMAINING_ESTATE_INFO,
        payload: {
          userRemainingEstateInfo: remainingEstateInfo,
        },
      }),
    setExecutorInfo: (executorInfo) =>
      dispatch({
        type: actionTypes.SET_USER_EXECUTOR_INFO,
        payload: {
          userExecutorInfo: executorInfo,
        },
      }),
    setRequestInfo: (requestInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REQUEST_INFO,
        payload: {
          userRequestInfo: requestInfo,
        },
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    stepNo: state.user.singleWillStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleWill);

// this.state.currentView === "info" ? (
//   <SingleWillInfo
//     setCurrentView={this.setCurrentView}
//     setStepNo={this.setStepNo}
//   />
// ) : (
