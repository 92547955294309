import React from "react";
import Header from "./header/Header";
import Consulting from "./Consulting";
import Footer from "../footer/Footer";

import OurServices from "./ourServices/OurServices";
import VideoSection from "./header/VedioSection";
import MapContainer from "../ContactUs/MapContainer";
import Testimonials from "./Testimonial/Testimonials";

function LandingPage() {
  return (
    <div>
      <Header />
      <VideoSection />

      <OurServices />
      <Testimonials />
      <Consulting />
      {/* <LegalArticles />
      <SocialMedia />
      <Map /> */}
      <div id="map">
        <MapContainer />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
// 01876891500
