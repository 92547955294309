import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import firebase from "../../../config/firebase";

class SubmitHealthLPA extends Component {
  state = {
    disableSubmit: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onFormSubmit = async (e) => {
    this.setState({ disableSubmit: true, submitted: false });
    e.preventDefault();

    if (
      this.props.userInfo.userDoc === null ||
      this.props.userInfo.userDoc.profile_photo === null ||
      this.props.userInfo.userDoc.government_id === null ||
      this.props.userInfo.userDoc.proof_of_address === null ||
      this.props.userInfo.userDoc.profile_photo === undefined ||
      this.props.userInfo.userDoc.government_id === undefined ||
      this.props.userInfo.userDoc.proof_of_address === undefined
    ) {
      document.getElementById("modalON").click();
      return;
    }

    let db = firebase.firestore();

    // let collectionRef = await db.collection("users");

    // let userRef = (await collectionRef.get()).docs;
    // let userDoc = userRef.find((item) => {
    //   return item.id === this.props.currentUser.uid;
    // });
    let donor = null,
      lifeSustainingTreatment = null,
      attorney = null,
      attorneysMakeDecision = null,
      replacementAttorney = null,
      replacementAttorneysMakeDecision = null,
      certificateProvider = null,
      personToNotify = null,
      preferencesAndInstructions = null,
      registration = null,
      correspondent = null,
      repeat = null;

    //...........

    donor = {
      donor: this.props.healthLpa.donor,
      date_of_birth: firebase.firestore.Timestamp.fromDate(
        new Date(this.props.healthLpa.donor.date_of_birth)
      ),
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };

    lifeSustainingTreatment = {
      life_sustaining_treatment: this.props.healthLpa.lifeSustainingTreatment,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };

    let tempAttorney = [...this.props.healthLpa.attorney];
    tempAttorney.forEach((item) => {
      //   // console.log(item.date_of_birth);
      if (item.date_of_birth !== undefined && item.date_of_birth !== null)
        item.date_of_birth = firebase.firestore.Timestamp.fromDate(
          new Date(item.date_of_birth)
        );
    });
    attorney = {
      attorney: tempAttorney,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };

    if (this.props.healthLpa.attorneysMakeDecision !== null) {
      attorneysMakeDecision = {
        ...this.props.healthLpa.attorneysMakeDecision,
        user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
      };
    }
    let tempReplacementAttorney = [...this.props.healthLpa.replacementAttorney];
    tempReplacementAttorney.forEach((item) => {
      if (item.date_of_birth !== undefined && item.date_of_birth !== null)
        item.date_of_birth = firebase.firestore.Timestamp.fromDate(
          new Date(item.date_of_birth)
        );
    });
    replacementAttorney = {
      replacementAttorney: tempReplacementAttorney,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };
    if (this.props.healthLpa.replacementAttorneysMakeDecision !== null) {
      replacementAttorneysMakeDecision = {
        ...this.props.healthLpa.replacementAttorneysMakeDecision,
        user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
      };
    }

    certificateProvider = {
      certificate_provider: this.props.healthLpa.certificateProvider,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };

    personToNotify = {
      person_to_notify: this.props.healthLpa.personToNotify,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };

    preferencesAndInstructions = {
      ...this.props.healthLpa.preferencesAndInstructions,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };
    registration = {
      ...this.props.healthLpa.registration,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };
    correspondent = {
      ...this.props.healthLpa.correspondent,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };
    repeat = {
      repeat: this.props.healthLpa.repeat,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    };

    let ref = (
      await db.collection("users").doc(firebase.auth().currentUser.uid).get()
    ).data().health_lpa_id[0];
    ref.set({
      donor: donor,
      life_sustaining_treatment: lifeSustainingTreatment,
      attorney: attorney,
      attorney_make_decision: attorneysMakeDecision,
      replacement_attorney: replacementAttorney,
      replacement_attorney_make_decision: replacementAttorneysMakeDecision,
      certificate_provider: certificateProvider,
      person_to_notify: personToNotify,
      preferences_and_instructions: preferencesAndInstructions,
      registration: registration,
      correspondent: correspondent,
      repeat: repeat,
      user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
      time_stamp: firebase.firestore.Timestamp.now(),
      status: "payment_pending",
    });

    let healthLpa = {
      health_lpa_donor_id: db.doc(
        "healthLpaDonorInfo/" + this.props.userInfo.currentUser.uid
      ),
      health_lpa_life_sustaining_treatment_id: db.doc(
        "healthLpaLifeSustainingTreatment/" +
          this.props.userInfo.currentUser.uid
      ),
      health_lpa_attorney_id: db.doc(
        "healthLpaAttorneyInfo/" + this.props.userInfo.currentUser.uid
      ),
      health_lpa_attorney_make_decision_id: db.doc(
        "healthLpaAttorneyMakeDecision/" + this.props.userInfo.currentUser.uid
      ),
      health_lpa_replacement_attorney_id: db.doc(
        "healthLpaReplacementAttorneyInfo/" +
          this.props.userInfo.currentUser.uid
      ),

      health_lpa_replacement_attorney_make_decision_id: db.doc(
        "healthLpaReplacementAttorneyMakeDecision/" +
          this.props.userInfo.currentUser.uid
      ),
      health_lpa_certificate_provider_id: db.doc(
        "healthLpaCertificateProviderInfo/" +
          this.props.userInfo.currentUser.uid
      ),
      health_lpa_persona_to_notify_id: db.doc(
        "healthLpaPersonToNotifyInfo/" + this.props.userInfo.currentUser.uid
      ),
      health_lpa_preferences_and_instructions_id: db.doc(
        "healthLpaPreferencesAndInstructions/" +
          this.props.userInfo.currentUser.uid
      ),
      health_lpa_registration_id: db.doc(
        "healthLpaRegistrationInfo/" + this.props.userInfo.currentUser.uid
      ),
      health_lpa_correspondent_id: db.doc(
        "healthLpaCorrespondentInfo/" + this.props.userInfo.currentUser.uid
      ),
      health_lpa_repeat_id: db.doc(
        "healthLpaRepeatInfo/" + this.props.userInfo.currentUser.uid
      ),
    };
    let res;
    res = await db
      .collection("users")
      .doc(this.props.userInfo.currentUser.uid)
      .set(healthLpa, { merge: true });

    // let healthLpaDoc = await healthLpaRef.get();
    // if (!healthLpaDoc.exists) {
    //   // console.log("No such healthLpaDocument!");
    // } else {
    //   let data = healthLpaDoc.data();
    //   tempHealthLpaArray = data.healthLPAs;
    // }
    // tempHealthLpaArray[tempHealthLpaArray.length] = {
    //   donor: donor,
    //   life_sustaining_treatment: lifeSustainingTreatment,
    //   attorney: attorney,
    //   attorney_make_decision: attorneysMakeDecision,
    //   replacement_attorney: replacementAttorney,
    //   replacement_attorney_make_decision: replacementAttorneysMakeDecision,
    //   certificate_provider: certificateProvider,
    //   person_to_notify: personToNotify,
    //   preferences_and_instructions: preferencesAndInstructions,
    //   registration: registration,
    //   correspondent: correspondent,
    //   repeat: repeat,
    //   user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    //   time_stamp: firebase.firestore.Timestamp.now(),
    //   status:"payment_pending"
    // };
    // res = await db
    //   .collection("healthLpa")
    //   .doc(this.props.userInfo.currentUser.uid)
    //   .set({ healthLPAs: tempHealthLpaArray });

    // res = await healthLpaRef.set({
    //   donor: donor,
    //   life_sustaining_treatment: lifeSustainingTreatment,
    //   attorney: attorney,
    //   attorney_make_decision: attorneysMakeDecision,
    //   replacement_attorney: replacementAttorney,
    //   replacement_attorney_make_decision: replacementAttorneysMakeDecision,
    //   certificate_provider: certificateProvider,
    //   person_to_notify: personToNotify,
    //   preferences_and_instructions: preferencesAndInstructions,
    //   registration: registration,
    //   correspondent: correspondent,
    //   repeat: repeat,
    //   user_id: db.doc("users/" + this.props.userInfo.currentUser.uid),
    //   time_stamp: firebase.firestore.Timestamp.now(),
    //   status: "payment_pending",
    // });
    this.setState({ submitted: true });
    //TODO: redirect to payment page

    this.props.history.push("/checkout/payment/healthlpa/" + ref.id);

    return res;
  };

  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>Submit </h1>
        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "justify" }}
        >
          <div>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                margin: "2rem 1.3rem",
                display: "block",
              }}
            >
              <div className="row justify-content-around">
                <div className="col-md-auto d-flex justify-content-center">
                  <Icon name="hand point left" size="huge"></Icon>
                </div>
                <div className="col-md-9">
                  Please check that all the information you have provided is
                  correct before submitting. Users can do this easily from the
                  left navigation panel.
                </div>
              </div>
            </span>
          </div>
        </div>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "justify" }}
        >
          <span
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              margin: "2rem 1.3rem",
              display: "block",
            }}
          >
            <form onSubmit={this.onFormSubmit}>
              <div style={{ marginBottom: "1.5rem" }}>
                <input id="terms" type="checkbox" required></input>
                <label htmlFor="terms" style={{ paddingLeft: "1.5rem" }}>
                  By ticking this box I accept the Neolexical Ltd.{" "}
                  <Link to="/tnc">Terms & Conditions</Link>
                </label>
              </div>

              <div>
                <input id="terms2" type="checkbox" required></input>
                <label
                  htmlFor="terms2"
                  style={{
                    paddingLeft: "1.5rem",
                    display: "inline",
                    textAlign: "justify",
                  }}
                >
                  I consent to you using Special Category Personal Data (e.g.
                  racial or ethnic origin, religious and philosophical beliefs,
                  data concerning health, sex life and sexual orientation) in
                  use of the Neolexical app and its services, and in accordance
                  with the Neolexical Privacy Policy.
                </label>
              </div>

              <button
                disabled={this.state.disableSubmit}
                type="submit"
                className=" will-next-button"
              >
                Submit
              </button>
            </form>
          </span>
        </div>

        <button
          type="button"
          id="modalON"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          style={{ display: "none" }}
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Warning
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                class="modal-body"
                style={{
                  fontSize: "1.1rem",
                  textAlign: "justify",
                  lineHeight: "1.5rem",
                  padding: "2rem",
                }}
              >
                Before we can accept any submission from you, we need to verify
                your authenticity. For this reason we need your photo, image of
                governement id and image of proof of address. Please upload
                these from the <a href="/user">Personal Info</a> section.
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={() => {
                    this.props.history.push("/user");
                  }}
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  Go to Personal Info
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    healthLpa: state.health_lpa,
    userInfo: state.user,
  };
};

const SubmitHealthLPAWithRouter = withRouter(SubmitHealthLPA);

export default connect(mapStateToProps)(SubmitHealthLPAWithRouter);
