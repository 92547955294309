import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";

class ProductCard extends Component {
  dbStatusToUserStatus = (status) => {
    switch (status) {
      case "created":
        return "Form Fillup in Progress";
      case "payment_pending":
        return "Payment Incomplete";
      default:
        return "";
    }
  };

  renderComponent = () => {
    /* loading */
    if (this.props.loading && this.props.data === null)
      return (
        <div>
          <button
            type="button"
            className={"blue-will-button will-button"}
            name=""
            // onClick={this.onClickNo}
            disabled
            style={{ width: "70%" }}
          >
            <Icon loading name="spinner"></Icon>
          </button>
        </div>
      );
    /* no product found */ else if (
      !this.props.loading &&
      this.props.data === null
    )
      return (
        <div>
          <Link to={this.props.link}>
            <button
              type="button"
              className={"blue-will-button will-button"}
              name=""
              // onClick={this.onClickNo}
              style={{ width: "70%" }}
            >
              Create Now
            </button>
          </Link>
        </div>
      );
    /* product status created */ else if (
      this.props.data !== null &&
      this.props.data.status !== null &&
      this.props.data.status === "created"
    )
      return (
        <div>
          <Link to={this.props.link}>
            <button
              type="button"
              className={"blue-will-button will-button"}
              name=""
              // onClick={this.onClickNo}
              style={{ width: "70%" }}
            >
              Continue Editing
            </button>
          </Link>
        </div>
      );
    /* product status  payment_pending  */ else if (
      this.props.data !== null &&
      this.props.data.status !== null &&
      this.props.data.status === "payment_pending"
    )
      return (
        <div>
          <Link to={this.props.link}>
            <button
              type="button"
              className={"blue-will-button will-button"}
              name=""
              style={{ width: "70%" }}
            >
              Continue Editing
            </button>
          </Link>
          <div>
            <Link to={this.props.paymentLink}>
              <button
                type="button"
                className={"blue-will-button will-button"}
                name=""
                // onClick={this.onClickNo}

                style={{ width: "70%" }}
              >
                Go To Payment
              </button>
            </Link>
          </div>
        </div>
      );
  };

  render() {
    return (
      <div className="ourServicesCard">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6">
            {" "}
            <div className="ourServicesCard-heading">
              <i className={"huge icon " + this.props.icon}></i>

              <span className="ourServicesCard-heading-text">
                {this.props.headingText}
              </span>
            </div>
            <div className="ourServicesCard-description">
              {this.props.data !== null && this.props.data.status !== null && (
                <span
                  style={{
                    fontSize: "1.14285714em",
                    fontWeight: "bold",
                    color: " #00a1cc",
                  }}
                >
                  Status: {this.dbStatusToUserStatus(this.props.data.status)}
                </span>
              )}
            </div>
          </div>
          <div className="col-lg-5 col-md-6 align-self-center">
            {this.renderComponent()}
          </div>
        </div>
      </div>
    );
  }
}

const ProductCardWithRouter = withRouter(ProductCard);

export default ProductCardWithRouter;
