import React, { Component } from "react";
import Footer from "../footer/Footer";
import Header from "../landingPage/header/Header";
import firebase from "../../config/firebase";
import priceJson from "../../config/price.json";
import { Dimmer, Loader } from "semantic-ui-react";
import { loadStripe } from "@stripe/stripe-js";
import "../../css/payment.css";

class Payment extends Component {
  state = {
    data: null,
    isLoading: true,
    error: null,
    totalAmount: 0,
  };
  readData = async () => {
    let db = firebase.firestore();

    let completedProductRef = db
      .collection("completedProducts")
      .doc(firebase.auth().currentUser.uid)
      .collection(
        this.productToSubCollectionName(this.props.match.params.product)
      )
      .doc(this.props.match.params.id);
    let completedProductDoc = await completedProductRef.get();
    if (!completedProductDoc.exists) {
      this.props.history.push(
        this.productToRedirectedLink(this.props.match.params.product)
      );
      // console.log("No such completedProductDocument!");
      return;
    } else {
      let total = 0;
      total += this.productToPrice(this.props.match.params.product);
      if (completedProductDoc.data()?.visit_home) total += priceJson.visit_home;
      if (completedProductDoc.data()?.registration?.selected === "neolexical")
        total += priceJson.court_registration;
      this.setState({ data: completedProductDoc.data(), totalAmount: total });

      this.setState({ isLoading: false });
    }
  };
  async componentDidMount() {
    const user = firebase.auth().currentUser;

    if (user === null || !user.emailVerified) {
      this.props.history.push("/login");
    } else {
      this.readData();
    }
  }

  productToName = (product) => {
    switch (product) {
      case "singlewill":
        return "Single Will";
      case "doublewill":
        return "Double Will";
      case "healthlpa":
        return "Health LPA";
      case "financiallpa":
        return "Financial LPA";

      default:
        return "Will & LPA";
    }
  };

  productToSubCollectionName = (product) => {
    switch (product) {
      case "singlewill":
        return "singleWills";
      case "doublewill":
        return "doubleWills";
      case "healthlpa":
        return "healthLPAs";
      case "financiallpa":
        return "financialLPAs";
      default:
        return "Will & LPA";
    }
  };

  productToRedirectedLink = (product) => {
    switch (product) {
      case "singlewill":
        return "/single-will-info";
      case "doublewill":
        return "/double-will-info";
      case "healthlpa":
        return "/health-welfare-info";
      case "financiallpa":
        return "/financial-affairs-info";
      default:
        return "Will & LPA";
    }
  };



  productToPriceId = (product) => {
    switch (product) {
      case "singlewill":
        return process.env.REACT_APP_ENV === "development"
          ? "price_1I0PT4JpU6MVFiPsojginMVM"
          : "price_1IEUULJpU6MVFiPsZfKY7YbR";

      case "doublewill":
        return process.env.REACT_APP_ENV === "development"
          ? "price_1I1R5zJpU6MVFiPsPVoh063E"
          : "price_1IEX1FJpU6MVFiPsyxE3rzro";

      case "healthlpa":
        return process.env.REACT_APP_ENV === "development"
          ? "price_1I1RClJpU6MVFiPsDodrvmuP"
          : "price_1IEX1CJpU6MVFiPsymaPPlFB";

      case "financiallpa":
        return process.env.REACT_APP_ENV === "development"
          ? "price_1I1RGJJpU6MVFiPs0Ij1IpPY"
          : "price_1IEX18JpU6MVFiPs1fhpuY2y";

      default:
        return "";
    }
  };

  productToPrice = (product) => {
    switch (product) {
      case "singlewill":
        return priceJson.single_will;
      case "doublewill":
        return priceJson.double_will;
      case "healthlpa":
        return priceJson.health_lpa;
      case "financiallpa":
        return priceJson.financial_lpa;

      default:
        return 0;
    }
  };

  productToProdName = (product) => {
    switch (product) {
      case "singlewill":
        return "singleWill";
      case "doublewill":
        return "doubleWill";
      case "healthlpa":
        return "healthLPA";
      case "financiallpa":
        return "financialLPA";

      default:
        return "";
    }
  };

  handleProceedToPayment = async () => {
    this.setState({ isLoading: true });

    try {
      let response = await fetch(
        process.env.REACT_APP_ENV === "development"
          ? "https://europe-west2-neolexical-demo.cloudfunctions.net/stripe/create-checkout-session"
          : "https://europe-west2-neolexical-app.cloudfunctions.net/stripe/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: firebase.auth().currentUser.uid,
            priceId: this.productToPriceId(this.props.match.params.product),
            completedProductId: this.props.match.params.id,
            productName: this.productToProdName(
              this.props.match.params.product
            ),
            locale: "en",
          }),
        }
      );

      response = await response.json();

      const stripe = await loadStripe(  // use test keys for staging/dev servers and live keys for prod server
        process.env.REACT_APP_ENV === "development"
          ? "pk_test_51H7hJCJpU6MVFiPsnvNbMPjq1jGCJvM0V4nIclYNS9Oby8z1AJHba7mgP0g1HhqbbGnnqIbfs4EamSxB8DyCAlXM00kWjHUrLd"
          : "pk_live_51H7hJCJpU6MVFiPss2wWGpB73yoN71IES83V5EHYBp0dvREa5qM3P4xOShZIGMNnrRdKF6YgEUR10laRO7lRoHXM00PcblXpOw"
      );

      await stripe.redirectToCheckout({
        sessionId: await response["sessionId"],
      });
    } catch (error) {
      this.setState({ error: error, isLoading: false });
      console.error(error);
    }
  };

  render() {
    return this.state.isLoading ? (
      <div style={{ height: "100vh" }}>
        <Dimmer className="loader-bg" active>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      </div>
    ) : (
        <React.Fragment>
          <Header />
          <main className="d-flex align-items-center justify-content-center">
            <div className="payment-form">
              <div className="main-header">
                <span>{this.productToName(this.props.match.params.product)}</span>
              </div>

              <section className="info-row">
                <div className="product-info">
                  <div>Status</div>
                  <div>Pending Payment</div>
                </div>
              </section>

              <section className="info-row">
                <div className="product-info">
                  <div>
                    {this.productToName(this.props.match.params.product)} Price
                </div>
                  <div>
                    £ {this.productToPrice(this.props.match.params.product)}
                  </div>
                </div>
              </section>

              {this.state.data?.visit_home && (
                <section className="info-row">
                  <div className="product-info">
                    <div>Home Visit Fee</div>
                    <div>£ {priceJson.visit_home}</div>
                  </div>
                </section>
              )}

              {this.state.data?.registration?.selected === "neolexical" && (
                <section className="info-row">
                  <div className="product-info">
                    <div>Court Registration Fee</div>
                    <div>£ {priceJson.court_registration}</div>
                  </div>
                </section>
              )}

              <section className="info-row">
                <div className="product-info">
                  <div>Total Price</div>
                  <div>£ {this.state.totalAmount}</div>
                </div>
              </section>

              <div className="divider"></div>

              <section>
                <button
                  onClick={this.handleProceedToPayment}
                  className="neo-button"
                >
                  Proceed To Payment
              </button>
              </section>

              {this.state.error != null && (
                <div class="alert alert-danger alert-dismissible fade show">
                  Sorry, failed to load payment page! Please try again!
                  <button
                    type="button"
                    class="close"
                    onClick={(e) => this.setState({ error: null })}
                  >
                    &times;
                </button>
                </div>
              )}
            </div>
          </main>
          <Footer />
        </React.Fragment>
      );
  }
}

export default Payment;
