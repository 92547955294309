import React, { Component } from "react";
import { connect } from "react-redux";

import BenificiaryType from "./BeneficiaryType";
import CharityGift from "./CharityGift";
import FamilyGift from "./FamilyGift";
import GiftInfoSteps from "./GiftInfoSteps";
import * as actionTypes from "../../../../actions/types";
import Modal from "../Modal";
import * as helpTextList from "../../HelpTextList";

class GiftInfo extends Component {
  state = { giftInfoCurrentView: "beneficiaryType" };
  setGiftInfoCurrentView = (view) => {
    this.setState({ giftInfoCurrentView: view });
  };
  componentDidMount() {
    if (this.props.stepNo < 7) this.props.setStepNo(7);
  }
  render() {
    let currentViewComponent;
    if (this.state.giftInfoCurrentView === "beneficiaryType")
      currentViewComponent = (
        <BenificiaryType
          setCurrentView={this.setGiftInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "familyGift")
      currentViewComponent = (
        <FamilyGift
          setCurrentView={this.setGiftInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "charityGift")
      currentViewComponent = (
        <CharityGift
          setCurrentView={this.setGiftInfoCurrentView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.giftInfoCurrentView === "next") {
      this.props.setCurrentView("remainingEstateInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Gift Info</h1>
        <GiftInfoSteps
          setCurrentView={this.setGiftInfoCurrentView}
          currentView={this.state.giftInfoCurrentView}
        />
        {currentViewComponent}
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal title="Gift Info" body={helpTextList.giftText} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    giftInfo: state.user.giftInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftInfo);
