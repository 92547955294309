import * as actionTypes from "../actions/types";
import { combineReducers } from "redux";

const initialUserState = {
  currentUser: null,
  personalInfo: null,
  partnerInfo: null,
  childrenInfo: null,
  guardianInfo: null,
  liquidAssetInfo: null,
  realAssetInfo: null,
  generalConcernInfo: null,
  giftInfo: {
    gift_beneficiary_type: null,
    charity_gift_count: null,
    charity_gifts: null,
    family_cash_gift_count: null,
    family_cash_gifts: null,
    family_general_gift_count: null,
    family_general_gifts: null,
  },
  remainingEstateInfo: {
    spouse_all_in: null,
    children_all_in: null,
    step_children_included: null,
    beneficiaries: [],
  },
  executorInfo: {
    executor_type: null,
    executors: [],
  },
  requestInfo: {
    donate_organs: false,
    freeze_body: false,
    funeral_preference: null,
    funeral_song: null,
    request: null,
  },
  userDoc: null,
  singleWillStepNo: 0,
  userDocLoading: true,
  isLoading: true,
  personalInfoDone: false,
};

const user_reducer = (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isLoading: false,
      };
    case actionTypes.SET_USER_DOC:
      return {
        ...state,
        userDoc: action.payload.userDoc,
        userDocLoading: false,
      };
    case actionTypes.CLEAR_USER:
      return {
        ...initialUserState,
        isLoading: false,
      };
    case actionTypes.SET_USER_PERSONAL_INFO:
      return {
        ...state,
        personalInfo: action.payload.userPersonalInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_PARTNER_INFO:
      return {
        ...state,
        partnerInfo: action.payload.userPartnerInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_CHILDREN_INFO:
      return {
        ...state,
        childrenInfo: action.payload.userChildrenInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_GUARDIAN_INFO:
      return {
        ...state,
        guardianInfo: action.payload.userGuardianInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_LIQUID_ASSET_INFO:
      return {
        ...state,
        liquidAssetInfo: action.payload.userLiquidAssetInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_REAL_ASSET_INFO:
      return {
        ...state,
        realAssetInfo: action.payload.userRealAssetInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_GENERAL_CONCERN_INFO:
      return {
        ...state,
        generalConcernInfo: action.payload.userGeneralConcernInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_GIFT_INFO:
      return {
        ...state,
        giftInfo: action.payload.userGiftInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_REMAINING_ESTATE_INFO:
      return {
        ...state,
        remainingEstateInfo: action.payload.userRemainingEstateInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_EXECUTOR_INFO:
      return {
        ...state,
        executorInfo: action.payload.userExecutorInfo,
        isLoading: false,
      };
    case actionTypes.SET_USER_REQUEST_INFO:
      return {
        ...state,
        requestInfo: action.payload.userRequestInfo,
        isLoading: false,
        personalInfoDone: true,
      };
    case actionTypes.SET_SINGLE_WILL_STEP_NO:
      return {
        ...state,
        singleWillStepNo: action.payload.singleWillStepNo,
        isLoading: false,
      };
    case actionTypes.SET_PERSONAL_INFO_DONE:
      return {
        ...state,
        personalInfoDone: true,
      };

    default:
      return state;
  }
};

const initialPartnerState = {
  childrenInfo: null,
  guardianInfo: null,
  liquidAssetInfo: null,
  realAssetInfo: null,
  generalConcernInfo: null,
  giftInfo: {
    gift_beneficiary_type: null,
    charity_gift_count: null,
    charity_gifts: null,
    family_cash_gift_count: null,
    family_cash_gifts: null,
    family_general_gift_count: null,
    family_general_gifts: null,
  },
  remainingEstateInfo: {
    spouse_all_in: null,
    children_all_in: null,
    step_children_included: null,
    beneficiaries: [],
  },
  executorInfo: {
    executor_type: null,
    executors: [],
  },
  requestInfo: {
    donate_organs: false,
    freeze_body: false,
    funeral_preference: null,
    funeral_song: null,
    request: null,
  },
  partnerStepNo: 2, //starting index from 2(childreninfo)
  partnerInfoDone: false,
};
const partner_reducer = (state = initialPartnerState, action) => {
  switch (action.type) {
    case actionTypes.SET_PARTNER_CHILDREN_INFO:
      return {
        ...state,
        childrenInfo: action.payload.partnerChildrenInfo,
      };
    case actionTypes.SET_PARTNER_GUARDIAN_INFO:
      return {
        ...state,
        guardianInfo: action.payload.partnerGuardianInfo,
      };
    case actionTypes.SET_PARTNER_LIQUID_ASSET_INFO:
      return {
        ...state,
        liquidAssetInfo: action.payload.partnerLiquidAssetInfo,
      };
    case actionTypes.SET_PARTNER_REAL_ASSET_INFO:
      return {
        ...state,
        realAssetInfo: action.payload.partnerRealAssetInfo,
      };
    case actionTypes.SET_PARTNER_GENERAL_CONCERN_INFO:
      return {
        ...state,
        generalConcernInfo: action.payload.partnerGeneralConcernInfo,
      };
    case actionTypes.SET_PARTNER_GIFT_INFO:
      return {
        ...state,
        giftInfo: action.payload.partnerGiftInfo,
      };
    case actionTypes.SET_PARTNER_REMAINING_ESTATE_INFO:
      return {
        ...state,
        remainingEstateInfo: action.payload.partnerRemainingEstateInfo,
      };
    case actionTypes.SET_PARTNER_EXECUTOR_INFO:
      return {
        ...state,
        executorInfo: action.payload.partnerExecutorInfo,
      };
    case actionTypes.SET_PARTNER_REQUEST_INFO:
      return {
        ...state,
        requestInfo: action.payload.partnerRequestInfo,
        partnerInfoDone: true,
      };
    case actionTypes.SET_PARTNER_STEP_NO:
      return {
        ...state,
        partnerStepNo: action.payload.partnerStepNo,
      };
    case actionTypes.SET_PARTNER_INFO_DONE:
      return {
        ...state,
        partnerInfoDone: true,
      };

    default:
      return state;
  }
};

//financial lpa reducer....................

const initialFinancialLPAState = {
  donor: null,
  whenCanBeUsed: null,
  attorney: null,
  attorneysMakeDecision: null,
  replacementAttorney: null,
  replacementAttorneysMakeDecision: null,
  certificateProvider: null,
  personToNotify: null,
  preferencesAndInstructions: null,
  registration: null,
  correspondent: null,
  repeat: null,
  stepNo: 0,
  disable: false,
};
const financial_lpa_reducer = (state = initialFinancialLPAState, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCIAL_LPA_DONOR:
      return {
        ...state,
        donor: action.payload.donor,
      };
    case actionTypes.SET_FINANCIAL_LPA_WHEN_CAN_BE_USED:
      return {
        ...state,
        whenCanBeUsed: action.payload.whenCanBeUsed,
      };
    case actionTypes.SET_FINANCIAL_LPA_ATTORNEY:
      return {
        ...state,
        attorney: action.payload.attorney,
      };
    case actionTypes.SET_FINANCIAL_LPA_ATTORNEYS_MAKE_DECISION:
      return {
        ...state,
        attorneysMakeDecision: action.payload.attorneysMakeDecision,
      };
    case actionTypes.SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEY:
      return {
        ...state,
        replacementAttorney: action.payload.replacementAttorney,
      };
    case actionTypes.SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION:
      return {
        ...state,
        replacementAttorneysMakeDecision:
          action.payload.replacementAttorneysMakeDecision,
      };
    case actionTypes.SET_FINANCIAL_LPA_CERTIFICATE_PROVIDER:
      return {
        ...state,
        certificateProvider: action.payload.certificateProvider,
      };
    case actionTypes.SET_FINANCIAL_LPA_PERSON_TO_NOTIFY:
      return {
        ...state,
        personToNotify: action.payload.personToNotify,
      };
    case actionTypes.SET_FINANCIAL_LPA_PREFERENCES_AND_INSTRUCTIONS:
      return {
        ...state,
        preferencesAndInstructions: action.payload.preferencesAndInstructions,
      };
    case actionTypes.SET_FINANCIAL_LPA_REGISTRATION:
      return {
        ...state,
        registration: action.payload.registration,
      };
    case actionTypes.SET_FINANCIAL_LPA_CORRESPONDENT:
      return {
        ...state,
        correspondent: action.payload.correspondent,
      };
    case actionTypes.SET_FINANCIAL_LPA_REPEAT:
      return {
        ...state,
        repeat: action.payload.repeat,
      };
    case actionTypes.SET_FINANCIAL_LPA_STEP:
      return {
        ...state,
        stepNo: action.payload.stepNo,
      };
    case actionTypes.SET_FINANCIAL_LPA_DISABLE_REG_COR:
      return {
        ...state,
        disable: action.payload.disable,
      };
    default:
      return state;
  }
};

//health lpa reducer....................

const initialHealthLPAState = {
  donor: null,
  lifeSustainingTreatment: null,
  attorney: null,
  attorneysMakeDecision: null,
  replacementAttorney: null,
  replacementAttorneysMakeDecision: null,
  certificateProvider: null,
  personToNotify: null,
  preferencesAndInstructions: null,
  registration: null,
  correspondent: null,
  repeat: null,
  stepNo: 0,
  disable: false,
};
const health_lpa_reducer = (state = initialHealthLPAState, action) => {
  switch (action.type) {
    case actionTypes.SET_HEALTH_LPA_DONOR:
      return {
        ...state,
        donor: action.payload.donor,
      };
    case actionTypes.SET_HEALTH_LPA_LIFE_SUSTAINING_TREATMENT:
      return {
        ...state,
        lifeSustainingTreatment: action.payload.lifeSustainingTreatment,
      };
    case actionTypes.SET_HEALTH_LPA_ATTORNEY:
      return {
        ...state,
        attorney: action.payload.attorney,
      };
    case actionTypes.SET_HEALTH_LPA_ATTORNEYS_MAKE_DECISION:
      return {
        ...state,
        attorneysMakeDecision: action.payload.attorneysMakeDecision,
      };
    case actionTypes.SET_HEALTH_LPA_REPLACEMENT_ATTORNEY:
      return {
        ...state,
        replacementAttorney: action.payload.replacementAttorney,
      };
    case actionTypes.SET_HEALTH_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION:
      return {
        ...state,
        replacementAttorneysMakeDecision:
          action.payload.replacementAttorneysMakeDecision,
      };
    case actionTypes.SET_HEALTH_LPA_CERTIFICATE_PROVIDER:
      return {
        ...state,
        certificateProvider: action.payload.certificateProvider,
      };
    case actionTypes.SET_HEALTH_LPA_PERSON_TO_NOTIFY:
      return {
        ...state,
        personToNotify: action.payload.personToNotify,
      };
    case actionTypes.SET_HEALTH_LPA_PREFERENCES_AND_INSTRUCTIONS:
      return {
        ...state,
        preferencesAndInstructions: action.payload.preferencesAndInstructions,
      };
    case actionTypes.SET_HEALTH_LPA_REGISTRATION:
      return {
        ...state,
        registration: action.payload.registration,
      };
    case actionTypes.SET_HEALTH_LPA_CORRESPONDENT:
      return {
        ...state,
        correspondent: action.payload.correspondent,
      };
    case actionTypes.SET_HEALTH_LPA_REPEAT:
      return {
        ...state,
        repeat: action.payload.repeat,
      };
    case actionTypes.SET_HEALTH_LPA_STEP:
      return {
        ...state,
        stepNo: action.payload.stepNo,
      };
    case actionTypes.SET_HEALTH_LPA_DISABLE_REG_COR:
      return {
        ...state,
        disable: action.payload.disable,
      };
    default:
      return state;
  }
};

//wills visit home

const initialWillVisitHomeState = {
  singleWillVisitHome: null,
  doubleWillVisitHome: null,
};

const will_visit_home = (state = initialWillVisitHomeState, action) => {
  switch (action.type) {
    case actionTypes.SET_DOUBLE_WILL_VISIT_HOME:
      return {
        ...state,
        doubleWillVisitHome: action.payload.visitHome,
      };
    case actionTypes.SET_SINGLE_WILL_VISIT_HOME:
      return {
        ...state,
        singleWillVisitHome: action.payload.visitHome,
      };

    default:
      return state;
  }
};
const rootReducer = combineReducers({
  user: user_reducer,
  partner: partner_reducer,
  financial_lpa: financial_lpa_reducer,
  health_lpa: health_lpa_reducer,
  will_visit_home: will_visit_home,
});

export default rootReducer;
