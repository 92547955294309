import React, { Component } from "react";
import firebase from "../../config/firebase";
import { connect } from "react-redux";

export class ResetPasswordModal extends Component {
  state = {
    message: "",
    error: "",
    email: "",
    dataLoading: false,
  };
  componentDidMount() {
    this.setState({ message: "", email: "" });
    // var auth = firebase.auth();
    // var actionCodeSettings = {
    //   url: "neolexical-demo.firebaseapp.com",
    //   handleCodeInApp: false,
    // };
    // auth
    //   .sendPasswordResetEmail(this.props.currentUser.email)
    //   .then(() => {
    //     // Email sent.
    //     this.setState({ message: "Check Your Mail" });
    //     // console.log("check mail");
    //   })
    //   .catch(function (error) {
    //     // An error happened.
    //     this.setState({ message: "cant reset" });
    //     // console.log("cant reset , " + error.message);
    //   });
  }
  onClickReset = () => {
    var auth = firebase.auth();
    // var actionCodeSettings = {
    //   url: "neolexical-demo.firebaseapp.com",
    //   handleCodeInApp: false,
    // };
    if (this.state.email !== "") {
      this.setState({ dataLoading: true });
      auth
        .sendPasswordResetEmail(this.state.email)
        .then(() => {
          // Email sent.
          this.setState({ message: "Check Your Mail", error: "" });
          // console.log("check mail");
          this.setState({ dataLoading: false });
        })
        .catch((error) => {
          // An error happened.
          this.setState({
            error: "Can not reset , " + error.message,
            message: "",
          });
          this.setState({ dataLoading: false });
          // console.log("cant reset , " + error.message);
        });
    }
  };
  render() {
    return (
      <div
        className="modal fade"
        id="resetPasswordModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Reset Password
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ message: "", email: "", error: "" });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                required
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <div style={{ marginTop: "1rem" }}>
                {this.state.message === "" ? (
                  <span></span>
                ) : (
                  <span>{this.state.message}</span>
                )}
                {this.state.error === "" ? (
                  <span></span>
                ) : (
                  <span style={{ color: "red" }}>{this.state.error}</span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  this.setState({ message: "", email: "", error: "" });
                }}
              >
                Close
              </button>
              <button
                disabled={this.state.dataLoading}
                type="button"
                className="btn btn-primary"
                onClick={this.onClickReset}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps)(ResetPasswordModal);
