import React, { Component } from "react";
import Modal from "../Modal";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";
import * as PartnerHelpTextList from "../../PartnerHelpTextList";

class RemainingEstateSelect extends Component {
  state = {
    beneficiary_type: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      beneficiary_type: this.props.remainingEstateInfo.beneficiary_type,
    });
  }

  onClick = (e) => {
    // // console.log(e.target.name);
    this.setState({ beneficiary_type: e.target.name });

    if (e.target.name === "specify") {
      this.props.setPartnerRemainingEstateInfo({
        ...this.props.remainingEstateInfo,
        beneficiary_type: e.target.name,
      });
      this.props.setCurrentView("add");
    } else {
      this.props.setPartnerRemainingEstateInfo({
        ...this.props.remainingEstateInfo,
        beneficiaries: null,
        substitute_beneficiary: null,
        beneficiary_type: e.target.name,
      });
      let db = firebase.firestore();
      db.collection("partnerRemainingEstateInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.remainingEstateInfo,
          user_id: db.doc("users/" + this.props.currentUser.uid),
          beneficiaries: [],
          substitute_beneficiary: null,
          beneficiary_type: e.target.name,
        })
        .then(() => {
          // console.log("remaining estate info in firestore");
        })
        .catch(() => {
          // console.log("remaining estate info in firestore failed");
        });
      this.props.setCurrentView("next");
    }
  };

  // onClickNext = () => {
  //   this.props.setPartnerRemainingEstateInfo({
  //     ...this.props.remainingEstateInfo,
  //     ...this.state,
  //   });
  //   if (this.state.spouse_all_in || this.state.children_all_in) {
  //     this.props.setCurrentView("next");
  //   } else this.props.setCurrentView("add");
  // };

  render() {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginTop: "2.5rem",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              display: "block",
              marginBottom: "4rem",
            }}
          >
            To whom do you want to leave the remainder of your estate?
          </span>
          <div>
            <button
              className={
                this.state.beneficiary_type === "spouse"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="spouse"
            >
              Spouse
            </button>
          </div>
          <div>
            <button
              className={
                this.state.beneficiary_type === "children"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="children"
            >
              Children
            </button>
          </div>
          <div>
            <button
              className={
                this.state.beneficiary_type === "specify"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="specify"
            >
              Specify Manually
            </button>
          </div>
        </div>

        {/* <button
          className=" will-next-button"
          style={{ marginTop: "1rem" }}
          onClick={this.onClickNext}
        >
          Next
        </button> */}

        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="Remaining Estate Info"
          body={PartnerHelpTextList.remainingEstateText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REMAINING_ESTATE_INFO,
        payload: {
          partnerRemainingEstateInfo: remainingEstateInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    remainingEstateInfo: state.partner.remainingEstateInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemainingEstateSelect);
