import React, { Component } from "react";
import member1 from "../../images/member1.jpg";
import member2 from "../../images/member2.jpg";
import Header from "../landingPage/header/Header";
import Footer from "../footer/Footer";

class OurMembers extends Component {
  render() {
    return (
      // <React.Fragment>
        <div className="body-container">
          <div className="aboutus-section-container" id="our-members">
            {/*<h1 className="aboutus-section-heading">Our Team</h1>*/}
            {/*<div className="ourServices-subheading alert-info">*/}
            {/*Your Will shall be always drafted by a solicitor once the instructions and payment are received via our Website or via the soon to be launched Mobile Application on Android and Apple mobile phones/services. If the lawyer feels he or she needs to contact you to further confirm or verify your instructions, this can be done via Zoom or another Video-conferencing application such as Goole Meet or Microsoft Teams. On the occasion, it cannot be avoided an appointment can be made to visit our offices.*/}
            {/*</div>*/}
            <div
              className="row justify-content-center mb-10"
              style={{ marginTop: "6rem" }}
            >
              <div
                className="col-4 mb-5"
                style={{ maxWidth: "20rem", maxHeight: "20rem" }}
              >
                {/*{ <img*/}
                {/*  className="img"*/}
                {/*  style={{*/}
                {/*    height: "13rem",*/}
                {/*    width: "13rem",*/}
                {/*    objectFit: "cover",*/}
                {/*    borderRadius: "50%",*/}
                {/*  }}*/}
                {/*  alt="member1"*/}
                {/*  src={member1}*/}
                {/*/> }*/}
              </div>
              <div className="col-xl-8 align-self-center">
                <div className="">
                  <span className="aboutus-section-subtitle">Ahmed Alef</span>
                  <span className="aboutus-section-text ">
                  8 years Post Qualified English Solicitor admitted to the Law Society of England and Wales with a range of experience in different areas of practice in Wills, Trust and Probate/Administration of Estates, special Needs Educational Law, especially EHCPlan Appeals.

                  </span>
                </div>
              </div>
            </div>

            {/* <div
              className="row justify-content-center mb-5"
              style={{ marginTop: "5rem" }}
            >
              <div className="col-xl-8 align-self-center mb-3">
                <div className="">
                  <span className="aboutus-section-subtitle">Jane Doe</span>
                  <span className="aboutus-section-text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quas, iure vel? Ipsum ipsa voluptatibus voluptatem fugiat,
                    ratione impedit excepturi quas ipsam dicta molestiae magnam
                    iusto repudiandae, enim, quidem obcaecati quasi.
                  </span>
                </div>
              </div>
              <div
                className="col-4 "
                style={{ maxWidth: "20rem", maxHeight: "20rem" }}
              >
                <img
                  alt="member1"
                  className="img"
                  style={{
                    height: "13rem",
                    width: "13rem",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  src={member2}
                />
              </div>
            </div> */}
          </div>
        </div>
    );
  }
}

export default OurMembers;
