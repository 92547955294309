import React, { Component } from "react";

import * as actionTypes from "../../../actions/types";
import "../../../css/will.css";
import Modal from "./Modal";

import firebase from "../../../config/firebase";

import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import * as PartnerHelpTextList from "../PartnerHelpTextList";

class RequestInfo extends Component {
  state = {
    donate_organs: false,
    freeze_body: false,
    funeral_preference: "",
    funeral_song: "",
    request: "",
  };

  funeralList = [
    "Burial - Plot",
    "Cremation - Store in Urn",
    "Burial - Woodland",
    "Cremation - Spread Ashes",
  ];
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.partnerStepNo < 10) this.props.setPartnerStepNo(10);
    this.setState({ ...this.props.requestInfo });
  }
  onClickYes = (e) => {
    this.setState({ [e.target.name]: true });
  };
  onClickNo = (e) => {
    // console.log(e.target.name);
    this.setState({ [e.target.name]: false });
    // this.setState({ needGuardian: false });
    // this.props.setCurrentView("liquidAssetInfo");
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onClickSkip = () => {
    this.props.setPartnerInfoDone();
    this.props.setPartnerStepNo(11);
    this.props.setCurrentView("submit");
  };
  onFormSubmit = (e) => {
    e.preventDefault();

    let db = firebase.firestore();
    db.collection("partnerRequestInfo")
      .doc(this.props.currentUser.uid)
      .set({
        ...this.state,
        user_id: db.doc("users/" + this.props.currentUser.uid),
      })
      .then(() => {
        // console.log("request info in firestore");
      })
      .catch(() => {
        // console.log("request info in firestore failed");
      });

    this.props.setPartnerRequestInfo({ ...this.state });
    this.props.setCurrentView("submit");
  };

  onClickSame = () => {
    if (this.props.userRequestInfo !== null) {
      let db = firebase.firestore();
      db.collection("partnerRequestInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.userRequestInfo,
        })
        .then(() => {
          // console.log("request info in firestore");
        })
        .catch(() => {
          // console.log("request info in firestore failed");
        });

      this.props.setPartnerRequestInfo({ ...this.props.userRequestInfo });
      this.props.setCurrentView("submit");
    }
  };

  render() {
    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Your Funeral Requests</h1>

        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            className="will-button blue-will-button"
            style={{
              width: "70%",
              letterSpacing: "0.1rem",
              wordSpacing: ".2rem",
              fontSize: "1.2rem",
            }}
            onClick={this.onClickSame}
          >
            Same as Partner
          </button>
        </div>
        <Divider horizontal>Or</Divider>

        <div
          className="skip-card"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div>
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              You can skip this section!
            </span>
            <button
              className="will-button blue-will-button"
              style={{ marginLeft: "1rem" }}
              onClick={this.onClickSkip}
            >
              skip
            </button>
          </div>
        </div>

        <form onSubmit={this.onFormSubmit}>
          <label
            htmlFor={"type"}
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            What is your funeral preference?
          </label>

          <select
            id={"type"}
            className="form-control"
            value={this.state.funeral_preference || ""}
            onChange={(e) => {
              this.setState({ funeral_preference: e.target.value });
            }}
            required
          >
            <option value="" disabled hidden selected>
              Funeral Type
            </option>
            {this.funeralList.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>

          <div
            className="skip-card"
            style={{ width: "100%", textAlign: "center", marginTop: "3rem" }}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "1.7rem",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  display: "block",
                  marginBottom: "1rem",
                }}
              >
                Would you like information on how to freeze your funeral cost at
                today's price?
              </span>
              <span
                style={{
                  display: "block",
                  marginBottom: "1rem",
                }}
              >
                <button
                  type="button"
                  className={
                    this.state.freeze_body
                      ? "will-button-selected"
                      : "will-button"
                  }
                  style={{ marginRight: "2rem", width: "30%" }}
                  onClick={this.onClickYes}
                  name="freeze_body"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={
                    this.state.freeze_body
                      ? "will-button"
                      : "will-button-selected"
                  }
                  name="freeze_body"
                  onClick={this.onClickNo}
                  style={{ width: "30%" }}
                >
                  No
                </button>
              </span>
            </div>
          </div>

          <div
            className="skip-card"
            style={{ width: "100%", textAlign: "center", marginTop: "3rem" }}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "1.7rem",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  display: "block",
                  marginBottom: "1rem",
                }}
              >
                Would you like like opt out of the default organ donation plan?
              </span>
              <span
                style={{
                  display: "block",
                  marginBottom: "1rem",
                }}
              >
                <button
                  type="button"
                  className={
                    this.state.donate_organs
                      ? "will-button-selected"
                      : "will-button"
                  }
                  style={{ marginRight: "2rem", width: "30%" }}
                  onClick={this.onClickYes}
                  name="donate_organs"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={
                    this.state.donate_organs
                      ? "will-button"
                      : "will-button-selected"
                  }
                  name="donate_organs"
                  onClick={this.onClickNo}
                  style={{ width: "30%" }}
                >
                  No
                </button>
              </span>
            </div>
          </div>

          <label
            htmlFor="funeral_song"
            className="col-form-label col-form-label "
            style={{ marginTop: "0.5rem", fontWeight: "bold" }}
          >
            Would you like to play a certain piece of music at your funeral?
          </label>

          <input
            type="text"
            className="form-control"
            id="funeral_song"
            placeholder="Funeral Song"
            onChange={this.onChangeHandler}
            value={this.state.funeral_song || ""}
            name="funeral_song"
          />

          <label
            htmlFor="request"
            className="col-form-label col-form-label "
            style={{ marginTop: "2rem", fontWeight: "bold" }}
          >
            Are there any other requests you would like to make?
          </label>

          <input
            type="text"
            className="form-control"
            id="request"
            placeholder="Eg - Specific instructions of how you want your estate or your remains to be dealt with."
            onChange={this.onChangeHandler}
            value={this.state.request || ""}
            name="request"
          />

          <button className=" will-next-button">Next</button>
        </form>

        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal title="Request Info" body={PartnerHelpTextList.requestText} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerRequestInfo: (requestInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REQUEST_INFO,
        payload: {
          partnerRequestInfo: requestInfo,
        },
      }),
    setPartnerInfoDone: () =>
      dispatch({
        type: actionTypes.SET_PARTNER_INFO_DONE,
        payload: {
          partnerInfoDone: true,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userRequestInfo: state.user.requestInfo,
    requestInfo: state.partner.requestInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestInfo);
