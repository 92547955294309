import React, { Component } from "react";
import Footer from "../footer/Footer";
import MainHeader from "../landingPage/header/Header";
import firebase from "../../config/firebase";
import { Header, Icon, Segment } from "semantic-ui-react";

class Failure extends Component {
  state = {
    data: null,
  };
  readData = async () => {
    let db = firebase.firestore();

    let completedSingleWillRef = db
      .collection("completedProducts")
      .doc(firebase.auth().currentUser.uid)
      .collection("singleWills")
      .doc(this.props.match.params.id);
    let completedSingleWillDoc = await completedSingleWillRef.get();
    if (!completedSingleWillDoc.exists) {
      // this.props.history.push("/single-will-info");
      // // console.log("No such completedSingleWillDocument!");
      return;
    } else {
      this.setState({ data: completedSingleWillDoc.data() });
    }
  };
  async componentDidMount() {
    const user = firebase.auth().currentUser;

    if (user === null || !user.emailVerified) {
      this.props.history.push("/login");
    } else {
      this.readData();
    }
    //TODO: validate transaction @Tasin
  }
  render() {
    return (
      <React.Fragment>
        <MainHeader />
        <div
          className="body-container"
          style={{ padding: 0, marginBottom: "7rem" }}
        >
          <Segment placeholder>
            <Header icon style={{ marginTop: "2rem", marginBottom: "5rem" }}>
              <span className="text-danger">
                <Icon className="text-danger" name="close" />
                Payment Failed
              </span>
              <p className="text-danger " style={{ marginTop: "1.5rem" }}>
                Sorry, Try again!
              </p>
            </Header>
            <div style={{ textAlign: "center" }}>
              <button
                className={"will-button blue-will-button mr-3"}
                style={{
                  paddingLeft: "2.3rem",
                  paddingRight: "2.5rem",
                  fontSize: "1.4rem",
                }}
                onClick={() => this.props.history.push("/user")}
              >
                Continue
              </button>
              <button
                className={"will-button blue-will-button"}
                style={{ fontSize: "1.4rem" }}
                onClick={() => this.props.history.push("/contactus")}
              >
                Contact Us
              </button>
            </div>
          </Segment>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Failure;
