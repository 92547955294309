import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../footer/Footer";
import Header from "../../landingPage/header/Header";
import HealthAndWelfareBody from "./HealthAndWelfareBody";
import firebase from "../../../config/firebase";
import { Dimmer, Loader } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";

export class HealthAndWelfare extends Component {
  state = {
    visibleFooter: "float-button",
    currentView: "info",
    stepNo: null,
    isLogged: false,
    loadingUser: true,
    dataLoading: true,
  };

  readData = async (uid) => {
    let db = firebase.firestore();
    let stepNo = 0;
    //reading donor info..........
    let donorRef = db.collection("healthLpaDonorInfo").doc(uid);
    let donorDoc = await donorRef.get();
    if (!donorDoc.exists) {
      // console.log("No such donorDocument!");
    } else {
      stepNo = 1;
      this.props.setHealthLpaDonor({
        ...donorDoc.data(),
        date_of_birth: donorDoc
          .data()
          .date_of_birth.toDate()
          .toLocaleDateString("fr-CA"),
      });
    }

    //reading when can be used info..........
    let lifeSustainingTreatmentRef = db
      .collection("healthLpaLifeSustainingTreatment")
      .doc(uid);
    let lifeSustainingTreatmentDoc = await lifeSustainingTreatmentRef.get();
    if (!lifeSustainingTreatmentDoc.exists) {
      // console.log("No such lifeSustainingTreatmentDoc document!");
    } else {
      stepNo = 2;
      let data = lifeSustainingTreatmentDoc.data().life_sustaining_treatment;
      // console.log(data);
      this.props.setHealthLpaLifeSustainingTreatment(data);
    }

    //reading Attorney info............
    let attorneyRef = db.collection("healthLpaAttorneyInfo").doc(uid);
    let attorneyDoc = await attorneyRef.get();
    if (!attorneyDoc.exists) {
      // console.log("No such attorneyDoc document!");
    } else {
      stepNo = 3;
      let data = attorneyDoc.data().attorney;
      // console.log(data);
      this.props.setHealthLpaAttorney(data);
    }

    //reading Attorney Make Decision...............
    let attorneyMakeDecisionRef = db
      .collection("healthLpaAttorneyMakeDecision")
      .doc(uid);
    let attorneyMakeDecisionDoc = await attorneyMakeDecisionRef.get();
    if (!attorneyMakeDecisionDoc.exists) {
      // console.log("No such attorneyMakeDecisionDoc!");
    } else {
      stepNo = 4;
      let data = {
        selected: attorneyMakeDecisionDoc.data().attorney_make_decision,
        details: attorneyMakeDecisionDoc.data().details,
      };
      // // console.log(data);
      this.props.setHealthLpaAttorneysMakeDecision(data);
    }

    //reading replacement Attorney info............
    let replacementAttorneyRef = db
      .collection("healthLpaReplacementAttorneyInfo")
      .doc(uid);
    let replacementAttorneyDoc = await replacementAttorneyRef.get();
    if (!replacementAttorneyDoc.exists) {
      // console.log("No such attorneyDoc document!");
    } else {
      stepNo = 5;
      let data = replacementAttorneyDoc.data().replacementAttorney;
      // console.log(data);
      this.props.setHealthLpaReplacementAttorney(data);
    }

    //reading Replacement Attorney Make Decision...............
    let replacementAttorneyMakeDecisionRef = db
      .collection("healthLpaReplacementAttorneyMakeDecision")
      .doc(uid);
    let replacementAttorneyMakeDecisionDoc = await replacementAttorneyMakeDecisionRef.get();
    if (!replacementAttorneyMakeDecisionDoc.exists) {
      // console.log("No such replacementAttorneyMakeDecisionDoc!");
    } else {
      stepNo = 6;
      let data = {
        selected: replacementAttorneyMakeDecisionDoc.data()
          .replacement_attorney_make_decision,
        details: replacementAttorneyMakeDecisionDoc.data().details,
      };
      // // console.log(data);
      this.props.setHealthLpaReplacemnetAttorneysMakeDecision(data);
    }

    // reading certificate provider info.............
    let certificateProviderRef = db
      .collection("healthLpaCertificateProviderInfo")
      .doc(uid);
    let certificateProviderDoc = await certificateProviderRef.get();
    if (!certificateProviderDoc.exists) {
      // console.log("No such certificateProviderDocument!");
    } else {
      stepNo = 7;
      this.props.setHealthLpaCertificateProvider({
        ...certificateProviderDoc.data(),
      });
    }

    //reading person to notify................

    let personToNotifyRef = db
      .collection("healthLpaPersonToNotifyInfo")
      .doc(uid);
    let personToNotifyDoc = await personToNotifyRef.get();
    if (!personToNotifyDoc.exists) {
      // console.log("No such attorneyDoc document!");
    } else {
      stepNo = 8;
      let data = personToNotifyDoc.data().person_to_notify;
      // console.log(data);
      this.props.setHealthLpaPersonToNotify(data);
    }

    //reading preferences and instructions...........
    let preferencesAndInstructionsRef = db
      .collection("healthLpaPreferencesAndInstructions")
      .doc(uid);
    let preferencesAndInstructionsDoc = await preferencesAndInstructionsRef.get();
    if (!preferencesAndInstructionsDoc.exists) {
      // console.log("No such preferencesAndInstructionsDoc!");
    } else {
      stepNo = 9;
      let data = {
        preferences: preferencesAndInstructionsDoc.data().preferences,
        instructions: preferencesAndInstructionsDoc.data().instructions,
      };
      // // console.log(data);
      this.props.setHealthLpaPreferencesAndInstructions(data);
    }

    //reading registration info
    let registrationRef = db.collection("healthLpaRegistrationInfo").doc(uid);
    let registrationDoc = await registrationRef.get();
    if (!registrationDoc.exists) {
      // console.log("No such registrationDoc!");
    } else {
      stepNo = 10;
      let data = {
        selected: registrationDoc.data().selected,
        attorney: registrationDoc.data().attorney,
      };
      // // console.log(data);
      this.props.setHealthLpaRegistration(data);
    }

    //reading correspondent info
    let correspondentRef = db.collection("healthLpaCorrespondentInfo").doc(uid);
    let correspondentDoc = await correspondentRef.get();
    if (!correspondentDoc.exists) {
      // console.log("No such correspondentDoc!");
    } else {
      stepNo = 11;
      this.props.setHealthLpaCorrespondent(correspondentDoc.data());
    }

    //reading repeat info
    let repeatRef = db.collection("healthLpaRepeatInfo").doc(uid);
    let repeatDoc = await repeatRef.get();
    if (!repeatDoc.exists) {
      // console.log("No such repeatDoc document!");

      this.props.setHealthLpaStep(stepNo);
      this.setState({ dataLoading: false });
    } else {
      stepNo = 12;
      let data = repeatDoc.data().case;
      // console.log(data);
      this.props.setHealthLpaRepeat(data);
      this.props.setHealthLpaStep(stepNo);
      this.setState({ dataLoading: false });
    }
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const db = firebase.firestore();
    var user = { ...firebase.auth().currentUser };
    // console.log(user.providerData);
    if (user !== null && user !== undefined && user.providerData !== undefined)
      user.providerData.forEach((item) => {
        if (item.providerId === "facebook.com") user.emailVerified = true;
      });
    if (user === null || !user.emailVerified) {
      this.props.history.push("/login");
    } else if (firebase.auth().currentUser !== null) {
      const userSnapshot = await db
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .get();
      // this.props.setUser({ currentUser: userSnapshot.data() });
      if (
        userSnapshot.exists &&
        userSnapshot.data()["health_lpa_id"] !== null &&
        userSnapshot.data()["health_lpa_id"] !== undefined &&
        userSnapshot.data()["health_lpa_id"].length > 0
      ) {
        this.setState({ loadingUser: false });
        this.readData(firebase.auth().currentUser.uid);
      } else {
        this.props.history.push("/health-welfare-info");
      }
    } else {
      this.props.history.push("/login");
    }
  }
  render() {
    if (this.state.loadingUser || this.state.dataLoading === true) {
      window.scrollTo(0, 0);
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    } else
      return (
        <div style={{ height: "100vh" }}>
          <Header />
          <HealthAndWelfareBody stepNo={this.props.stepNo} />
          <Footer />
        </div>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHealthLpaDonor: (donor) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_DONOR,
        payload: {
          donor: donor,
        },
      }),
    setHealthLpaLifeSustainingTreatment: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_LIFE_SUSTAINING_TREATMENT,
        payload: {
          lifeSustainingTreatment: data,
        },
      }),
    setHealthLpaAttorney: (attorney) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_ATTORNEY,
        payload: {
          attorney: attorney,
        },
      }),
    setHealthLpaAttorneysMakeDecision: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_ATTORNEYS_MAKE_DECISION,
        payload: {
          attorneysMakeDecision: data,
        },
      }),
    setHealthLpaReplacementAttorney: (replacementAttorney) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_REPLACEMENT_ATTORNEY,
        payload: {
          replacementAttorney: replacementAttorney,
        },
      }),
    setHealthLpaReplacemnetAttorneysMakeDecision: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION,
        payload: {
          replacementAttorneysMakeDecision: data,
        },
      }),
    setHealthLpaCertificateProvider: (certificateProvider) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_CERTIFICATE_PROVIDER,
        payload: {
          certificateProvider: certificateProvider,
        },
      }),
    setHealthLpaPersonToNotify: (personToNotify) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_PERSON_TO_NOTIFY,
        payload: {
          personToNotify: personToNotify,
        },
      }),
    setHealthLpaPreferencesAndInstructions: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_PREFERENCES_AND_INSTRUCTIONS,
        payload: {
          preferencesAndInstructions: data,
        },
      }),
    setHealthLpaRegistration: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_REGISTRATION,
        payload: {
          registration: data,
        },
      }),
    setHealthLpaCorrespondent: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_CORRESPONDENT,
        payload: {
          correspondent: data,
        },
      }),
    setHealthLpaRepeat: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_REPEAT,
        payload: {
          repeat: data,
        },
      }),
    setHealthLpaStep: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_STEP,
        payload: {
          stepNo: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    donor: state.health_lpa.donor,
    currentUser: state.user.currentUser,
    stepNo: state.health_lpa.stepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthAndWelfare);
