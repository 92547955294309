import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";

class PreferencesAndInstructions extends Component {
  state = {
    preferences: "",
    instructions: "",
  };

  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.preferencesAndInstructions !== null)
      this.setState({ ...this.props.preferencesAndInstructions });
  }

  onSubmitHandle = (e) => {
    e.preventDefault();
    let db = firebase.firestore();
    let financialLpaPreferencesAndInstructions = {
      preferences: this.state.preferences,
      instructions: this.state.instructions,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("financialLpaPreferencesAndInstructions")
      .doc(this.props.currentUser.uid)
      .set(financialLpaPreferencesAndInstructions)
      .then(() => {
        // console.log("FinancialLpaPreferencesAndInstructions in firestore");
      })
      .catch(() => {
        // console.log(
        //   "FinancialLpaPreferencesAndInstructions in firestore failed"
        // );
      });

    this.props.setFinancialLpaPreferencesAndInstructions({ ...this.state });
    this.props.setCurrentView(FinancialAffairsStepsEnum.REGISTRATION);
  };

  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Preferences and instructions
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            display: "block",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
          }}
        >
          <p>
            You've entered all the essential information needed for the LPA.
          </p>

          <p>
            The optional section below lets you add the donor’s preferences or
            instructions for their attorneys. The donor can state things that
            the attorneys must or must not do, or give guidance that they’d like
            their attorneys to follow.
          </p>
          <p>
            For example, the donor could add preferences about making charitable
            donations or managing their bank accounts. The donor could add
            instructions about giving gifts, managing investments or seeking
            professional tax advice.
          </p>
        </div>
        <Divider />
        <form onSubmit={this.onSubmitHandle}>
          <label
            htmlFor={"preferences"}
            className="col-form-label col-form-label "
            style={{
              marginTop: "1rem",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            Preferences
          </label>
          <p style={{ fontSize: "1.1rem", color: "gray" }}>
            These are things the donor would like their attorneys to think about
            when making decisions. Use words like 'prefer' or 'would like'.
          </p>
          <textarea
            rows="6"
            type="text"
            className="form-control"
            id={"preferences"}
            placeholder="Preferences"
            onChange={(e) => {
              this.setState({ preferences: e.target.value });
            }}
            value={this.state.preferences}
            name="preferences"
          />

          <label
            htmlFor={"instructions"}
            className="col-form-label col-form-label "
            style={{
              marginTop: "3rem",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            Instructions
          </label>

          <p style={{ fontSize: "1.1rem", color: "gray" }}>
            These are things that the attorneys MUST or MUST NOT do. Use words
            like 'must' or 'have to'
          </p>
          <textarea
            rows="6"
            type="text"
            className="form-control"
            id={"instructions"}
            placeholder="Instructions"
            onChange={(e) => {
              this.setState({ instructions: e.target.value });
            }}
            value={this.state.instructions}
            name="instructions"
          />

          <button className=" will-next-button" style={{ marginTop: "2rem" }}>
            {this.state.instructions === "" && this.state.preferences === "" ? (
              <span>Skip</span>
            ) : (
              <span>Save And Proceed</span>
            )}
          </button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaPreferencesAndInstructions: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_PREFERENCES_AND_INSTRUCTIONS,
        payload: {
          preferencesAndInstructions: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    preferencesAndInstructions: state.financial_lpa.preferencesAndInstructions,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferencesAndInstructions);
