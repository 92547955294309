import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";

class ReplacementAttorneysMakeDecision extends Component {
  state = {
    selected: null,
    details: "",
    error: "",
  };
  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.replacementAttorney.length === 1)
      this.setCurrentView(FinancialAffairsStepsEnum.REPLACEMENT_ATTORNEY);
    if (this.props.replacementAttorneysMakeDecision !== null)
      this.setState({ ...this.props.replacementAttorneysMakeDecision });
  }

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (this.state.selected === "" || this.state.selected === null) {
      // console.log("hello");
      this.setState({ error: "Please Select One" });
      return;
    } else {
      let db = firebase.firestore();
      let financialLpaReplacemnetAttorneyMakeDecision = {
        replacement_attorney_make_decision: this.state.selected,
        details: this.state.details,
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };

      db.collection("financialLpaReplacementAttorneyMakeDecision")
        .doc(this.props.currentUser.uid)
        .set(financialLpaReplacemnetAttorneyMakeDecision)
        .then(() => {
          // console.log(
          //   "FinancialLpaReplacementAttorneyMakeDecision in firestore"
          // );
        })
        .catch(() => {
          // console.log(
          //   "FinancialLpaReplacementAttorneyMakeDecision in firestore failed"
          // );
        });
      let data = { ...this.state };
      delete data.error;
      this.props.setFinancialLpaReplacemnetAttorneysMakeDecision(data);
      this.props.setCurrentView(FinancialAffairsStepsEnum.CERTIFICATE_PROVIDER);
    }
  };
  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          How should the replacement attorneys make decisions?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            display: "block",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
          }}
        >
          <p>
            The donor’s choice here is very important as it affects how their
            LPA can be used. Whichever option the donor chooses, the attorneys
            must always act in the donor’s best interests.
          </p>
        </div>
        <Divider />

        <form onSubmit={this.onSubmitHandle}>
          <h2>How should the attorneys make decisions?</h2>
          <div style={{ position: "relative" }}>
            <input
              type="radio"
              className=""
              id="jointlyAndSeverally"
              checked={this.state.selected === "jointlyAndSeverally" ? 1 : 0}
              onChange={() =>
                this.setState({ details: "", selected: "jointlyAndSeverally" })
              }
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="jointlyAndSeverally"
            />
            <label
              htmlFor="jointlyAndSeverally"
              // className="col-form-label col-form-label required -field"
              style={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              Jointly and severally
            </label>
          </div>
          <span
            style={{
              fontSize: "1.2rem",
              paddingLeft: "1rem",
              marginLeft: ".75rem",
              marginBottom: "2rem",
              marginTop: "1rem",
              //   borderLeft: "0.5rem solid grey",
              display: "block",
            }}
          >
            <p>
              Attorneys can make decisions on their own or together. Most people
              choose this option because it’s the most practical.
            </p>
          </span>
          <div style={{ position: "relative" }}>
            <input
              type="radio"
              className=""
              id="jointly"
              onChange={() =>
                this.setState({ details: "", selected: "jointly" })
              }
              checked={this.state.selected === "jointly" ? 1 : 0}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="jointly"
            />
            <label
              htmlFor="jointly"
              // className="col-form-label col-form-label required -field"
              style={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              Jointly
            </label>
          </div>

          <span
            style={{
              fontSize: "1.2rem",
              paddingLeft: "1rem",
              marginLeft: ".75rem",
              marginBottom: "2rem",
              marginTop: "1rem",
              //   borderLeft: "0.5rem solid grey",
              display: "block",
            }}
          >
            <p>
              Attorneys must agree unanimously on every decision, however big or
              small. Be careful - if one of the attorneys can no longer act,
              none of the other attorneys will be able to act either, unless the
              donor states otherwise in their instructions.
            </p>
          </span>

          <div style={{ position: "relative" }}>
            <input
              type="radio"
              className=""
              id="someJointlySomeSeverally"
              onChange={() =>
                this.setState({ selected: "someJointlySomeSeverally" })
              }
              checked={
                this.state.selected === "someJointlySomeSeverally" ? 1 : 0
              }
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="someJointlySomeSeverally"
            />
            <label
              htmlFor="someJointlySomeSeverally"
              // className="col-form-label col-form-label required -field"
              style={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                // top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
                lineHeight: "1.5rem",
              }}
            >
              Jointly for some decisions, and jointly and severally for other
              decisions
            </label>
          </div>

          <span
            style={{
              fontSize: "1.2rem",
              paddingLeft: "1rem",
              marginLeft: ".75rem",
              marginBottom: "2rem",
              marginTop: "2.5rem",
              //   borderLeft: "0.5rem solid grey",
              display: "block",
            }}
          >
            <p>
              Attorneys must agree unanimously on some decisions, but can make
              others on their own. The donor must state which decisions need to
              be agreed unanimously.
            </p>
          </span>
          {this.state.selected === "someJointlySomeSeverally" ? (
            <span
              style={{
                fontSize: "1.2rem",
                paddingLeft: "1rem",
                marginLeft: ".75rem",
                marginBottom: "1.5rem",
                marginTop: "3rem",
                borderLeft: "0.5rem solid grey",
                display: "block",
              }}
            >
              <p>
                Please tell us which decisions the donor wants to be made
                jointly. These decisions will be printed on a extra sheet that
                the donor will need to sign and date. Take a look at the
                guidance for examples of how the donor can make their wishes
                clear.
              </p>
              <label
                htmlFor={"details"}
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Details
              </label>
              <textarea
                rows="6"
                type="text"
                className="form-control"
                id={"details"}
                placeholder="Details"
                onChange={(e) => {
                  this.setState({ details: e.target.value });
                }}
                value={this.state.details}
                name="details"
                required
              />
            </span>
          ) : (
            <span></span>
          )}

          {this.state.error === "" ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                marginTop: "3rem",
              }}
            >
              {this.state.error}
            </div>
          )}
          <button className=" will-next-button">Save And Proceed</button>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaReplacemnetAttorneysMakeDecision: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_REPLACEMENT_ATTORNEYS_MAKE_DECISION,
        payload: {
          replacementAttorneysMakeDecision: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    replacementAttorney: state.financial_lpa.replacementAttorney,
    replacementAttorneysMakeDecision:
      state.financial_lpa.replacementAttorneysMakeDecision,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementAttorneysMakeDecision);
