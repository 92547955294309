import React, { Component } from "react";
import { Button, Divider, Icon, Popup } from "semantic-ui-react";
import PlaceholderImage from "../../images/placeholder.png";
import DpPlaceholderImage from "../../images/dp-placeholder.png";
import UploadPhotoModal from "./UploadPhotoModal";
import firebase from "../../config/firebase";
import UploadPhoneNumberModal from "./UploadPhoneNumberModal";
import UploadGovtIDModal from "./UploadGovtIDModal";
import { connect } from "react-redux";
import ChangePasswordModal from "./ChangePasswordModal";
import UploadProofOfAddressModal from "./UploadProofOfAddressModal";
import "../../css/user.css";
class BasicInfo extends Component {
  state = {
    profilePhotoUrl: null,
    phoneNumber: null,
    govtID: null,
    proofOfAddress: null,
    passwordChangeMsg: "",
  };
  componentDidMount() {
    // // console.log(firebase.auth().currentUser);
    // let db = firebase.firestore();
    // let userRef = db.collection("users").doc(this.props.user.uid);
    // let userDoc = await userRef.get();
    // if (!userDoc.exists) {
    //   // console.log("No such user Document!");
    // } else {
    //   // console.log("found user doc");
    // console.log(this.props.userDoc);
    this.setState({
      profilePhotoUrl: this.props.userDoc.profile_photo,
      phoneNumber: this.props.userDoc.phone,
      govtID: this.props.userDoc.government_id,
      proofOfAddress: this.props.userDoc.proof_of_address,
    });
    // }
  }
  setProfilePhotoUrl = (url) => {
    this.setState({ profilePhotoUrl: url });
  };
  setPhoneNumber = (number) => {
    this.setState({ phoneNumber: number });
  };
  setGovtID = (url) => {
    this.setState({ govtID: url });
  };
  setProofOfAddress = (url) => {
    this.setState({ proofOfAddress: url });
  };

  onClickPassChange = () => {
    var auth = firebase.auth();

    auth
      .sendPasswordResetEmail(this.props.user.email)
      .then(() => {
        // Email sent.
        this.setState({
          passwordChangeMsg:
            "A password reset link has been sent to you. Please check your email.",
        });
        // console.log("check mail");
      })
      .catch((error) => {
        // An error happened.
        this.setState({
          passwordChangeMsg: "Cant Reset. Unknown error occurred.",
        });
        // console.log("cant reset , " + error.message);
      });
  };

  render() {
    return (
      <div className="col-md-9 col-10">
        <h1 className="aboutus-section-heading">Basic Info</h1>
        <div className="basicinfo-container">
          <div className="d-flex justify-content-center">
            <div style={{ position: "relative" }}>
              <img
                src={
                  this.state.profilePhotoUrl === null ||
                  this.state.profilePhotoUrl === undefined
                    ? DpPlaceholderImage
                    : this.state.profilePhotoUrl
                }
                className="rounded-circle"
                alt="Avatar"
                width="150"
                height="150"
              />

              <button
                type="button"
                className="profile-upload-overlay"
                style={{
                  border: "none",
                  padding: "0",
                }}
                data-toggle="modal"
                data-target="#uploadProfilePhotoModal"
              >
                <Icon
                  className="profile-overlay-image"
                  name="pencil"
                  size="big"
                ></Icon>
              </button>

              <UploadPhotoModal
                user={this.props.user}
                setProfilePhotoUrl={this.setProfilePhotoUrl}
              />
              <Popup
                content="In order to comply with Anti-Money Laundering legislation, we must verify the identity of all our customers. Here simply upload a your photograph."
                trigger={
                  <div className="mytooltip">
                    <Icon className="question circle align-items-end"></Icon>
                  </div>
                }
                position="right center"
              />
              <Button
                icon="upload"
                data-toggle="modal"
                data-target="#uploadProfilePhotoModal"
                className="ml-4 upload-btn"
              ></Button>
            </div>
          </div>
          <div className="d-flex justify-content-center m-md-5 m-0 text-center text-md-left">
            <div className="container" style={{ fontSize: "1.25rem" }}>
              <Divider />
              <div className="row m-3 ">
                <div className="col-md-4 font-weight-bold mb-md-0 mb-3">
                  Username
                </div>
                <div className="col-md-8">{this.props.user.displayName}</div>
              </div>
              <Divider />
              <div className="row m-3 ">
                <div className="col-md-4 font-weight-bold mb-md-0 mb-3">
                  Email
                </div>
                <div className="col-md-8">{this.props.user.email}</div>
              </div>
              <Divider />
              <div className="row m-3 ">
                <div className="col-md-4 font-weight-bold mb-md-0 mb-3">
                  Password
                </div>
                <div className="col-md-8">
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                    }}
                    data-toggle="modal"
                    data-target="#changePasswordModal"
                    onClick={this.onClickPassChange}
                  >
                    <div className="row">
                      <div className="col-md-4 text-md-left text-center">
                        ****
                      </div>
                      <div className="col-md-8">
                        <Icon name={"edit"}></Icon>Change Your Password
                      </div>
                    </div>
                  </button>
                  <ChangePasswordModal message={this.state.passwordChangeMsg} />
                </div>
              </div>
              <Divider />
              <div className="row m-3 ">
                <div className="col-md-4 font-weight-bold mb-md-0 mb-3">
                  Phone Number
                </div>
                <div className="col-md-8">
                  <button
                    type="button"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    data-toggle="modal"
                    data-target="#uploadPhoneNumberModal"
                  >
                    <Icon
                      name={
                        this.state.phoneNumber === null ||
                        this.state.phoneNumber === undefined ||
                        this.state.phoneNumber?.length === 0
                          ? "edit"
                          : "phone"
                      }
                    ></Icon>
                    {this.state.phoneNumber === null ||
                    this.state.phoneNumber === undefined ||
                    this.state.phoneNumber?.length === 0
                      ? "Please Add your Phone Number"
                      : this.state.phoneNumber}
                  </button>

                  <UploadPhoneNumberModal
                    user={this.props.user}
                    setPhoneNumber={this.setPhoneNumber}
                  />
                </div>
              </div>
              <Divider />
              <div className="row m-3  d-flex align-items-center">
                <div className="col-md-4 font-weight-bold mb-md-0 mb-3">
                  Government ID
                </div>
                <div className="col-md-8">
                  <div style={{ position: "relative" }}>
                    <img
                      src={
                        this.state.govtID === null ||
                        this.state.govtID === undefined
                          ? PlaceholderImage
                          : this.state.govtID
                      }
                      className="rounded basicinfo-square-image"
                      alt="ID Card"
                    />
                    {/* <button
                      type="button"
                      className="square-upload-overlay"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      data-toggle="modal"
                      data-target="#uploadGovtIDModal"
                    >
                      <Icon
                        className="square-overlay-image"
                        name="pencil"
                        size="big"
                      ></Icon>
                    </button> */}
                    <UploadGovtIDModal
                      user={this.props.user}
                      setGovtID={this.setGovtID}
                    />
                    <Popup
                      content=" In order to comply with Anti-Money Laundering legislation, we must verify the identity of all our customers. Here simply upload a copy of your current valid national Passport or Photographic Drivers Licence or national Identity Card that has a photograph of you. We may need to verify this at a later stage via a Zoom meeting or face to face video-conference call.
                      "
                      trigger={
                        <div className="mytooltip">
                          <Icon className="question circle align-items-end"></Icon>
                        </div>
                      }
                      position="right center"
                    />
                    <Button
                      icon="upload"
                      data-toggle="modal"
                      data-target="#uploadGovtIDModal"
                      className="ml-4 upload-btn"
                    ></Button>
                    {/* <div className="mytooltip">
                      <Icon className="question circle"></Icon>
                      <span className="tooltiptext">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Ipsa voluptatibus qui delectus quod atque eligendi
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <Divider />
              <div className="row m-3  d-flex align-items-center">
                <div className="col-md-4 font-weight-bold mb-md-0 mb-3">
                  Proof Of Address
                </div>
                <div className="col-md-8">
                  <div style={{ position: "relative" }}>
                    <img
                      src={
                        this.state.proofOfAddress === null ||
                        this.state.proofOfAddress === undefined
                          ? PlaceholderImage
                          : this.state.proofOfAddress
                      }
                      className="rounded basicinfo-square-image"
                      alt="ID Card"
                    />

                    {/* <button
                      type="button"
                      className="square-upload-overlay"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      data-toggle="modal"
                      data-target="#uploadProofOfAddressModal"
                    >
                      <Icon
                        className="square-overlay-image"
                        name="pencil"
                        size="big"
                      ></Icon>
                    </button> */}
                    <UploadProofOfAddressModal
                      user={this.props.user}
                      setProofofAddress={this.setProofOfAddress}
                    />
                    <Popup
                      content="For AML compliance we need an up to date (No more than three months old) evidence of proof of address. This could be any document document such as - Bank Statement, Utility Bill, Current yearly Council Tax Demand, Government department letter from HMRC or DWP etc. 
                      "
                      trigger={
                        <div className="mytooltip">
                          <Icon className="question circle align-items-end"></Icon>
                        </div>
                      }
                      position="right center"
                    />

                    <Button
                      icon="upload"
                      data-toggle="modal"
                      data-target="#uploadProofOfAddressModal"
                      className="ml-4 upload-btn"
                    ></Button>
                    {/* <div className="mytooltip">
                      <Icon name="question circle"></Icon>
                      <span className="tooltiptext">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Ipsa voluptatibus qui delectus quod atque eligendi
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDoc: state.user.userDoc,
    userDocLoading: state.user.userDocLoading,
  };
};

export default connect(mapStateToProps)(BasicInfo);
