import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "../config/firebase";
import { Dimmer, Loader } from "semantic-ui-react";
import * as actionTypes from "../actions/types";
import LandingPage from "./landingPage/LandingPage";
import AboutUs from "./AboutUs/AboutUs";
import Register from "./Auth/Register";
import ContactUs from "./ContactUs/ContactUs";
import UserProfile from "./UserProfile/UserProfile";

import SingleWill from "./Will/SingleWill/SingleWill";
import SingleWillInfo from "./Will/SingleWill/SingleWillInfo";
import TermsNConditions from "./TNC/TermsNConditions";
import DoubleWill from "./Will/DoubleWill/DoubleWill";
import DoubleWillInfo from "./Will/DoubleWill/DoubleWillInfo";
import FinancialAffairs from "./LPA/FinancialAffairs/FinancialAffairs";
import HealthAndWelfare from "./LPA/HealthAndWelfare/HealthAndWelfare2";
import Verification from "./Auth/Verification";
import Login from "./Auth/Login";
import Payment from "./Checkout/Payment";
import Success from "./Checkout/Success";
import Failure from "./Checkout/Failure";
import FinancialAffairsInfo from "./LPA/FinancialAffairs/FinancialAffairsInfo";
import HealthAndWelfareInfo from "./LPA/HealthAndWelfare/HealthAndWelfareInfo";
import OurMembers from "./AboutUs/OurMembers";
import Pricing from "./Pricing/Pricing";
import Complaints from "./Complaints/Complaints";

class App extends React.Component {
  async componentDidMount() {

    if (process.env.REACT_APP_ENV === "development") {
      console.log("Running development build");
    }

    if (process.env.TEST_ENV === "NOTFUCKINGUNDEFINED") {
      console.log("TEST HELLO")

    }

    firebase.auth().onAuthStateChanged(async (signedInUser) => {
      if (signedInUser) {
        this.props.setUser({ ...signedInUser });
        let db = firebase.firestore();
        let userRef = db.collection("users").doc(signedInUser.uid);
        let userDoc = await userRef.get();
        if (!userDoc.exists) {
          // console.log("No such user Document!");
          this.props.setUserDoc({
            profile_photo: null,
            phone: null,
            government_id: null,
            proof_of_address: null,
          });
        } else {
          // console.log("found user doc");

          this.props.setUserDoc({
            profile_photo: userDoc.data().profile_photo,
            phone: userDoc.data().phone,
            government_id: userDoc.data().government_id,
            proof_of_address: userDoc.data().proof_of_address,
          });

          //   this.setState({
          // profilePhotoUrl: userDoc.data().profile_photo,
          // phoneNumber: userDoc.data().phone,
          // govtID: userDoc.data().government_id,
          //   });
        }
      } else {
        this.props.clearUser();
      }
    });
  }
  render() {
    if (this.props.loading) {
      return (
        <div>
          <div>
            <Dimmer active>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </div>
          <div style={{ height: "50vh" }}></div>
        </div>
      );
    } else {
      return (
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/aboutus" component={AboutUs} />
          <Route exact path="/ourmember" component={OurMembers} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/auth/verification" component={Verification} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/user" component={UserProfile} />
          <Route exact path="/singlewill" component={SingleWill} />
          <Route path="/single-will-info" component={SingleWillInfo} />
          <Route exact path="/doublewill" component={DoubleWill} />
          <Route exact path="/double-will-info" component={DoubleWillInfo} />
          <Route exact path="/financialaffairs" component={FinancialAffairs} />
          <Route
            path="/financial-affairs-info"
            component={FinancialAffairsInfo}
          />
          <Route exact path="/healthnwelfare" component={HealthAndWelfare} />
          <Route path="/health-welfare-info" component={HealthAndWelfareInfo} />
          <Route path="/tnc" component={TermsNConditions} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/complaints" component={Complaints}/>
          <Route
            exact
            path="/checkout/payment/:product/:id"
            component={Payment}
          />
          <Route exact path="/checkout/success/:id" component={Success} />
          <Route exact path="/checkout/failure/:id" component={Failure} />
        </Switch>
      );
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) =>
      dispatch({
        type: "SET_USER",
        payload: {
          currentUser: user,
        },
      }),
    setUserDoc: (doc) =>
      dispatch({
        type: actionTypes.SET_USER_DOC,
        payload: {
          userDoc: doc,
        },
      }),
    clearUser: () =>
      dispatch({
        type: "CLEAR_USER",
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.user.isLoading,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
