import React, { Component } from "react";
import RemainingEstateAddBeneficiary from "./RemainingEstateAddBeneficiary";
import RemainingEstateInfoSteps from "./RemainingEstateInfoSteps";
import RemainingEstateSelect from "./RemainingEstateSelect";
import RemainingEstateSubBeneficiary from "./RemainingEstateSubBeneficiary";
import firebase from "../../../../config/firebase";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

class RemainingEstateInfo extends Component {
  state = { remainingEstateInfoCurrentView: "select" };
  setRemainingEstateInfoView = (view) => {
    this.setState({ remainingEstateInfoCurrentView: view });
  };
  componentDidMount() {
    if (this.props.partnerStepNo < 8) this.props.setPartnerStepNo(8);
  }
  onClickSame = () => {
    if (this.props.userRemainingEstateInfo !== null) {
      let db = firebase.firestore();
      db.collection("partnerRemainingEstateInfo")
        .doc(this.props.currentUser.uid)
        .set({
          ...this.props.userRemainingEstateInfo,
        })
        .then(() => {
          // console.log("remaining estate info in firestore");
        })
        .catch(() => {
          // console.log("remaining estate info in firestore failed");
        });
      this.props.setPartnerRemainingEstateInfo({
        ...this.props.userRemainingEstateInfo,
      });
      this.props.setCurrentView("executorInfo");
    }
  };

  render() {
    let currentViewComponent;
    if (this.state.remainingEstateInfoCurrentView === "select")
      currentViewComponent = (
        <RemainingEstateSelect
          setCurrentView={this.setRemainingEstateInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.remainingEstateInfoCurrentView === "add")
      currentViewComponent = (
        <RemainingEstateAddBeneficiary
          setCurrentView={this.setRemainingEstateInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.remainingEstateInfoCurrentView === "substitute")
      currentViewComponent = (
        <RemainingEstateSubBeneficiary
          setCurrentView={this.setRemainingEstateInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.remainingEstateInfoCurrentView === "next") {
      this.props.setCurrentView("executorInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Partner's Remaining Estate</h1>

        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            className="will-button blue-will-button"
            style={{
              width: "70%",
              letterSpacing: "0.1rem",
              wordSpacing: ".2rem",
              fontSize: "1.2rem",
            }}
            onClick={this.onClickSame}
          >
            Same as Partner
          </button>
        </div>
        <Divider horizontal>Or</Divider>

        <RemainingEstateInfoSteps
          setCurrentView={this.setRemainingEstateInfoView}
          currentView={this.state.remainingEstateInfoCurrentView}
        />
        {currentViewComponent}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_REMAINING_ESTATE_INFO,
        payload: {
          partnerRemainingEstateInfo: remainingEstateInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userRemainingEstateInfo: state.user.remainingEstateInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemainingEstateInfo);
