import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";

class RemainingEstateSubBeneficiary extends Component {
  state = {
    substitute_beneficiary: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      substitute_beneficiary: this.props.remainingEstateInfo
        .substitute_beneficiary,
    });
  }

  onClick = (e) => {
    // // console.log(e.target.name);
    this.setState({ substitute_beneficiary: e.target.name });
    this.props.setRemainingEstateInfo({
      ...this.props.remainingEstateInfo,

      substitute_beneficiary: e.target.name,
    });

    let db = firebase.firestore();
    db.collection("remainingEstateInfo")
      .doc(this.props.currentUser.uid)
      .set({
        ...this.props.remainingEstateInfo,
        user_id: db.doc("users/" + this.props.currentUser.uid),
        substitute_beneficiary: e.target.name,
      })
      .then(() => {
        // console.log("remaining estate info in firestore");
      })
      .catch(() => {
        // console.log("remaining estate info in firestore failed");
      });
    this.props.setCurrentView("next");
  };

  //   onClickNext = () => {
  //     this.props.setRemainingEstateInfo({
  //       ...this.props.remainingEstateInfo,
  //       ...this.state,
  //     });
  //     if (this.state.spouse_all_in || this.state.children_all_in) {
  //       this.props.setCurrentView("next");
  //     } else this.props.setCurrentView("add");
  //   };

  render() {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginTop: "2.5rem",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              display: "block",
              marginBottom: "4rem",
            }}
          >
            Who should receive your estate upon the death of your
            beneficiary(s)?
          </span>
          <div>
            <button
              className={
                this.state.substitute_beneficiary === "children"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="children"
            >
              Children
            </button>
          </div>
          <div>
            <button
              className={
                this.state.substitute_beneficiary ===
                "children and step children"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="children and step children"
            >
              Children and Step Children
            </button>
          </div>
          <div>
            <button
              className={
                this.state.substitute_beneficiary === "spouse"
                  ? "will-button-selected"
                  : "will-button"
              }
              style={{ marginBottom: "1.3rem", width: "50%" }}
              onClick={this.onClick}
              name="spouse"
            >
              Spouse
            </button>
          </div>
        </div>

        {/* <button
          className=" will-next-button"
          style={{ marginTop: "1rem" }}
          onClick={this.onClickNext}
        >
          Next
        </button> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRemainingEstateInfo: (remainingEstateInfo) =>
      dispatch({
        type: actionTypes.SET_USER_REMAINING_ESTATE_INFO,
        payload: {
          userRemainingEstateInfo: remainingEstateInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    remainingEstateInfo: state.user.remainingEstateInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemainingEstateSubBeneficiary);
