import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Step } from "semantic-ui-react";

export class SingleWillSteps extends Component {
  disablePartnerInfo = true;
  disableChildrenInfo = true;
  disableGuardianInfo = true;
  disableLiquidAssetInfo = true;
  disableRealAssetInfo = true;
  disableGeneralConcernInfo = true;
  disableGiftInfo = true;
  disableRemainingEstateInfo = true;
  disableExecutorInfo = true;
  disableRequestInfo = true;
  disableSubmit = true;

  componentDidMount() {
    this.goToStep();
  }

  isMinor = () => {
    let minorCheck = false;
    if (this.props.childrenInfo !== null)
      this.props.childrenInfo.info.forEach((x) => {
        if (x.age < 18) minorCheck = true;
      });
    return minorCheck;
  };

  goToStep = () => {
    for (let index = 1; index <= this.props.stepNo; index++) {
      if (index === 1) {
        this.disablePartnerInfo = false;
      } else if (index === 2) {
        this.disableChildrenInfo = false;
      } else if (index === 3) {
        this.disableGuardianInfo = false;
      } else if (index === 4) {
        this.disableLiquidAssetInfo = false;
      } else if (index === 5) {
        this.disableRealAssetInfo = false;
      } else if (index === 6) {
        this.disableGeneralConcernInfo = false;
      } else if (index === 7) {
        this.disableGiftInfo = false;
      } else if (index === 8) {
        this.disableRemainingEstateInfo = false;
      } else if (index === 9) {
        this.disableExecutorInfo = false;
      } else if (index === 10) {
        this.disableRequestInfo = false;
      } else if (index === 11) {
        this.disableSubmit = false;
      }
    }
  };
  render() {
    // console.log(this.isMinor());
    if (this.props.currentView === "partnerInfo")
      this.disablePartnerInfo = false;
    else if (this.props.currentView === "childrenInfo")
      this.disableChildrenInfo = false;
    else if (this.props.currentView === "guardianInfo")
      this.disableGuardianInfo = false;
    else if (this.props.currentView === "liquidAssetInfo")
      this.disableLiquidAssetInfo = false;
    else if (this.props.currentView === "realAssetInfo")
      this.disableRealAssetInfo = false;
    else if (this.props.currentView === "generalConcernInfo")
      this.disableGeneralConcernInfo = false;
    else if (this.props.currentView === "giftInfo")
      this.disableGiftInfo = false;
    else if (this.props.currentView === "remainingEstateInfo")
      this.disableRemainingEstateInfo = false;
    else if (this.props.currentView === "executorInfo")
      this.disableExecutorInfo = false;
    else if (this.props.currentView === "requestInfo")
      this.disableRequestInfo = false;
    else if (this.props.currentView === "submit") this.disableSubmit = false;
    return (
      <Step.Group vertical>
        <Step
          active={this.props.currentView === "personalInfo" ? true : false}
          link
          onClick={() => this.props.setCurrentView("personalInfo")}
          className={"step-mini"}
        >
          <Icon name="user" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Personal Info</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={
            this.disablePartnerInfo ||
            (this.props.personalInfo !== null &&
              this.props.personalInfo.marital_status === "Single")
          }
          active={this.props.currentView === "partnerInfo" ? true : false}
          onClick={() => this.props.setCurrentView("partnerInfo")}
          className={"step-mini"}
        >
          <Icon name="heart" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Spouse Or Partner</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableChildrenInfo}
          active={this.props.currentView === "childrenInfo" ? true : false}
          onClick={() => this.props.setCurrentView("childrenInfo")}
          className={"step-mini"}
        >
          <Icon name="child" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Children</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={
            this.disableGuardianInfo ||
            (this.props.childrenInfo !== null &&
              this.props.childrenInfo.number === 0) ||
            !this.isMinor()
          }
          active={this.props.currentView === "guardianInfo" ? true : false}
          onClick={() => this.props.setCurrentView("guardianInfo")}
          className={"step-mini"}
        >
          <Icon name="users" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Guardian Preferences</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableLiquidAssetInfo}
          active={this.props.currentView === "liquidAssetInfo" ? true : false}
          onClick={() => this.props.setCurrentView("liquidAssetInfo")}
          className={"step-mini"}
        >
          <Icon name="money bill alternate" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Liquid Asset</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableRealAssetInfo}
          active={this.props.currentView === "realAssetInfo" ? true : false}
          onClick={() => this.props.setCurrentView("realAssetInfo")}
          className={"step-mini"}
        >
          <Icon name="home" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Real Asset</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableGeneralConcernInfo}
          active={
            this.props.currentView === "generalConcernInfo" ? true : false
          }
          onClick={() => this.props.setCurrentView("generalConcernInfo")}
          className={"step-mini"}
        >
          <Icon name="exclamation" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>General Concern</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableGiftInfo}
          active={this.props.currentView === "giftInfo" ? true : false}
          onClick={() => this.props.setCurrentView("giftInfo")}
          className={"step-mini"}
        >
          <Icon name="gift" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Gifts</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableRemainingEstateInfo}
          active={
            this.props.currentView === "remainingEstateInfo" ? true : false
          }
          onClick={() => this.props.setCurrentView("remainingEstateInfo")}
          className={"step-mini"}
        >
          <Icon name="building" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Remaining Estate</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableExecutorInfo}
          active={this.props.currentView === "executorInfo" ? true : false}
          onClick={() => this.props.setCurrentView("executorInfo")}
          className={"step-mini"}
        >
          <Icon name="handshake" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Executors</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableRequestInfo}
          active={this.props.currentView === "requestInfo" ? true : false}
          onClick={() => this.props.setCurrentView("requestInfo")}
          className={"step-mini"}
        >
          <Icon name="tasks" className="mt-1 mb-1 " />
          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Your Funeral Requests</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableSubmit}
          active={this.props.currentView === "submit" ? true : false}
          onClick={() => this.props.setCurrentView("submit")}
          className={"step-mini"}
        >
          <Icon name="check" className="mt-1 mb-1 " />

          <Step.Content className="d-none d-md-flex ">
            <Step.Title>Submit</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    personalInfo: state.user.personalInfo,
    childrenInfo: state.user.childrenInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps)(SingleWillSteps);
