import React, { Component } from "react";
import AddExecutor from "./AddExecutor";
import ExecutorInfoSteps from "./ExecutorInfoSteps";
import SelectExecutor from "./SelectExecutor";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import Modal from "../Modal";
import * as helpTextList from "../../HelpTextList";

class ExecutorInfo extends Component {
  state = { executorInfoCurrentView: "select" };
  setExecutorInfoView = (view) => {
    this.setState({ executorInfoCurrentView: view });
  };
  componentDidMount() {
    if (this.props.stepNo < 9) this.props.setStepNo(9);
  }
  render() {
    let currentViewComponent;
    if (this.state.executorInfoCurrentView === "select")
      currentViewComponent = (
        <SelectExecutor
          setCurrentView={this.setExecutorInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.executorInfoCurrentView === "add")
      currentViewComponent = (
        <AddExecutor
          setCurrentView={this.setExecutorInfoView}
          visibleFooter={this.props.visibleFooter}
        />
      );
    else if (this.state.executorInfoCurrentView === "next") {
      this.props.setCurrentView("requestInfo");
    }

    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Your Executors</h1>
        <ExecutorInfoSteps
          setCurrentView={this.setExecutorInfoView}
          currentView={this.state.executorInfoCurrentView}
        />
        {currentViewComponent}
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal title="Executor Info" body={helpTextList.executorText} />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    executorInfo: state.user.executorInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutorInfo);
