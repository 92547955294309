import React, { Component } from "react";
// import Scrollspy from "react-scrollspy";

import "../../css/navigation.css";
import BasicInfo from "./BasicInfo";
// import FAQs from "./FAQs";
import OurMembers from "./OurMembers";

class AboutUsBody extends Component {
  state = {
    isVisible: "",
    navContainerPos: "",
    message: "",
  };
  handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      // console.log("bottom");
    }
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
      this.setState({ navContainerPos: " top" });
    } else {
      this.setState({ navContainerPos: "" });
    }
  };
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }
  render() {
    // Commented out, FAQ, Board sections because alef bhaia did not provide any info for these.
    return (
      <div className="body-container">
        {/* <div
          className={
            this.props.navDisplay +
            " navigation-container aboutus-list" +
            this.state.navContainerPos
          }
          style={{ background: "#e7e9ec" }}
        >

          <h2>ABOUT US</h2>

          <Scrollspy
            items={["basic-info", "FAQs", "our-members"]}
            currentClassName="current"
            className="navigation-items"
          >
            <li>
              <a href="#basic-info">
                <i className="info circle icon" /> Basic Info
              </a>
            </li>
            <li>
              <a href="#FAQs">
                <i className="question circle icon" /> FAQs
              </a>
            </li>
            <li>
              <a href="#our-members">
                <i className="users icon" /> Our Members
              </a>
            </li>
          </Scrollspy>
        </div> */}
        <div>
          <BasicInfo />
          {/*{<FAQs />*/}
          <OurMembers />}
        </div>

        {/* <VizSensor
          onChange={(isVisible) => {
            if (isVisible) this.setState({ isVisible: "doNotDisplay" });
            else this.setState({ isVisible: "" });
          }}
        >
          <div style={{ height: "3rem" }}></div>
        </VizSensor> */}
      </div>
    );
  }
}

export default AboutUsBody;
