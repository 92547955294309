import React, { Component } from "react";

import firebase from "../../../../config/firebase";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";

class ChildrenCount extends Component {
  state = {
    children: null,
    isChildNumberChanged: false,
    error: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.childrenInfo != null) {
      this.setState({
        children: this.props.childrenInfo.number,
      });
    }
  }
  onChangeHandler = (e) => {
    if (e.target.value === "")
      this.setState({
        children: e.target.value,
      });
    else if (
      this.props.childrenInfo !== null &&
      Number(e.target.value) === this.props.childrenInfo.number
    ) {
      // console.log("igotdata and they are equal");
      this.setState({
        children: Number(e.target.value),
        isChildNumberChanged: false,
      });
    } else if (this.props.childrenInfo === null) {
      // console.log("igot no data");
      this.setState({
        children: Number(e.target.value),
        isChildNumberChanged: true,
      });
    } else if (
      this.props.childrenInfo !== null &&
      Number(e.target.value) !== this.props.childrenInfo.number
    ) {
      // console.log("i got data and they are not equal");
      this.setState({
        children: Number(e.target.value),
        isChildNumberChanged: true,
      });
    }
  };

  clearGueardianInfo = async () => {
    let db = firebase.firestore();
    this.props.setGuardianInfo(null); // clearing prev guardian info

    db.collection("guardianInfo")
      .doc(this.props.currentUser.uid)
      .delete()
      .then(() => {
        // console.log("guardian info cleared");
      })
      .catch(() => {
        // console.log("no guardian info to delete");
      });
  };

  onSubmitHandleChildrenNumberForm = (e) => {
    e.preventDefault();
    if (this.state.children != null && this.state.children < 0) {
      this.setState({ error: "Children Number can not be negative" });
    } else if (this.state.children != null && this.state.children === 0) {
      this.props.setChildrenInfo({
        number: this.state.children,
        info: [],
      });
      let db = firebase.firestore();

      let childrenInfo = {
        children_count: 0,
        children_details: [],
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };

      db.collection("childrenInfo")
        .doc(this.props.currentUser.uid)
        .set(childrenInfo)
        .then(() => {
          // console.log("children info in firestore");
        })
        .catch(() => {
          // console.log("children info in firestore failed");
        });
      this.clearGueardianInfo();
      this.props.setCurrentView("liquidAssetInfo");
    } else {
      if (this.props.childrenInfo === null || this.state.isChildNumberChanged) {
        let tempChildrenInfo = [];
        for (let index = 0; index < this.state.children; index++) {
          tempChildrenInfo[index] = { name: "", age: null, gender: null };
        }
        this.setState({
          error: null,
          childrenNumberProvided: true,
          childrenInfo: tempChildrenInfo,
          isChildNumberChanged: false,
        });

        this.props.setChildrenInfo({
          number: this.state.children,
          info: tempChildrenInfo,
        });
        window.scrollTo(0, 0);
        this.props.setCurrentView("childrenInfo");
      } else {
        this.setState({
          error: null,
          childrenNumberProvided: true,
        });
        window.scrollTo(0, 0);
        this.props.setCurrentView("childrenInfo");
      }
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmitHandleChildrenNumberForm}>
          <label
            htmlFor="children"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How many children do you have?
          </label>
          <input
            type="number"
            step="1"
            className="form-control"
            id="children"
            placeholder="Children no"
            onChange={this.onChangeHandler}
            value={this.state.children === 0 ? 0 : this.state.children || ""}
            name="children"
            required
          />
          {this.state.error === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">{this.state.error}</div>
          )}
          <button className=" will-next-button">Next</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_USER_CHILDREN_INFO,
        payload: {
          userChildrenInfo: childrenInfo,
        },
      }),
    setGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_USER_GUARDIAN_INFO,
        payload: {
          userGuardianInfo: guardianInfo,
        },
      }),
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    childrenInfo: state.user.childrenInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenCount);
