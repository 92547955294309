import React, { Component } from "react";

import * as actionTypes from "../../../actions/types";
import "../../../css/will.css";
import Modal from "./Modal";

import { connect } from "react-redux";
import firebase from "../../../config/firebase";

export class PersonalInfo extends Component {
  state = {
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    previous_last_name: "",
    gender: "",
    marital_status: "",
    address: "",
    mobile: "",
    email: "",
    date_of_birth: "",
  };

  titleList = ["Mr", "Mrs", "Ms", "Miss", "Professor", "Dr"];
  genderList = ["Male", "Female", "Non-binary"];
  maritalStatusList = [
    "Single",
    "Married",
    "Widowed",
    "Divorced",
    "Separated",
    "Living with Partner",
    "Partner (Living Separately)",
    "Civil Partnership",
  ];

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandle = (e) => {
    e.preventDefault();
    let db = firebase.firestore();
    let personalInfo = {
      ...this.state,
      date_of_birth: firebase.firestore.Timestamp.fromDate(
        new Date(this.state.date_of_birth)
      ),
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };

    db.collection("personalInfo")
      .doc(this.props.currentUser.uid)
      .set(personalInfo)
      .then(() => {
        // console.log("personal info in firestore");
      })
      .catch(() => {
        // console.log("personal info in firestore failed");
      });
    this.props.setPersonalInfo(this.state);
    if (this.state.marital_status === "Single") {
      // clearing prev partner info
      this.props.setPartnerInfo(null);

      db.collection("partnerInfo")
        .doc(this.props.currentUser.uid)
        .delete()
        .then(() => {
          // console.log("partner info cleared");
        })
        .catch(() => {
          // console.log("no partner info to delete");
        });
      this.props.setCurrentView("childrenInfo");
    } else this.props.setCurrentView("partnerInfo");
  };

  getPersonalInfoDoc = async () => {
    let db = firebase.firestore();
    let personalInfoRef = db
      .collection("personalInfo")
      .doc(this.props.currentUser.uid);
    let doc = await personalInfoRef.get();
    // console.log(doc);
    return doc;
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    // let doc = this.getPersonalInfoDoc();
    // while (this.props.currentUser === null);
    // let db = firebase.firestore();
    // let personalInfoRef = db
    //   .collection("personalInfo")
    //   .doc(this.props.currentUser.uid);
    // let doc = await personalInfoRef.get();
    // if (!doc.exists) {
    //   // console.log("No such document!");
    // } else {
    //   // console.log(
    //     doc.data().date_of_birth.toDate().toLocaleDateString("fr-CA")
    //   );
    //   // {{doc.data().date_of_birth.toDate() | date: 'dd MMM hh:mm' }}
    //   // this.setState(doc.data);

    //   this.props.setPersonalInfo({
    //     ...doc.data(),
    //     date_of_birth: doc
    //       .data()
    //       .date_of_birth.toDate()
    //       .toLocaleDateString("fr-CA"),
    //   });
    // }
    this.setState(this.props.personalInfo);
  }
  render() {
    return (
      <div className="col-6">
        <h1 style={{ textAlign: "center" }}>Personal Info</h1>
        <form onSubmit={this.onSubmitHandle}>
          <label
            htmlFor="title"
            className="col-form-label col-form-label required-field"
            style={{ fontWeight: "bold" }}
          >
            Title
          </label>
          <select
            id="title"
            className="form-control"
            value={this.state.title}
            onChange={this.onChangeHandler}
            name="title"
            required
          >
            <option value="" disabled hidden selected>
              Title
            </option>
            {this.titleList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>

          <label
            htmlFor="firstName"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="First Name"
            onChange={this.onChangeHandler}
            value={this.state.first_name}
            name="first_name"
            required
          />

          <label
            htmlFor="middleName"
            className="col-form-label col-form-label "
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Middle Name
          </label>

          <input
            type="text"
            className="form-control"
            id="middleName"
            placeholder="Middle Name"
            onChange={this.onChangeHandler}
            value={this.state.middle_name}
            name="middle_name"
          />

          <label
            htmlFor="surname"
            className="col-form-label col-form-label  required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Surname
          </label>

          <input
            type="text"
            className="form-control"
            id="surname"
            placeholder="Surname"
            onChange={this.onChangeHandler}
            value={this.state.last_name}
            name="last_name"
            required
          />

          <label
            htmlFor="prevSurname"
            className="col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Previous Surname
          </label>

          <input
            type="text"
            className="form-control"
            id="prevSurname"
            placeholder="Previous Surname"
            onChange={this.onChangeHandler}
            value={this.state.previous_last_name}
            name="previous_last_name"
          />

          <label
            htmlFor="gender"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Gender
          </label>

          <select
            id="gender"
            className="form-control"
            value={this.state.gender}
            onChange={this.onChangeHandler}
            name="gender"
            required
          >
            <option value="" disabled hidden selected>
              Gender
            </option>
            {this.genderList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>

          <label
            htmlFor="maritalStatus"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Marital Status
          </label>

          <select
            id="maritalStatus"
            className="form-control"
            value={this.state.marital_status}
            onChange={this.onChangeHandler}
            name="marital_status"
            required
          >
            <option value="" disabled hidden selected>
              Marital Status
            </option>
            {this.maritalStatusList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>

          <label
            htmlFor="address"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Address
          </label>

          <textarea
            type="textarea"
            className="form-control"
            id="address"
            placeholder="Address"
            onChange={this.onChangeHandler}
            value={this.state.address}
            name="address"
            required
          />

          <label
            htmlFor="mobile"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Mobile
          </label>

          <input
            type="text"
            className="form-control"
            id="mobile"
            placeholder="Mobile"
            onChange={this.onChangeHandler}
            value={this.state.mobile}
            name="mobile"
            required
          />

          <label
            htmlFor="email"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Email
          </label>

          <input
            type="text"
            className="form-control"
            id="email"
            placeholder="email"
            onChange={this.onChangeHandler}
            value={this.state.email}
            name="email"
            required
          />

          <label
            htmlFor="birthdate"
            className="required-field col-form-label col-form-label"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Birthdate
          </label>

          <input
            type="date"
            className="form-control"
            id="birthdate"
            placeholder="Birthdate"
            name="date_of_birth"
            onChange={this.onChangeHandler}
            // onClick={onClick}
            value={this.state.date_of_birth}
            required
          />

          <button className=" will-next-button">Next</button>
        </form>
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="Personal Info"
          body=" Please enter your details so we can start to create your Smart Will. A person writing a Will is known as a testator or testatrix. "
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalInfo: (personalInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PERSONAL_INFO,
        payload: {
          userPersonalInfo: personalInfo,
        },
      }),
    setPartnerInfo: (partnerInfo) =>
      dispatch({
        type: actionTypes.SET_USER_PARTNER_INFO,
        payload: {
          userPartnerInfo: partnerInfo,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    personalInfo: state.user.personalInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
