import React, { Component } from "react";
import * as actionTypes from "../../../actions/types";
import { Divider } from "semantic-ui-react";

import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import Modal from "./Modal";
import * as PartnerHelpTextList from "../PartnerHelpTextList";

class GuardianInfo extends Component {
  state = {
    needGuardian: false,
    guardianInfo: {
      title: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      relationship: "",
      address: "",
      email: "",
    },
  };
  titleList = ["Mr", "Mrs", "Ms", "Miss", "Professor", "Dr"];
  relationshipList = [
    "Wife",
    "Husband",
    "Civil Partner",
    "Friend",
    "Daughter",
    "Son",
    "Mother",
    "Father",
    "Sister",
    "Brother",
    "Step-Daughter",
    "Step-Son",
    "Mother-in-law",
    "Father-in-law",
    "Sister-in-law",
    "Brother-in-law",
    "Ex-Wife",
    "Ex-Husband",
    "Ex-Partner",
    "Niece",
    "Nephew",
    "Grandson",
    "Granddaughter",
    "Cousin",
    "Aunt",
    "Uncle",
  ];
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.childrenInfo.number === 0) {
      this.props.setCurrentView("liquidAssetInfo");
      if (this.props.partnerStepNo <= 3) this.props.setPartnerStepNo(4);
    }
    if (this.props.partnerStepNo < 3) this.props.setPartnerStepNo(3);

    if (this.props.guardianInfo !== null)
      this.setState({
        needGuardian: true,
        guardianInfo: this.props.guardianInfo,
      });
    else this.setState({ needGuardian: false });
  }

  clearGueardianInfo = async () => {
    let db = firebase.firestore();
    this.props.setPartnerGuardianInfo(null); // clearing prev guardian info

    db.collection("partnerGuardianInfo")
      .doc(this.props.currentUser.uid)
      .delete()
      .then(() => {
        // console.log("guardian info cleared");
      })
      .catch(() => {
        // console.log("no guardian info to delete");
      });
  };

  onClickYes = () => {
    this.setState({ needGuardian: true });
  };
  onClickNo = () => {
    this.setState({ needGuardian: false });
    this.clearGueardianInfo();
    this.props.setCurrentView("liquidAssetInfo");
  };
  onSubmitHandle = (e) => {
    e.preventDefault();
    let db = firebase.firestore();
    let guardianInfo = {
      ...this.state.guardianInfo,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    db.collection("partnerGuardianInfo")
      .doc(this.props.currentUser.uid)
      .set(guardianInfo)
      .then(() => {
        // console.log("guardian info in firestore");
      })
      .catch(() => {
        // console.log("guardian info in firestore failed");
      });

    this.props.setPartnerGuardianInfo({
      ...this.state.guardianInfo,
    });
    this.props.setCurrentView("liquidAssetInfo");
  };
  onChangeHandler = (e) => {
    let tempGuardianInfo = {
      ...this.state.guardianInfo,
      [e.target.name]: e.target.value,
    };
    //   tempGuardianInfo = {...}
    // console.log(this.state.guardianInfo);
    this.setState({ guardianInfo: tempGuardianInfo });
  };

  onClickSame = () => {
    let db = firebase.firestore();
    if (this.props.userGuardianInfo === null) {
      this.clearGueardianInfo();
      this.props.setCurrentView("liquidAssetInfo");
    } else {
      let guardianInfo = {
        ...this.props.userGuardianInfo,
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };
      db.collection("partnerGuardianInfo")
        .doc(this.props.currentUser.uid)
        .set(guardianInfo)
        .then(() => {
          // console.log("guardian info in firestore");
        })
        .catch(() => {
          // console.log("guardian info in firestore failed");
        });

      this.props.setPartnerGuardianInfo({
        ...this.props.userGuardianInfo,
      });
      this.props.setCurrentView("liquidAssetInfo");
    }
  };

  render() {
    return (
      <div className="">
        <h1 style={{ textAlign: "center" }}>Partner's Guardian Preferences</h1>

        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            className="will-button blue-will-button"
            style={{
              width: "70%",
              letterSpacing: "0.1rem",
              wordSpacing: ".2rem",
              fontSize: "1.2rem",
            }}
            onClick={this.onClickSame}
          >
            Same as Partner
          </button>
        </div>
        <Divider horizontal>Or</Divider>

        <div
          style={{
            textAlign: "center",
            marginTop: "4rem",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              display: "block",
              marginBottom: "1.6rem",
            }}
          >
            Would you like to appoint guardians for your children?
          </span>
          <span>
            <button
              className={
                this.state.needGuardian
                  ? "will-button-selected "
                  : "will-button"
              }
              style={{ marginRight: "2rem" }}
              onClick={this.onClickYes}
            >
              Yes
            </button>
            <button
              className={
                this.state.needGuardian
                  ? "will-button "
                  : "will-button-selected"
              }
              onClick={this.onClickNo}
            >
              No
            </button>
          </span>
        </div>
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal title="Help Title" body={PartnerHelpTextList.guardianText} />

        {this.state.needGuardian ? (
          <div className="will-component-slide-in">
            <Divider></Divider>
            <h2 style={{ textAlign: "center" }}>Guardian Info</h2>
            <form onSubmit={this.onSubmitHandle}>
              <label
                htmlFor="title"
                className="col-form-label col-form-label required-field"
                style={{ fontWeight: "bold" }}
              >
                Title
              </label>
              <select
                id="title"
                className="form-control"
                value={this.state.guardianInfo.title}
                onChange={this.onChangeHandler}
                name="title"
                required
              >
                <option value="" disabled hidden selected>
                  Title
                </option>
                {this.titleList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>

              <label
                htmlFor="firstName"
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                onChange={this.onChangeHandler}
                value={this.state.guardianInfo.first_name}
                name="first_name"
                required
              />

              <label
                htmlFor="middleName"
                className="col-form-label col-form-label "
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Middle Name
              </label>

              <input
                type="text"
                className="form-control"
                id="middleName"
                placeholder="Middle Name"
                onChange={this.onChangeHandler}
                value={this.state.guardianInfo.middle_name}
                name="middle_name"
              />

              <label
                htmlFor="surname"
                className="col-form-label col-form-label  required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Surname
              </label>

              <input
                type="text"
                className="form-control"
                id="surname"
                placeholder="Surname"
                onChange={this.onChangeHandler}
                value={this.state.guardianInfo.last_name}
                name="last_name"
                required
              />

              <label
                htmlFor="relationship"
                className="col-form-label col-form-label required-field"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Relationship
              </label>

              <select
                id="relationship"
                className="form-control"
                value={this.state.guardianInfo.relationship}
                onChange={this.onChangeHandler}
                name="relationship"
                required
              >
                <option value="" disabled hidden selected>
                  Relationship
                </option>
                {this.relationshipList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>

              <label
                htmlFor="address"
                className="required-field col-form-label col-form-label"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Address
              </label>

              <textarea
                type="textarea"
                className="form-control"
                id="address"
                placeholder="Address"
                onChange={this.onChangeHandler}
                value={this.state.guardianInfo.address}
                name="address"
                required
              />

              <label
                htmlFor="email"
                className="col-form-label col-form-label"
                style={{ marginTop: "1rem", fontWeight: "bold" }}
              >
                Email
              </label>

              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="email"
                onChange={this.onChangeHandler}
                value={this.state.guardianInfo.email}
                name="email"
              />

              <button className=" will-next-button">Next</button>
            </form>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GUARDIAN_INFO,
        payload: {
          partnerGuardianInfo: guardianInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userGuardianInfo: state.user.guardianInfo,
    childrenInfo: state.partner.childrenInfo,
    guardianInfo: state.partner.guardianInfo,
    currentUser: state.user.currentUser,
    partnerStepNo: state.partner.partnerStepNo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuardianInfo);
