import React, { Component } from "react";
import { Link } from "react-router-dom";

class OurServicesCard extends Component {
  render() {
    return (
      <Link to={this.props.link} style={{ color: "black" }}>
        <div className="ourServicesCard">
          <div className="ourServicesCard-heading">
            <i className={"huge icon " + this.props.icon}></i>

            <span className="ourServicesCard-heading-text">
              {this.props.headingText}
            </span>
          </div>
          <div className="ourServicesCard-description">
            {this.props.description}
          </div>
          <div className="ourServicesCard-link">
            {/* <a href={this.props.link}>{this.props.linkDescription}</a> */}
            <span>{this.props.linkDescription}</span>
          </div>
        </div>
      </Link>
    );
  }
}

export default OurServicesCard;
