import React, { Component } from "react";

import Modal from "../Modal";

import firebase from "../../../../config/firebase";
import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import * as PartnerHelpTextList from "../../PartnerHelpTextList";

class ChildrenCount extends Component {
  state = {
    children: null,
    isChildNumberChanged: false,
    error: null,
    sameAsPartnerError: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.childrenInfo != null) {
      this.setState({
        children: this.props.childrenInfo.number,
      });
    }
  }
  onChangeHandler = (e) => {
    if (
      this.props.childrenInfo !== null &&
      e.target.value === this.props.childrenInfo.number
    ) {
      // console.log("igotdata and they are equal");
      this.setState({ children: e.target.value, isChildNumberChanged: false });
    } else if (this.props.childrenInfo === null) {
      // console.log("igot no data");
      this.setState({ children: e.target.value, isChildNumberChanged: true });
    } else if (
      this.props.childrenInfo !== null &&
      e.target.value !== this.props.childrenInfo.number
    ) {
      // console.log("i got data and they are not equal");
      this.setState({ children: e.target.value, isChildNumberChanged: true });
    }
  };

  clearGueardianInfo = async () => {
    let db = firebase.firestore();
    this.props.setPartnerGuardianInfo(null); // clearing prev guardian info

    db.collection("partnerGuardianInfo")
      .doc(this.props.currentUser.uid)
      .delete()
      .then(() => {
        // console.log("guardian info cleared");
      })
      .catch(() => {
        // console.log("no guardian info to delete");
      });
  };
  onSubmitHandleChildrenNumberForm = (e) => {
    if (e !== undefined) e.preventDefault();
    if (this.state.children != null && this.state.children < 0) {
      this.setState({ error: "Children Number can not be negative" });
    } else if (this.state.children != null && this.state.children === 0) {
      this.props.setPartnerChildrenInfo({
        number: this.state.children,
        info: [],
      });
      let db = firebase.firestore();

      let childrenInfo = {
        children_count: 0,
        children_details: [],
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };

      db.collection("partnerChildrenInfo")
        .doc(this.props.currentUser.uid)
        .set(childrenInfo)
        .then(() => {
          // console.log("children info in firestore");
        })
        .catch(() => {
          // console.log("children info in firestore failed");
        });
      this.clearGueardianInfo();
      this.props.setCurrentView("liquidAssetInfo");
    } else {
      if (this.props.childrenInfo === null || this.state.isChildNumberChanged) {
        let tempChildrenInfo = [];
        for (let index = 0; index < this.state.children; index++) {
          tempChildrenInfo[index] = { name: "", age: null, gender: null };
        }
        this.setState({
          error: null,

          childrenInfo: tempChildrenInfo,
          isChildNumberChanged: false,
        });

        this.props.setPartnerChildrenInfo({
          number: this.state.children,
          info: tempChildrenInfo,
        });
        this.props.setCurrentView("childrenInfo");
        window.scrollTo(0, 0);
      } else {
        this.setState({
          error: null,
        });
        this.props.setCurrentView("childrenInfo");
        window.scrollTo(0, 0);
      }
    }
  };

  onClickSame = (e) => {
    e.preventDefault();
    if (this.props.userChildrenInfo != null) {
      this.setState({
        children: this.props.userChildrenInfo.number,
        childrenInfo: [...this.props.userChildrenInfo.info],
      });

      let isMinor = false;
      let db = firebase.firestore();

      let childrenInfo = {
        children_count: parseInt(this.props.userChildrenInfo.number),
        children_details: [...this.props.userChildrenInfo.info],
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };

      db.collection("partnerChildrenInfo")
        .doc(this.props.currentUser.uid)
        .set(childrenInfo)
        .then(() => {
          // console.log("children info in firestore");
        })
        .catch(() => {
          // console.log("children info in firestore failed");
        });

      this.props.setPartnerChildrenInfo({
        number: this.props.userChildrenInfo.number,
        info: [...this.props.userChildrenInfo.info],
      });
      for (let index = 0; index < this.props.userChildrenInfo.number; index++) {
        if ([...this.props.userChildrenInfo.info][index].age < 18) {
          isMinor = true;
          break;
        }
      }
      if (isMinor) {
        this.props.setCurrentView("guardianInfo");
      } else {
        this.clearGueardianInfo();
        this.props.setCurrentView("liquidAssetInfo");
      }
    } else {
      this.setState({ error: "No Data Found" });
    }
  };

  render() {
    return (
      <div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            className="will-button blue-will-button"
            style={{
              width: "70%",
              letterSpacing: "0.1rem",
              wordSpacing: ".2rem",
              fontSize: "1.2rem",
            }}
            onClick={this.onClickSame}
          >
            Same as Partner
          </button>
        </div>
        <Divider horizontal>Or</Divider>
        <form onSubmit={this.onSubmitHandleChildrenNumberForm}>
          <label
            htmlFor="children"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How many children do you have?
          </label>
          <input
            type="number"
            step="1"
            className="form-control"
            id="children"
            placeholder="Children no"
            onChange={this.onChangeHandler}
            value={this.state.children || ""}
            name="children"
            required
          />
          {this.state.error === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">{this.state.error}</div>
          )}
          <button className=" will-next-button">Next</button>
        </form>
        <button
          type="button"
          //  className=""
          className={"modal-button " + this.props.visibleFooter}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}
          >
            ?
          </span>
        </button>
        <Modal
          title="Children Info"
          body={PartnerHelpTextList.childrenInfoText}
        />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerChildrenInfo: (childrenInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_CHILDREN_INFO,
        payload: {
          partnerChildrenInfo: childrenInfo,
        },
      }),
    setPartnerGuardianInfo: (guardianInfo) =>
      dispatch({
        type: actionTypes.SET_PARTNER_GUARDIAN_INFO,
        payload: {
          partnerGuardianInfo: guardianInfo,
        },
      }),
    setPartnerStepNo: (step) =>
      dispatch({
        type: actionTypes.SET_PARTNER_STEP_NO,
        payload: {
          partnerStepNo: step,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    userChildrenInfo: state.user.childrenInfo,
    childrenInfo: state.partner.childrenInfo,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenCount);
