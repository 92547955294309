import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";
import * as HealthAndWelfareStepsEnum from "./HealthAndWelfareStepsEnum";
import firebase from "../../../config/firebase";

class LifeSustainingTreatment extends Component {
  state = {
    selected: "",
    error: "",
  };
  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.lifeSustainingTreatment !== null)
      this.setState({ selected: this.props.lifeSustainingTreatment });
  }

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (this.state.selected === "") {
      // console.log("hello");
      this.setState({ error: "Please Select One" });
      return;
    } else {
      let db = firebase.firestore();
      let healthLpaLifeSustainingTreatment = {
        life_sustaining_treatment: this.state.selected,
        user_id: db.doc("users/" + this.props.currentUser.uid),
      };

      db.collection("healthLpaLifeSustainingTreatment")
        .doc(this.props.currentUser.uid)
        .set(healthLpaLifeSustainingTreatment)
        .then(() => {
          // console.log("HealthLpaLifeSustainingTreatment in firestore");
        })
        .catch(() => {
          // console.log("HealthLpaLifeSustainingTreatment in firestore failed");
        });

      this.props.setHealthLpaLifeSustainingTreatment(this.state.selected);
      this.props.setCurrentView(HealthAndWelfareStepsEnum.ATTORNEY);
    }
  };
  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Who does the donor want to make decisions about life-sustaining
          treatment?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
            position: "relative",
          }}
        >
          <div className="row">
            <div>
              <p>
                This is a very important decision. The donor should read the
                guidance carefully before they answer.
              </p>
              <p>
                Life-sustaining treatment is care, surgery, medicine or other
                help from doctors that's needed to keep someone alive.
              </p>
              <p>
                The donor must choose who they want to make decisions about
                life-sustaining treatment if they do not have the mental
                capacity to decide themselves.
              </p>
              <p>
                After the LPA document is printed, the donor will need to sign
                and date the option they've chosen. This must be witnessed. The
                witness must also sign and include their full name and address.
                If they do not, OPG cannot register the LPA and the donor may
                have to pay the application fee again.
              </p>
            </div>
          </div>
        </div>
        <Divider />

        <form onSubmit={this.onSubmitHandle}>
          <h2>
            Who does the donor want to make decisions about life-sustaining
            treatment?
          </h2>
          <div
            style={{
              position: "relative",
              marginBottom: "2rem",
              marginTop: "3rem",
            }}
          >
            <input
              type="radio"
              className=""
              id="attorneys"
              placeholder="First Name"
              checked={this.state.selected === "attorneys" ? 1 : 0}
              onChange={() => this.setState({ selected: "attorneys" })}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="attorneys"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "0%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
                lineHeight: "1.6rem",
              }}
            >
              Option A – The donor gives their attorneys authority to give or
              refuse consent to life-sustaining treatment on their behalf.
            </label>
          </div>
          <span className="life-sustaining-treatment-details">
            <p>
              The donor can add preferences and instructions later in the LPA to
              explain what they would like their attorneys to consider if they
              have to make decisions about life-sustaining treatment.
            </p>
          </span>
          <div
            style={{
              position: "relative",
              marginBottom: "2rem",
              marginTop: "3rem",
            }}
          >
            <input
              type="radio"
              className=""
              id="doctors"
              placeholder="First Name"
              checked={this.state.selected === "doctors" ? 1 : 0}
              onChange={() => this.setState({ selected: "doctors" })}
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="doctors"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "0%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "2rem",
                lineHeight: "1.6rem",
              }}
            >
              Option B – The donor does not give their attorneys authority to
              give or refuse consent to life-sustaining treatment on their
              behalf.
            </label>
          </div>

          <span className="life-sustaining-treatment-details">
            <p>
              Doctors will make any decisions about life-sustaining treatment,
              taking into account the donor’s best interests and, where
              possible, the views of others involved in the donor’s welfare,
              such as their attorneys and family members.
            </p>
          </span>
          {this.state.error === "" ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{ fontSize: "1.1rem", fontWeight: "bold" }}
            >
              {this.state.error}
            </div>
          )}
          <button className=" will-next-button">Save And Proceed</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHealthLpaLifeSustainingTreatment: (data) =>
      dispatch({
        type: actionTypes.SET_HEALTH_LPA_LIFE_SUSTAINING_TREATMENT,
        payload: {
          lifeSustainingTreatment: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    lifeSustainingTreatment: state.health_lpa.lifeSustainingTreatment,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifeSustainingTreatment);
