import React, { Component } from "react";
import { Step } from "semantic-ui-react";

export class GiftInfoSteps extends Component {
  disableChildrenInfo = true;

  setDisable = (stepName) => {
    this[stepName] = true;
  };
  componentDidMount() {}

  render() {
    if (this.props.currentView === "childrenCount") {
      this.disableChildrenInfo = true;
    } else if (this.props.currentView === "childrenInfo")
      this.disableChildrenInfo = false;

    return (
      <Step.Group widths={3} size="small">
        <Step
          active={this.props.currentView === "childrenCount" ? true : false}
          link
          onClick={() => this.props.setCurrentView("childrenCount")}
        >
          <Step.Content>
            <Step.Title>Children Count</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableChildrenInfo}
          active={this.props.currentView === "childrenInfo" ? true : false}
          onClick={() => this.props.setCurrentView("childrenInfo")}
        >
          <Step.Content>
            <Step.Title>Children Info</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

export default GiftInfoSteps;
