import React, { Component } from "react";

import "../../../css/videosection.css";

// import landingPageVideo from "../../../videos/landingPageVideo.mp4";
import landingPageImage from "../../../images/bg-home.jpg";

class VedioSection extends Component {
  render() {
    return (
      <div className="header-container">
        <div className="row no-gutters">
          <div className="col-sm-4 videosection">
            <div className="videosection-text">
              <span className="videosection-text-heading">
                Welcome to Neolexical
              </span>
              <span className="videosection-text-description-normal">
              We pride ourselves in providing the best possible service to you and your family in drafting your Will, advising on Probate Services and Lasting Powers of Attorney.
              </span>
              <span className="videosection-text-description-bold">
              Sign up to use our innovative online Will and LPA making solutions.
              </span>
              {/*<span className="videosection-text-notice">*/}
              {/*NOTE: Website currently undergoing update and revision of information. Expect changes in price information and complaints procedure.*/}
              {/*</span>*/}
              <a className="videosection-text-button" href="/register">
                Start now
              </a>
            </div>
          </div>
          <div className="col-sm-8 ">
            <div className="video-container">
              {/* <video
                src={landingPageVideo}
                // controls
                autoPlay={true}
                loop
                muted
                // poster={neologo}
              ></video> */}
              <img
                className="video-container"
                src={landingPageImage}
                alt="Neolexical Ltd."
              ></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VedioSection;
