import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";

import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import firebase from "../../../config/firebase";

class RepeatApplication extends Component {
  state = {
    error: "",
    selected: "",
    case: "",
  };

  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.repeat !== null && this.props.repeat === "")
      this.setState({ selected: "no" });
    else if (this.props.repeat !== null && this.props.repeat !== "")
      this.setState({
        selected: "yes",
        case: this.props.repeat,
        error:
          "I confirm that OPG has said a repeat application can be made within 3 months for half the normal application fee.",
      });
  }

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (this.state.selected === "") {
      this.setState({ error: "Select One" });
      return;
    }
    let db = firebase.firestore();
    let financialLpaRepeat = {
      case: this.state.case,
      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    db.collection("financialLpaRepeatInfo")
      .doc(this.props.currentUser.uid)
      .set(financialLpaRepeat)
      .then(() => {
        // console.log("FinancialLpaRepeat in firestore");
      })
      .catch(() => {
        // console.log("FinancialLpaRepeat in firestore failed");
      });
    this.props.setFinancialLpaRepeat(this.state.case);
    this.props.setCurrentView(FinancialAffairsStepsEnum.SUBMIT);
  };

  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Is the donor making a repeat application to register their LPA?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
            position: "relative",
          }}
        >
          If an application has already been made to register an LPA for the
          donor, but OPG could not register it, we will have written to the
          correspondent to let them know. The letter will explain that if a
          repeat application is made within 3 months, the donor can pay half the
          normal application fee.
        </div>
        <Divider />

        <form onSubmit={this.onSubmitHandle}>
          <div
            style={{
              position: "relative",
              marginBottom: "0.7rem",
              marginTop: "2rem",
            }}
          >
            <input
              type="radio"
              className=""
              id="yes"
              placeholder="First Name"
              checked={this.state.selected === "yes" ? 1 : 0}
              onChange={() =>
                this.setState({
                  selected: "yes",
                  error:
                    "I confirm that OPG has said a repeat application can be made within 3 months for half the normal application fee.",
                })
              }
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="yes"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              Yes
            </label>

            {this.state.selected === "yes" ? (
              <input
                type="text"
                className="form-control"
                id="yes"
                placeholder="Case No."
                value={this.state.case}
                onChange={(e) => this.setState({ case: e.target.value })}
                style={{ marginTop: "1rem", marginBottom: "2rem" }}
                required
                name="case"
              />
            ) : (
              <span></span>
            )}
          </div>

          <div style={{ position: "relative", marginBottom: "2rem" }}>
            <input
              type="radio"
              className=""
              id="no"
              placeholder="First Name"
              checked={this.state.selected === "no" ? 1 : 0}
              onChange={() =>
                this.setState({ selected: "no", case: "", error: "" })
              }
              style={{ width: "2rem", height: "2rem", display: "inline-block" }}
              name="first_name"
            />
            <label
              htmlFor="no"
              // className="col-form-label col-form-label required-field"
              style={{
                // fontWeight: "bold",
                fontSize: "1.4rem",
                position: "absolute",
                top: "15%",
                paddingLeft: "1rem",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              No
            </label>
          </div>

          {this.state.error === "" ? (
            <span></span>
          ) : (
            <div
              className="alert alert-danger"
              role="alert"
              style={{ fontSize: "1.2rem", fontWeight: "bold" }}
            >
              {this.state.error}
            </div>
          )}
          <button className=" will-next-button">Save And Go To Submit</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaRepeat: (data) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_REPEAT,
        payload: {
          repeat: data,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    repeat: state.financial_lpa.repeat,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RepeatApplication);
