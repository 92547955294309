import React, { Component } from "react";
import { Step } from "semantic-ui-react";

export class GiftInfoSteps extends Component {
  disableBenificiaryType = true;
  disableFamilyGift = true;
  disableCharityGift = true;

  setDisable = (stepName) => {
    this[stepName] = true;
  };
  componentDidMount() {}

  render() {
    if (this.props.currentView === "beneficiaryType") {
      this.disableBenficiaryType = false;
      this.disableFamilyGift = true;
      this.disableCharityGift = true;
    } else if (this.props.currentView === "familyGift")
      this.disableFamilyGift = false;
    else if (this.props.currentView === "charityGift")
      this.disableCharityGift = false;

    return (
      <Step.Group widths={3} size="small">
        <Step
          active={this.props.currentView === "beneficiaryType" ? true : false}
          link
          onClick={() => this.props.setCurrentView("beneficiaryType")}
        >
          <Step.Content>
            <Step.Title>Benificiary Type</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableFamilyGift}
          active={this.props.currentView === "familyGift" ? true : false}
          onClick={() => this.props.setCurrentView("familyGift")}
        >
          <Step.Content>
            <Step.Title>Family Gift</Step.Title>
          </Step.Content>
        </Step>

        <Step
          link
          disabled={this.disableCharityGift}
          active={this.props.currentView === "charityGift" ? true : false}
          onClick={() => this.props.setCurrentView("charityGift")}
        >
          <Step.Content>
            <Step.Title>Charity Gift</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}

export default GiftInfoSteps;
