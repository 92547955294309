import React, { Component } from "react";

class CompletedProductCard extends Component {
  render() {
    return (
      <div
        className="ourServicesCard"
        style={{
          margin: "2rem 2rem",
          borderRadius: "0.5rem",
          background:
            " linear-gradient(324deg,rgba(99,178,208,1) 0%, rgba(0,161,204,1) 50%)",
          color: "white",
          boxShadow: " -1px 2px 5px 2px rgba(0,0,0,0.5)",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6">
            <div className="ourServicesCard-heading" style={{ color: "white" }}>
              <i className={"huge icon " + this.props.icon}></i>

              <span className="ourServicesCard-heading-text">
                {this.props.headingText}
              </span>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-6 align-self-center text-md-left text-center"
            style={{
              fontSize: "1.15rem",
              lineHeight: "1.8rem",
            }}
          >
            <div>Status : {this.props.status}</div>
            <div>Update Time : {this.props.time} </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompletedProductCard;
