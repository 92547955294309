import React, { Component } from "react";
import { connect } from "react-redux";

export class ChangePasswordModal extends Component {
  state = {
    message: "",
  };
  componentDidMount() {
    // var auth = firebase.auth();
    // var actionCodeSettings = {
    //   url: "neolexical-demo.firebaseapp.com",
    //   handleCodeInApp: false,
    // };
    // auth
    //   .sendPasswordResetEmail(this.props.currentUser.email)
    //   .then(() => {
    //     // Email sent.
    //     this.setState({ message: "Check Your Mail" });
    //     // console.log("check mail");
    //   })
    //   .catch(function (error) {
    //     // An error happened.
    //     this.setState({ message: "cant reset" });
    //     // console.log("cant reset , " + error.message);
    //   });
  }
  render() {
    return (
      <div
        className="modal fade"
        id="changePasswordModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Change Password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{this.props.message}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps)(ChangePasswordModal);
