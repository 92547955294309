import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../../config/firebase";
import CompletedProductCard from "./CompletedProductCard";

class CompletedProducts extends Component {
  state = {
    singleWillSnapShot: null,
    singleWillData: null,
    singleWillDocID: null,
    doubleWillSnapShot: null,
    doubleWillData: null,
    doubleWillDocID: null,
    healthLpaSnapShot: null,
    healthLpaData: null,
    healthLpaDocID: null,
    financialLpaSnapShot: null,
    financialLpaData: null,
    financialLpaDocID: null,
  };
  readData = async () => {
    let db = firebase.firestore();

    //reading single will

    let snap = await db
      .collection("completedProducts")
      .doc(firebase.auth().currentUser.uid)
      .collection("singleWills")
      .get();
    this.setState({ singleWillSnapShot: snap });

    //reading double will

    snap = await db
      .collection("completedProducts")
      .doc(firebase.auth().currentUser.uid)
      .collection("doubleWills")
      .get();
    this.setState({ doubleWillSnapShot: snap });

    //reading health LPA

    snap = await db
      .collection("completedProducts")
      .doc(firebase.auth().currentUser.uid)
      .collection("healthLPAs")
      .get();
    this.setState({ healthLpaSnapShot: snap });

    //reading financial LPa

    snap = await db
      .collection("completedProducts")
      .doc(firebase.auth().currentUser.uid)
      .collection("financialLPAs")
      .get();
    this.setState({ financialLpaSnapShot: snap });
  };

  getTimestampData = (time_stamp) => {
    if (typeof time_stamp == "number") {
      const tempTimeStamp = new Date(time_stamp);
      return tempTimeStamp.toLocaleDateString("en-GB", {
        month: "long",
        year: "numeric",
        day: "numeric",
      })
      .toString();
    } else if (typeof time_stamp == "object") {
      return time_stamp.toDate()
        .toLocaleDateString("en-GB", {
          month: "long",
          year: "numeric",
          day: "numeric",
        })
        .toString();
    }
    else return "N/A";
  }

  renderSingleWill = () => {
    let singleWills = [];
    if (this.state.singleWillSnapShot !== null)
      this.state.singleWillSnapShot.docs.forEach((doc, index) => {
        if (
          doc.data() !== null &&
          doc.data().status !== "payment_pending" &&
          doc.data().status !== "created"
        )
        
          singleWills.push(
            <span key={"singleWill" + index}>
              {
                <CompletedProductCard
                  {...{
                    icon: "user",
                    headingText: "Your Single Will",
                    status: doc.data().status,
                    time: this.getTimestampData(doc.data().time_stamp),
                  }}
                />
              }
            </span>
          );
      });

    return singleWills;
  };

  renderDoubleWill = () => {
    let doubleWills = [];
    if (this.state.doubleWillSnapShot !== null)
      this.state.doubleWillSnapShot.docs.forEach((doc, index) => {
        if (
          doc.data() !== null &&
          doc.data().status !== "payment_pending" &&
          doc.data().status !== "created"
        )
          doubleWills.push(
            <span key={"doubleWill" + index}>
              <CompletedProductCard
                {...{
                  icon: "users",
                  headingText: "Your Double Will",
                  status: doc.data().status,
                  time: doc
                    .data()
                    .time_stamp.toDate()
                    .toLocaleDateString("en-GB", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    })
                    .toString(),
                }}
              />
            </span>
          );
      });

    return doubleWills;
  };

  renderHealthLpa = () => {
    let healthLPAs = [];
    if (this.state.healthLpaSnapShot !== null)
      this.state.healthLpaSnapShot.docs.forEach((doc, index) => {
        if (
          doc.data() !== null &&
          doc.data().status !== "payment_pending" &&
          doc.data().status !== "created"
        )
          healthLPAs.push(
            <span key={"healthLPA" + index}>
              <CompletedProductCard
                {...{
                  icon: "users",
                  headingText: "Your Health LPA",
                  status: doc.data().status,
                  time: doc
                    .data()
                    .time_stamp.toDate()
                    .toLocaleDateString("en-GB", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    })
                    .toString(),
                }}
              />
            </span>
          );
      });

    return healthLPAs;
  };

  renderFinancialLpa = () => {
    let financialLPAs = [];
    if (this.state.financialLpaSnapShot !== null)
      this.state.financialLpaSnapShot.docs.forEach((doc, index) => {
        if (
          doc.data() !== null &&
          doc.data().status !== "payment_pending" &&
          doc.data().status !== "created"
        )
          financialLPAs.push(
            <span key={"financialLPA" + index}>
              <CompletedProductCard
                {...{
                  icon: "users",
                  headingText: "Your Financial LPA",
                  status: doc.data().status,
                  time: doc
                    .data()
                    .time_stamp.toDate()
                    .toLocaleDateString("en-GB", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    })
                    .toString(),
                }}
              />
            </span>
          );
      });

    return financialLPAs;
  };
  componentDidMount() {
    this.readData();
  }
  render() {
    return (
      <div className="col-9">
        <h1 className="aboutus-section-heading">Completed Wills & LPAs</h1>
        <div className="basicinfo-container">
          {this.renderSingleWill().map((item) => item)}

          {this.renderDoubleWill().map((item) => item)}

          {this.renderHealthLpa().map((item) => item)}

          {this.renderFinancialLpa().map((item) => item)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps)(CompletedProducts);
