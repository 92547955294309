import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Footer from "../footer/Footer";
import Header from "../landingPage/header/Header";
import UserProfileBody from "./UserProfileBody";
import firebase from "../../config/firebase";
import { connect } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";

const UserProfileBodyWithRouter = withRouter(UserProfileBody);
class UserProfile extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    // console.log(this.props.location);
    var firebaseUser = firebase.auth().currentUser;
    var tempUser = { ...firebase.auth().currentUser };
    // // console.log(user);
    // let thirdPartyVerified = false;
    if (firebaseUser !== null)
      tempUser.providerData.forEach((item) => {
        if (item.providerId === "facebook.com") tempUser.emailVerified = true;
      });
    if (firebaseUser === null || !tempUser.emailVerified) {
      this.setState({ loading: false });
      this.props.history.push("/login");
    } else {
      this.setState({ loading: false });
    }
    //
  }
  render() {
    if (this.state.loading)
      return (
        <div style={{ height: "100vh" }}>
          <Dimmer active>
            <Loader size="massive">Loading</Loader>
          </Dimmer>
        </div>
      );
    else
      return (
        <React.Fragment>
          <Header />
          <UserProfileBodyWithRouter />
          <Footer />
        </React.Fragment>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};
export default connect(mapStateToProps)(UserProfile);
