import React, { Component } from "react";
import { connect } from "react-redux";
import ProductCard from "./ProductCard";

class MyLPAs extends Component {
  componentDidMount() {}
  render() {
    return (
      <div className="col-9">
        <h1 className="aboutus-section-heading">My LPAs</h1>
        <div className="basicinfo-container">
          <ProductCard
            {...{
              icon: "money bill alternate",
              headingText: "Financial Affairs",
              loading: this.props.data.financialLpaLoading,
              link:
                this.props.data.financialLpaData !== null &&
                this.props.data.financialLpaData.status !== null
                  ? "/financialaffairs"
                  : "/financial-affairs-info",
              paymentLink:
                this.props.data.financialLpaDocID !== null
                  ? "/checkout/payment/financiallpa/" +
                    this.props.data.financialLpaDocID
                  : "",

              data: this.props.data.financialLpaData,
            }}
          />
          <ProductCard
            {...{
              icon: "heartbeat",
              headingText: "Health & Welfare",
              loading: this.props.data.healthLpaLoading,
              link:
                this.props.data.healthLpaData !== null &&
                this.props.data.healthLpaData.status !== null
                  ? "/healthnwelfare"
                  : "/health-welfare-info",
              paymentLink:
                this.props.data.healthLpaDocID !== null
                  ? "/checkout/payment/healthlpa/" +
                    this.props.data.healthLpaDocID
                  : "",

              data: this.props.data.healthLpaData,
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps)(MyLPAs);
