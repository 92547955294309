import React, { Component } from "react";

import * as actionTypes from "../../../../actions/types";
import { connect } from "react-redux";
import firebase from "../../../../config/firebase";
import * as liquidAssetInfoStepEnum from "./LiquidAssetInfoEnums";

class LiquidAssetInfoCount extends Component {
  state = {
    error_cash: null,
    cash: null,

    error_investment_count: null,
    investment_count: null,
    investment_count_change: false,
    investment_details: [],

    error_life_insurance_count: null,
    life_insurance_count: null,
    life_insurance_count_change: false,
    life_insurance_details: [],

    error_pension_count: null,
    pension_count: null,
    pension_count_change: false,
    pension_details: [],

    error_personal_asset_count: null,
    personal_asset_count: null,
    personal_asset_count_change: false,
    personal_assets: [],

    primaryDataProvided: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.liquidAssetInfo !== null)
      this.setState({
        ...this.props.liquidAssetInfo,
      });
  }

  onChangeHandler = (e) => {
    let targetName = [e.target.name];

    if (e.target.name === "cash") {
      this.setState({ [e.target.name]: parseFloat(e.target.value) });
    } else {
      if (
        this.props.liquidAssetInfo !== null &&
        parseInt(e.target.value) !== this.props.liquidAssetInfo[targetName]
      ) {
        let key = e.target.name + "_change";
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (
        this.props.liquidAssetInfo !== null &&
        parseInt(e.target.value) === this.props.liquidAssetInfo[targetName]
      ) {
        let key = e.target.name + "_change";
        this.setState({
          [key]: false,
          [e.target.name]: parseInt(e.target.value),
        });
      } else if (this.props.liquidAssetInfo === null) {
        let key = e.target.name + "_change";
        this.setState({
          [key]: true,
          [e.target.name]: parseInt(e.target.value),
        });
      }
      // this.setState({ [e.target.name]: parseInt(e.target.value) });
    }
  };
  isFormValid = () => {
    if (this.state.cash < 0) {
      this.setState({ error_cash: "invalid input" });
      return false;
    } else this.setState({ error_cash: null });

    if (this.state.pension_count < 0 || this.state.pension_count % 1 !== 0) {
      this.setState({ error_pension_count: "invalid input" });
      return false;
    } else this.setState({ error_pension_count: null });

    if (
      this.state.life_insurance_count < 0 ||
      this.state.life_insurance_count % 1 !== 0
    ) {
      this.setState({ error_life_insurance_count: "invalid input" });
      return false;
    } else this.setState({ error_life_insurance_count: null });

    if (
      this.state.investment_count < 0 ||
      this.state.investment_count % 1 !== 0
    ) {
      this.setState({ error_investment_count: "invalid input" });
      return false;
    } else this.setState({ error_investment_count: null });

    if (
      this.state.personal_asset_count < 0 ||
      this.state.personal_asset_count % 1 !== 0
    ) {
      this.setState({ error_personal_asset_count: "invalid input" });
      return false;
    } else this.setState({ error_personal_asset_count: null });

    return true;
  };

  onSubmitHandleCountForm = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      // this.props.liquidAssetInfo === null
      window.scrollTo(0, 0);

      if (
        this.state.investment_count === 0 &&
        this.state.life_insurance_count === 0 &&
        this.state.pension_count === 0 &&
        this.state.personal_asset_count === 0
      ) {
        let db = firebase.firestore();
        let liquidAssetInfo = {
          cash: this.state.cash,
          investment_count: 0,
          investment_details: [],
          life_insurance_count: 0,
          life_insurance_details: [],
          pension_count: 0,
          pension_details: [],
          personal_asset_count: 0,
          personal_assets: [],
          user_id: db.doc("users/" + this.props.currentUser.uid),
        };

        db.collection("liquidAssetInfo")
          .doc(this.props.currentUser.uid)
          .set(liquidAssetInfo)
          .then(() => {
            // console.log("liquidasset info in firestore");
          })
          .catch(() => {
            // console.log("liquidasset info in firestore failed");
          });

        this.props.setLiquidAssetInfo({
          ...this.props.liquidAssetInfo,
          cash: this.state.cash,
          investment_count: 0,
          investment_details: [],
          life_insurance_count: 0,
          life_insurance_details: [],
          pension_count: 0,
          pension_details: [],
          personal_asset_count: 0,
          personal_assets: [],
        });

        this.props.setCurrentView("realAssetInfo");
      } else if (this.props.liquidAssetInfo === null) {
        let pension_details = [];
        for (let index = 0; index < this.state.pension_count; index++) {
          pension_details[index] = { name: "", value: null };
        }

        let personal_assets = [];
        for (let index = 0; index < this.state.personal_asset_count; index++) {
          personal_assets[index] = { name: "", value: null };
        }

        let life_insurance_details = [];
        for (let index = 0; index < this.state.life_insurance_count; index++) {
          life_insurance_details[index] = { name: "", value: null };
        }

        let investment_details = [];
        for (let index = 0; index < this.state.investment_count; index++) {
          investment_details[index] = { type: "", name: "", value: null };
        }
        this.setState({
          primaryDataProvided: true,
          pension_details: pension_details,
          investment_details: investment_details,
          life_insurance_details: life_insurance_details,
          personal_assets: personal_assets,
          pension_count_change: false,
          investment_count_change: false,
          life_insurance_count_change: false,
          personal_asset_count_change: false,
        });
        let tempState = {
          ...this.state,
          pension_details: pension_details,
          investment_details: investment_details,
          life_insurance_details: life_insurance_details,
          personal_assets: personal_assets,
        };
        delete tempState.primaryDataProvided;
        delete tempState.error_cash;
        delete tempState.pension_count_change;
        delete tempState.investment_count_change;
        delete tempState.life_insurance_count_change;
        delete tempState.personal_asset_count_change;
        delete tempState.error_investment_count;
        delete tempState.error_life_insurance_count;
        delete tempState.error_pension_count;
        delete tempState.error_personal_asset_count;

        this.props.setLiquidAssetInfo(tempState);
        this.props.setCurrentView(
          liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_FORM
        );
      } else {
        let tempState = {
          ...this.state,
        };

        if (this.state.pension_count_change) {
          // console.log("pension count change");
          let pension_details = [];
          for (let index = 0; index < this.state.pension_count; index++) {
            pension_details[index] = { name: "", value: null };
          }

          tempState = { ...tempState, pension_details: pension_details };
          this.setState({ pension_details: pension_details });
        }

        if (this.state.investment_count_change) {
          let investment_details = [];
          for (let index = 0; index < this.state.investment_count; index++) {
            investment_details[index] = { type: "", name: "", value: null };
          }

          tempState = { ...tempState, investment_details: investment_details };
          this.setState({ investment_details: investment_details });
        }

        if (this.state.life_insurance_count_change) {
          let life_insurance_details = [];
          for (
            let index = 0;
            index < this.state.life_insurance_count;
            index++
          ) {
            life_insurance_details[index] = { name: "", value: null };
          }

          tempState = {
            ...tempState,
            life_insurance_details: life_insurance_details,
          };
          this.setState({ life_insurance_details: life_insurance_details });
        }

        if (this.state.personal_asset_count_change) {
          let personal_assets = [];
          for (
            let index = 0;
            index < this.state.personal_asset_count;
            index++
          ) {
            personal_assets[index] = { name: "", value: null };
          }

          tempState = { ...tempState, personal_assets: personal_assets };
          this.setState({ personal_assets: personal_assets });
        }

        this.setState({
          primaryDataProvided: true,
          pension_count_change: false,
          investment_count_change: false,
          life_insurance_count_change: false,
          personal_asset_count_change: false,
        });

        // console.log(tempState);
        delete tempState.primaryDataProvided;
        delete tempState.error_cash;
        delete tempState.pension_count_change;
        delete tempState.investment_count_change;
        delete tempState.life_insurance_count_change;
        delete tempState.personal_asset_count_change;
        delete tempState.error_investment_count;
        delete tempState.error_life_insurance_count;
        delete tempState.error_pension_count;
        delete tempState.error_personal_asset_count;

        this.props.setLiquidAssetInfo(tempState);
        this.props.setCurrentView(
          liquidAssetInfoStepEnum.LIQUID_ASSET_INFO_TYPE_FORM
        );
      }
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmitHandleCountForm}>
          <label
            htmlFor="cash"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How much cash do you have?
          </label>
          <input
            type="number"
            step="0.01"
            className="form-control"
            id="cash"
            placeholder="Cash"
            onChange={this.onChangeHandler}
            value={this.state.cash === 0 ? 0 : this.state.cash || ""}
            name="cash"
            required
          />
          {this.state.error_cash === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">{this.state.error_cash}</div>
          )}

          <label
            htmlFor="pension_count"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How many pensions do you have?
          </label>
          <input
            type="number"
            className="form-control"
            id="pension_count"
            placeholder="Number Of Pensions"
            onChange={this.onChangeHandler}
            value={
              this.state.pension_count === 0
                ? 0
                : this.state.pension_count || ""
            }
            name="pension_count"
            required
          />
          {this.state.error_pension_count === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">
              {this.state.error_pension_count}
            </div>
          )}

          <label
            htmlFor="life_insurance_count"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How many life insurances do you have?
          </label>
          <input
            type="number"
            className="form-control"
            id="life_insurance_count"
            placeholder="Number Of Life Insurances"
            onChange={this.onChangeHandler}
            value={
              this.state.life_insurance_count === 0
                ? 0
                : this.state.life_insurance_count || ""
            }
            name="life_insurance_count"
            required
          />
          {this.state.error_life_insurance_count === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">
              {this.state.error_life_insurance_count}
            </div>
          )}

          <label
            htmlFor="investment_count"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How many life investments (savings, cash accounts, ISA's, Bonds,
            Shares or other investments) do you have?
          </label>
          <input
            type="number"
            className="form-control"
            id="investment_count"
            placeholder="Number Of Investments"
            onChange={this.onChangeHandler}
            value={
              this.state.investment_count === 0
                ? 0
                : this.state.investment_count || ""
            }
            name="investment_count"
            required
          />
          {this.state.error_investment_count === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">
              {this.state.error_investment_count}
            </div>
          )}

          <label
            htmlFor="personal_asset_count"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            How many valuable personal items (e.g. car, jewellery, electronic
            device etc.) do you have?
          </label>
          <input
            type="number"
            className="form-control"
            id="personal_asset_count"
            placeholder="Number Of Personal Asset"
            onChange={this.onChangeHandler}
            value={
              this.state.personal_asset_count === 0
                ? 0
                : this.state.personal_asset_count || ""
            }
            name="personal_asset_count"
            required
          />
          {this.state.error_personal_asset_count === null ? (
            <span></span>
          ) : (
            <div className="invalid-input">
              {this.state.error_personal_asset_count}
            </div>
          )}

          <button className=" will-next-button">Next</button>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLiquidAssetInfo: (liquidAssetInfo) =>
      dispatch({
        type: actionTypes.SET_USER_LIQUID_ASSET_INFO,
        payload: {
          userLiquidAssetInfo: liquidAssetInfo,
        },
      }),
    setStepNo: (step) => {
      dispatch({
        type: actionTypes.SET_SINGLE_WILL_STEP_NO,
        payload: {
          singleWillStepNo: step,
        },
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    liquidAssetInfo: state.user.liquidAssetInfo,
    stepNo: state.user.singleWillStepNo,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiquidAssetInfoCount);
