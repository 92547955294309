import React, { Component } from "react";
import { Divider } from "semantic-ui-react";
import * as Lists from "../Lists";
import * as actionTypes from "../../../actions/types";
import * as FinancialAffairsStepsEnum from "./FinancialAffairsStepsEnum";
import { connect } from "react-redux";
import firebase from "../../../config/firebase";
import axios from "axios";

class CertificateProvider extends Component {
  state = {
    title: "",
    first_name: "",
    last_name: "",

    address: {
      street_or_house_no: "",
      city: "",
      postal_code: "",
      country: "",
    },

    error_postal_code: "",
  };

  countryList = Lists.countryList;
  titleList = Lists.titleList;

  componentDidMount() {
    window.scroll(0, 0);
    if (this.props.certificateProvider !== null)
      this.setState({ ...this.props.certificateProvider });
  }

  dbValidAction = () => {
    this.setState({ error_postal_code: "" });
    let db = firebase.firestore();
    let financialLpaCertificateProvider = {
      ...this.state,

      user_id: db.doc("users/" + this.props.currentUser.uid),
    };
    delete financialLpaCertificateProvider.error_postal_code;

    db.collection("financialLpaCertificateProviderInfo")
      .doc(this.props.currentUser.uid)
      .set(financialLpaCertificateProvider)
      .then(() => {
        // console.log("FinancialLpaCertificateProvider in firestore");
      })
      .catch(() => {
        // console.log("FinancialLpaCertificateProvider in firestore failed");
      });
    this.props.setFinancialLpaCertificateProvider(
      financialLpaCertificateProvider
    );
    this.props.setCurrentView(FinancialAffairsStepsEnum.PERSON_TO_NOTIFY);
  };

  onSubmitHandle = (e) => {
    e.preventDefault();
    if (
      this.state.address.country === "United Kingdom" ||
      this.state.address.country === "Wales"
    )
      axios
        .get(
          `https://api.postcodes.io/postcodes/${this.state.address.postal_code.trim()}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            let resultCountry =
              res.data.result.country === "England"
                ? "United Kingdom"
                : res.data.result.country;

            if (this.state.address.country === resultCountry)
              this.dbValidAction();
            else {
              this.setState({ error_postal_code: "Invalid Post Code" });
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          this.setState({ error_postal_code: "Invalid Post Code" });
        });
    else this.dbValidAction();
  };

  onChangeHandler = (e) => {
    if (e.target.name === "street_or_house_no") {
      let tempAddress = {
        ...this.state.address,
        street_or_house_no: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "city") {
      let tempAddress = {
        ...this.state.address,
        city: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "postal_code") {
      let tempAddress = {
        ...this.state.address,
        postal_code: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else if (e.target.name === "country") {
      let tempAddress = {
        ...this.state.address,
        country: e.target.value,
      };
      this.setState({ address: tempAddress });
    } else this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Who is the certificate provider?
        </h1>
        <div
          className="alert alert-info"
          role="alert"
          style={{
            fontSize: "1.16rem",
            display: "block",
            margin: "2rem 0rem",
            padding: "2rem 2rem",
          }}
        >
          <p>Every LPA must have a certificate provider.</p>
          <p>
            The certificate provider is an impartial person who helps to protect
            the donor’s interests.
          </p>
          <p>
            The certificate provider should discuss the LPA with the donor to
            make sure:
            <ul style={{ margin: "2rem 0rem", marginTop: "0.5rem" }}>
              <li>the donor understands their LPA</li>
              <li>
                no one is putting the donor under pressure to make the LPA
              </li>
            </ul>
          </p>
          <p>The certificate provider signs the LPA after the donor.</p>
          <p>
            The certificate provider must:
            <ul style={{ margin: "2rem 0rem", marginTop: "0.5rem" }}>
              <li>be 18 years old or over</li>
              <li>have mental capacity</li>
              <li>
                have known the donor personally for at least 2 years, or have
                relevant professional skills and expertise (for example, the
                donor’s GP or solicitor)
              </li>
            </ul>
          </p>
          <p>
            The certificate provider should:
            <ul style={{ margin: "2rem 0rem", marginTop: "0.5rem" }}>
              <li>be 18 years old or over</li>
              <li>
                be the kind of person who speaks out if anything is wrong{" "}
              </li>
              <li>be impartial</li>
            </ul>
          </p>
        </div>

        <Divider />

        <form onSubmit={this.onSubmitHandle}>
          <label
            htmlFor="title"
            className="col-form-label col-form-label required-field"
            style={{ fontWeight: "bold" }}
          >
            Title
          </label>
          <select
            id="title"
            className="form-control"
            value={this.state.title}
            onChange={this.onChangeHandler}
            name="title"
            required
          >
            <option value="" disabled hidden>
              Title
            </option>
            {this.titleList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>

          <label
            htmlFor="firstName"
            className="col-form-label col-form-label required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="First Name"
            onChange={this.onChangeHandler}
            value={this.state.first_name}
            name="first_name"
            required
          />

          <label
            htmlFor="lastname"
            className="col-form-label col-form-label  required-field"
            style={{ marginTop: "1rem", fontWeight: "bold" }}
          >
            Last Name
          </label>

          <input
            type="text"
            className="form-control"
            id="lastname"
            placeholder="Last Name"
            onChange={this.onChangeHandler}
            value={this.state.last_name}
            name="last_name"
            required
          />

          <label
            htmlFor="address"
            className="required-field col-form-label col-form-label"
            style={{
              marginTop: "2.8rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            Address:
          </label>

          {/* <textarea
            type="textarea"
            className="form-control"
            id="address"
            placeholder="Address"
            onChange={this.onChangeHandler}
            value={this.state.address}
            name="address"
            required
          /> */}
          <div id="address" style={{ marginLeft: "0rem" }}>
            <label
              htmlFor="streetOrHouseNo"
              className="col-form-label col-form-label"
              style={{ marginTop: "0rem", fontWeight: "bold" }}
            >
              Street/House No.
            </label>

            <input
              type="text"
              className="form-control"
              id="streetOrHouseNo"
              placeholder="Street/House No"
              onChange={this.onChangeHandler}
              required
              value={this.state.address.street_or_house_no || ""}
              name="street_or_house_no"
            />

            <label
              htmlFor="city"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              City
            </label>

            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="City"
              onChange={this.onChangeHandler}
              required
              value={this.state.address.city || ""}
              name="city"
            />

            <label
              htmlFor="postalCode"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              Postal Code
            </label>
            <div>
              {this.state.error_postal_code === "" ? (
                <span></span>
              ) : (
                <span style={{ color: "red" }}>
                  {this.state.error_postal_code}
                </span>
              )}
            </div>

            <input
              type="text"
              className="form-control"
              id="postalCode"
              placeholder="Postal Code"
              onChange={this.onChangeHandler}
              required
              value={this.state.address.postal_code || ""}
              name="postal_code"
            />

            <label
              htmlFor="country"
              className="col-form-label col-form-label"
              style={{ marginTop: "0.5rem", fontWeight: "bold" }}
            >
              Country
            </label>

            <select
              id="country"
              className="form-control"
              value={this.state.address.country}
              onChange={this.onChangeHandler}
              name="country"
              required
            >
              <option value="" disabled hidden>
                Country
              </option>
              {this.countryList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <button className=" will-next-button">Save And Proceed</button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinancialLpaCertificateProvider: (certificateProvider) =>
      dispatch({
        type: actionTypes.SET_FINANCIAL_LPA_CERTIFICATE_PROVIDER,
        payload: {
          certificateProvider: certificateProvider,
        },
      }),
  };
};
const mapStateToProps = (state) => {
  return {
    certificateProvider: state.financial_lpa.certificateProvider,
    currentUser: state.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateProvider);
