import React, { Component } from "react";
import { Divider } from "semantic-ui-react";

export class PricingBody extends Component {


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="body-container">
                <div
                    className="aboutus-section-heading"
                    style={{ marginBottom: "5rem", wordSpacing: "1.1rem" }}
                >
                    Pricing Policy
                </div>
                <div className="tnc-list">
                    <span className="tnc-list-description">
                        The price for standard single wils and standard double wills are fixed. However, for complex matters such as litigation, probate, divorce, etc we charge on a time spent on your matter basis. Our charges are based on Law Society guidelines for solicitors as published on the <a href="https://www.lawsociety.org.uk"> Law Society website</a>.
                    <p/>
                        Grade A, Grade B, Grade C, and Grade D London intermediate rate dependent on the amount of experience of the solicitor or caseworker allocated to your case. Further details can be found on the <a href={"https://www.gov.uk/guidance/solicitors-guideline-hourly-rates"}> government website.</a>

                    <p/> <p/>
                        In addition, a single home visits within one hour travel time costs £50. If travel to and from the clients home is more than one hour, then the cost £50/hour.
                         <p/><p/>
                        Our fess quoted are VAT exclusive. All prices are our fees plus VAT at 20%, whether fixed fee for such items as Will or Lasting Power of Attorney or hourly rate of Grade A Solicitors which is £267/hour plus VAT. Our fees do not include Court fees, HM Land registry fees, Counsel fees or Probate Registry fees.  These are commonly know as disbursements and they have to be paid for in addition to our fees. Depending upon the nature of your specific instructions you shall be advised in our client care letter what the likely disbursements costs are.
                    </span>
                </div>
            </div>
        );
    }
}

export default PricingBody;
